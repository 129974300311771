import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
}

export type AdBlueTanksLevel = {
  __typename?: 'AdBlueTanksLevel'
  adblue1Level?: Maybe<Scalars['Float']>
  adblue1LevelPercentage?: Maybe<Scalars['Float']>
  adblue2Level?: Maybe<Scalars['Float']>
  adblue2LevelPercentage?: Maybe<Scalars['Float']>
}

export type AdBlueTanksLevelTimeStamp = {
  __typename?: 'AdBlueTanksLevelTimeStamp'
  adblue1Level?: Maybe<Scalars['String']>
  adblue1LevelPercentage?: Maybe<Scalars['String']>
  adblue2Level?: Maybe<Scalars['String']>
  adblue2LevelPercentage?: Maybe<Scalars['String']>
}

export type Agreement = {
  __typename?: 'Agreement'
  allAgreementLocations: Array<AgreementLocation>
  amountOfAssets?: Maybe<Scalars['Int']>
  billingType?: Maybe<Scalars['String']>
  currency?: Maybe<Scalars['String']>
  customerContact?: Maybe<SrContactInfo>
  customerPoNumber?: Maybe<Scalars['String']>
  endDate?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  kcContact?: Maybe<SrContactInfo>
  location?: Maybe<SrServiceLocationOutput>
  name?: Maybe<Scalars['String']>
  number?: Maybe<Scalars['String']>
  serviceProducts: Array<ServiceProduct>
  startDate?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  totalValue?: Maybe<Scalars['Float']>
  type?: Maybe<Scalars['String']>
}

export type AgreementAppFilterBy = {
  __typename?: 'AgreementAppFilterBy'
  address?: Maybe<Scalars['String']>
  id: Scalars['String']
  name: Scalars['String']
}

export enum AgreementAppFilterCategory {
  AgreementLocation = 'agreementLocation',
  AgreementName = 'agreementName',
  AssetName = 'assetName',
  ServiceProduct = 'serviceProduct',
  ServiceStatus = 'serviceStatus',
}

export type AgreementAppFilterOptionsReturn = {
  __typename?: 'AgreementAppFilterOptionsReturn'
  edges?: Maybe<Array<AgreementAppFilteringOptionsEdge>>
}

export type AgreementAppFilteringOption = {
  __typename?: 'AgreementAppFilteringOption'
  filterBy: AgreementAppFilterCategory
  options?: Maybe<Array<AgreementAppFilterBy>>
}

export type AgreementAppFilteringOptions = {
  __typename?: 'AgreementAppFilteringOptions'
  filteringOptions: SingleAgreementViewFilters
}

export type AgreementAppFilteringOptionsEdge = {
  __typename?: 'AgreementAppFilteringOptionsEdge'
  node?: Maybe<AgreementAppFilteringOptions>
}

export type AgreementLocation = {
  __typename?: 'AgreementLocation'
  customerId?: Maybe<Scalars['String']>
  id: Scalars['String']
  name: Scalars['String']
}

export type AgreementOverview = {
  __typename?: 'AgreementOverview'
  allAgreementLocations: Array<AgreementLocation>
  amountOfAssets?: Maybe<Scalars['Int']>
  amountOfServiceProducts?: Maybe<Scalars['Int']>
  currency?: Maybe<Scalars['String']>
  endDate?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  location?: Maybe<SrServiceLocationOutput>
  name?: Maybe<Scalars['String']>
  number?: Maybe<Scalars['String']>
  startDate?: Maybe<Scalars['String']>
  totalValue?: Maybe<Scalars['Float']>
  type?: Maybe<Scalars['String']>
}

export type AgreementsForLocationEdge = {
  __typename?: 'AgreementsForLocationEdge'
  cursor: Scalars['String']
  node?: Maybe<AgreementOverview>
}

export type AgreementsForLocationReturn = {
  __typename?: 'AgreementsForLocationReturn'
  edges?: Maybe<Array<AgreementsForLocationEdge>>
  pageInfo?: Maybe<PageInfo>
}

export type Alert = {
  __typename?: 'Alert'
  alertId?: Maybe<Scalars['String']>
  alertLocation?: Maybe<Scalars['String']>
  assembly?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  durationMs?: Maybe<Scalars['Int']>
  equipment?: Maybe<Scalars['String']>
  flickeringCounter?: Maybe<Scalars['Int']>
  functionalGroup?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  timestamp?: Maybe<Scalars['String']>
}

export type AlertCountReturn = {
  __typename?: 'AlertCountReturn'
  alertCounts?: Maybe<Array<Maybe<AlertCountV3>>>
}

export type AlertCountV3 = {
  __typename?: 'AlertCountV3'
  alertId?: Maybe<Scalars['String']>
  count?: Maybe<Scalars['Int']>
  description?: Maybe<Scalars['String']>
  totalDurationMs?: Maybe<Scalars['Int']>
}

export type AlertCountsResult = AlertCountReturn | PermissionDeniedError

export type AlertFilterOptions = {
  __typename?: 'AlertFilterOptions'
  alertId?: Maybe<Array<Maybe<Scalars['String']>>>
  alertLocation?: Maybe<Array<Maybe<Scalars['String']>>>
  assembly?: Maybe<Array<Maybe<Scalars['String']>>>
  description?: Maybe<Array<Maybe<Scalars['String']>>>
  equipment?: Maybe<Array<Maybe<Scalars['String']>>>
  status?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type AlertsEdge = {
  __typename?: 'AlertsEdge'
  cursor: Scalars['String']
  node: Alert
}

export type AlertsResult = AlertsReturn | PermissionDeniedError

export type AlertsReturn = {
  __typename?: 'AlertsReturn'
  dataExceeded?: Maybe<Scalars['Boolean']>
  edges: Array<AlertsEdge>
  filterOptions?: Maybe<AlertFilterOptions>
  pageInfo?: Maybe<PageInfo>
  totalAlertsCount?: Maybe<Scalars['Int']>
}

export type AlphaAssetId = {
  __typename?: 'AlphaAssetId'
  /**   Crane mappings. Documentation: https://confluence.konecranes.com/display/DPDD/Asset+ID+mapping+table. */
  craneMappings?: Maybe<Array<Maybe<CraneMapping>>>
  /**   Datalake ID (e.g. ECU_1, CRAU_12312) */
  id: Scalars['String']
}

export type AnyAssetId = AlphaAssetId | AssetId

export type Asset = {
  __typename?: 'Asset'
  activeZuoraSubscriptions?: Maybe<Array<Maybe<Scalars['String']>>>
  adBlueFuelCapacity?: Maybe<Scalars['String']>
  adBlueFuelCapacitySecondary?: Maybe<Scalars['String']>
  agreementServiceProductRowIds?: Maybe<Array<Maybe<Scalars['String']>>>
  alerts?: Maybe<TotalAlertsForAssetResult>
  assetCreationDate?: Maybe<Scalars['String']>
  assetCriticality?: Maybe<Scalars['String']>
  assetDescription?: Maybe<Scalars['String']>
  assetDesignClass?: Maybe<Scalars['String']>
  assetDocuments?: Maybe<AssetDocuments>
  assetEnvironment?: Maybe<Scalars['String']>
  assetHubSerialNumber?: Maybe<Scalars['String']>
  assetId: Scalars['String']
  assetInstalledDate?: Maybe<Scalars['String']>
  assetManufacturingYear?: Maybe<Scalars['String']>
  assetModel?: Maybe<Scalars['String']>
  assetNumber?: Maybe<Scalars['String']>
  assetProductClassId?: Maybe<Scalars['String']>
  assetProductClassName?: Maybe<Scalars['String']>
  assetProductId?: Maybe<Scalars['String']>
  assetProductName?: Maybe<Scalars['String']>
  assetRatedCapacity?: Maybe<Scalars['String']>
  assetSiteLocation1?: Maybe<Scalars['String']>
  assetSiteLocation2?: Maybe<Scalars['String']>
  assetSiteLocation3?: Maybe<Scalars['String']>
  /** Formatted as 'assetSiteLocation3 - assetSiteLocation2 - assetSiteLocation1' */
  assetSiteLocationJoined?: Maybe<Scalars['String']>
  assetStatus?: Maybe<Scalars['String']>
  assetTemperatureMax?: Maybe<Scalars['String']>
  assetTemperatureMin?: Maybe<Scalars['String']>
  components?: Maybe<Array<Maybe<Component>>>
  contacts?: Maybe<ContactInfo>
  contractBeginDate?: Maybe<Scalars['String']>
  contractEndDate?: Maybe<Scalars['String']>
  coordinates?: Maybe<Coordinates>
  craneType?: Maybe<Scalars['String']>
  customerAssetName?: Maybe<Scalars['String']>
  customerId: Scalars['String']
  datalakeId?: Maybe<Scalars['String']>
  dutyClass?: Maybe<Scalars['String']>
  equipmentDeliveryDate?: Maybe<Scalars['String']>
  equipmentId?: Maybe<Scalars['Float']>
  equipmentType?: Maybe<Scalars['String']>
  features?: Maybe<Array<Maybe<Scalars['String']>>>
  fuelCapacity?: Maybe<Scalars['String']>
  handOverDate?: Maybe<Scalars['String']>
  /** Hardware package. Equivalent of feature type in CDL 'ft' */
  hardware?: Maybe<HardwarePackage>
  hasRealTimeSensorData?: Maybe<Scalars['Boolean']>
  heightLift?: Maybe<Scalars['String']>
  heightLiftUnitOfMeasure?: Maybe<Scalars['String']>
  lifttruckModel?: Maybe<Scalars['String']>
  lifttruckModelId?: Maybe<Scalars['String']>
  location?: Maybe<Location>
  locationId: Scalars['String']
  machineGeneration?: Maybe<Scalars['String']>
  machineModel?: Maybe<Scalars['String']>
  manufacturer?: Maybe<Scalars['String']>
  manufacturerName?: Maybe<Scalars['String']>
  metadata?: Maybe<AssetMetadata>
  modemDeliveryDate?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  nickname?: Maybe<Scalars['String']>
  officeId?: Maybe<Scalars['String']>
  openFindings?: Maybe<Array<FindingsAndActionsSummary>>
  openQuotations?: Maybe<Scalars['Int']>
  predictions?: Maybe<Predictions>
  projectId?: Maybe<Scalars['String']>
  /** power source */
  ps?: Maybe<Scalars['String']>
  salesOrderNumber?: Maybe<Scalars['String']>
  scad?: Maybe<Scalars['String']>
  serialNumber?: Maybe<Scalars['String']>
  serviceAgreements?: Maybe<Array<Scalars['String']>>
  serviceProducts?: Maybe<Array<Maybe<AssetServiceProduct>>>
  siebelId?: Maybe<Scalars['String']>
  smartConnectedLTPackages?: Maybe<SmartConnectedLtPackages>
  span?: Maybe<Scalars['String']>
  spanUnitOfMeasure?: Maybe<Scalars['String']>
  technicalInformation?: Maybe<TechnicalInformation>
  truConnect?: Maybe<TruConnect>
  truConnectIndicator?: Maybe<Scalars['Boolean']>
  truConnectIndicatorFlag?: Maybe<Scalars['String']>
  viewId?: Maybe<Scalars['String']>
  voltPhaseHz?: Maybe<Scalars['String']>
  warrantyEndDate?: Maybe<Scalars['String']>
  warrantyLimitHours?: Maybe<Scalars['String']>
  ykcId?: Maybe<Scalars['String']>
  ykcPrioId?: Maybe<Scalars['String']>
}

export type AssetAlertsArgs = {
  endDate: Scalars['String']
  startDate: Scalars['String']
}

export type AssetPredictionsArgs = {
  endDate: Scalars['String']
  startDate: Scalars['String']
}

export type AssetTruConnectArgs = {
  endDate: Scalars['String']
  startDate: Scalars['String']
  timeZone?: InputMaybe<Scalars['String']>
}

export type AssetAlertsResult = AssetAlertsReturn | PermissionDeniedError

export type AssetAlertsReturn = {
  __typename?: 'AssetAlertsReturn'
  activeAlerts?: Maybe<Scalars['Int']>
  totalAlerts?: Maybe<Scalars['Int']>
}

export type AssetComponent = {
  __typename?: 'AssetComponent'
  /**   Connection ID */
  c?: Maybe<Scalars['String']>
  /**   Component (alias to cn) */
  ci?: Maybe<Scalars['String']>
  /**   Capacity (tons) */
  cp?: Maybe<Scalars['Float']>
  /**   Duty class (Craneportal id) */
  dc?: Maybe<Scalars['Int']>
  /**   Is main */
  im?: Maybe<Scalars['Boolean']>
}

export type AssetCoordinates = {
  __typename?: 'AssetCoordinates'
  /**   Latitude */
  lat?: Maybe<Scalars['Float']>
  /**   Longitude */
  lon?: Maybe<Scalars['Float']>
  /**   Timestamp */
  ts?: Maybe<Scalars['String']>
}

export type AssetCounts = {
  __typename?: 'AssetCounts'
  mhcAssets?: Maybe<Scalars['Int']>
  totalAssets?: Maybe<Scalars['Int']>
  truConnectAssets?: Maybe<Scalars['Int']>
}

export type AssetData = {
  __typename?: 'AssetData'
  /**   AdBlue fuel capacity */
  adblue_cap?: Maybe<Scalars['String']>
  /**   Connection ID */
  c?: Maybe<Scalars['String']>
  /**   Contract end date */
  ced?: Maybe<Scalars['String']>
  /**   Customer Id */
  cid: Scalars['String']
  /**   Components (ce) data from Craneportal */
  components?: Maybe<Array<Maybe<AssetComponent>>>
  /**   Coordinates from IoT stream */
  coordinates?: Maybe<AssetCoordinates>
  /**   CranePortal ID */
  cpid?: Maybe<Scalars['Int']>
  /**   Crane metadata from IoT stream */
  craneMetadata?: Maybe<Array<Maybe<CraneMetadata>>>
  /**   Datalake Id */
  dlid: Scalars['String']
  /**   Hand over date */
  eqdd?: Maybe<Scalars['String']>
  /**   Equipment Id */
  eqid?: Maybe<Scalars['Int']>
  /**   Manufacturer */
  eqma?: Maybe<Scalars['String']>
  /**   Equipment type */
  et?: Maybe<Scalars['String']>
  /**   Hardware features */
  ft?: Maybe<Array<Maybe<Scalars['String']>>>
  /**   Fuel capacity */
  fuel_cap?: Maybe<Scalars['String']>
  /**   Has real time sensor data */
  hd?: Maybe<Scalars['Boolean']>
  /**   Hardware package, only applied for LT's */
  hw?: Maybe<HardwarePackage>
  /**   Location Id */
  lid: Scalars['String']
  /**   Machine generation */
  mgen?: Maybe<Scalars['String']>
  /**   Machine model */
  mm?: Maybe<Scalars['String']>
  /**   Machine model id */
  mm_id?: Maybe<Scalars['String']>
  /**   Nickname */
  ni?: Maybe<Scalars['String']>
  /**   Office Id */
  oi?: Maybe<Scalars['String']>
  /**   Project ID. Truconnect projectID = kdp asset serial number. */
  pi?: Maybe<Scalars['String']>
  /**   Power source */
  ps?: Maybe<Scalars['String']>
  /**   Siebel Id */
  sb?: Maybe<Scalars['String']>
  /**   Asset creation date */
  scad?: Maybe<Scalars['String']>
  /**   Serial number. Also coming from siebel. */
  sn?: Maybe<Scalars['String']>
  /**   Sales order number */
  son?: Maybe<Scalars['String']>
  /**   Truconnect indicator. If some of components have connection ID this is true. */
  tci?: Maybe<Scalars['Boolean']>
  /**   Asset type */
  ty?: Maybe<Scalars['String']>
}

export type AssetDocument = {
  __typename?: 'AssetDocument'
  edges: Array<AssetDocumentEdge>
  filterOptions?: Maybe<AssetDocumentFilterOptions>
  pageInfo: PageInfo
}

export type AssetDocumentEdge = {
  __typename?: 'AssetDocumentEdge'
  cursor: Scalars['String']
  node: Document
}

export type AssetDocumentFilterOption = {
  __typename?: 'AssetDocumentFilterOption'
  count?: Maybe<Scalars['Int']>
  label?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

export type AssetDocumentFilterOptions = {
  __typename?: 'AssetDocumentFilterOptions'
  component?: Maybe<Array<Maybe<AssetDocumentFilterOption>>>
  count?: Maybe<Scalars['Int']>
  languages?: Maybe<Array<Maybe<AssetDocumentFilterOption>>>
  manufacturer?: Maybe<Array<Maybe<AssetDocumentFilterOption>>>
  type?: Maybe<Array<Maybe<AssetDocumentFilterOption>>>
}

export type AssetDocuments = {
  __typename?: 'AssetDocuments'
  assetTypeDocuments?: Maybe<AssetDocument>
  fluidDocuments?: Maybe<AssetFluidDocument>
}

export type AssetEventsSummaryInput = {
  /**   Active faults timeframe length in milliseconds. Default value is 4000 hours. */
  activeFaultsTimeframeLengthInHours?: InputMaybe<Scalars['Int']>
  /**   Datalake ID */
  dlid: Scalars['String']
  /**   Timeframe end time epoch */
  endTime: Scalars['String']
  /**   Timeframe start time epoch */
  startTime: Scalars['String']
}

export type AssetFilterOptions = {
  __typename?: 'AssetFilterOptions'
  craneTypes: Array<FilterOption>
  serviceAssetFilterData?: Maybe<Array<ServiceAssetFilterData>>
}

export type AssetFluidDocument = {
  __typename?: 'AssetFluidDocument'
  edges: Array<AssetFluidDocumentEdge>
  filterOptions?: Maybe<AssetDocumentFilterOptions>
  pageInfo: PageInfo
}

export type AssetFluidDocumentEdge = {
  __typename?: 'AssetFluidDocumentEdge'
  cursor: Scalars['String']
  node: FluidDocument
}

export type AssetGps = {
  __typename?: 'AssetGps'
  lat?: Maybe<Scalars['String']>
  lon?: Maybe<Scalars['String']>
  timestamp?: Maybe<Scalars['String']>
}

export type AssetId = {
  __typename?: 'AssetId'
  /**   customer id */
  cid: Scalars['String']
  /**   Crane mappings. Documentation: https://confluence.konecranes.com/display/DPDD/Asset+ID+mapping+table. */
  craneMappings?: Maybe<Array<Maybe<CraneMapping>>>
  /**   Datalake ID (e.g. ECU_1, CRAU_12312) */
  id: Scalars['String']
  /**   location id */
  lid: Scalars['String']
}

export type AssetIdInput = {
  /**   Customer id */
  cid: Scalars['String']
  /**   Datalake ID */
  id: Scalars['String']
  /**   location id */
  lid: Scalars['String']
}

export enum AssetIntegration {
  AssetHub = 'AssetHub',
  Cdl = 'CDL',
  Kdp = 'KDP',
  Uaa = 'UAA',
}

export type AssetLoadCap = {
  __typename?: 'AssetLoadCap'
  max_ld?: Maybe<Scalars['String']>
  max_lor?: Maybe<Scalars['String']>
  max_or?: Maybe<Scalars['String']>
}

export type AssetMeasurement = {
  __typename?: 'AssetMeasurement'
  /**   Timestamp */
  ts?: Maybe<Scalars['String']>
  /**   Value */
  v?: Maybe<Scalars['Float']>
  /**   Measurement ID */
  vid: Scalars['String']
  /**   Value map */
  vm?: Maybe<Array<Maybe<MeasurementMapValueItem>>>
}

export type AssetMeasurementInput = {
  /**   Component. Default is ROOT. */
  cn?: InputMaybe<Scalars['String']>
  /**   Datalake ID */
  dlid: Scalars['String']
  /**   Timeframe end time epoch (UTC). */
  endTime: Scalars['String']
  /**   Timeframe start time epoch (UTC). */
  startTime: Scalars['String']
  /**
   *  Start time direction. Default is EARLIER.
   * Use case example: timeframe 1.1.-31.12., data: [{"1.7".: 100}, {"31.12.": 200}
   * Result: startTimeDirection=EARLIER => 200
   * Result: startTimeDirection=LATER => 200-100 = 100
   */
  startTimeDirection?: InputMaybe<TimeDirection>
  /**   Measurement ID V_1 / V_3 */
  vid: Scalars['String']
}

export type AssetMetadata = {
  __typename?: 'AssetMetadata'
  componentNames?: Maybe<Array<Maybe<Scalars['String']>>>
  gps?: Maybe<AssetGps>
  load_cap?: Maybe<AssetLoadCap>
  modemIpAddress?: Maybe<Scalars['String']>
}

export type AssetOwner = {
  customerId: Scalars['String']
  locationIds?: InputMaybe<Array<Scalars['String']>>
}

export type AssetServicePlan = {
  __typename?: 'AssetServicePlan'
  assetSiebelId: Scalars['String']
  serviceProductMonths?: Maybe<Array<ServiceProductMonth>>
  serviceProductPlans: Array<ServiceProductPlan>
}

export type AssetServiceProduct = {
  __typename?: 'AssetServiceProduct'
  agreementKey?: Maybe<Scalars['String']>
  agreementName?: Maybe<Scalars['String']>
  agreementNumber?: Maybe<Scalars['String']>
  assetId?: Maybe<Scalars['String']>
  kcEmail?: Maybe<Scalars['String']>
  kcFirstName?: Maybe<Scalars['String']>
  kcLastName?: Maybe<Scalars['String']>
  kcPhoneNumber?: Maybe<Scalars['String']>
  productName?: Maybe<Scalars['String']>
  serviceProductId?: Maybe<Scalars['String']>
  serviceProgramKey?: Maybe<Scalars['String']>
}

export type AssetShockDetails = {
  __typename?: 'AssetShockDetails'
  /**   Driving shock items (speed 2km/h or more) */
  drivingData?: Maybe<Array<Maybe<ShockData>>>
  /**   latest timestamp for summary query, shocksSummary. This is not returned for single asset query (shockdetails). */
  latestTimestamp?: Maybe<Scalars['String']>
  /**   Load handling shock items (speed less than 2km/h) */
  loadHandlingData?: Maybe<Array<Maybe<ShockData>>>
  /**   most severe load handling and driving shocks for ShocksSummary query. This is not returned for single asset query (shockdetails). */
  mostSevereShocks?: Maybe<ShockType>
  /**   total number of shocks in each category: total, load handling and driving. This is for ShocksSummary query. sThis is not returned for single asset query (shockdetails). */
  shockCounts?: Maybe<ShockCounts>
}

export enum AssetSummaryType {
  Eforklift = 'EFORKLIFT',
  Lifttruck = 'LIFTTRUCK',
  Mhc = 'MHC',
}

export enum AssetType {
  Agd = 'AGD',
  Agv = 'AGV',
  Armg = 'ARMG',
  Artg = 'ARTG',
  Asc = 'ASC',
  Asprinter = 'ASPRINTER',
  Eforklift = 'EFORKLIFT',
  Forklift = 'FORKLIFT',
  Lifttruck = 'LIFTTRUCK',
  Mhc = 'MHC',
  Rmg = 'RMG',
  Rtg = 'RTG',
  Sc = 'SC',
  Shipyard = 'SHIPYARD',
  Sts = 'STS',
}

export type AssetUtilization = {
  __typename?: 'AssetUtilization'
  statusHours?: Maybe<StatusHours>
  timestamp?: Maybe<Scalars['String']>
}

export type AssetUtilizationHistory = {
  __typename?: 'AssetUtilizationHistory'
  label?: Maybe<Scalars['String']>
  statusHours?: Maybe<StatusHours>
}

export type AssetUtilizationHistoryResult = AssetUtilizationHistory | PermissionDeniedError

export type AssetUtilizationResult = AssetUtilization | PermissionDeniedError

export type AssetUtilizationSummary = {
  __typename?: 'AssetUtilizationSummary'
  /**   Total operating hours */
  operatingTotal?: Maybe<Scalars['Float']>
  /**   Total selected hours */
  timeSelectedTotal?: Maybe<Scalars['Float']>
  /**   Asset utilization hours per state */
  utilizationPerState?: Maybe<ValuePerState>
}

export type Attachment = {
  __typename?: 'Attachment'
  attachmentId: Scalars['String']
  attachmentName: Scalars['String']
  fileExtension: Scalars['String']
  offerVersion?: Maybe<Scalars['String']>
  salesCaseId: Scalars['String']
  viewDate: Scalars['String']
}

export type AverageActiveMeasurementInput = {
  /**   Component. Default is ROOT. */
  cn?: InputMaybe<Scalars['String']>
  /**   Datalake ID */
  dlid: Scalars['String']
  /**   Timeframe end time epoch. */
  endTime: Scalars['String']
  /**   Timeframe start time epoch. */
  startTime: Scalars['String']
  /**   Timezone (IANA time zone name). Default is UTC. */
  timeZone?: InputMaybe<Scalars['String']>
  /**   Measurement ID V_1 / V_3 */
  vid: Scalars['String']
}

export type BatteryStatus = {
  __typename?: 'BatteryStatus'
  batteryScaledStateOfChargeLatest?: Maybe<Scalars['Float']>
  estimationOfMachineAutonomyInTimeLatest?: Maybe<Scalars['Float']>
  timestamp?: Maybe<Scalars['String']>
}

export type BatteryStatusHistory = {
  __typename?: 'BatteryStatusHistory'
  batteryScaledStateOfCharge?: Maybe<Scalars['Float']>
  estimationOfMachineAutonomyInTime?: Maybe<Scalars['Float']>
  label?: Maybe<Scalars['String']>
}

export type BatteryStatusHistoryResult = BatteryStatusHistory | PermissionDeniedError

export type BatteryStatusResult = BatteryStatus | PermissionDeniedError

export type BmuKpis = {
  __typename?: 'BmuKpis'
  /**   Air gap status */
  airGapStatus: ComponentConditionStatus
  /**   Monthly change (reduction) of air gap condition percentage */
  airGapStatusMonthlyChange?: Maybe<Scalars['Float']>
  /**   Boost time (ms) */
  boostTime: ComponentUsage
  /**   Component name */
  cn: Scalars['String']
  /**   Latest measurement update (epoch) */
  latestUpdate?: Maybe<Scalars['String']>
}

export type BmuTrend = {
  __typename?: 'BmuTrend'
  /**   Average BMU air gap percentage trend periods (%) */
  averageAirGapPercentageTrends: Array<TrendPeriod>
  /**   Component name */
  cn: Scalars['String']
  /**   Maximum BMU air gap percentage trend periods (%) */
  maximumAirGapPercentageTrends: Array<TrendPeriod>
  /**   Minimum BMU air gap percentage trend periods (%) */
  minimumAirGapPercentageTrends: Array<TrendPeriod>
  /**   BMU condition status limits (%) */
  statusLimits: Array<StatusLimit>
}

export type BrAsset = {
  __typename?: 'BrAsset'
  id?: Maybe<Scalars['String']>
  location?: Maybe<BrServiceLocationOutput>
  name?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  ykcId?: Maybe<Scalars['String']>
}

export type BrServiceLocation = {
  customerId: Scalars['String']
  locationId: Scalars['String']
  timeZone?: InputMaybe<Scalars['String']>
}

export type BrServiceLocationOutput = {
  __typename?: 'BrServiceLocationOutput'
  customerId: Scalars['String']
  locationId: Scalars['String']
  timeZone?: Maybe<Scalars['String']>
}

export type CarbonCurrent = {
  __typename?: 'CarbonCurrent'
  kgPerLoadCycle?: Maybe<Scalars['Float']>
  operatingHours?: Maybe<Scalars['Float']>
  totalEmissionInCurrentTimeFrame?: Maybe<Scalars['Float']>
  totalEmissionTimeStamp?: Maybe<Scalars['String']>
}

export type CarbonCurrentResult = CarbonCurrent | PermissionDeniedError

export type CarbonFootprint = {
  __typename?: 'CarbonFootprint'
  averageEmissionByOperatingState?: Maybe<EmissionByOperatingState>
  emissionByOperatingState?: Maybe<EmissionByOperatingState>
  emissionPerMeasurement?: Maybe<EmissionPerMeasurement>
  /** Total emissions since startup */
  lifeCycleEmissions?: Maybe<Scalars['Float']>
  /** Average of total emissions since startup */
  lifeCycleEmissionsAvg?: Maybe<Scalars['Float']>
  operatingHours?: Maybe<Scalars['Float']>
  timestamp?: Maybe<Scalars['String']>
}

export type CarbonFootprintHistory = {
  __typename?: 'CarbonFootprintHistory'
  averageEmission?: Maybe<Scalars['Float']>
  emissionByOperatingState?: Maybe<EmissionByOperatingState>
  label?: Maybe<Scalars['String']>
}

export type CarbonFootprintHistoryResult = CarbonFootprintHistory | PermissionDeniedError

export type CarbonFootprintResult = CarbonFootprint | PermissionDeniedError

export type CommonSpectrum = {
  __typename?: 'CommonSpectrum'
  spectrum_0_10?: Maybe<Scalars['Float']>
  spectrum_11_20?: Maybe<Scalars['Float']>
  spectrum_21_30?: Maybe<Scalars['Float']>
  spectrum_31_40?: Maybe<Scalars['Float']>
  spectrum_41_50?: Maybe<Scalars['Float']>
  spectrum_51_60?: Maybe<Scalars['Float']>
  spectrum_61_70?: Maybe<Scalars['Float']>
  spectrum_71_80?: Maybe<Scalars['Float']>
  spectrum_81_90?: Maybe<Scalars['Float']>
  spectrum_91_100?: Maybe<Scalars['Float']>
  spectrum_100?: Maybe<Scalars['Float']>
}

export type Component = {
  __typename?: 'Component'
  capacity?: Maybe<Scalars['Int']>
  componentId?: Maybe<Scalars['String']>
  connectionId?: Maybe<Scalars['String']>
  dutyClass?: Maybe<Scalars['Int']>
  isMain?: Maybe<Scalars['Boolean']>
}

export type ComponentConditionKpis = {
  __typename?: 'ComponentConditionKpis'
  /**   Component name */
  cn: Scalars['String']
  /**   condition status just now (%) */
  conditionStatus: ComponentConditionStatus
  /**   End of serice life time (epoch) */
  endOfServiceLifeTime?: Maybe<Scalars['String']>
  /**   Condition status in last 90 days (%) */
  lastConditionChangePercentage?: Maybe<Scalars['Float']>
  /**   Latest measurement update (epoch) */
  latestUpdate?: Maybe<Scalars['String']>
  /**   Condition status in next 90 days (%) */
  nextConditionStatus: ComponentConditionStatus
  /**   Component type */
  type?: Maybe<Scalars['String']>
  /**   Measurement id */
  vid: Scalars['String']
}

export type ComponentConditionStatus = {
  __typename?: 'ComponentConditionStatus'
  /**
   *  Component category (type)
   * ECU: HOIST | BRAKE | CONTACTOR
   * Other: TROLLEY | BRIDGE | INVERTER
   */
  category?: Maybe<Scalars['String']>
  /**   Component name */
  cn: Scalars['String']
  /**   Status details */
  details?: Maybe<StatusDetails>
  /**   Latest component condition status percentage */
  latestPercentage?: Maybe<Scalars['Float']>
  /**   Component condition status: CRITICAL | WARNING | NORMAL | UNKNOWN */
  status: Scalars['String']
  /**   Latest component condition status timestamp epoch */
  timestamp?: Maybe<Scalars['String']>
  /**   Component type */
  type?: Maybe<Scalars['String']>
  /**   Measurement id */
  vid: Scalars['String']
}

export type ComponentConditionTrend = {
  __typename?: 'ComponentConditionTrend'
  /**   Component name */
  cn: Scalars['String']
  /**   Measurement periods */
  measurementPeriods: Array<TrendPeriod>
  /**   Measurement prediction periods */
  predictionPeriods: Array<TrendPeriod>
  /**   Condition status limits (%) */
  statusLimits: Array<StatusLimit>
  /**   Component type */
  type?: Maybe<Scalars['String']>
  /**   Measurement id */
  vid: Scalars['String']
}

export type ComponentFleet = {
  __typename?: 'ComponentFleet'
  /**   Brake service life status */
  brakeServiceLifeStatus: ComponentConditionStatus
  /**   Component name */
  cn: Scalars['String']
  /**   Contactors service life status */
  contactorServiceLifeStatus: ComponentConditionStatus
  /**   Hoist DWP status */
  hoistDwpStatus: ComponentConditionStatus
}

export type ComponentLoadKpis = {
  __typename?: 'ComponentLoadKpis'
  /**   Average load (t) */
  averageLoadTons: ComponentUsage
  /**   Component name */
  cn: Scalars['String']
  /**   Average cycles per hour */
  cyclesPerHour: ComponentUsage
  /**   Latest measurement update (epoch) */
  latestUpdate?: Maybe<Scalars['String']>
  /**   Overloads attempts */
  overloads: ComponentUsage
  /**   Total cycles */
  totalCycles: ComponentUsage
  /**   Total load (t) */
  totalLoadTons: ComponentUsage
}

export type ComponentLoadSpectrum = {
  __typename?: 'ComponentLoadSpectrum'
  /**   Component name */
  cn: Scalars['String']
  /**   Latest measurement update (epoch) */
  latestUpdate?: Maybe<Scalars['String']>
  /**   Overloads */
  overloads?: Maybe<LoadPercentile>
  /**   Load percentiles */
  percentiles?: Maybe<Array<LoadPercentile>>
}

export type ComponentLoadTrend = {
  __typename?: 'ComponentLoadTrend'
  /**   Average load trend periods (t) */
  averageLoadTrendTons: Array<TrendPeriod>
  /**   Component name */
  cn: Scalars['String']
  /**   Maximum load trend periods (t) */
  maximumLoadTrendTons: Array<TrendPeriod>
  /**   Minimum load trend periods (t) */
  minimumLoadTrendTons: Array<TrendPeriod>
}

export type ComponentMotorKpis = {
  __typename?: 'ComponentMotorKpis'
  /**   Component name */
  cn: Scalars['String']
  /**   Fast speed seconds relative to slow speed seconds (%) */
  fastSpeedPercentage: ComponentUsage
  /**   Latest measurement update (epoch) */
  latestUpdate?: Maybe<Scalars['String']>
  /**   Motor Over ED time (h) */
  overEdHours: ComponentUsage
  /**   Motor over temperatures (pcs) */
  overTemperatures: ComponentUsage
  /**   Slow speed seconds relative to fast speed seconds (%) */
  slowSpeedPercentage: ComponentUsage
}

export type ComponentMotorTrend = {
  __typename?: 'ComponentMotorTrend'
  /**   Component name */
  cn: Scalars['String']
  /**   Over Ed periods */
  overEdHourPeriods: Array<TrendPeriod>
  /**   Over temperature periods */
  overTemperaturePeriods: Array<TrendPeriod>
  /**   Slow speed percentage periods */
  slowSpeedPercentagePeriods: Array<TrendPeriod>
}

export type ComponentPredictionTrend = {
  __typename?: 'ComponentPredictionTrend'
  /** Component name */
  cn?: Maybe<Scalars['String']>
  /** Trend data */
  trendData?: Maybe<Array<Maybe<TrendData>>>
}

export type ComponentRecommendation = {
  __typename?: 'ComponentRecommendation'
  /**   Component name */
  cn: Scalars['String']
  /**   Recommendation id */
  recommendationId: Scalars['String']
  /**   Timestamp */
  ts?: Maybe<Scalars['String']>
}

export enum ComponentState {
  Critical = 'CRITICAL',
  Normal = 'NORMAL',
  Outdated = 'OUTDATED',
  Warning = 'WARNING',
}

export type ComponentStatuses = {
  __typename?: 'ComponentStatuses'
  /** Component name */
  cn?: Maybe<Scalars['String']>
  /** Status details */
  details?: Maybe<StatusDetails>
  /** prediction type */
  predictionType?: Maybe<Scalars['String']>
  /** Component severity state */
  state?: Maybe<ComponentState>
  /** Status value */
  value?: Maybe<Scalars['Float']>
}

export type ComponentTemperatureKpis = {
  __typename?: 'ComponentTemperatureKpis'
  /**   Average temperature (°C) */
  averageTemperatureCelcius: ComponentUsage
  /**   Average temperature (°F) */
  averageTemperatureFahrenheit: ComponentUsage
  /**   Component name */
  cn: Scalars['String']
  /**   Latest measurement update (epoch) */
  latestUpdate?: Maybe<Scalars['String']>
  /**   Maximum temperature (°C) */
  maximumTemperatureCelcius: ComponentUsage
  /**   Maximum temperature (°F) */
  maximumTemperatureFahrenheit: ComponentUsage
  /**   Minimum temperature (°C) */
  minimumTemperatureCelcius: ComponentUsage
  /**   Minimum temperature (°F) */
  minimumTemperatureFahrenheit: ComponentUsage
}

export type ComponentTemperatureTrend = {
  __typename?: 'ComponentTemperatureTrend'
  /**   Average temperature trend periods (°C) */
  averageTemperatureTrendCelcius: Array<TrendPeriod>
  /**   Average temperature trend periods (°F) */
  averageTemperatureTrendFahrenheit: Array<TrendPeriod>
  /**   Component name */
  cn: Scalars['String']
  /**   Maximum temperature trend periods (°C) */
  maximumTemperatureTrendCelcius: Array<TrendPeriod>
  /**   Maximum temperature trend periods (°F) */
  maximumTemperatureTrendFahrenheit: Array<TrendPeriod>
  /**   Minimum temperature trend periods (°C) */
  minimumTemperatureTrendCelcius: Array<TrendPeriod>
  /**   Minimum temperature trend periods (°F) */
  minimumTemperatureTrendFahrenheit: Array<TrendPeriod>
}

export type ComponentTrend = {
  __typename?: 'ComponentTrend'
  /**   Component name */
  cn: Scalars['String']
  /**   Period data */
  periods: Array<TrendPeriod>
}

export type ComponentUsage = {
  __typename?: 'ComponentUsage'
  /**   Component name */
  cn: Scalars['String']
  /**   Design limit */
  designLimit?: Maybe<Scalars['Float']>
  /**   Component usage status: CRITICAL | WARNING | NORMAL | UNKNOWN */
  status?: Maybe<Scalars['String']>
  /**   Timeframe value */
  timeframe?: Maybe<Scalars['Float']>
}

export type ComponentUsageKpis = {
  __typename?: 'ComponentUsageKpis'
  /**   Component name */
  cn: Scalars['String']
  /**   Hoist jogging events */
  hoistJoggings: ComponentUsage
  /**   Latest measurement update (epoch) */
  latestUpdate?: Maybe<Scalars['String']>
  /**   Power on hours */
  powerOnHours: ComponentUsage
  /**   Running hours per day */
  runningHoursPerDay: ComponentUsage
  /**   Starts per cycle (deprecated) */
  startsPerCycle?: Maybe<ComponentUsage>
  /**   Starts per hour */
  startsPerHour: ComponentUsage
  /**   Total running hours */
  totalRunningHours: ComponentUsage
  /**   Total starts */
  totalStarts: ComponentUsage
}

export type ConsumptionAndEmissionSummary = {
  __typename?: 'ConsumptionAndEmissionSummary'
  /**   averages */
  avgs?: Maybe<MeasurementSummaryAverage>
  /**   Fuel consumption by state */
  consumptionPerState?: Maybe<ValuePerState>
  /**   Total consuption */
  total?: Maybe<Scalars['Float']>
}

export type ConsumptionByOperatingState = {
  __typename?: 'ConsumptionByOperatingState'
  empty?: Maybe<Scalars['Float']>
  idle?: Maybe<Scalars['Float']>
  laden?: Maybe<Scalars['Float']>
  total?: Maybe<Scalars['Float']>
}

export type Contact = {
  __typename?: 'Contact'
  contactType?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  phoneNumber?: Maybe<Scalars['String']>
}

export type ContactData = {
  __typename?: 'ContactData'
  email?: Maybe<Scalars['String']>
  first_name?: Maybe<Scalars['String']>
  last_name?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
}

export type ContactInfo = {
  __typename?: 'ContactInfo'
  customer?: Maybe<Contact>
  kc?: Maybe<Contact>
}

export type Coordinates = {
  __typename?: 'Coordinates'
  latitude?: Maybe<Scalars['String']>
  longitude?: Maybe<Scalars['String']>
  timestamp?: Maybe<Scalars['String']>
}

export type CostPercentageByServiceCategory = {
  __typename?: 'CostPercentageByServiceCategory'
  categoryName: Scalars['String']
  value: Scalars['Float']
}

export type CostSummary = {
  __typename?: 'CostSummary'
  costSummary: Array<CostPercentageByServiceCategory>
}

export type CostsByAsset = {
  __typename?: 'CostsByAsset'
  assetSiebelId: Scalars['String']
  costs: Array<CostsByServiceCategory>
  total: Scalars['Int']
}

export type CostsByLocation = {
  __typename?: 'CostsByLocation'
  costs: Array<CostsByServiceCategory>
  location: BrServiceLocationOutput
  total: Scalars['Int']
}

export type CostsBySelectedTimeframe = {
  __typename?: 'CostsBySelectedTimeframe'
  byAsset: Array<CostsByAsset>
  byLocation: Array<CostsByLocation>
  total: Scalars['Int']
  totalByCategory: Array<CostsByServiceCategory>
  totalCreditsByCategory: Array<CostsByServiceCategory>
}

export type CostsByServiceCategory = {
  __typename?: 'CostsByServiceCategory'
  categoryName: Scalars['String']
  value: Scalars['Int']
}

export type CostsForLocation = {
  __typename?: 'CostsForLocation'
  costsBySelectedTimeframe: CostsBySelectedTimeframe
  currency?: Maybe<Scalars['String']>
  serviceVisits: Scalars['Int']
  trend: Array<TrendYear>
}

export type CostsForLocationEdge = {
  __typename?: 'CostsForLocationEdge'
  cursor: Scalars['String']
  node?: Maybe<CostsForLocation>
}

export type CostsForLocationReturn = {
  __typename?: 'CostsForLocationReturn'
  edges?: Maybe<Array<CostsForLocationEdge>>
  pageInfo?: Maybe<PageInfo>
}

export type CostsSummaryForLocationEdge = {
  __typename?: 'CostsSummaryForLocationEdge'
  cursor: Scalars['String']
  node?: Maybe<CostSummary>
}

export type CostsSummaryForLocationReturn = {
  __typename?: 'CostsSummaryForLocationReturn'
  edges?: Maybe<Array<CostsSummaryForLocationEdge>>
  pageInfo?: Maybe<PageInfo>
}

export type CraneMapping = {
  __typename?: 'CraneMapping'
  /**   Defines the epoch time until the operation is valid. By default, there is no limit to the end time. */
  endTime?: Maybe<Scalars['String']>
  /**
   *  Operation, e.g. LINK, REPLACE, CLONE, IGNORE.
   * Documentation: https://confluence.konecranes.com/display/DPDD/Asset+ID+mapping+table.
   */
  operation: Scalars['String']
  /**   Defines the epoch time from which the operation is valid. By default, there is no limit to the start time. */
  startTime?: Maybe<Scalars['String']>
  /**   value for the operation */
  value?: Maybe<Scalars['String']>
}

export type CraneMetadata = {
  __typename?: 'CraneMetadata'
  /**   Component name */
  cn?: Maybe<Scalars['String']>
  /**   Component type (component_metadata_type: ropemon, bmu) */
  cn_type?: Maybe<Scalars['String']>
  /**   Capacity (kilograms) */
  cp?: Maybe<Scalars['Int']>
  /**   Duty class (value from IoT stream) */
  dc?: Maybe<Scalars['String']>
}

export type CraneStatistics = {
  __typename?: 'CraneStatistics'
  /**   BMU KPIs */
  bmuKpis?: Maybe<Array<BmuKpis>>
  /**   BMU trends */
  bmuTrends?: Maybe<Array<BmuTrend>>
  /**   Condition trend KPIs */
  conditionKpis?: Maybe<Array<ComponentConditionKpis>>
  /**   Component condition statuses */
  conditionStatuses?: Maybe<Array<ComponentConditionStatus>>
  /**   Condition trends */
  conditionTrends?: Maybe<Array<ComponentConditionTrend>>
  /**   Controller temperature KPIs */
  controllerTemperatureKpis?: Maybe<Array<ComponentTemperatureKpis>>
  /**   Controller temperature trends */
  controllerTemperatureTrends?: Maybe<Array<ComponentTemperatureTrend>>
  /**   Fleet */
  fleet?: Maybe<Array<ComponentFleet>>
  /**   Hoist condition overviews */
  hoistConditionOverviews?: Maybe<Array<HoistConditionOverview>>
  /**   Hoist usage overviews */
  hoistUsageOverviews?: Maybe<Array<HoistUsageOverview>>
  /**   Load KPIs */
  loadKpis?: Maybe<Array<ComponentLoadKpis>>
  /**   Load spectrum */
  loadSpectrums?: Maybe<Array<ComponentLoadSpectrum>>
  /**   Load trends */
  loadTrends?: Maybe<Array<ComponentLoadTrend>>
  /**   Component Motor KPIs */
  motorKpis?: Maybe<Array<ComponentMotorKpis>>
  /**   Component Motor trends (Over temperatures, over ED hours and slow speed hours) */
  motorTrends?: Maybe<Array<ComponentMotorTrend>>
  /**   Recommendations */
  recommendations?: Maybe<Array<ComponentRecommendation>>
  /**   Running hours trends */
  runningHoursTrends?: Maybe<Array<ComponentTrend>>
  /**   Usage KPIs (running hours and starts) */
  usageKpis?: Maybe<Array<ComponentUsageKpis>>
}

export type CraneStatus = {
  __typename?: 'CraneStatus'
  TotalRunningHours?: Maybe<Scalars['Float']>
  TotalRunningHoursTimestamp?: Maybe<Scalars['String']>
  averageEnergyConsumptionPerHour?: Maybe<Scalars['Float']>
  averageEnergyConsumptionPerLoad?: Maybe<Scalars['Float']>
  averageEnergyConsumptionPerMove?: Maybe<Scalars['Float']>
  averageFuelConsumption?: Maybe<Scalars['Float']>
  currentCraneStatus?: Maybe<CurrentCraneStatus>
  currentCraneStatusTimestamp?: Maybe<Scalars['String']>
  driveWithLoad?: Maybe<Scalars['Float']>
  driveWithoutLoad?: Maybe<Scalars['Float']>
  engineOffHours?: Maybe<Scalars['Float']>
  engineOffHoursTimestamp?: Maybe<Scalars['String']>
  fuelOperatingHour?: Maybe<Scalars['Float']>
  fuelUtilizationPerLoad?: Maybe<Scalars['Float']>
  fuelUtilizationPerMove?: Maybe<Scalars['Float']>
  idlingHours?: Maybe<Scalars['Float']>
  idlingHoursCMS?: Maybe<Scalars['Float']>
  idlingHoursTimestamp?: Maybe<Scalars['String']>
  operatingHours?: Maybe<Scalars['Float']>
  operatingHoursTimestamp?: Maybe<Scalars['String']>
  powerOffHours?: Maybe<Scalars['Float']>
  powerOffHoursTimestamp?: Maybe<Scalars['String']>
  runTimePerComponent?: Maybe<Array<Maybe<RunTimePerComponent>>>
  runningHoursSinceInstallation?: Maybe<Scalars['Float']>
  sleepingHours?: Maybe<Scalars['Float']>
  sleepingHoursTimestamp?: Maybe<Scalars['String']>
  standbyHours?: Maybe<Scalars['Float']>
  standbyHoursTimestamp?: Maybe<Scalars['String']>
  startsCountPerComponent?: Maybe<Array<Maybe<StartsCountPerComponent>>>
  totalEnergyConsumption?: Maybe<Scalars['Float']>
  totalEnergyConsumptionSinceInstallation?: Maybe<Scalars['Float']>
  totalEnergyConsumptionTimestamp?: Maybe<Scalars['String']>
  totalFuelConsumption?: Maybe<Scalars['Float']>
  totalFuelConsumptionSinceInstallation?: Maybe<Scalars['Float']>
  totalFuelConsumptionTimestamp?: Maybe<Scalars['String']>
}

export type CraneStatusHistory = {
  __typename?: 'CraneStatusHistory'
  TotalRunningHours?: Maybe<Scalars['Float']>
  averageEnergyConsumptionPerHour?: Maybe<Scalars['Float']>
  averageFuelConsumption?: Maybe<Scalars['Float']>
  driveWithLoad?: Maybe<Scalars['Float']>
  driveWithoutLoad?: Maybe<Scalars['Float']>
  engineOffHours?: Maybe<Scalars['Float']>
  idlingHours?: Maybe<Scalars['Float']>
  idlingHoursCMS?: Maybe<Scalars['Float']>
  label?: Maybe<Scalars['String']>
  operatingHours?: Maybe<Scalars['Float']>
  powerOffHours?: Maybe<Scalars['Float']>
  standbyHours?: Maybe<Scalars['Float']>
  totalEnergyConsumption?: Maybe<Scalars['Float']>
  totalFuelConsumption?: Maybe<Scalars['Float']>
}

export type CraneStatusHistoryResult = CraneStatusHistory | PermissionDeniedError

export type CraneStatusHistorySc = {
  __typename?: 'CraneStatusHistorySC'
  empty?: Maybe<Scalars['Float']>
  idle?: Maybe<Scalars['Float']>
  label?: Maybe<Scalars['String']>
  laden?: Maybe<Scalars['Float']>
  operatingHours?: Maybe<Scalars['Float']>
}

export type CraneStatusResult = CraneStatus | PermissionDeniedError

export type CraneStatusSc = {
  __typename?: 'CraneStatusSC'
  empty?: Maybe<Scalars['Float']>
  emptySinceInstallation?: Maybe<Scalars['Float']>
  idle?: Maybe<Scalars['Float']>
  idleSinceInstallation?: Maybe<Scalars['Float']>
  laden?: Maybe<Scalars['Float']>
  ladenSinceInstallation?: Maybe<Scalars['Float']>
  operatingTotal?: Maybe<Scalars['Float']>
}

export enum CurrentCraneStatus {
  Active = 'Active',
  EngineOff = 'EngineOff',
  Idling = 'Idling',
  PowerOff = 'PowerOff',
  Standby = 'Standby',
}

export type CustomerLocation = {
  /**   Customer id (format: KC_usa_1-12345) */
  cid: Scalars['String']
  /**   Location id (format LT_M12345) */
  lid?: InputMaybe<Scalars['String']>
}

export type DailyStatus = {
  __typename?: 'DailyStatus'
  day: Scalars['String']
  status: Array<Scalars['String']>
}

export type DiagonalShock = {
  __typename?: 'DiagonalShock'
  /**   most severe longitudinal shock */
  longitudinal?: Maybe<Scalars['Float']>
  /**   most severe vertical shock */
  vertical?: Maybe<Scalars['Float']>
}

export type DistributedDiagonalShock = {
  __typename?: 'DistributedDiagonalShock'
  /**   distributed longitudinal shocks */
  longitudinal?: Maybe<DistributedPolarShock>
  /**   distributed vertical shocks */
  vertical?: Maybe<DistributedPolarShock>
}

export type DistributedPolarShock = {
  __typename?: 'DistributedPolarShock'
  /**   distributed negative shocks */
  negative?: Maybe<DistributedValues>
  /**   distributed positive shocks */
  positive?: Maybe<DistributedValues>
}

export type DistributedShockType = {
  __typename?: 'DistributedShockType'
  /**   distributed loadhandling shocks */
  driving?: Maybe<DistributedDiagonalShock>
  /**   distributed loadhandling shocks */
  loadHandling?: Maybe<DistributedDiagonalShock>
}

export type DistributedValues = {
  __typename?: 'DistributedValues'
  /**   average value */
  avg?: Maybe<Scalars['Float']>
  /**   maximum value */
  max?: Maybe<Scalars['Float']>
  /**   minimum value */
  min?: Maybe<Scalars['Float']>
}

export type Document = {
  __typename?: 'Document'
  audiences?: Maybe<Array<Maybe<Scalars['String']>>>
  availabilities?: Maybe<Array<Maybe<Scalars['String']>>>
  brands?: Maybe<Array<Maybe<Scalars['String']>>>
  components?: Maybe<Array<Maybe<Scalars['String']>>>
  created?: Maybe<Scalars['Int']>
  documentOwner?: Maybe<Scalars['String']>
  documentOwnerEmail?: Maybe<Scalars['String']>
  documentOwnerName?: Maybe<Scalars['String']>
  downloadLink?: Maybe<Scalars['String']>
  fileActions?: Maybe<Array<Scalars['String']>>
  fileSize?: Maybe<Scalars['Int']>
  fileType?: Maybe<Scalars['String']>
  filename?: Maybe<Scalars['String']>
  highlights?: Maybe<DocumentHighlights>
  id: Scalars['String']
  keywords?: Maybe<Array<Maybe<Scalars['String']>>>
  language?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  objectType?: Maybe<Scalars['String']>
  product?: Maybe<Scalars['String']>
  publicationType?: Maybe<Scalars['String']>
  salesOrder?: Maybe<Scalars['Int']>
  serialNumber?: Maybe<Scalars['Int']>
  type?: Maybe<Scalars['String']>
}

export type DocumentHighlights = {
  __typename?: 'DocumentHighlights'
  contentParagraphs?: Maybe<Scalars['String']>
  contentTitles?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
}

export type DocumentLocation = {
  customerId: Scalars['String']
  locationId: Scalars['String']
  timeZone?: InputMaybe<Scalars['String']>
}

export enum DocumentType {
  Manual = 'MANUAL',
  ServiceRequest = 'SERVICE_REQUEST',
  ServiceRequestReport = 'SERVICE_REQUEST_REPORT',
  Uploaded = 'UPLOADED',
}

export type EmailSendStatus = {
  __typename?: 'EmailSendStatus'
  status?: Maybe<Scalars['String']>
}

export type EmissionByOperatingState = {
  __typename?: 'EmissionByOperatingState'
  empty?: Maybe<Scalars['Float']>
  idle?: Maybe<Scalars['Float']>
  laden?: Maybe<Scalars['Float']>
  total?: Maybe<Scalars['Float']>
}

export type EmissionPerMeasurement = {
  __typename?: 'EmissionPerMeasurement'
  kgPerKm?: Maybe<Scalars['Float']>
  kgPerLift?: Maybe<Scalars['Float']>
  kgPerTn?: Maybe<Scalars['Float']>
}

export type EncodedDocument = {
  __typename?: 'EncodedDocument'
  fileContents?: Maybe<Scalars['String']>
}

export type EnergyConsumption = {
  __typename?: 'EnergyConsumption'
  average?: Maybe<EnergyConsumptionAverage>
  energyConsumptionTotalByMachineState?: Maybe<EnergyConsumptionTotalByMachineStateValues>
  /** Total energy recovered since startup */
  lifeCycleRecovered?: Maybe<Scalars['Float']>
  /** energy recovered since startup */
  lifeCycleRecoveredAvg?: Maybe<Scalars['Float']>
  /** Total energy usage kWh since startup */
  lifeCycleUsage?: Maybe<Scalars['Float']>
  /** Energy usage average kWh since startup */
  lifeCycleUsageAvg?: Maybe<Scalars['Float']>
  operatingTotal?: Maybe<Scalars['Float']>
  /** Recovered energy average */
  recoveredEnergyAvg?: Maybe<Scalars['Float']>
  /** Recovered energy percentage */
  recoveredEnergyPercentage?: Maybe<Scalars['Float']>
  timestamp?: Maybe<Scalars['String']>
  utilization?: Maybe<EnergyUtilization>
}

export type EnergyConsumptionAverage = {
  __typename?: 'EnergyConsumptionAverage'
  empty?: Maybe<Scalars['Float']>
  idle?: Maybe<Scalars['Float']>
  laden?: Maybe<Scalars['Float']>
  operatingHours?: Maybe<Scalars['Float']>
}

export type EnergyConsumptionHistory = {
  __typename?: 'EnergyConsumptionHistory'
  energyConsumptionAvg?: Maybe<Scalars['Float']>
  energyConsumptionTotalByMachineState?: Maybe<EnergyConsumptionTotalByMachineStateValues>
  label?: Maybe<Scalars['String']>
}

export type EnergyConsumptionHistoryResult = EnergyConsumptionHistory | PermissionDeniedError

export type EnergyConsumptionResult = EnergyConsumption | PermissionDeniedError

export type EnergyConsumptionTotalByMachineStateValues = {
  __typename?: 'EnergyConsumptionTotalByMachineStateValues'
  recoveredEmpty?: Maybe<Scalars['Float']>
  recoveredLaden?: Maybe<Scalars['Float']>
  totalEnergyRecovered?: Maybe<Scalars['Float']>
  totalEnergyUsage?: Maybe<Scalars['Float']>
  usageEmpty?: Maybe<Scalars['Float']>
  usageIdle?: Maybe<Scalars['Float']>
  usageIdleEmptyMoving?: Maybe<Scalars['Float']>
  usageIdleLadenMoving?: Maybe<Scalars['Float']>
  usageIdleStopped?: Maybe<Scalars['Float']>
  usageLaden?: Maybe<Scalars['Float']>
}

export type EnergySuppliedByPowerStation = {
  __typename?: 'EnergySuppliedByPowerStation'
  avgChargeTime?: Maybe<Scalars['Float']>
  avgConsumption?: Maybe<Scalars['Float']>
  avgEnergyChargedInHighRate?: Maybe<Scalars['Float']>
  avgEnergyChargedInLowRate?: Maybe<Scalars['Float']>
  avgOperatingTimePerChargeTimeHighRate?: Maybe<Scalars['Float']>
  calculatedTimeSelectedTotal?: Maybe<Scalars['Float']>
  chargeTimeHighRate?: Maybe<Scalars['Float']>
  chargeTimeLowRate?: Maybe<Scalars['Float']>
  chargeTotal?: Maybe<Scalars['Float']>
  chargingOperationEnergySuppliedByPowerStation?: Maybe<Scalars['Float']>
  energyChargedInHighRate?: Maybe<Scalars['Float']>
  energyChargedInLowRate?: Maybe<Scalars['Float']>
  timestamp?: Maybe<Scalars['String']>
}

export type EnergySuppliedByPowerStationHistory = {
  __typename?: 'EnergySuppliedByPowerStationHistory'
  chargeTotal?: Maybe<Scalars['Float']>
  chargingOperationEnergySuppliedByPowerStation?: Maybe<Scalars['Float']>
  energyChargedInHighRate?: Maybe<Scalars['Float']>
  energyChargedInLowRate?: Maybe<Scalars['Float']>
  label?: Maybe<Scalars['String']>
}

export type EnergySuppliedByPowerStationHistoryResult =
  | EnergySuppliedByPowerStationHistory
  | PermissionDeniedError

export type EnergySuppliedByPowerStationResult =
  | EnergySuppliedByPowerStation
  | PermissionDeniedError

export type EnergyUtilization = {
  __typename?: 'EnergyUtilization'
  liftCount?: Maybe<Scalars['Float']>
  liftLoad?: Maybe<Scalars['Float']>
  travelDistance?: Maybe<Scalars['Float']>
}

export type Error = {
  code?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
}

export type EventData = {
  __typename?: 'EventData'
  /**   Component */
  cn?: Maybe<Scalars['String']>
  /**   Description */
  desc?: Maybe<Scalars['String']>
  /**   Duration */
  dur?: Maybe<Scalars['Float']>
  /**   Event ID */
  eid: Scalars['String']
  /**   Event type id */
  et: Scalars['Int']
  /**   Event type name */
  etn?: Maybe<Scalars['String']>
  /**   Gross load (supported by MHC) */
  gl?: Maybe<Scalars['Float']>
  /**   Heading */
  head?: Maybe<Scalars['Float']>
  /**   Latitude (supported by Lifttruck, MHC) */
  lat?: Maybe<Scalars['Float']>
  /**   Level */
  le: Scalars['Int']
  /**   Lifting gear (supported by MHC) */
  lg?: Maybe<Scalars['String']>
  /**   Longitude (supported by Lifttruck, MHC) */
  lon?: Maybe<Scalars['Float']>
  /**   Merged events */
  me?: Maybe<Scalars['Int']>
  /**   Net load (supported by MHC) */
  nl?: Maybe<Scalars['Float']>
  /**   Operator (supported by MHC) */
  op?: Maybe<Scalars['String']>
  /**   Risk (not needed by Lifttruck?) */
  r?: Maybe<Scalars['Int']>
  /**   Shift name (supported by MHC) */
  sh?: Maybe<Scalars['String']>
  /**   Speed (supported by Lifttruck, MHC) */
  spd?: Maybe<Scalars['Float']>
  /**   Event state */
  st: Scalars['Int']
  /**   Timestamp */
  ts: Scalars['String']
  /**   Description version */
  v?: Maybe<Scalars['String']>
}

export type EventDetails = {
  __typename?: 'EventDetails'
  /**   Event data */
  data?: Maybe<Array<Maybe<EventData>>>
  /**   Event facets */
  facets?: Maybe<Facets>
  /**   Event lifecycle data */
  lifecycleData?: Maybe<Array<Maybe<LifecycleCount>>>
  /**   Total amount of data items for paging */
  totalDataCount?: Maybe<Scalars['Int']>
}

export type EventDetailsDayCounts = {
  __typename?: 'EventDetailsDayCounts'
  /**   Count of events */
  c: Scalars['Float']
}

export type EventDetailsDayCountsFilter = {
  /**   Event Type */
  et: Scalars['Int']
  /**   Event Level */
  le?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  /**   Event risk */
  r?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  /**   Event state */
  st?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
}

export type EventDetailsDayCountsInput = {
  /**   Datalake ID */
  dlid: Scalars['String']
  /**   Timeframe end time epoch */
  endTime: Scalars['String']
  /**   Filter for getting counts based on event type */
  filter: EventDetailsDayCountsFilter
  /**   Timeframe start time epoch */
  startTime: Scalars['String']
}

export type EventDetailsResult = EventDetails | PermissionDeniedError

export type EventIdFacet = {
  __typename?: 'EventIdFacet'
  /**   Count */
  c: Scalars['Int']
  /**   Total duration in milliseconds */
  dur?: Maybe<Scalars['Float']>
  /**   Event type */
  et?: Maybe<Scalars['Int']>
  /**   Event type name */
  etn?: Maybe<Scalars['String']>
  /**   First occurrence epoch */
  first: Scalars['Float']
  /**   Event id */
  id: Scalars['String']
  /**   Last occurrence epoch */
  last: Scalars['Float']
  /**   translation version ids if more than one translation (needed to fetch alert messages) */
  translations?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type EventLifecycle = {
  __typename?: 'EventLifecycle'
  /**   Datalake ID */
  id: Scalars['String']
  /**   Lifecycle event type count */
  lc?: Maybe<Array<LifecycleCount>>
}

export type EventPeriod = {
  __typename?: 'EventPeriod'
  /**   Event counters */
  events: Array<MostFrequentEvent>
  /**   Period date as id */
  id: Scalars['String']
  /**   Date as human readable string */
  label: Scalars['String']
}

export type EventTranslation = {
  __typename?: 'EventTranslation'
  /**   Description */
  desc: Scalars['String']
  /**   Event ID (e.g. 98) and optionally also translation version id (e.g. 98-1232132). */
  eid: Scalars['String']
}

export type EventTranslationDetails = {
  __typename?: 'EventTranslationDetails'
  /**   Description */
  desc?: Maybe<Scalars['String']>
  /**   Event id */
  eid: Scalars['String']
  /**   Possible malfunction or causes */
  pmoc?: Maybe<Scalars['String']>
  /**   Recommended actions */
  ra?: Maybe<Scalars['String']>
  /**   Language version */
  version?: Maybe<Scalars['String']>
}

export enum EventType {
  Quote = 'QUOTE',
  ServiceRequest = 'SERVICE_REQUEST',
}

export type EventsInput = {
  /**   Return items after index */
  after?: InputMaybe<Scalars['Int']>
  /**   Datalake ID */
  dlid: Scalars['String']
  /**   Timeframe end time epoch */
  endTime: Scalars['String']
  /**   Amount of items to return */
  first?: InputMaybe<Scalars['Int']>
  /**   Language as ISO language code (e.g. fi or en-us). If the language is not set, english (en) will be used as a default language. */
  language?: InputMaybe<Scalars['String']>
  /**   Timeframe start time epoch */
  startTime: Scalars['String']
  /**   Timezone (IANA time zone name). Default is UTC. */
  timeZone?: InputMaybe<Scalars['String']>
}

export type EventsSummary = {
  __typename?: 'EventsSummary'
  /**   Datalake ID */
  dlid: Scalars['String']
  /**   Events Summary data. Conditions Monitoring and Alert Notifications subscription required for Customers */
  summaryData?: Maybe<EventsSummaryDataResult>
  /**   Timestamp of the latest faults/alarms data */
  ts?: Maybe<Scalars['String']>
}

export type EventsSummaryData = {
  __typename?: 'EventsSummaryData'
  /**   Unique Active faults by alert id in timeframe specified with activeFaultsTimeframeLength parameter */
  activeFaults: Scalars['Int']
  /**   Alarms in timeframe */
  alarms: Scalars['Int']
  /**   Faults in timeframe */
  faults: Scalars['Int']
}

export type EventsSummaryDataResult = EventsSummaryData | PermissionDeniedError

export type EventsSummaryInput = {
  /**   Active faults timeframe length in milliseconds. Default value is 4000 hours. Not applicable for shocks */
  activeFaultsTimeframeLengthInHours?: InputMaybe<Scalars['Int']>
  /**   Return items after index */
  after?: InputMaybe<Scalars['Int']>
  /**   List of customer IDs (required if dlIds not provided) */
  cidLids?: InputMaybe<Array<InputMaybe<CustomerLocation>>>
  /**   List of Datalake IDs (required if cidLids not provided) */
  dlIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  /**   Timeframe end time epoch (If endTime is not provided current time is used as endTime.) */
  endTime: Scalars['String']
  /**   Amount of items to return (default 20) */
  first?: InputMaybe<Scalars['Int']>
  /**   Filter results by asset power source */
  ps?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  /**   Filter results by asset search text. Filtering is case insensitive. Fields to search against: dlid, serial number (sn), manufacturer (eqma), nickname (ni), machine model (mm), asset type (ty), cid, lid */
  search?: InputMaybe<Scalars['String']>
  /**   Sort order is descending by default. (Sorted based on active faults count.) */
  sortOrder?: InputMaybe<SortOrder>
  /**   Timeframe start time epoch */
  startTime: Scalars['String']
  /**   Timezone (IANA time zone name). Default is UTC. */
  timeZone?: InputMaybe<Scalars['String']>
  /**   Filter results by asset type */
  ty?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type EventsSummaryPage = {
  __typename?: 'EventsSummaryPage'
  /**   Available asset summary crane types in the response. (Needed for minifeed dropdown list.) */
  assetSummaryCraneTypes?: Maybe<Array<Maybe<AssetSummaryType>>>
  /**   List of asset types in the response */
  assetTypes?: Maybe<Array<Maybe<Scalars['String']>>>
  /**   Total assets Count */
  assetsCount?: Maybe<Scalars['Int']>
  /**   Array of event summaries and asset meta data */
  data?: Maybe<Array<Maybe<EventsSummaryWithAssetData>>>
  /**   Has some of the assets coordinates */
  hasSomeOfAssetsCoordinates?: Maybe<Scalars['Boolean']>
}

export type EventsSummaryWithAssetData = {
  __typename?: 'EventsSummaryWithAssetData'
  /**   Asset data */
  asset: AssetData
  /**   Datalake ID */
  dlid: Scalars['String']
  /**   Location data */
  location: LocationData
  /**   Events Summary data. Conditions Monitoring and Alert Notifications subscription required for Customers */
  summaryData?: Maybe<EventsSummaryDataResult>
  /**   Timestamp of the latest faults/alarms data */
  ts?: Maybe<Scalars['String']>
}

export type Facet = {
  __typename?: 'Facet'
  /**   Count */
  c: Scalars['Int']
  /**   Facet id */
  id: Scalars['Int']
  /**   Facet name if available */
  n?: Maybe<Scalars['String']>
}

export type FacetFilter = {
  /**   Component (alias to ci) */
  cn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  /**   Event id */
  eid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  /**   Event type */
  et?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  /**   Level */
  le?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  /**   Risk */
  r?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  /**   Event state */
  st?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
}

export enum FacetType {
  Eid = 'eid',
  Et = 'et',
  Le = 'le',
  R = 'r',
  St = 'st',
}

export type Facets = {
  __typename?: 'Facets'
  /**   Event id */
  eid: Array<Maybe<EventIdFacet>>
  /**   Event type */
  et: Array<Maybe<Facet>>
  /**   Level */
  le: Array<Maybe<Facet>>
  /**   Risk */
  r?: Maybe<Array<Maybe<Facet>>>
  /**   Event state */
  st: Array<Maybe<Facet>>
}

export type FaultsAndAlarmsInput = {
  /**   Active faults timeframe length in milliseconds. Default value is 4000 hours. */
  activeFaultsTimeframeLengthInHours?: InputMaybe<Scalars['Int']>
  /**   List of customer IDs (required if dlIds not provided) */
  cidLids: Array<CustomerLocation>
  /**   Timeframe end time epoch */
  endTime: Scalars['String']
  /**   Timeframe start time epoch */
  startTime: Scalars['String']
}

export type FilterBy = {
  __typename?: 'FilterBy'
  id: Scalars['String']
  name: Scalars['String']
}

export type FilteredBy = {
  filterBy: Scalars['String']
  options?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type FilteringOption = {
  __typename?: 'FilteringOption'
  filterBy: Scalars['String']
  options?: Maybe<Array<FilterBy>>
}

export type FilteringOptions = {
  __typename?: 'FilteringOptions'
  filteringOptions: Array<FilteringOption>
}

export type FilteringOptionsEdge = {
  __typename?: 'FilteringOptionsEdge'
  cursor: Scalars['String']
  node?: Maybe<FilteringOptions>
}

export type FilteringOptionsReturn = {
  __typename?: 'FilteringOptionsReturn'
  edges?: Maybe<Array<FilteringOptionsEdge>>
  pageInfo?: Maybe<PageInfo>
}

export type Filters = {
  filterBy: Scalars['String']
  values: Array<Scalars['String']>
}

export type FindingAndActionComponent = {
  __typename?: 'FindingAndActionComponent'
  componentDescription?: Maybe<Scalars['String']>
  componentNumber?: Maybe<Scalars['String']>
  componentProductSiebelId?: Maybe<Scalars['String']>
}

export type FindingAndActionEvent = {
  __typename?: 'FindingAndActionEvent'
  comment?: Maybe<Scalars['String']>
  componentDescription?: Maybe<Scalars['String']>
  componentNumber: Scalars['String']
  componentProductSiebelId?: Maybe<Scalars['String']>
  componentSiebelId: Scalars['String']
  components: Array<FindingAndActionComponent>
  faultCodeSiebelId?: Maybe<Scalars['String']>
  nextStep?: Maybe<NextStep>
  open: Scalars['Boolean']
  recommendation?: Maybe<Scalars['String']>
  reportedDate?: Maybe<Scalars['String']>
  serviceProductSiebelId?: Maybe<Scalars['String']>
  siebelId: Scalars['String']
  siebelRiskType: YkcRiskType
  taskType: Scalars['String']
  technicianFirstName?: Maybe<Scalars['String']>
  technicianLastName?: Maybe<Scalars['String']>
  ykcRiskType: YkcRiskType
}

export type FindingsAndActionsSummary = {
  __typename?: 'FindingsAndActionsSummary'
  amount: Scalars['Int']
  riskName: Scalars['String']
}

export type FluidDocument = {
  __typename?: 'FluidDocument'
  component?: Maybe<Array<Maybe<Scalars['String']>>>
  customerId: Scalars['String']
  fluidTopicsType: Scalars['String']
  id: Scalars['String']
  language?: Maybe<Scalars['String']>
  locationId: Scalars['String']
  manufacturer?: Maybe<Array<Maybe<Scalars['String']>>>
  mimeType?: Maybe<Scalars['String']>
  publicationDate?: Maybe<Scalars['String']>
  s3Url?: Maybe<Scalars['String']>
  title: Scalars['String']
  type?: Maybe<Scalars['String']>
}

export type FuelConsumption = {
  __typename?: 'FuelConsumption'
  averageConsumptionByOperatingState?: Maybe<ConsumptionByOperatingState>
  consumptionByOperatingState?: Maybe<ConsumptionByOperatingState>
  /** total fuel consumption since startup */
  lifeCycleConsumption?: Maybe<Scalars['Float']>
  /** total averega fuel consumption by total operating hours since startup */
  lifeCycleConsumptionAvg?: Maybe<Scalars['Float']>
  operatingHours?: Maybe<Scalars['Float']>
  timestamp?: Maybe<Scalars['String']>
  utilization?: Maybe<FuelUtilization>
}

export type FuelConsumptionHistory = {
  __typename?: 'FuelConsumptionHistory'
  averageConsumption?: Maybe<Scalars['Float']>
  consumptionByOperatingState?: Maybe<ConsumptionByOperatingState>
  label?: Maybe<Scalars['String']>
}

export type FuelConsumptionHistoryResult = FuelConsumptionHistory | PermissionDeniedError

export type FuelConsumptionHistorySc = {
  __typename?: 'FuelConsumptionHistorySC'
  fuelConsumptionEmpty?: Maybe<Scalars['Float']>
  fuelConsumptionIdle?: Maybe<Scalars['Float']>
  fuelConsumptionLaden?: Maybe<Scalars['Float']>
  label?: Maybe<Scalars['String']>
  totalConsumedDiesel?: Maybe<Scalars['Float']>
}

export type FuelConsumptionResult = FuelConsumption | PermissionDeniedError

export type FuelConsumptionSc = {
  __typename?: 'FuelConsumptionSC'
  dieselTotalConsumption?: Maybe<Scalars['Float']>
  dieselTotalConsumptionSinceInstallation?: Maybe<Scalars['Float']>
  fuelConsumptionEmpty?: Maybe<Scalars['Float']>
  fuelConsumptionIdle?: Maybe<Scalars['Float']>
  fuelConsumptionLaden?: Maybe<Scalars['Float']>
  fuelConsumptionPerKm?: Maybe<Scalars['Float']>
  fuelConsumptionPerKmSinceInstallation?: Maybe<Scalars['Float']>
  fuelConsumptionPerMove?: Maybe<Scalars['Float']>
  fuelConsumptionPerMoveSinceInstallation?: Maybe<Scalars['Float']>
  fuelUtilizationPerTon?: Maybe<Scalars['Float']>
}

export type FuelCurrent = {
  __typename?: 'FuelCurrent'
  adBlueCurrentLevel?: Maybe<Scalars['Float']>
  adBlueCurrentLevelTimestamp?: Maybe<Scalars['String']>
  adBlueTanksCurrentLevel?: Maybe<AdBlueTanksLevel>
  adBlueTanksCurrentLevelTimestamp?: Maybe<AdBlueTanksLevelTimeStamp>
  dieselCurrentLevel?: Maybe<Scalars['Float']>
  dieselCurrentLevelTimestamp?: Maybe<Scalars['String']>
  totalConsumedAdBlueInCurrentTimeFrame?: Maybe<Scalars['Float']>
  totalConsumedAdBlueTank1InCurrentTimeFrame?: Maybe<Scalars['Float']>
  totalConsumedAdBlueTank1TimeStamp?: Maybe<Scalars['String']>
  totalConsumedAdBlueTank2InCurrentTimeFrame?: Maybe<Scalars['Float']>
  totalConsumedAdBlueTank2TimeStamp?: Maybe<Scalars['String']>
  totalConsumedAdBlueTimeStamp?: Maybe<Scalars['String']>
  totalConsumedDieselInCurrentTimeFrame?: Maybe<Scalars['Float']>
  totalConsumedDieselTimeStamp?: Maybe<Scalars['String']>
  utilization?: Maybe<FuelUtilizationPorts>
}

export type FuelCurrentResult = FuelCurrent | PermissionDeniedError

export type FuelHistory = {
  __typename?: 'FuelHistory'
  label?: Maybe<Scalars['String']>
  totalConsumedAbBlue?: Maybe<Scalars['Float']>
  totalConsumedAbBlueTank1?: Maybe<Scalars['Float']>
  totalConsumedAbBlueTank2?: Maybe<Scalars['Float']>
  totalConsumedDiesel?: Maybe<Scalars['Float']>
}

export type FuelHistoryResult = FuelHistory | PermissionDeniedError

export type FuelUtilization = {
  __typename?: 'FuelUtilization'
  liftCount?: Maybe<Scalars['Float']>
  liftLoad?: Maybe<Scalars['Float']>
  travelDistance?: Maybe<Scalars['Float']>
}

export type FuelUtilizationPorts = {
  __typename?: 'FuelUtilizationPorts'
  loadCycleCount?: Maybe<Scalars['Float']>
  loadCycleLoad?: Maybe<Scalars['Float']>
}

export type FullEventsInput = {
  /**   Datalake ID */
  dlid: Scalars['String']
  /**   Timeframe end time epoch */
  endTime: Scalars['String']
  /**   Include end time condition in query in order to filter out data in the last day of the timeframe. False by default. */
  endTimeCondition?: InputMaybe<Scalars['Boolean']>
  /**   Amount of items to return */
  first?: InputMaybe<Scalars['Int']>
  /**   Timeframe start time epoch */
  startTime: Scalars['String']
  /**   Include start time condition in query in order to filter out data in the first day of the timeframe. False by default. */
  startTimeCondition?: InputMaybe<Scalars['Boolean']>
}

export type FullEventsSummaryInput = {
  /**   Active faults timeframe length in milliseconds. Default value is 4000 hours. */
  activeFaultsTimeframeLengthInHours?: InputMaybe<Scalars['Int']>
  /**   List of Datalake IDs (required if cidLids not provided) */
  assetIds?: InputMaybe<Array<InputMaybe<AssetIdInput>>>
  /**   Timeframe end time epoch (If endTime is not provided current time is used as endTime.) */
  endTime: Scalars['String']
  /**   Timeframe start time epoch */
  startTime: Scalars['String']
}

export enum HardwarePackage {
  Basic = 'BASIC',
  Premium = 'PREMIUM',
  PremiumPlus = 'PREMIUM_PLUS',
}

export type HoistConditionOverview = {
  __typename?: 'HoistConditionOverview'
  /**   Component name */
  cn: Scalars['String']
  /**   Hoist DWP status */
  hoistDwpStatus?: Maybe<ComponentConditionStatus>
  /**   Latest measurement update (epoch) */
  latestUpdate?: Maybe<Scalars['String']>
  /**   Critical, warning and three lowest component conditions */
  lowestConditionStatuses: Array<ComponentConditionStatus>
}

export type HoistUsageOverview = {
  __typename?: 'HoistUsageOverview'
  /**   Average load tons */
  averageLoadTons?: Maybe<ComponentUsage>
  /**   Component name */
  cn: Scalars['String']
  /**   Cycles */
  cyclesPerHour?: Maybe<ComponentUsage>
  /**   Latest measurement update (epoch) */
  latestUpdate?: Maybe<Scalars['String']>
  /**   Running hours per day */
  runningHoursPerDay?: Maybe<ComponentUsage>
  /**   Starts per hour */
  startsPerHour?: Maybe<ComponentUsage>
}

export type HydraulicOil = {
  __typename?: 'HydraulicOil'
  particleCounts?: Maybe<ParticleCounts>
  relativeHumidity?: Maybe<HydraulicOilValues>
  remainingUsefulLifetime?: Maybe<Scalars['Float']>
  temperature?: Maybe<HydraulicOilValues>
}

export type HydraulicOilHistory = {
  __typename?: 'HydraulicOilHistory'
  label?: Maybe<Scalars['String']>
  particleCounts?: Maybe<ParticleCountsHistory>
  relativeHumidity?: Maybe<HydraulicOilHistoryValues>
  remainingUsefulLifetime?: Maybe<Scalars['Float']>
  temperature?: Maybe<HydraulicOilHistoryValues>
}

export type HydraulicOilHistoryResult = HydraulicOilHistory | PermissionDeniedError

export type HydraulicOilHistoryValues = {
  __typename?: 'HydraulicOilHistoryValues'
  average?: Maybe<Scalars['Float']>
  maximum?: Maybe<Scalars['Float']>
  minimum?: Maybe<Scalars['Float']>
}

export type HydraulicOilResult = HydraulicOil | PermissionDeniedError

export type HydraulicOilValues = {
  __typename?: 'HydraulicOilValues'
  latest?: Maybe<Scalars['Float']>
  maximum?: Maybe<Scalars['Float']>
  minimum?: Maybe<Scalars['Float']>
}

export type InternalShockInput = {
  /**   Datalake ID */
  dlid: Scalars['String']
  /**   Timeframe end time epoch */
  endTime: Scalars['String']
  /**   Timeframe start time epoch */
  startTime: Scalars['String']
}

export enum IotApiAssetType {
  Bmu = 'BMU',
  Crau = 'CRAU',
  Ecu = 'ECU',
  Eflt = 'EFLT',
  Lt = 'LT',
  Pscms = 'PSCMS',
  Rc = 'RC',
  Sc = 'SC',
  Smt = 'SMT',
}

export type KdpAssetComponentDetails = {
  __typename?: 'KdpAssetComponentDetails'
  assetCapacity?: Maybe<Scalars['String']>
  assetDescription?: Maybe<Scalars['String']>
  assetManufacturingYear?: Maybe<Scalars['String']>
  assetModel?: Maybe<Scalars['String']>
  capacityUom?: Maybe<Scalars['String']>
  dutyClass?: Maybe<Scalars['String']>
  imei?: Maybe<Scalars['String']>
  manufacturerName?: Maybe<Scalars['String']>
  productId: Scalars['String']
  productType?: Maybe<KdpAssetComponentType>
  rowId: Scalars['String']
  serialNumber?: Maybe<Scalars['String']>
}

export type KdpAssetComponentDetailsEdge = {
  __typename?: 'KdpAssetComponentDetailsEdge'
  cursor: Scalars['String']
  node: KdpAssetComponentDetails
}

export type KdpAssetComponentDetailsReturn = {
  __typename?: 'KdpAssetComponentDetailsReturn'
  edges: Array<KdpAssetComponentDetailsEdge>
  pageInfo: PageInfo
}

export enum KdpAssetComponentType {
  Hoist = 'HOIST',
  Modem = 'MODEM',
}

export type KeyOption = {
  algorithm?: InputMaybe<Scalars['String']>
  key: Scalars['String']
}

export type KeyValuePair = {
  __typename?: 'KeyValuePair'
  /**   Column key */
  key: Scalars['String']
  /**   Column value */
  value?: Maybe<Scalars['String']>
}

export type LifecycleCount = {
  __typename?: 'LifecycleCount'
  /**   Count - i.e. event occurrences during lifecycle */
  c: Scalars['Int']
  /**   Event Type */
  et: Scalars['Int']
}

export type Load = {
  __typename?: 'Load'
  avgLoadCycleTime?: Maybe<Scalars['Float']>
  loadCycleCountPerHour?: Maybe<Scalars['Float']>
  loadCycleCountPerOperatingHour?: Maybe<Scalars['Float']>
  loadCyclesCount?: Maybe<Scalars['Int']>
  loadCyclesCountTimestamp?: Maybe<Scalars['String']>
  loadPerCycle?: Maybe<Scalars['Float']>
  loadPerHour?: Maybe<Scalars['Float']>
  loadSpectrum?: Maybe<PortsSpectrum>
  totalLoadCycleTime?: Maybe<Scalars['Float']>
  totalLoadCycleTimeSinceInstallation?: Maybe<Scalars['Float']>
  totalNetLoad?: Maybe<Scalars['Float']>
  totalNetLoadSinceInstallation?: Maybe<Scalars['Float']>
  totalNetLoadTimestamp?: Maybe<Scalars['String']>
}

export type LoadCycleAverage = {
  __typename?: 'LoadCycleAverage'
  /**   Average load cycle in minutes */
  avgLoadCycle?: Maybe<Scalars['Float']>
  /**   Average per hour */
  avgPerHour?: Maybe<Scalars['Float']>
}

export type LoadCycleGroup = {
  __typename?: 'LoadCycleGroup'
  loadCycleCount?: Maybe<Scalars['Float']>
  name?: Maybe<Scalars['String']>
}

export type LoadCycleSummary = {
  __typename?: 'LoadCycleSummary'
  /**   Load cycle averages */
  avgs?: Maybe<LoadCycleAverage>
  /**   Total lifts or containers moved */
  total?: Maybe<Scalars['Float']>
}

export type LoadHistory = {
  __typename?: 'LoadHistory'
  label?: Maybe<Scalars['String']>
  loadCycleCountGrouped?: Maybe<Array<Maybe<LoadCycleGroup>>>
  loadCycleCountPerHour?: Maybe<Scalars['Float']>
  loadCycleCountPerOperatingHour?: Maybe<Scalars['Float']>
  loadCyclesCount?: Maybe<Scalars['Int']>
  totalNetLoad?: Maybe<Scalars['Float']>
}

export type LoadHistoryResult = LoadHistory | PermissionDeniedError

export type LoadLift = {
  __typename?: 'LoadLift'
  avgLoadCycle?: Maybe<Scalars['Float']>
  avgWorkCycle?: Maybe<Scalars['Float']>
  containersMoved?: Maybe<Scalars['Float']>
  containersPerHour?: Maybe<Scalars['Float']>
  lifeCycleContainersMoved?: Maybe<Scalars['Float']>
  lifeCycleTotalLiftCount?: Maybe<Scalars['Float']>
  lifeCycleTotalLiftLoad?: Maybe<Scalars['Float']>
  liftsPerHour?: Maybe<Scalars['Float']>
  loadSpectrum?: Maybe<CommonSpectrum>
  timestamp?: Maybe<Scalars['String']>
  tonsPerHour?: Maybe<Scalars['Float']>
  tonsPerLift?: Maybe<Scalars['Float']>
  totalLiftCount?: Maybe<Scalars['Float']>
  totalLiftLoad?: Maybe<Scalars['Float']>
}

export type LoadLiftHistory = {
  __typename?: 'LoadLiftHistory'
  containersMoved?: Maybe<Scalars['Float']>
  containersMovedByOperatingHour?: Maybe<Scalars['Float']>
  label?: Maybe<Scalars['String']>
  totalLiftCount?: Maybe<Scalars['Float']>
  totalLiftLoad?: Maybe<Scalars['Float']>
}

export type LoadLiftHistoryResult = LoadLiftHistory | PermissionDeniedError

export type LoadLiftResult = LoadLift | PermissionDeniedError

export type LoadLiftedAverage = {
  __typename?: 'LoadLiftedAverage'
  /**   Average per hour */
  avgPerHour?: Maybe<Scalars['Float']>
  /**   Average per lift */
  avgPerLift?: Maybe<Scalars['Float']>
}

export type LoadLiftedSummary = {
  __typename?: 'LoadLiftedSummary'
  /**   Load lifted averages */
  avgs?: Maybe<LoadLiftedAverage>
  /**   Total tons lifted */
  total?: Maybe<Scalars['Float']>
}

export type LoadPercentile = {
  __typename?: 'LoadPercentile'
  /**   Cycles in timeframe */
  cycles?: Maybe<Scalars['Float']>
  /**   Duration in timeframe */
  duration?: Maybe<Scalars['Float']>
  /**   Percentile name */
  name: Scalars['String']
}

export type LoadResult = Load | PermissionDeniedError

export type Location = {
  __typename?: 'Location'
  branchContacts?: Maybe<Array<Contact>>
  brand?: Maybe<Scalars['String']>
  capacityId?: Maybe<Scalars['Int']>
  country?: Maybe<Scalars['String']>
  customerContacts?: Maybe<Array<Contact>>
  customerName?: Maybe<Scalars['String']>
  gcmId?: Maybe<Scalars['String']>
  id: Scalars['String']
  name?: Maybe<Scalars['String']>
  serviceRequestCreationAllowed?: Maybe<Scalars['Boolean']>
  siebelId?: Maybe<Scalars['String']>
  timeZone?: Maybe<Scalars['String']>
}

export type LocationData = {
  __typename?: 'LocationData'
  /**   Branch contacts */
  branch_contact?: Maybe<Array<ContactData>>
  /**   Brand */
  brand?: Maybe<Scalars['String']>
  /**   Customer id */
  cid?: Maybe<Scalars['String']>
  /**   Customer name */
  cun?: Maybe<Scalars['String']>
  /**   Customer contacts */
  cus_contact?: Maybe<Array<ContactData>>
  /**   Country */
  cy?: Maybe<Scalars['String']>
  /**   GCM ID */
  gc?: Maybe<Scalars['String']>
  /**   Location id */
  lid?: Maybe<Scalars['String']>
  /**   name */
  n?: Maybe<Scalars['String']>
  /**   Siebel id */
  sb?: Maybe<Scalars['String']>
  /**   Service request creation allowed */
  sc?: Maybe<Scalars['Boolean']>
  /**   Timezone */
  tz?: Maybe<Scalars['String']>
}

export type MappingTable = {
  __typename?: 'MappingTable'
  /**   contains each row  key column and value column   */
  rows: Array<KeyValuePair>
}

export enum MappingTableEnum {
  UnifiedMeasurements = 'unified_measurements',
}

export type MappingTableInput = {
  /**   Selected key column */
  keyColumn: Scalars['String']
  /**   Mapping table */
  table: MappingTableEnum
  /**   Selected value column */
  valueColumn: Scalars['String']
}

export type Measurement = {
  __typename?: 'Measurement'
  /**   asset metadata */
  asset?: Maybe<AssetData>
  /**   Component */
  cn?: Maybe<Scalars['String']>
  /**   Datalake ID */
  dlid: Scalars['String']
  /**   Values */
  values: Array<AssetMeasurement>
}

export type MeasurementInput = {
  /**   Component. Default is ROOT. */
  cn?: InputMaybe<Scalars['String']>
  /**   Datalake ID */
  dlid: Scalars['String']
  /**
   *  Timeframe timezone end time epoch. Unset to fetch lifecycle value.
   * e.g. 23:59 -> new Date('2023-01-01T23:59Z').getTime().toString()
   * NOTE! Set current time to bypass cache (1 hour).
   */
  endTime?: InputMaybe<Scalars['String']>
  /**
   *  Timeframe timezone start time epoch. Unset to fetch lifecycle value.
   * e.g. 00:00 -> new Date('2023-01-01T00:00Z').getTime().toString()
   */
  startTime?: InputMaybe<Scalars['String']>
  /**
   *  Start time direction. Default is EARLIER.
   * Note! LATER excludes first found value from lifecycle value.
   */
  startTimeDirection?: InputMaybe<TimeDirection>
  /**   Timezone (IANA time zone name). Default is UTC. */
  timeZone?: InputMaybe<Scalars['String']>
  /**   list of Measurement IDs V_1 / V_3 */
  vid: Array<Scalars['String']>
}

export type MeasurementMapValueItem = {
  __typename?: 'MeasurementMapValueItem'
  /**   Value id */
  id: Scalars['String']
  /**   Timestamp */
  ts?: Maybe<Scalars['String']>
  /**   Value */
  v: Scalars['Float']
}

export type MeasurementPeriod = {
  __typename?: 'MeasurementPeriod'
  /**   Period date as id */
  id: Scalars['String']
  /**   Date as human readable string */
  label: Scalars['String']
  /**   Asset measurements */
  values: Array<AssetMeasurement>
}

export type MeasurementStatistics = {
  __typename?: 'MeasurementStatistics'
  /**   Crane statistics */
  crane?: Maybe<CraneStatistics>
  /**   ECU statistics (deprecated) */
  ecu?: Maybe<CraneStatistics>
}

export type MeasurementStatisticsInput = {
  /**   Components. Default is ROOT. */
  cns?: InputMaybe<Array<Scalars['String']>>
  /**   Datalake ID */
  dlid: Scalars['String']
  /**   Timeframe timezone end time epoch */
  endTime: Scalars['String']
  /**   Trend period type */
  periodType?: InputMaybe<PeriodType>
  /**   Timeframe timezone start time epoch */
  startTime: Scalars['String']
  /**   Timezone (IANA time zone name). Default is UTC. */
  timeZone?: InputMaybe<Scalars['String']>
}

export type MeasurementSummary = {
  __typename?: 'MeasurementSummary'
  /**   Asset utilization */
  assetUtilization?: Maybe<AssetUtilizationSummary>
  /**   CO2 emissions */
  co2Emissions?: Maybe<ConsumptionAndEmissionSummary>
  /**   Containers */
  containerLoadCycles?: Maybe<LoadCycleSummary>
  /**   Energy Consumption */
  energyConsumption?: Maybe<ConsumptionAndEmissionSummary>
  /**   Fuel Consumption */
  fuelConsumption?: Maybe<ConsumptionAndEmissionSummary>
  /**   does customer have classical lifttrucks */
  hasClassicalTrucks?: Maybe<Scalars['Boolean']>
  /**   does customer have electrical lifttrucks */
  hasElectricalTrucks?: Maybe<Scalars['Boolean']>
  /**   Lifts */
  liftingLoadCycles?: Maybe<LoadCycleSummary>
  /**   Load lifted */
  loadLifted?: Maybe<LoadLiftedSummary>
  /**   Travel distance */
  travelDistance?: Maybe<TravelDistanceSummary>
}

export type MeasurementSummaryAverage = {
  __typename?: 'MeasurementSummaryAverage'
  /**   Averages per hour */
  avgPerHour?: Maybe<Scalars['Float']>
  /**   Averages per kilometers */
  avgPerKm?: Maybe<Scalars['Float']>
  /**   Averages per lift */
  avgPerLift?: Maybe<Scalars['Float']>
  /**   Averages per lifted tons */
  avgPerTons?: Maybe<Scalars['Float']>
}

export type MeasurementSummaryInput = {
  /**   List of customer IDs */
  cidLids?: InputMaybe<Array<InputMaybe<CustomerLocation>>>
  /**   Timeframe timezone end time epoch */
  endTime: Scalars['String']
  /**   Timeframe timezone start time epoch */
  startTime: Scalars['String']
  /**   Timezone (IANA time zone name). Default is UTC. */
  timeZone?: InputMaybe<Scalars['String']>
}

export type MonthlyCosts = {
  __typename?: 'MonthlyCosts'
  costs: Array<CostsByServiceCategory>
  month: Scalars['String']
  total: Scalars['Int']
}

export type MonthlyStatus = {
  __typename?: 'MonthlyStatus'
  days: Array<DailyStatus>
  month: Scalars['String']
  status: Scalars['String']
}

export type MostFrequentEvent = {
  __typename?: 'MostFrequentEvent'
  /**   count */
  c: Scalars['Int']
  /**   Description (only available for event id group) */
  desc?: Maybe<Scalars['String']>
  /**   Total duration in milliseconds (only available for event id group) */
  dur?: Maybe<Scalars['Float']>
  /**   Event type */
  et?: Maybe<Scalars['Int']>
  /**   Event type name */
  etn?: Maybe<Scalars['String']>
  /**   First occurrence epoch (only available for event id group) */
  first?: Maybe<Scalars['Float']>
  /**   ID */
  id: Scalars['String']
  /**   Last occurrence epoch (only available for event id group) */
  last?: Maybe<Scalars['Float']>
  /**   Name if available */
  n?: Maybe<Scalars['String']>
}

export type MostFrequentEventResult = MostFrequentEvent | PermissionDeniedError

export type MostSevereShocksSummary = {
  __typename?: 'MostSevereShocksSummary'
  longitudinal?: Maybe<Scalars['Float']>
  vertical?: Maybe<Scalars['Float']>
}

export enum NextStep {
  AuthorizedWork = 'AUTHORIZED_WORK',
  Declined = 'DECLINED',
  DoWork = 'DO_WORK',
  Notification = 'NOTIFICATION',
  Quote = 'QUOTE',
  Repaired = 'REPAIRED',
  Reschedule = 'RESCHEDULE',
  ReReported = 'RE_REPORTED',
}

export type PageInfo = {
  __typename?: 'PageInfo'
  after?: Maybe<Scalars['Int']>
  endCursor?: Maybe<Scalars['String']>
  hasNextPage?: Maybe<Scalars['Boolean']>
  hasPreviousPage?: Maybe<Scalars['Boolean']>
  startCursor?: Maybe<Scalars['String']>
}

export type ParticleCounts = {
  __typename?: 'ParticleCounts'
  particleCount4up1?: Maybe<HydraulicOilValues>
  particleCount6up1?: Maybe<HydraulicOilValues>
  particleCount14up1?: Maybe<HydraulicOilValues>
}

export type ParticleCountsHistory = {
  __typename?: 'ParticleCountsHistory'
  particleCount4up1?: Maybe<HydraulicOilHistoryValues>
  particleCount6up1?: Maybe<HydraulicOilHistoryValues>
  particleCount14up1?: Maybe<HydraulicOilHistoryValues>
}

export enum PeriodType {
  Day = 'day',
  Hour = 'hour',
  Month = 'month',
  Quarter = 'quarter',
  Week = 'week',
  Year = 'year',
}

export type PeriodicEvents = {
  __typename?: 'PeriodicEvents'
  /**   Datalake ID */
  dlid: Scalars['String']
  /**   Event periods */
  periods: Array<EventPeriod>
}

export type PeriodicEventsInput = {
  /**   Return items after index */
  after?: InputMaybe<Scalars['Int']>
  /**   Datalake ID */
  dlid: Scalars['String']
  /**   Timeframe end time epoch, set to current time if timeframe is ongoing */
  endTime: Scalars['String']
  /**   Amount of items to return */
  first?: InputMaybe<Scalars['Int']>
  /**   Selected timeframe end time epoch, null if selected timeframe is in the past. Only periodicShocks */
  selectedEndTime?: InputMaybe<Scalars['String']>
  /**   Timeframe start time epoch */
  startTime: Scalars['String']
  /**   Timezone (IANA time zone name). Default is UTC. */
  timeZone?: InputMaybe<Scalars['String']>
}

export type PeriodicMeasurements = {
  __typename?: 'PeriodicMeasurements'
  /**   Component */
  cn: Scalars['String']
  /**   Datalake ID */
  dlid: Scalars['String']
  /**   Measurements (history) */
  periods: Array<MeasurementPeriod>
  /**   Measurement prediction details */
  predictionDetails?: Maybe<Array<Maybe<PredictionDetails>>>
  /**   Predictions (future) */
  predictionPeriods?: Maybe<Array<MeasurementPeriod>>
  /**   Measurement replacement details */
  replacementDetails?: Maybe<Array<Maybe<ReplacementDetails>>>
}

export type PeriodicMeasurementsInput = {
  /**   Return items after index. determines after how many indexes (periods) the results are returned. Default  0. */
  after?: InputMaybe<Scalars['Int']>
  /**   Component name. Default is ROOT if not given. */
  cn?: InputMaybe<Scalars['String']>
  /**   Datalake ID, mandatory. e.g. LT_M10504 */
  dlid: Scalars['String']
  /**   Timeframe timezone end time epoch, mandatory. e.g. "1640995200000" startTime/endTime must be in the same time as timeZone. If timeZone is not entered, the times are assumed to be 'UTC' time. */
  endTime: Scalars['String']
  /**   Amount of items to return. Default is 20 if not given, MAX 85 */
  first?: InputMaybe<Scalars['Int']>
  /**   Timeframe timezone start time epoch, mandatory. e.g. "1609459200000" startTime/endTime must be in the same time as timeZone. If timeZone is not entered, the times are assumed to be 'UTC' time. */
  startTime: Scalars['String']
  /**   Timezone (IANA time zone name). Default is UTC if not given. */
  timeZone?: InputMaybe<Scalars['String']>
  /**   Measurement IDs mandatory, Supports multiple IDs. e.g.["V_16"] or ["V_16","V_19"]. Also calculatedAssetMeasurement vids like ["VO_808_3"] are supported */
  vids: Array<Scalars['String']>
}

export type PeriodicShocks = {
  __typename?: 'PeriodicShocks'
  /**   load handling and driving shocks */
  distributedShocks?: Maybe<DistributedShockType>
  /**   periodic date as id */
  id?: Maybe<Scalars['String']>
  /**   date as human readable string */
  label?: Maybe<Scalars['String']>
}

export type PeriodicShocksResult = PeriodicShocks | PermissionDeniedError

export type Permission = {
  __typename?: 'Permission'
  /**   datalake id */
  assetId?: Maybe<Scalars['String']>
  /**   list of features where user has access to */
  feature?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type PermissionDeniedError = Error & {
  __typename?: 'PermissionDeniedError'
  code?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  subscription?: Maybe<PermissionFeature>
}

export enum PermissionFeature {
  ConditioningMonitoringAndAlertNotifications = 'CONDITIONING_MONITORING_AND_ALERT_NOTIFICATIONS',
  Essentials = 'ESSENTIALS',
  MhcBasicModule = 'MHC_BASIC_MODULE',
  MhcExpertModule = 'MHC_EXPERT_MODULE',
  OperatingAndUsageData = 'OPERATING_AND_USAGE_DATA',
}

export type PermissionInput = {
  /**   List of Datalake IDs (all fields mandatory) */
  assetIds: Array<AssetIdInput>
  /**   namespace where pemission policies are fetched */
  namespace: Scalars['String']
}

export type PortsSpectrum = {
  __typename?: 'PortsSpectrum'
  spectrum_0_10?: Maybe<Scalars['Float']>
  spectrum_10_20?: Maybe<Scalars['Float']>
  spectrum_20_30?: Maybe<Scalars['Float']>
  spectrum_30_40?: Maybe<Scalars['Float']>
  spectrum_40_50?: Maybe<Scalars['Float']>
  spectrum_50_60?: Maybe<Scalars['Float']>
  spectrum_60_70?: Maybe<Scalars['Float']>
  spectrum_70_80?: Maybe<Scalars['Float']>
  spectrum_80_90?: Maybe<Scalars['Float']>
  spectrum_90_100?: Maybe<Scalars['Float']>
  spectrum_100?: Maybe<Scalars['Float']>
}

export type PredictionDetails = {
  __typename?: 'PredictionDetails'
  /**   End of life date (epoch) */
  endOfLifeTime?: Maybe<Scalars['String']>
  /**   Prediction change per day (used to get estimated end of life value in 90 days) */
  predictionChangeDay?: Maybe<Scalars['Float']>
  /**   Measurement ID */
  vid: Scalars['String']
}

export type PredictionTrends = {
  __typename?: 'PredictionTrends'
  /** Hoist prediction trends */
  hoistPredictionTrends?: Maybe<Array<Maybe<ComponentPredictionTrend>>>
  /** 5 lowest prediction trends */
  lowestPredictionTrends?: Maybe<Array<Maybe<ComponentPredictionTrend>>>
  /** Rotating Components prediction trends */
  rotatingComponentsPredictionTrends?: Maybe<Array<Maybe<ComponentPredictionTrend>>>
  /** Wire Rope prediction trends */
  wireRopePredictionTrends?: Maybe<Array<Maybe<ComponentPredictionTrend>>>
}

export type Predictions = {
  __typename?: 'Predictions'
  /** Cache id */
  cacheId?: Maybe<Scalars['String']>
  /** Prediction statuses */
  predictionStatuses?: Maybe<Array<Maybe<ComponentStatuses>>>
  /** Prediction status */
  predictionTrends?: Maybe<PredictionTrends>
}

export type ProductionData = {
  __typename?: 'ProductionData'
  craneOperator?: Maybe<Scalars['String']>
  liftingGear?: Maybe<Scalars['String']>
  netLoad?: Maybe<Scalars['Float']>
  shift?: Maybe<Scalars['String']>
  timestamp?: Maybe<Scalars['String']>
  totalLoad?: Maybe<Scalars['Float']>
}

export type ProductionDataEdge = {
  __typename?: 'ProductionDataEdge'
  cursor: Scalars['String']
  node: ProductionData
}

export type ProductionDataFilterOptions = {
  __typename?: 'ProductionDataFilterOptions'
  craneOperator?: Maybe<Array<Scalars['String']>>
  liftingGear?: Maybe<Array<Scalars['String']>>
  shift?: Maybe<Array<Scalars['String']>>
}

export type ProductionDataResult = PermissionDeniedError | ProductionDataReturn

export type ProductionDataReturn = {
  __typename?: 'ProductionDataReturn'
  dataExceeded?: Maybe<Scalars['Boolean']>
  edges: Array<ProductionDataEdge>
  filterOptions: ProductionDataFilterOptions
  pageInfo?: Maybe<PageInfo>
  totalEventsCount?: Maybe<Scalars['Int']>
  totalLoadSum?: Maybe<Scalars['Float']>
  totalNetLoad?: Maybe<Scalars['Float']>
}

export type Query = {
  __typename?: 'Query'
  agreementsForLocation?: Maybe<AgreementsForLocationReturn>
  alertCounts?: Maybe<AlertCountsResult>
  alerts?: Maybe<AlertsResult>
  asset?: Maybe<Asset>
  assetCounts?: Maybe<AssetCounts>
  /**   (Internal) Asset data */
  assetData?: Maybe<AssetData>
  /**   (Internal) Full events summary */
  assetEventsSummary?: Maybe<EventsSummary>
  /**   assetMeasurement also returns latest cumulative value if vid is requested with VO prefix and 9 suffix */
  assetMeasurement?: Maybe<AssetMeasurement>
  assetPackageSubscriptionEmail?: Maybe<EmailSendStatus>
  /**   (Internal) Asset Shock Details. With Detailed data parameter internally it will process and returns all detailed shockdata */
  assetShockDetails?: Maybe<AssetShockDetails>
  assetsByOwner: AssetReturn
  /**   (Internal) Authorized assets for Alpha portal customers. Utilizes Universal Assets API as data source. */
  authorizedAlphaAssets: Array<AlphaAssetId>
  /**   (Internal) Authorized assets. Parameters: User ID (uid) is CIDM ID (example format: 20021234). Returns assets' Datalake IDs where user has access. */
  authorizedAssets?: Maybe<Array<Maybe<AssetId>>>
  /**
   *  averageActiveMeasurement  Endpoint returns the average change per active day / hour. For example, if the time interval is 1 week, but only 2 days have been driven,
   * the average is calculated as the change in values divided by 2 days instead of 7 days.
   * vid prefix is _10  for day average and _11  for hour average. So measurement or averageActiveMeasurements Endpoint is called with vid VO_33_10 for example
   */
  averageActiveMeasurement?: Maybe<Array<Maybe<AssetMeasurement>>>
  /**
   *  (Internal) Calculated measurements derived from incremental measurement values in operative database.
   * Request with vid prefix VO_ and suffix for calculated value (e.g. VO_805_3).
   * Explanations for suffixes: https://confluence.konecranes.com/display/DPDD/Konecranes+IoT+API
   * The maximum and minimum values are returned with the timestamp of when the maximum/minimum value occurred for the first time. All other calculated values are returned with the timestamp of the last existing measurement value.
   */
  calculatedAssetMeasurement?: Maybe<Array<Maybe<AssetMeasurement>>>
  costsForLocation?: Maybe<CostsForLocationReturn>
  costsSummaryForLocation?: Maybe<CostsSummaryForLocationReturn>
  documentData?: Maybe<EncodedDocument>
  documentUrl?: Maybe<S3Document>
  /**   Event Details. Search is applied to event descriptions. FacetFilter filter results based on facet values. */
  eventDetails?: Maybe<EventDetailsResult>
  /**   (Internal) Event details for one day */
  eventDetailsDay?: Maybe<EventDetails>
  /**   (Internal) Event counts by event type */
  eventDetailsDayCounts?: Maybe<EventDetailsDayCounts>
  /**
   *  (Internal) Event Lifecycle. Counts of Alarm level events, grouped by event type for full asset lifecycle.
   * Supports filters eid, et, le and r of FacetFilter.
   */
  eventLifecycle?: Maybe<EventLifecycle>
  /**   Event translation details. Language as ISO language code (e.g. fi or en-us). Returns English translation as a backup. Returns all event ids as default. Returns latest version as a backup. */
  eventTranslationDetails?: Maybe<Array<EventTranslationDetails>>
  /**   (Internal) Event translations. Language as ISO language code (e.g. fi or en-us). If the language is not set, english (en) will be used as a default language. */
  eventTranslations?: Maybe<Array<Maybe<EventTranslation>>>
  /**
   *  Events summary data for assets found by provided customers, locations and Datalake ids.
   * Only assets that user is allowed to see is included in the result.
   * Can be used to show "alerts minifeed"s in asset fleet page. Sorted based on active faults count.
   */
  eventsSummary?: Maybe<EventsSummaryPage>
  /**
   *  Lifttruck Dashboard KPI for customer and location level fault and alarms KPI.
   * Active faults in last 4000 hours (default), total of faults and alarms
   */
  faultsAndAlarmsKpi?: Maybe<EventsSummaryData>
  filterOptions?: Maybe<SpendFilterOptionsReturn>
  filteringOptions?: Maybe<FilteringOptionsReturn>
  /**   (Internal) Full event Details. */
  fullEventDetails?: Maybe<EventDetails>
  /**   (Internal) Full events summary */
  fullEventsSummary?: Maybe<Array<Maybe<EventsSummaryWithAssetData>>>
  /**   (Internal) Full shock Details. */
  fullShockDetails?: Maybe<ShockDetails>
  getAssetDocuments?: Maybe<AssetDocument>
  getAssetFluidDocuments?: Maybe<AssetFluidDocument>
  getKdpAssetComponentDetails?: Maybe<KdpAssetComponentDetailsReturn>
  getServiceCalendar?: Maybe<ServiceCalendarReturn>
  getServiceCalendarFilters?: Maybe<ServiceRequestFiltersReturn>
  getServiceCalendarStatuses?: Maybe<ServiceCalendarStatusReturn>
  getServiceRequests?: Maybe<ServiceRequestsReturn>
  getSingleAgreement?: Maybe<SingleAgreementReturn>
  getSingleAgreementServicePlan?: Maybe<SingleAgreementServicePlanReturn>
  /**   (Internal) Location data */
  locationData?: Maybe<LocationData>
  /**   mappingTable internal endpoint returns selected mapping table columns as key value pairs. */
  mappingTable?: Maybe<MappingTable>
  /**   Cumulative or non-cumulative measurement values for asset by given v codes */
  measurement?: Maybe<Measurement>
  /**
   *  measurementLimit internal endpoint returns the latest item after value has dropped under certain limit. At the moment, the limit value is 20,
   * which means endpoint returns the item that comes after it. vid prefix for measurementLimit is _12.
   */
  measurementLimit?: Maybe<Array<Maybe<AssetMeasurement>>>
  /**   Measurement statistic collections for asset */
  measurementStatistics?: Maybe<MeasurementStatistics>
  /**
   *  Measurement summary data for assets found by provided customers and locations.
   * Only Lifttruck assets that user is allowed to see is included in the result.
   */
  measurementSummary?: Maybe<MeasurementSummary>
  /**   Most Frequent Alerts. Default result size is 10. */
  mostFrequentEvents?: Maybe<Array<Maybe<MostFrequentEventResult>>>
  openFindings: Array<FindingsAndActionsSummary>
  openQuotations?: Maybe<Scalars['Int']>
  /**
   *  The public query for periodicMeasurements returns measurements that are grouped and aggregated according to a selected time frame and period type.
   * The query directly retrieves the results from the mostFrequentEvents endpoint for the specified time period, resulting in identical outputs.
   * If the startTime parameter provided in the periodicMeasurements query does not align with the beginning of a complete period,
   * the query will return data for the period beginning at the start of the next complete period (i.e., the first day of the next month),
   * with the label for the incomplete period (e.g., "Jan") including data from the partial period (i.e., from January 15 to January 31).
   * For subsequent periods, the query will return complete periods, and the endTime parameter will limit the returned data to the specified time.
   * Year, quarter, month, week, day and hour periods are supported. If the endTime is in the future, the data is retrieved up to the present moment.
   * ID and Label are returned until set endTime.periods are formed by startTime >= period <= endTime principle
   */
  periodicEvents?: Maybe<PeriodicEvents>
  /**
   *  If the regression slope is infinity, no predictions are given and therefore the value remains empty []
   * If the regression slope is NaN, it means that the data does not follow a linear trend and therefore no predictions are given and therefore the value remains empty []
   */
  periodicMeasurements?: Maybe<PeriodicMeasurements>
  /**   shock charts. Periodic shock data distributed in load handling and driving shocks */
  periodicShocks?: Maybe<Array<Maybe<PeriodicShocksResult>>>
  /**   (Internal) Permissions. Returns enabled features from permission toggle service for given assets */
  permissions?: Maybe<Array<Maybe<Permission>>>
  priceQuoteEmail?: Maybe<EmailSendStatus>
  productionData?: Maybe<ProductionDataResult>
  /**
   *  Lifttruck Dashboard KPI for customer and location level Safety alarms KPI.
   * Lifttruck Override and Overload values
   */
  safetyAlarmsKpi?: Maybe<SafetyAlarmsKpiData>
  saiComponents?: Maybe<SaiComponentsReturn>
  saiFilters?: Maybe<SaiFilteringOptionsReturn>
  serviceDocumentFilters?: Maybe<ServiceDocumentFilteringOptionsReturn>
  serviceDocuments?: Maybe<ServiceDocumentsReturn>
  serviceEventSummary?: Maybe<ServiceEventSummaryReturn>
  serviceEvents?: Maybe<ServiceEventsReturn>
  serviceProducts: Array<AssetServiceProduct>
  /**
   *  Shock details. Use cases: Shocks Distribution, Shocks Distribution Map.
   * One query returns max 1000 rows. Default value for "first" attribute is 50. Data sorted by timestamp desc.
   */
  shockDetails?: Maybe<ShockDetailsResult>
  /**   Shock kpis. Shock counts and most severe shocks */
  shockKpis?: Maybe<ShockKpisResult>
  /**
   *  Shocks summary data for assets found by provided customers, locations and Datalake ids.
   * Only assets that user is allowed to see is included in the result.
   * Can be used to show "shocks minifeed"s in asset fleet page. Sorted based on total shock counts.
   */
  shocksSummary?: Maybe<ShocksSummaryPage>
  srFilterOptionsSingleAgreementView?: Maybe<AgreementAppFilterOptionsReturn>
  subscriptions?: Maybe<SmartConnectedLtPackages>
  totalAlertsByAssetOwners?: Maybe<TotalAlerts>
  zuoraProducts?: Maybe<ZuoraProducts>
  zuoraSubscriptions?: Maybe<ZuoraSubscriptions>
}

export type QueryAgreementsForLocationArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  locations?: InputMaybe<Array<SrServiceLocation>>
  sortBy?: InputMaybe<SortBy>
}

export type QueryAlertCountsArgs = {
  assetId?: InputMaybe<Scalars['String']>
  contractBeginDate?: InputMaybe<Scalars['String']>
  craneType?: InputMaybe<Scalars['String']>
  customerId?: InputMaybe<Scalars['String']>
  endDate?: InputMaybe<Scalars['String']>
  locationId?: InputMaybe<Scalars['String']>
  startDate?: InputMaybe<Scalars['String']>
}

export type QueryAlertsArgs = {
  after?: InputMaybe<Scalars['String']>
  assetId?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  craneType?: InputMaybe<Scalars['String']>
  customerId?: InputMaybe<Scalars['String']>
  endDate?: InputMaybe<Scalars['String']>
  filters?: InputMaybe<Array<InputMaybe<Filters>>>
  first?: InputMaybe<Scalars['Int']>
  language?: InputMaybe<Scalars['String']>
  last?: InputMaybe<Scalars['Int']>
  locationId?: InputMaybe<Scalars['String']>
  search?: InputMaybe<SearchInput>
  startDate?: InputMaybe<Scalars['String']>
}

export type QueryAssetArgs = {
  assetId?: InputMaybe<Scalars['String']>
  customerId?: InputMaybe<Scalars['String']>
  integrations?: InputMaybe<Array<InputMaybe<AssetIntegration>>>
  locationId?: InputMaybe<Scalars['String']>
  onlyAssetsWithType?: InputMaybe<Scalars['Boolean']>
}

export type QueryAssetCountsArgs = {
  assetOwners: Array<AssetOwner>
  integrations?: InputMaybe<Array<InputMaybe<AssetIntegration>>>
  onlyAssetsWithType?: InputMaybe<Scalars['Boolean']>
}

export type QueryAssetDataArgs = {
  dlid: Scalars['String']
}

export type QueryAssetEventsSummaryArgs = {
  input: AssetEventsSummaryInput
}

export type QueryAssetMeasurementArgs = {
  input: AssetMeasurementInput
}

export type QueryAssetPackageSubscriptionEmailArgs = {
  assets?: InputMaybe<Array<InputMaybe<SubscriptionAsset>>>
  orderBy?: InputMaybe<Scalars['String']>
}

export type QueryAssetShockDetailsArgs = {
  input: InternalShockInput
  withDetailedData?: InputMaybe<Scalars['Boolean']>
}

export type QueryAssetsByOwnerArgs = {
  after?: InputMaybe<Scalars['String']>
  assetOwners: Array<AssetOwner>
  before?: InputMaybe<Scalars['String']>
  filters?: InputMaybe<Array<InputMaybe<Filters>>>
  first?: InputMaybe<Scalars['Int']>
  hiddenCustomerIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  hiddenLocationIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  integrations?: InputMaybe<Array<InputMaybe<AssetIntegration>>>
  last?: InputMaybe<Scalars['Int']>
  onlyActiveAssets?: InputMaybe<Scalars['Boolean']>
  onlyAssetsWithType?: InputMaybe<Scalars['Boolean']>
  search?: InputMaybe<SearchInput>
  skipFeatureRequests?: InputMaybe<Scalars['Boolean']>
  sortBy?: InputMaybe<SortBy>
  withServiceAgreements?: InputMaybe<Scalars['Boolean']>
}

export type QueryAuthorizedAlphaAssetsArgs = {
  brandId: Scalars['String']
  customerId: Scalars['String']
  dlIds: Array<Scalars['String']>
}

export type QueryAuthorizedAssetsArgs = {
  cidLids?: InputMaybe<Array<InputMaybe<CustomerLocation>>>
  dlIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  uid?: InputMaybe<Scalars['String']>
}

export type QueryAverageActiveMeasurementArgs = {
  input: AverageActiveMeasurementInput
}

export type QueryCalculatedAssetMeasurementArgs = {
  input: AssetMeasurementInput
}

export type QueryCostsForLocationArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  endDate?: InputMaybe<Scalars['String']>
  filteredBy?: InputMaybe<Array<InputMaybe<FilteredBy>>>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  locations: Array<BrServiceLocation>
  startDate?: InputMaybe<Scalars['String']>
}

export type QueryCostsSummaryForLocationArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  endDate?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  locations: Array<BrServiceLocation>
  startDate?: InputMaybe<Scalars['String']>
}

export type QueryDocumentDataArgs = {
  documentId?: InputMaybe<Scalars['String']>
}

export type QueryDocumentUrlArgs = {
  documentId?: InputMaybe<Scalars['String']>
}

export type QueryEventDetailsArgs = {
  facetFilter?: InputMaybe<FacetFilter>
  input: EventsInput
  search?: InputMaybe<Scalars['String']>
}

export type QueryEventDetailsDayArgs = {
  date: Scalars['String']
  dlid: Scalars['String']
  endTime?: InputMaybe<Scalars['String']>
  startTime?: InputMaybe<Scalars['String']>
}

export type QueryEventDetailsDayCountsArgs = {
  input: EventDetailsDayCountsInput
}

export type QueryEventLifecycleArgs = {
  dlid: Scalars['String']
  facetFilter?: InputMaybe<FacetFilter>
}

export type QueryEventTranslationDetailsArgs = {
  assetType: IotApiAssetType
  eventIds?: InputMaybe<Array<Scalars['String']>>
  language?: InputMaybe<Scalars['String']>
  version?: InputMaybe<Scalars['String']>
}

export type QueryEventTranslationsArgs = {
  assetType: IotApiAssetType
  language?: InputMaybe<Scalars['String']>
}

export type QueryEventsSummaryArgs = {
  input: EventsSummaryInput
}

export type QueryFaultsAndAlarmsKpiArgs = {
  input?: InputMaybe<FaultsAndAlarmsInput>
}

export type QueryFilterOptionsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  locations: Array<BrServiceLocation>
}

export type QueryFilteringOptionsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  endDate?: InputMaybe<Scalars['String']>
  filteredBy: Array<ServiceEventFilter>
  locations: Array<ServiceLocation>
  startDate?: InputMaybe<Scalars['String']>
}

export type QueryFullEventDetailsArgs = {
  input: FullEventsInput
}

export type QueryFullEventsSummaryArgs = {
  input: FullEventsSummaryInput
}

export type QueryFullShockDetailsArgs = {
  filter?: InputMaybe<ShockFacetFilter>
  input: InternalShockInput
}

export type QueryGetAssetDocumentsArgs = {
  after?: InputMaybe<Scalars['String']>
  assetId: Scalars['String']
  assetType: Scalars['String']
  before?: InputMaybe<Scalars['String']>
  customerId: Scalars['String']
  equipmentId?: InputMaybe<Scalars['Float']>
  filters?: InputMaybe<Array<InputMaybe<Filters>>>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  locationId: Scalars['String']
  search?: InputMaybe<SearchInput>
  serialNumber?: InputMaybe<Scalars['String']>
}

export type QueryGetAssetFluidDocumentsArgs = {
  after?: InputMaybe<Scalars['String']>
  assetId: Scalars['String']
  before?: InputMaybe<Scalars['String']>
  customerId: Scalars['String']
  filters?: InputMaybe<Array<InputMaybe<Filters>>>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  locationId: Scalars['String']
  search?: InputMaybe<SearchInput>
  serialNumber?: InputMaybe<Scalars['String']>
}

export type QueryGetKdpAssetComponentDetailsArgs = {
  after?: InputMaybe<Scalars['String']>
  assetId: Scalars['String']
  before?: InputMaybe<Scalars['String']>
  customerId: Scalars['String']
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  locationId: Scalars['String']
}

export type QueryGetServiceCalendarArgs = {
  endDate: Scalars['String']
  filterBy: Array<ServiceRequestFilter>
  locations?: InputMaybe<Array<SrServiceLocation>>
  startDate: Scalars['String']
}

export type QueryGetServiceCalendarFiltersArgs = {
  endDate: Scalars['String']
  locations?: InputMaybe<Array<SrServiceLocation>>
  startDate: Scalars['String']
}

export type QueryGetServiceCalendarStatusesArgs = {
  endDate: Scalars['String']
  filterBy: Array<ServiceRequestFilter>
  locations?: InputMaybe<Array<SrServiceLocation>>
  startDate: Scalars['String']
}

export type QueryGetServiceRequestsArgs = {
  assetRowId?: InputMaybe<Scalars['String']>
  endDate: Scalars['String']
  filterBy: Array<ServiceRequestFilter>
  locations?: InputMaybe<Array<SrServiceLocation>>
  serviceRequestNumber?: InputMaybe<Scalars['String']>
  startDate: Scalars['String']
}

export type QueryGetSingleAgreementArgs = {
  agreementId: Scalars['String']
}

export type QueryGetSingleAgreementServicePlanArgs = {
  agreementId: Scalars['String']
  endDate: Scalars['String']
  filterBy: Array<ServiceRequestFilter>
  startDate: Scalars['String']
  timeZone: Scalars['String']
}

export type QueryLocationDataArgs = {
  cid: Scalars['String']
  lid: Scalars['String']
}

export type QueryMappingTableArgs = {
  input: MappingTableInput
}

export type QueryMeasurementArgs = {
  input?: InputMaybe<MeasurementInput>
}

export type QueryMeasurementLimitArgs = {
  input: AverageActiveMeasurementInput
}

export type QueryMeasurementStatisticsArgs = {
  input?: InputMaybe<MeasurementStatisticsInput>
}

export type QueryMeasurementSummaryArgs = {
  input?: InputMaybe<MeasurementSummaryInput>
}

export type QueryMostFrequentEventsArgs = {
  filter?: InputMaybe<FacetFilter>
  groupByFacet: FacetType
  input: EventsInput
}

export type QueryOpenFindingsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  customerId: Scalars['String']
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  locationId: Scalars['String']
}

export type QueryOpenQuotationsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  customerId: Scalars['String']
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  locationId: Scalars['String']
}

export type QueryPeriodicEventsArgs = {
  filter?: InputMaybe<FacetFilter>
  groupByFacet: FacetType
  input: PeriodicEventsInput
  type?: InputMaybe<PeriodType>
}

export type QueryPeriodicMeasurementsArgs = {
  input: PeriodicMeasurementsInput
  type?: InputMaybe<PeriodType>
}

export type QueryPeriodicShocksArgs = {
  input: PeriodicEventsInput
  type?: InputMaybe<PeriodType>
}

export type QueryPermissionsArgs = {
  input: PermissionInput
}

export type QueryPriceQuoteEmailArgs = {
  assets?: InputMaybe<Array<InputMaybe<SubscriptionAsset>>>
  contactMethod?: InputMaybe<Scalars['String']>
  email?: InputMaybe<Scalars['String']>
  phoneNumber?: InputMaybe<Scalars['String']>
  service?: InputMaybe<Scalars['String']>
}

export type QueryProductionDataArgs = {
  after?: InputMaybe<Scalars['String']>
  assetId?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  contractBeginDate?: InputMaybe<Scalars['String']>
  craneType?: InputMaybe<Scalars['String']>
  customerId?: InputMaybe<Scalars['String']>
  endDate?: InputMaybe<Scalars['String']>
  filters?: InputMaybe<Array<InputMaybe<Filters>>>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  locationId?: InputMaybe<Scalars['String']>
  search?: InputMaybe<SearchInput>
  startDate?: InputMaybe<Scalars['String']>
}

export type QuerySafetyAlarmsKpiArgs = {
  input?: InputMaybe<SafetyAlarmsKpiInput>
}

export type QuerySaiComponentsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filters?: InputMaybe<Array<InputMaybe<Filters>>>
  first?: InputMaybe<Scalars['Int']>
  language?: InputMaybe<Scalars['String']>
  last?: InputMaybe<Scalars['Int']>
  locations?: InputMaybe<Array<SrServiceLocation>>
  search?: InputMaybe<SearchInput>
}

export type QuerySaiFiltersArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filters?: InputMaybe<Array<InputMaybe<Filters>>>
  first?: InputMaybe<Scalars['Int']>
  language?: InputMaybe<Scalars['String']>
  last?: InputMaybe<Scalars['Int']>
  locations?: InputMaybe<Array<SrServiceLocation>>
  search?: InputMaybe<SearchInput>
}

export type QueryServiceDocumentFiltersArgs = {
  after?: InputMaybe<Scalars['String']>
  assetPriorityId?: InputMaybe<Scalars['String']>
  assetRowId?: InputMaybe<Scalars['String']>
  assetSerialNumber?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  locations: Array<DocumentLocation>
}

export type QueryServiceDocumentsArgs = {
  after?: InputMaybe<Scalars['String']>
  assetPriorityId?: InputMaybe<Scalars['String']>
  assetRowId?: InputMaybe<Scalars['String']>
  assetSerialNumber?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filters?: InputMaybe<Array<InputMaybe<Filters>>>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  locations: Array<DocumentLocation>
  search?: InputMaybe<SearchInput>
}

export type QueryServiceEventSummaryArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  endDate?: InputMaybe<Scalars['String']>
  locations: Array<ServiceLocation>
  startDate?: InputMaybe<Scalars['String']>
}

export type QueryServiceEventsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  endDate?: InputMaybe<Scalars['String']>
  filteredBy: Array<ServiceEventFilter>
  filters?: InputMaybe<Array<InputMaybe<Filters>>>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  locations: Array<ServiceLocation>
  sortBy?: InputMaybe<SortBy>
  startDate?: InputMaybe<Scalars['String']>
}

export type QueryServiceProductsArgs = {
  after?: InputMaybe<Scalars['String']>
  assetId: Scalars['String']
  before?: InputMaybe<Scalars['String']>
  customerId: Scalars['String']
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  locationId: Scalars['String']
}

export type QueryShockDetailsArgs = {
  filter?: InputMaybe<ShockFacetFilter>
  input: EventsInput
}

export type QueryShockKpisArgs = {
  input: ShocksInput
}

export type QueryShocksSummaryArgs = {
  input: EventsSummaryInput
}

export type QuerySrFilterOptionsSingleAgreementViewArgs = {
  agreementId: Scalars['String']
}

export type QuerySubscriptionsArgs = {
  customerId?: InputMaybe<Scalars['String']>
  equipmentId?: InputMaybe<Scalars['Float']>
}

export type QueryTotalAlertsByAssetOwnersArgs = {
  assetOwners: Array<AssetOwner>
  endDate: Scalars['String']
  integrations?: InputMaybe<Array<InputMaybe<AssetIntegration>>>
  onlyAssetsWithType?: InputMaybe<Scalars['Boolean']>
  startDate: Scalars['String']
}

export type QueryZuoraSubscriptionsArgs = {
  customerId?: InputMaybe<Scalars['String']>
  equipmentId?: InputMaybe<Scalars['Float']>
}

export enum QuoteYkcStatus {
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  Expired = 'EXPIRED',
  Open = 'OPEN',
}

export type ReplacementDetails = {
  __typename?: 'ReplacementDetails'
  /**   Replacement dates (epoch) */
  replacementDates?: Maybe<Array<Scalars['String']>>
  /**   Replacement periods */
  replacementPeriodIds?: Maybe<Array<Scalars['String']>>
  /**   Measurement ID */
  vid: Scalars['String']
}

export type RunTimePerComponent = {
  __typename?: 'RunTimePerComponent'
  component?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['Float']>
}

export type S3Document = {
  __typename?: 'S3Document'
  fileUrl?: Maybe<Scalars['String']>
}

export type SrContactInfo = {
  __typename?: 'SRContactInfo'
  email?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
}

export type SrServiceLocation = {
  customerId: Scalars['String']
  locationId: Scalars['String']
  timeZone?: InputMaybe<Scalars['String']>
}

export type SrServiceLocationOutput = {
  __typename?: 'SRServiceLocationOutput'
  customerId: Scalars['String']
  locationId: Scalars['String']
  timeZone?: Maybe<Scalars['String']>
}

export type SafetyAlarmsKpiData = {
  __typename?: 'SafetyAlarmsKpiData'
  /**   Count of overloads */
  overload?: Maybe<Scalars['Int']>
  /**   Count of overrides */
  override?: Maybe<Scalars['Int']>
}

export type SafetyAlarmsKpiInput = {
  /**   List of customer IDs (required if dlIds not provided) */
  cidLids: Array<CustomerLocation>
  /**   Timeframe end time epoch */
  endTime: Scalars['String']
  /**   Timeframe start time epoch */
  startTime: Scalars['String']
  /**   Timezone (IANA time zone name). Default is UTC. */
  timeZone?: InputMaybe<Scalars['String']>
}

export type SaiAttribute = {
  __typename?: 'SaiAttribute'
  assetNo?: Maybe<SaiAttributeObject>
  chainDiameter?: Maybe<SaiAttributeObject>
  deviceLength?: Maybe<SaiAttributeObject>
  deviceWeight?: Maybe<SaiAttributeObject>
  grade?: Maybe<SaiAttributeObject>
  lashingForce?: Maybe<SaiAttributeObject>
  legsCount?: Maybe<SaiAttributeObject>
  loadWidth?: Maybe<SaiAttributeObject>
  loadWidthMax?: Maybe<SaiAttributeObject>
  loadWidthMin?: Maybe<SaiAttributeObject>
  safeWorkload?: Maybe<SaiAttributeObject>
}

export type SaiAttributeObject = {
  __typename?: 'SaiAttributeObject'
  unit?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

export type SaiComponent = {
  __typename?: 'SaiComponent'
  attributes?: Maybe<SaiAttribute>
  componentProductName: Scalars['String']
  componentProductSiebelId: Scalars['String']
  componentSiebelId: Scalars['String']
  deviceStatuses: Array<SaiDeviceStatus>
  deviceType: Scalars['String']
  factoryNumber?: Maybe<Scalars['String']>
  inspectionStatus: SaiInspectionStatus
  inspections: Array<SaiInspection>
  interval?: Maybe<Scalars['Int']>
  inventoryNumber?: Maybe<Scalars['String']>
  latestInspection?: Maybe<SaiInspection>
  location?: Maybe<SaiLocation>
  manufacturer?: Maybe<Scalars['String']>
  manufacturingYear?: Maybe<Scalars['String']>
  tagId?: Maybe<Scalars['String']>
  tagType?: Maybe<Scalars['String']>
  typeSpec1?: Maybe<Scalars['String']>
  typeSpec2?: Maybe<Scalars['String']>
  typeSpecAdditional?: Maybe<Scalars['String']>
}

export type SaiComponentsEdge = {
  __typename?: 'SaiComponentsEdge'
  cursor: Scalars['String']
  node?: Maybe<SaiComponent>
}

export type SaiComponentsReturn = {
  __typename?: 'SaiComponentsReturn'
  edges: Array<SaiComponentsEdge>
  pageInfo?: Maybe<PageInfo>
  totalCount?: Maybe<Scalars['Int']>
}

export type SaiContact = {
  __typename?: 'SaiContact'
  email?: Maybe<Scalars['String']>
  firstName: Scalars['String']
  lastName: Scalars['String']
  phone?: Maybe<Scalars['String']>
}

export enum SaiDeviceStatus {
  Overdue = 'OVERDUE',
  UnavailableForInspection = 'UNAVAILABLE_FOR_INSPECTION',
}

export type SaiFilteringOptions = {
  __typename?: 'SaiFilteringOptions'
  deviceStatuses: Array<SaiDeviceStatus>
  deviceTypes: Array<Scalars['String']>
  functionalLocations: Array<Scalars['String']>
  inspectionStatuses: Array<Scalars['String']>
  manufacturers: Array<Scalars['String']>
  nextSteps: Array<NextStep>
  recommendations: Array<Scalars['String']>
  serviceRequestNumbers: Array<Scalars['String']>
}

export type SaiFilteringOptionsEdge = {
  __typename?: 'SaiFilteringOptionsEdge'
  cursor: Scalars['String']
  node?: Maybe<SaiFilteringOptions>
}

export type SaiFilteringOptionsReturn = {
  __typename?: 'SaiFilteringOptionsReturn'
  edges: Array<SaiFilteringOptionsEdge>
  pageInfo?: Maybe<PageInfo>
}

export type SaiInspection = {
  __typename?: 'SaiInspection'
  comment?: Maybe<Scalars['String']>
  faultCode?: Maybe<Scalars['String']>
  firstExamination: Scalars['Boolean']
  inspectionStatus: SaiInspectionStatus
  isDue?: Maybe<Scalars['Boolean']>
  isUnavailableForInspection?: Maybe<Scalars['Boolean']>
  nextInspectionDate?: Maybe<Scalars['String']>
  nextStep?: Maybe<NextStep>
  recommendation?: Maybe<Scalars['String']>
  serviceRequestNumber: Scalars['String']
  siebelWorkPackageId: Scalars['String']
  taskType: Scalars['String']
  technician: SaiContact
  viewDate: Scalars['String']
}

export enum SaiInspectionStatus {
  FailedNotRepairable = 'FAILED_NOT_REPAIRABLE',
  FailedRepairable = 'FAILED_REPAIRABLE',
  NotChecked = 'NOT_CHECKED',
  OkNotRepaired = 'OK_NOT_REPAIRED',
  OkRepaired = 'OK_REPAIRED',
  OutOfService = 'OUT_OF_SERVICE',
  Unknown = 'UNKNOWN',
}

export type SaiLocation = {
  __typename?: 'SaiLocation'
  customerId: Scalars['String']
  functionalLocation?: Maybe<Scalars['String']>
  locationId: Scalars['String']
}

export type SearchInput = {
  keyOptions?: InputMaybe<Array<InputMaybe<KeyOption>>>
  keys: Array<Scalars['String']>
  searchString: Scalars['String']
  searchType?: InputMaybe<Scalars['String']>
}

export type ServiceAgreement = {
  __typename?: 'ServiceAgreement'
  name: Scalars['String']
  siebelId: Scalars['String']
}

export enum ServiceAgreementNotAvailable {
  AgreementNotAvailable = 'AGREEMENT_NOT_AVAILABLE',
}

export type ServiceAssetFilterData = {
  __typename?: 'ServiceAssetFilterData'
  agreements: Array<ServiceAgreement>
  assetCriticality?: Maybe<Scalars['String']>
  assetId?: Maybe<Scalars['String']>
  assetProductClassId?: Maybe<Scalars['String']>
  assetProductClassName?: Maybe<Scalars['String']>
  assetSiteLocationJoined?: Maybe<Scalars['String']>
  locationId?: Maybe<Scalars['String']>
  manufacturerName?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  siebelId?: Maybe<Scalars['String']>
  truConnectIndicator?: Maybe<Scalars['Boolean']>
  viewId?: Maybe<Scalars['String']>
}

export type ServiceCalendar = {
  __typename?: 'ServiceCalendar'
  serviceCalendar: Array<Maybe<ServiceRequestForCalendar>>
}

export type ServiceCalendarEdge = {
  __typename?: 'ServiceCalendarEdge'
  node?: Maybe<ServiceCalendar>
}

export type ServiceCalendarFilters = {
  __typename?: 'ServiceCalendarFilters'
  serviceCalendarFilters: Array<Maybe<ServiceRequestFilters>>
}

export type ServiceCalendarReturn = {
  __typename?: 'ServiceCalendarReturn'
  edges?: Maybe<Array<ServiceCalendarEdge>>
}

export type ServiceCalendarStatus = {
  __typename?: 'ServiceCalendarStatus'
  monthlyStatuses: Array<Maybe<MonthlyStatus>>
}

export type ServiceCalendarStatusEdge = {
  __typename?: 'ServiceCalendarStatusEdge'
  node?: Maybe<ServiceCalendarStatus>
}

export type ServiceCalendarStatusReturn = {
  __typename?: 'ServiceCalendarStatusReturn'
  edges?: Maybe<Array<ServiceCalendarStatusEdge>>
}

export type ServiceDocument = {
  __typename?: 'ServiceDocument'
  createdBy?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  documentId: Scalars['String']
  documentType: Scalars['String']
  fluidTopicsType?: Maybe<Scalars['String']>
  locale?: Maybe<Scalars['String']>
  locationIds?: Maybe<Array<Scalars['String']>>
  mimeType?: Maybe<Scalars['String']>
  s3Url?: Maybe<Scalars['String']>
  serviceProducts: Array<Scalars['String']>
  serviceRequestNumber?: Maybe<Scalars['String']>
  title: Scalars['String']
  viewDate?: Maybe<Scalars['String']>
  ykcCategory: DocumentType
}

export type ServiceDocumentFilteringOptions = {
  __typename?: 'ServiceDocumentFilteringOptions'
  documentTypes: Array<Scalars['String']>
  serviceProducts: Array<Scalars['String']>
}

export type ServiceDocumentFilteringOptionsEdge = {
  __typename?: 'ServiceDocumentFilteringOptionsEdge'
  cursor: Scalars['String']
  node?: Maybe<ServiceDocumentFilteringOptions>
}

export type ServiceDocumentFilteringOptionsReturn = {
  __typename?: 'ServiceDocumentFilteringOptionsReturn'
  edges: Array<ServiceDocumentFilteringOptionsEdge>
  pageInfo?: Maybe<PageInfo>
}

export type ServiceDocumentsEdge = {
  __typename?: 'ServiceDocumentsEdge'
  cursor: Scalars['String']
  node?: Maybe<ServiceDocument>
}

export type ServiceDocumentsReturn = {
  __typename?: 'ServiceDocumentsReturn'
  edges: Array<ServiceDocumentsEdge>
  pageInfo?: Maybe<PageInfo>
  totalCount?: Maybe<Scalars['Int']>
}

export type ServiceEvent = {
  __typename?: 'ServiceEvent'
  eventId: Scalars['String']
  eventType: EventType
  location: ServiceLocationOutput
  quote?: Maybe<ServiceEventQuote>
  serviceRequest?: Maybe<ServiceEventServiceRequest>
  viewDate: Scalars['String']
}

export type ServiceEventFilter = {
  filterBy: Scalars['String']
  values?: InputMaybe<Array<Scalars['String']>>
}

export type ServiceEventQuote = {
  __typename?: 'ServiceEventQuote'
  assetSiebelIds?: Maybe<Array<Scalars['String']>>
  attachments: Array<Attachment>
  closedDate?: Maybe<Scalars['String']>
  createdDate?: Maybe<Scalars['String']>
  customerEmail?: Maybe<Scalars['String']>
  customerFirstName?: Maybe<Scalars['String']>
  customerId?: Maybe<Scalars['String']>
  customerLastName?: Maybe<Scalars['String']>
  customerPhoneNumber?: Maybe<Scalars['String']>
  customerReference?: Maybe<Scalars['String']>
  isAllowedToApprove?: Maybe<Scalars['Boolean']>
  isOnlineApproved?: Maybe<Scalars['Boolean']>
  isVisible?: Maybe<Scalars['Boolean']>
  kcEmail?: Maybe<Scalars['String']>
  kcFirstName?: Maybe<Scalars['String']>
  kcLastName?: Maybe<Scalars['String']>
  kcPhoneNumber?: Maybe<Scalars['String']>
  onlineApprovedAttachmentName?: Maybe<Scalars['String']>
  onlineApprovedDate?: Maybe<Scalars['String']>
  poNumber?: Maybe<Scalars['String']>
  salesCaseName?: Maybe<Scalars['String']>
  serviceRequestLocationRowIdNew?: Maybe<Scalars['String']>
  serviceRequestLocationRowIdOld?: Maybe<Scalars['String']>
  serviceRequestNumberNew?: Maybe<Scalars['String']>
  serviceRequestNumberOld?: Maybe<Scalars['String']>
  soldToCustomerId: Scalars['String']
  srLocationId?: Maybe<Scalars['String']>
  status: QuoteYkcStatus
  validUntilDate?: Maybe<Scalars['String']>
  ykcCategory?: Maybe<Scalars['String']>
}

export type ServiceEventServiceRequest = {
  __typename?: 'ServiceEventServiceRequest'
  description: Scalars['String']
  findingsAndActions: Array<FindingAndActionEvent>
  plan: Scalars['Boolean']
  serviceProducts: Array<Scalars['String']>
  serviceRequestNumber: Scalars['String']
  serviceStatus: ServiceStatus
  siebelId: Scalars['String']
  viewDate: Scalars['String']
}

export type ServiceEventSummary = {
  __typename?: 'ServiceEventSummary'
  accepted?: Maybe<Scalars['Int']>
  expiredOrDeclined?: Maybe<Scalars['Int']>
  open?: Maybe<Scalars['Int']>
}

export type ServiceEventSummaryEdge = {
  __typename?: 'ServiceEventSummaryEdge'
  node?: Maybe<ServiceEventSummary>
}

export type ServiceEventSummaryReturn = {
  __typename?: 'ServiceEventSummaryReturn'
  edges: Array<ServiceEventSummaryEdge>
}

export type ServiceEventsEdge = {
  __typename?: 'ServiceEventsEdge'
  cursor: Scalars['String']
  node: ServiceEvent
}

export type ServiceEventsReturn = {
  __typename?: 'ServiceEventsReturn'
  edges: Array<ServiceEventsEdge>
  pageInfo?: Maybe<PageInfo>
  summary?: Maybe<ServiceEventsSummary>
}

export type ServiceEventsSummary = {
  __typename?: 'ServiceEventsSummary'
  openFindings: Array<FindingsAndActionsSummary>
  openQuotations: Scalars['Int']
}

export type ServiceLocation = {
  assetSiebelId?: InputMaybe<Scalars['String']>
  customerId: Scalars['String']
  locationId: Scalars['String']
  salesCaseId?: InputMaybe<Scalars['String']>
  timeZone: Scalars['String']
}

export type ServiceLocationOutput = {
  __typename?: 'ServiceLocationOutput'
  customerId: Scalars['String']
  locationId: Scalars['String']
  timeZone: Scalars['String']
}

export type ServicePercentage = {
  __typename?: 'ServicePercentage'
  percentage: Scalars['Int']
  serviceStatus: ServiceStatus
}

export type ServicePlan = {
  __typename?: 'ServicePlan'
  assetServicePlans: Array<AssetServicePlan>
  serviceSummary: ServiceSummary
}

export type ServicePlanSummary = {
  __typename?: 'ServicePlanSummary'
  numberOfServices: Scalars['Int']
  serviceStatus: ServiceStatus
}

export type ServiceProduct = {
  __typename?: 'ServiceProduct'
  name?: Maybe<Scalars['String']>
  plan?: Maybe<Scalars['Boolean']>
  siebelId: Scalars['String']
}

export type ServiceProductMonth = {
  __typename?: 'ServiceProductMonth'
  monthId: Scalars['Int']
  servicePlanSummary?: Maybe<ServicePlanSummary>
  serviceProductSingleServices?: Maybe<Array<ServiceProductSingleService>>
}

export type ServiceProductPlan = {
  __typename?: 'ServiceProductPlan'
  serviceProduct: ServiceProduct
  serviceProductMonths: Array<ServiceProductMonth>
}

export type ServiceProductSingleService = {
  __typename?: 'ServiceProductSingleService'
  serviceRequestNumber: Scalars['String']
  serviceStatus: ServiceStatus
  viewDate: Scalars['String']
}

export type ServiceRequest = {
  __typename?: 'ServiceRequest'
  agreementName?: Maybe<Scalars['String']>
  agreementNumber?: Maybe<Scalars['String']>
  closedDate?: Maybe<Scalars['String']>
  customerSiebelId: Scalars['String']
  description?: Maybe<Scalars['String']>
  locationSiebelId: Scalars['String']
  plan: Scalars['Boolean']
  plannedDate: Scalars['String']
  serviceRequestNumber: Scalars['String']
  serviceStatus: ServiceStatus
  siebelId: Scalars['String']
  viewDate?: Maybe<Scalars['String']>
  workPackages: Array<WorkPackage>
}

export type ServiceRequestEdge = {
  __typename?: 'ServiceRequestEdge'
  node?: Maybe<ServiceRequest>
}

export type ServiceRequestFilter = {
  filterBy: Scalars['String']
  values?: InputMaybe<Array<Scalars['String']>>
}

export type ServiceRequestFilters = {
  __typename?: 'ServiceRequestFilters'
  agreements: Array<ServiceAgreement>
  assets: Array<Scalars['String']>
  serviceProducts: Array<Scalars['String']>
  serviceStatuses: Array<ServiceStatus>
}

export type ServiceRequestFiltersEdge = {
  __typename?: 'ServiceRequestFiltersEdge'
  node?: Maybe<ServiceRequestFilters>
}

export type ServiceRequestFiltersReturn = {
  __typename?: 'ServiceRequestFiltersReturn'
  edges?: Maybe<Array<ServiceRequestFiltersEdge>>
}

export type ServiceRequestForCalendar = {
  __typename?: 'ServiceRequestForCalendar'
  assets: Array<Scalars['String']>
  customerSiebelId: Scalars['String']
  description?: Maybe<Scalars['String']>
  locationSiebelId: Scalars['String']
  plan: Scalars['Boolean']
  serviceProducts: Array<Scalars['String']>
  serviceRequestNumber: Scalars['String']
  serviceStatus: ServiceStatus
  siebelId: Scalars['String']
  viewDate: Scalars['String']
}

export type ServiceRequestsReturn = {
  __typename?: 'ServiceRequestsReturn'
  edges?: Maybe<Array<ServiceRequestEdge>>
}

export enum ServiceStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Delayed = 'DELAYED',
  Draft = 'DRAFT',
  InProgress = 'IN_PROGRESS',
  Open = 'OPEN',
  Scheduled = 'SCHEDULED',
}

export type ServiceSummary = {
  __typename?: 'ServiceSummary'
  percentages: Array<ServicePercentage>
  totalNumber: Scalars['Int']
}

export type ShockCounts = {
  __typename?: 'ShockCounts'
  /**   total count of driving shocks (speed 2km/h or more) */
  driving?: Maybe<Scalars['Int']>
  /**   total count of load handling shocks (speed less than 2km/h) */
  loadHandling?: Maybe<Scalars['Int']>
  /**   total of longitudinal shocks */
  longitudinal?: Maybe<Scalars['Int']>
  /**   total count of shocks */
  total?: Maybe<Scalars['Int']>
  /**   total of vertial shocks */
  vertical?: Maybe<Scalars['Int']>
}

export type ShockCountsSummary = {
  __typename?: 'ShockCountsSummary'
  longitudinal?: Maybe<Scalars['Int']>
  total?: Maybe<Scalars['Int']>
  vertical?: Maybe<Scalars['Int']>
}

export type ShockData = {
  __typename?: 'ShockData'
  /**   Acceleration */
  acc?: Maybe<Scalars['Float']>
  /**   Axis */
  ax?: Maybe<Scalars['String']>
  /**   populated facet information per each shock data */
  facets?: Maybe<ShockDataFacet>
  /**   Heading */
  head?: Maybe<Scalars['Float']>
  /**   Latitude */
  lat?: Maybe<Scalars['Float']>
  /**   Longitude */
  lon?: Maybe<Scalars['Float']>
  /**   Merged events */
  me?: Maybe<Scalars['Int']>
  /**   Sensor */
  sen?: Maybe<Scalars['Int']>
  /**   Speed */
  spd?: Maybe<Scalars['Float']>
  /**   Timestamp */
  ts: Scalars['String']
}

export type ShockDataFacet = {
  __typename?: 'ShockDataFacet'
  /**   shock direction facets */
  direction?: Maybe<Array<Maybe<ShockFacetDirection>>>
  /**   G force facets */
  force?: Maybe<Array<Maybe<ShockFacetForce>>>
  /**   shockType facets */
  type?: Maybe<Array<Maybe<ShockFacetType>>>
}

export type ShockDetails = {
  __typename?: 'ShockDetails'
  /**   driving and load handling shocks */
  data?: Maybe<Array<Maybe<ShockData>>>
  /**   total count of filtered data */
  dataCount?: Maybe<Scalars['Int']>
  /**   Datalake id. Input dlid returned back */
  dlid?: Maybe<Scalars['String']>
  /**   shock facets: type, direction & force */
  facets?: Maybe<Array<Maybe<Facet>>>
  /**   latest timestamp for summary query, shocksSummary. This is not returned for single asset query (shockdetails). */
  latestTimestamp?: Maybe<Scalars['String']>
  /**   most severe load handling and driving shocks */
  mostSevereShocks?: Maybe<ShockType>
  /**   total number of shocks in each category: total, load handling and driving */
  shockCounts?: Maybe<ShockCounts>
}

export type ShockDetailsResult = PermissionDeniedError | ShockDetails

export enum ShockFacetDirection {
  Longitudinal = 'LONGITUDINAL',
  Vertical = 'VERTICAL',
}

export type ShockFacetFilter = {
  /**   shock direction filters */
  direction?: InputMaybe<Array<InputMaybe<ShockFacetDirection>>>
  /**   G force filters */
  force?: InputMaybe<Array<InputMaybe<ShockFacetForce>>>
  /**   shockType filters */
  type?: InputMaybe<Array<InputMaybe<ShockFacetType>>>
}

export enum ShockFacetForce {
  Negative = 'NEGATIVE',
  Positive = 'POSITIVE',
}

export enum ShockFacetType {
  Driving = 'DRIVING',
  LoadHandling = 'LOAD_HANDLING',
}

export type ShockKpis = {
  __typename?: 'ShockKpis'
  /**   most severe load handling and driving shocks */
  mostSevereShocks?: Maybe<ShockType>
  /**   total number of shocks in each category: total, load handling and driving */
  shockCounts?: Maybe<ShockCounts>
}

export type ShockKpisResult = PermissionDeniedError | ShockKpis

export type ShockType = {
  __typename?: 'ShockType'
  /**   most severe loadhandling shocks */
  driving?: Maybe<DiagonalShock>
  /**   most severe loadhandling shocks */
  loadHandling?: Maybe<DiagonalShock>
  longitudinal?: Maybe<Scalars['Float']>
  vertical?: Maybe<Scalars['Float']>
}

export type ShocksInput = {
  /**   Datalake ID */
  dlid: Scalars['String']
  /**   Timeframe end time epoch */
  endTime: Scalars['String']
  /**   Timeframe start time epoch */
  startTime: Scalars['String']
  /**   Timezone (IANA time zone name). Default is UTC. */
  timeZone?: InputMaybe<Scalars['String']>
}

export type ShocksSummary = {
  __typename?: 'ShocksSummary'
  /**   Asset data */
  asset: AssetData
  /**   Datalake ID */
  dlid: Scalars['String']
  /**   Location data */
  location: LocationData
  /**   Shocks Summary data. Conditions Monitoring and Alert Notifications subscription required for Customers */
  summaryData?: Maybe<ShocksSummaryDataResult>
  /**   Timestamp of the latest shock */
  ts?: Maybe<Scalars['String']>
}

export type ShocksSummaryData = {
  __typename?: 'ShocksSummaryData'
  /**   mostSevereShocks */
  mostSevereShocksSummary?: Maybe<MostSevereShocksSummary>
  /**   shockCounts */
  shockCountsSummary?: Maybe<ShockCountsSummary>
}

export type ShocksSummaryDataResult = PermissionDeniedError | ShocksSummaryData

export type ShocksSummaryPage = {
  __typename?: 'ShocksSummaryPage'
  /**   Available asset summary crane types in the response. (Needed for minifeed dropdown list.) */
  assetSummaryCraneTypes?: Maybe<Array<Maybe<AssetSummaryType>>>
  /**   List of asset types in the response */
  assetTypes?: Maybe<Array<Maybe<Scalars['String']>>>
  /**   Total assets Count */
  assetsCount?: Maybe<Scalars['Int']>
  /**   Array of event summaries and asset meta data */
  data?: Maybe<Array<Maybe<ShocksSummary>>>
  /**   Has some of the assets coordinates */
  hasSomeOfAssetsCoordinates?: Maybe<Scalars['Boolean']>
}

export type SingleAgreementEdge = {
  __typename?: 'SingleAgreementEdge'
  node?: Maybe<Agreement>
}

export type SingleAgreementReturn = {
  __typename?: 'SingleAgreementReturn'
  edges?: Maybe<Array<SingleAgreementEdge>>
}

export type SingleAgreementServicePlanEdge = {
  __typename?: 'SingleAgreementServicePlanEdge'
  node?: Maybe<ServicePlan>
}

export type SingleAgreementServicePlanReturn = {
  __typename?: 'SingleAgreementServicePlanReturn'
  edges?: Maybe<Array<SingleAgreementServicePlanEdge>>
}

export type SingleAgreementViewFilters = {
  __typename?: 'SingleAgreementViewFilters'
  agreementLocation: Array<AgreementAppFilterBy>
  assetName: Array<Scalars['String']>
  serviceProduct: Array<Scalars['String']>
  serviceStatus: Array<Scalars['String']>
}

export type SmartConnectedLtPackages = {
  __typename?: 'SmartConnectedLTPackages'
  subscriptions?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type SortBy = {
  field: Scalars['String']
  order: Scalars['String']
}

export enum SortOrder {
  Asc = 'asc',
  Ascending = 'ascending',
  Desc = 'desc',
  Descending = 'descending',
}

export type SpendFilterOptionsReturn = {
  __typename?: 'SpendFilterOptionsReturn'
  edges?: Maybe<Array<SpendFilteringOptionsEdge>>
  pageInfo?: Maybe<PageInfo>
}

export type SpendFilteringOptions = {
  __typename?: 'SpendFilteringOptions'
  filteringOptions?: Maybe<SpendFilters>
}

export type SpendFilteringOptionsEdge = {
  __typename?: 'SpendFilteringOptionsEdge'
  cursor: Scalars['String']
  node?: Maybe<SpendFilteringOptions>
}

export type SpendFilters = {
  __typename?: 'SpendFilters'
  agreementCost: Array<Scalars['String']>
  agreementName: Array<ServiceAgreement>
  assetName: Array<Scalars['String']>
  businessUnit: Array<Scalars['String']>
  serviceCategory: Array<Scalars['String']>
  serviceOrEquipment: Array<Scalars['String']>
  serviceProduct: Array<Scalars['String']>
}

export type StartsCountPerComponent = {
  __typename?: 'StartsCountPerComponent'
  component?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['Float']>
}

export type StatusDetails = {
  __typename?: 'StatusDetails'
  /**   Expected end of life time epoch */
  expectedEndOfLifeTime?: Maybe<Scalars['String']>
  /**   Timeframe change of condition percentage */
  timeframeChange?: Maybe<Scalars['Float']>
  /**   Yearly change of condition percentage */
  yearlyChange?: Maybe<Scalars['Float']>
}

export type StatusHours = {
  __typename?: 'StatusHours'
  calculatedPowerOff?: Maybe<Scalars['Float']>
  calculatedPowerOffTotal?: Maybe<Scalars['Float']>
  calculatedTimeSelectedTotal?: Maybe<Scalars['Float']>
  chargeTimeHighRate?: Maybe<Scalars['Float']>
  chargeTimeLowRate?: Maybe<Scalars['Float']>
  chargeTotal?: Maybe<Scalars['Float']>
  empty?: Maybe<Scalars['Float']>
  engineOff?: Maybe<Scalars['Float']>
  fullyCharged?: Maybe<Scalars['Float']>
  idle?: Maybe<Scalars['Float']>
  idleEmpty?: Maybe<Scalars['Float']>
  idleLaden?: Maybe<Scalars['Float']>
  idleStopped?: Maybe<Scalars['Float']>
  laden?: Maybe<Scalars['Float']>
  lifeCycleOperatingTotal?: Maybe<Scalars['Float']>
  operatingTotal?: Maybe<Scalars['Float']>
  powerOff?: Maybe<Scalars['Float']>
  powerOffTotal?: Maybe<Scalars['Float']>
  timeSelectedTotal?: Maybe<Scalars['Float']>
  underCharge?: Maybe<Scalars['Float']>
  underPreconditioning?: Maybe<Scalars['Float']>
}

export type StatusLimit = {
  __typename?: 'StatusLimit'
  /**   Limit */
  limit: Scalars['Float']
  /**   Component status: CRITICAL | WARNING */
  status: Scalars['String']
}

export type SubscriptionAsset = {
  addedPackage?: InputMaybe<Scalars['String']>
  customerId?: InputMaybe<Scalars['String']>
  equipmentId?: InputMaybe<Scalars['Float']>
  id?: InputMaybe<Scalars['String']>
  lastSignalDate?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  package?: InputMaybe<Scalars['String']>
  price?: InputMaybe<Scalars['Float']>
  selected?: InputMaybe<Scalars['Boolean']>
  serialNumber?: InputMaybe<Scalars['String']>
  subscriptionPeriod?: InputMaybe<Scalars['String']>
  type?: InputMaybe<Scalars['String']>
}

export type TankLevels = {
  __typename?: 'TankLevels'
  adBlue?: Maybe<Scalars['Float']>
  averageConsumption?: Maybe<Scalars['Float']>
  diesel?: Maybe<Scalars['Float']>
  operatingHours?: Maybe<Scalars['Float']>
  timestamp?: Maybe<Scalars['String']>
}

export type TankLevelsHistory = {
  __typename?: 'TankLevelsHistory'
  adBlue?: Maybe<TankLevelsHistoryValues>
  diesel?: Maybe<TankLevelsHistoryValues>
  label?: Maybe<Scalars['String']>
}

export type TankLevelsHistoryResult = PermissionDeniedError | TankLevelsHistory

export type TankLevelsHistoryValues = {
  __typename?: 'TankLevelsHistoryValues'
  average?: Maybe<Scalars['Float']>
  maximum?: Maybe<Scalars['Float']>
  minimum?: Maybe<Scalars['Float']>
}

export type TankLevelsResult = PermissionDeniedError | TankLevels

export type TechnicalInformation = {
  __typename?: 'TechnicalInformation'
  boom?: Maybe<Scalars['String']>
  driveAxle?: Maybe<Scalars['String']>
  engine?: Maybe<Scalars['String']>
  functionTest?: Maybe<Scalars['String']>
  hydraulics?: Maybe<Scalars['String']>
  machineMaintenance?: Maybe<Scalars['String']>
  mastAttachmentFork?: Maybe<Scalars['String']>
  steerAxle?: Maybe<Scalars['String']>
  transmission?: Maybe<Scalars['String']>
}

export enum TimeDirection {
  Earlier = 'EARLIER',
  Later = 'LATER',
}

export type TotalAlerts = {
  __typename?: 'TotalAlerts'
  assetAlerts?: Maybe<AssetAlertsResult>
}

export type TotalAlertsForAsset = {
  __typename?: 'TotalAlertsForAsset'
  activeAlerts?: Maybe<Scalars['Int']>
  latestTimestamp?: Maybe<Scalars['String']>
  totalAlerts?: Maybe<Scalars['Int']>
}

export type TotalAlertsForAssetResult = PermissionDeniedError | TotalAlertsForAsset

export type TravelDistance = {
  __typename?: 'TravelDistance'
  avgDistanceByLoadCycle?: Maybe<Scalars['Float']>
  avgDistanceByWorkCycle?: Maybe<Scalars['Float']>
  lifeCycleTravelDistance?: Maybe<Scalars['Float']>
  lifeCycleTravelDistanceAvg?: Maybe<Scalars['Float']>
  operatingTotal?: Maybe<Scalars['Float']>
  timestamp?: Maybe<Scalars['String']>
  travelDistanceByMachineState?: Maybe<TravelDistanceByMachineStateValues>
  travelDistanceByOperatingHour?: Maybe<Scalars['Float']>
}

export type TravelDistanceAverage = {
  __typename?: 'TravelDistanceAverage'
  /**   Average m per load cycle */
  avgMetersPerLoadCycle?: Maybe<Scalars['Float']>
  /**   Average km per hour */
  avgPerHour?: Maybe<Scalars['Float']>
}

export type TravelDistanceByMachineStateValues = {
  __typename?: 'TravelDistanceByMachineStateValues'
  empty?: Maybe<Scalars['Float']>
  idle?: Maybe<Scalars['Float']>
  idleEmpty?: Maybe<Scalars['Float']>
  idleLaden?: Maybe<Scalars['Float']>
  laden?: Maybe<Scalars['Float']>
  total?: Maybe<Scalars['Float']>
  totalIdleExcluded?: Maybe<Scalars['Float']>
}

export type TravelDistanceHistory = {
  __typename?: 'TravelDistanceHistory'
  avgDistanceByWorkCycle?: Maybe<Scalars['Float']>
  label?: Maybe<Scalars['String']>
  travelDistanceByMachineState?: Maybe<TravelDistanceByMachineStateValues>
}

export type TravelDistanceHistoryResult = PermissionDeniedError | TravelDistanceHistory

export type TravelDistanceResult = PermissionDeniedError | TravelDistance

export type TravelDistanceSummary = {
  __typename?: 'TravelDistanceSummary'
  /**   Travel distance averages */
  avgs?: Maybe<TravelDistanceAverage>
  /**   Total travel distance */
  total?: Maybe<Scalars['Float']>
}

export type TrendData = {
  __typename?: 'TrendData'
  /** EndOfServiceLife */
  endOfServiceLife?: Maybe<Scalars['Boolean']>
  /** Date as id */
  id?: Maybe<Scalars['String']>
  /** Date as human readable string */
  label?: Maybe<Scalars['String']>
  /** Replaced */
  replaced?: Maybe<Scalars['Boolean']>
  /** Number value */
  value?: Maybe<Scalars['Float']>
}

export type TrendPeriod = {
  __typename?: 'TrendPeriod'
  /**   End of service life in period */
  endOfServiceLife?: Maybe<Scalars['Boolean']>
  /**   Period date as human readable string */
  label: Scalars['String']
  /**   Period id */
  periodId: Scalars['String']
  /**   Replaced in period */
  replaced?: Maybe<Scalars['Boolean']>
  /**   Number value (null refers to not existing or not used component) */
  value?: Maybe<Scalars['Float']>
}

export type TrendYear = {
  __typename?: 'TrendYear'
  costs: Array<CostsByServiceCategory>
  monthlyCosts: Array<MonthlyCosts>
  total: Scalars['Int']
  year: Scalars['String']
}

export type TruConnect = {
  __typename?: 'TruConnect'
  assetUtilization?: Maybe<AssetUtilizationResult>
  assetUtilizationHistory?: Maybe<Array<Maybe<AssetUtilizationHistoryResult>>>
  batteryStatus?: Maybe<BatteryStatusResult>
  batteryStatusHistory?: Maybe<Array<Maybe<BatteryStatusHistoryResult>>>
  cacheId?: Maybe<Scalars['String']>
  carbon?: Maybe<CarbonCurrentResult>
  carbonFootprint?: Maybe<CarbonFootprintResult>
  carbonFootprintHistory?: Maybe<Array<Maybe<CarbonFootprintHistoryResult>>>
  craneStatus?: Maybe<CraneStatusResult>
  craneStatusHistory?: Maybe<Array<Maybe<CraneStatusHistoryResult>>>
  craneStatusHistorySC?: Maybe<Array<Maybe<CraneStatusHistorySc>>>
  craneStatusSC?: Maybe<CraneStatusSc>
  energyConsumption?: Maybe<EnergyConsumptionResult>
  energyConsumptionHistory?: Maybe<Array<Maybe<EnergyConsumptionHistoryResult>>>
  energySuppliedByPowerStation?: Maybe<EnergySuppliedByPowerStationResult>
  energySuppliedByPowerStationHistory?: Maybe<
    Array<Maybe<EnergySuppliedByPowerStationHistoryResult>>
  >
  fuel?: Maybe<FuelCurrentResult>
  fuelConsumption?: Maybe<FuelConsumptionResult>
  fuelConsumptionHistory?: Maybe<Array<Maybe<FuelConsumptionHistoryResult>>>
  fuelConsumptionHistorySC?: Maybe<Array<Maybe<FuelConsumptionHistorySc>>>
  fuelConsumptionSC?: Maybe<FuelConsumptionSc>
  fuelHistory?: Maybe<Array<Maybe<FuelHistoryResult>>>
  hydraulicOil?: Maybe<HydraulicOilResult>
  hydraulicOilHistory?: Maybe<Array<Maybe<HydraulicOilHistoryResult>>>
  load?: Maybe<LoadResult>
  loadHistory?: Maybe<Array<Maybe<LoadHistoryResult>>>
  loadLift?: Maybe<LoadLiftResult>
  loadLiftHistory?: Maybe<Array<Maybe<LoadLiftHistoryResult>>>
  tankLevels?: Maybe<TankLevelsResult>
  tankLevelsHistory?: Maybe<Array<Maybe<TankLevelsHistoryResult>>>
  travelDistance?: Maybe<TravelDistanceResult>
  travelDistanceHistory?: Maybe<Array<Maybe<TravelDistanceHistoryResult>>>
  tyrePressure?: Maybe<TyrePressureResult>
  tyrePressureHistory?: Maybe<TyrePressureHistoryResult>
  tyrePressureSC?: Maybe<TyrePressureSc>
  tyrePressureSCHistory?: Maybe<TyrePressureScHistory>
}

export type TyrePressure = {
  __typename?: 'TyrePressure'
  avgLeft?: Maybe<Scalars['Float']>
  avgRight?: Maybe<Scalars['Float']>
  tiltLeftPercentage?: Maybe<Scalars['Float']>
  tiltRightPercentage?: Maybe<Scalars['Float']>
  timestamp?: Maybe<Scalars['String']>
  tyres?: Maybe<Tyres>
  warningCount?: Maybe<Scalars['Int']>
}

export type TyrePressureHistory = {
  __typename?: 'TyrePressureHistory'
  average?: Maybe<Array<Maybe<Tyres>>>
  maximum?: Maybe<Array<Maybe<Tyres>>>
  minimum?: Maybe<Array<Maybe<Tyres>>>
}

export type TyrePressureHistoryResult = PermissionDeniedError | TyrePressureHistory

export type TyrePressureResult = PermissionDeniedError | TyrePressure

export type TyrePressureSc = {
  __typename?: 'TyrePressureSC'
  avgLeft?: Maybe<Scalars['Float']>
  avgRight?: Maybe<Scalars['Float']>
  tiltLeftPercentage?: Maybe<Scalars['Float']>
  tiltRightPercentage?: Maybe<Scalars['Float']>
  timestamp?: Maybe<Scalars['String']>
  tyres?: Maybe<TyresSc>
  warningCount?: Maybe<Scalars['Int']>
}

export type TyrePressureScHistory = {
  __typename?: 'TyrePressureSCHistory'
  average?: Maybe<Array<Maybe<TyresSc>>>
  maximum?: Maybe<Array<Maybe<TyresSc>>>
  minimum?: Maybe<Array<Maybe<TyresSc>>>
}

export type Tyres = {
  __typename?: 'Tyres'
  label?: Maybe<Scalars['String']>
  leftFrontInner?: Maybe<Scalars['Float']>
  leftFrontOuter?: Maybe<Scalars['Float']>
  leftRear?: Maybe<Scalars['Float']>
  rightFrontInner?: Maybe<Scalars['Float']>
  rightFrontOuter?: Maybe<Scalars['Float']>
  rightRear?: Maybe<Scalars['Float']>
}

export type TyresSc = {
  __typename?: 'TyresSC'
  label?: Maybe<Scalars['String']>
  leftFront?: Maybe<Scalars['Float']>
  leftFrontMiddle?: Maybe<Scalars['Float']>
  leftRear?: Maybe<Scalars['Float']>
  leftRearMiddle?: Maybe<Scalars['Float']>
  rightFront?: Maybe<Scalars['Float']>
  rightFrontMiddle?: Maybe<Scalars['Float']>
  rightRear?: Maybe<Scalars['Float']>
  rightRearMiddle?: Maybe<Scalars['Float']>
}

export type ValuePerState = {
  __typename?: 'ValuePerState'
  /**   Value in empty state */
  empty?: Maybe<Scalars['Float']>
  /**   Value in idle state */
  idle?: Maybe<Scalars['Float']>
  /**   Value in laden state */
  laden?: Maybe<Scalars['Float']>
}

export type WorkPackage = {
  __typename?: 'WorkPackage'
  assetSiebelId: Scalars['String']
  serviceProducts: Array<ServiceProduct>
  serviceStatus?: Maybe<ServiceStatus>
  siebelId?: Maybe<Scalars['String']>
}

export enum YkcRiskType {
  Comment = 'COMMENT',
  Improvement = 'IMPROVEMENT',
  Inspection = 'INSPECTION',
  NoDirectRisk = 'NO_DIRECT_RISK',
  Production = 'PRODUCTION',
  Repaired = 'REPAIRED',
  Safety = 'SAFETY',
  Undetermined = 'UNDETERMINED',
}

export type ZuoraPricingEntity = {
  __typename?: 'ZuoraPricingEntity'
  currency?: Maybe<Scalars['String']>
  price?: Maybe<Scalars['Float']>
}

export type ZuoraProduct = {
  __typename?: 'ZuoraProduct'
  category?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  effectiveEndDate?: Maybe<Scalars['String']>
  effectiveStartDate?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  productFeatures?: Maybe<Array<Maybe<ZuoraProductFeature>>>
  productRatePlans?: Maybe<Array<Maybe<ZuoraProductRatePlan>>>
  sku?: Maybe<Scalars['String']>
}

export type ZuoraProductFeature = {
  __typename?: 'ZuoraProductFeature'
  code?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
}

export type ZuoraProductRatePlan = {
  __typename?: 'ZuoraProductRatePlan'
  description?: Maybe<Scalars['String']>
  effectiveEndDate?: Maybe<Scalars['String']>
  effectiveStartDate?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  productRatePlanCharges?: Maybe<Array<Maybe<ZuoraProductRatePlanCharge>>>
  ratePlanBillingPeriod__c?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
}

export type ZuoraProductRatePlanCharge = {
  __typename?: 'ZuoraProductRatePlanCharge'
  id?: Maybe<Scalars['String']>
  model?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  pricing?: Maybe<Array<Maybe<ZuoraPricingEntity>>>
  pricingSummary?: Maybe<Array<Maybe<Scalars['String']>>>
  type?: Maybe<Scalars['String']>
}

export type ZuoraProducts = {
  __typename?: 'ZuoraProducts'
  products?: Maybe<Array<Maybe<ZuoraProduct>>>
}

export type ZuoraRatePlanCharge = {
  __typename?: 'ZuoraRatePlanCharge'
  billingPeriod?: Maybe<Scalars['String']>
  currency?: Maybe<Scalars['String']>
  effectiveEndDate?: Maybe<Scalars['String']>
  effectiveStartDate?: Maybe<Scalars['String']>
  model?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  originalOrderDate?: Maybe<Scalars['String']>
  price?: Maybe<Scalars['Float']>
  type?: Maybe<Scalars['String']>
}

export type ZuoraSubscription = {
  __typename?: 'ZuoraSubscription'
  equipmentName?: Maybe<Scalars['String']>
  equipmentType?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  intEquipmentId?: Maybe<Scalars['String']>
  ratePlans?: Maybe<Array<Maybe<ZuoraSubscriptionRatePlan>>>
  serialNumber?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  subscriptionEndDate?: Maybe<Scalars['String']>
  subscriptionStartDate?: Maybe<Scalars['String']>
  termType?: Maybe<Scalars['String']>
}

export type ZuoraSubscriptionProductFeature = {
  __typename?: 'ZuoraSubscriptionProductFeature'
  description?: Maybe<Scalars['String']>
  featureCode?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
}

export type ZuoraSubscriptionRatePlan = {
  __typename?: 'ZuoraSubscriptionRatePlan'
  productName?: Maybe<Scalars['String']>
  ratePlanCharges?: Maybe<Array<Maybe<ZuoraRatePlanCharge>>>
  ratePlanName?: Maybe<Scalars['String']>
  subscriptionProductFeatures?: Maybe<Array<Maybe<ZuoraSubscriptionProductFeature>>>
}

export type ZuoraSubscriptions = {
  __typename?: 'ZuoraSubscriptions'
  subscriptions?: Maybe<Array<Maybe<ZuoraSubscription>>>
}

export type AssetEdge = {
  __typename?: 'assetEdge'
  cursor: Scalars['String']
  node: Asset
}

/** asset return also filters for ui */
export type AssetReturn = {
  __typename?: 'assetReturn'
  assetCount?: Maybe<Scalars['Int']>
  /** Returns distinct list of different asset types */
  assetTypes?: Maybe<Array<Maybe<Scalars['String']>>>
  edges: Array<AssetEdge>
  filterOptions: AssetFilterOptions
  hasSomeOfAssetsCoordinates: Scalars['Boolean']
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

export type FilterOption = {
  __typename?: 'filterOption'
  selected: Scalars['Boolean']
  value: Scalars['String']
}

export type GetActiveAlertsQueryVariables = Exact<{
  dlid: Scalars['String']
  startTime: Scalars['String']
  endTime: Scalars['String']
  timeZone: Scalars['String']
}>

export type GetActiveAlertsQuery = {
  __typename?: 'Query'
  eventDetails?:
    | {
        __typename?: 'EventDetails'
        data?: Array<{
          __typename?: 'EventData'
          ts: string
          eid: string
          et: number
          r?: number | null
          le: number
          cn?: string | null
          st: number
          desc?: string | null
          dur?: number | null
        } | null> | null
        facets?: {
          __typename?: 'Facets'
          le: Array<{ __typename?: 'Facet'; id: number; c: number } | null>
          eid: Array<{
            __typename?: 'EventIdFacet'
            id: string
            c: number
            first: number
            last: number
          } | null>
          et: Array<{ __typename?: 'Facet'; id: number; c: number } | null>
          st: Array<{ __typename?: 'Facet'; id: number; c: number } | null>
        } | null
      }
    | { __typename?: 'PermissionDeniedError' }
    | null
}

export type GetBmuOverviewQueryVariables = Exact<{
  dlid: Scalars['String']
  cns?: InputMaybe<Array<Scalars['String']> | Scalars['String']>
  startTime: Scalars['String']
  endTime: Scalars['String']
  timeZone: Scalars['String']
}>

export type GetBmuOverviewQuery = {
  __typename?: 'Query'
  measurementStatistics?: {
    __typename?: 'MeasurementStatistics'
    crane?: {
      __typename?: 'CraneStatistics'
      bmuKpis?: Array<{
        __typename?: 'BmuKpis'
        cn: string
        airGapStatusMonthlyChange?: number | null
        airGapStatus: {
          __typename?: 'ComponentConditionStatus'
          cn: string
          vid: string
          latestPercentage?: number | null
          status: string
          timestamp?: string | null
        }
        boostTime: {
          __typename?: 'ComponentUsage'
          cn: string
          timeframe?: number | null
          designLimit?: number | null
          status?: string | null
        }
      }> | null
    } | null
  } | null
}

export type GetConditionBmuQueryVariables = Exact<{
  dlid: Scalars['String']
  cns?: InputMaybe<Array<Scalars['String']> | Scalars['String']>
  startTime: Scalars['String']
  endTime: Scalars['String']
  timeZone: Scalars['String']
  periodType?: InputMaybe<PeriodType>
}>

export type GetConditionBmuQuery = {
  __typename?: 'Query'
  measurementStatistics?: {
    __typename?: 'MeasurementStatistics'
    crane?: {
      __typename?: 'CraneStatistics'
      bmuKpis?: Array<{
        __typename?: 'BmuKpis'
        cn: string
        airGapStatusMonthlyChange?: number | null
        latestUpdate?: string | null
        airGapStatus: {
          __typename?: 'ComponentConditionStatus'
          cn: string
          vid: string
          latestPercentage?: number | null
          status: string
          timestamp?: string | null
        }
        boostTime: {
          __typename?: 'ComponentUsage'
          cn: string
          timeframe?: number | null
          designLimit?: number | null
          status?: string | null
        }
      }> | null
      bmuTrends?: Array<{
        __typename?: 'BmuTrend'
        cn: string
        maximumAirGapPercentageTrends: Array<{
          __typename?: 'TrendPeriod'
          periodId: string
          label: string
          value?: number | null
        }>
        averageAirGapPercentageTrends: Array<{
          __typename?: 'TrendPeriod'
          periodId: string
          label: string
          value?: number | null
        }>
        minimumAirGapPercentageTrends: Array<{
          __typename?: 'TrendPeriod'
          periodId: string
          label: string
          value?: number | null
        }>
        statusLimits: Array<{ __typename?: 'StatusLimit'; status: string; limit: number }>
      }> | null
    } | null
  } | null
}

export type GetConditionMotorQueryVariables = Exact<{
  dlid: Scalars['String']
  cns?: InputMaybe<Array<Scalars['String']> | Scalars['String']>
  startTime: Scalars['String']
  endTime: Scalars['String']
  timeZone: Scalars['String']
  periodType?: InputMaybe<PeriodType>
}>

export type GetConditionMotorQuery = {
  __typename?: 'Query'
  measurementStatistics?: {
    __typename?: 'MeasurementStatistics'
    crane?: {
      __typename?: 'CraneStatistics'
      motorKpis?: Array<{
        __typename?: 'ComponentMotorKpis'
        cn: string
        latestUpdate?: string | null
        fastSpeedPercentage: {
          __typename?: 'ComponentUsage'
          cn: string
          timeframe?: number | null
        }
        slowSpeedPercentage: {
          __typename?: 'ComponentUsage'
          cn: string
          timeframe?: number | null
          designLimit?: number | null
          status?: string | null
        }
        overEdHours: { __typename?: 'ComponentUsage'; cn: string; timeframe?: number | null }
        overTemperatures: { __typename?: 'ComponentUsage'; cn: string; timeframe?: number | null }
      }> | null
      motorTrends?: Array<{
        __typename?: 'ComponentMotorTrend'
        cn: string
        overTemperaturePeriods: Array<{
          __typename?: 'TrendPeriod'
          periodId: string
          label: string
          value?: number | null
        }>
        overEdHourPeriods: Array<{
          __typename?: 'TrendPeriod'
          periodId: string
          label: string
          value?: number | null
        }>
        slowSpeedPercentagePeriods: Array<{
          __typename?: 'TrendPeriod'
          periodId: string
          label: string
          value?: number | null
        }>
      }> | null
    } | null
  } | null
}

export type GetConditionOverviewQueryVariables = Exact<{
  dlid: Scalars['String']
  cns?: InputMaybe<Array<Scalars['String']> | Scalars['String']>
  startTime: Scalars['String']
  endTime: Scalars['String']
  timeZone: Scalars['String']
}>

export type GetConditionOverviewQuery = {
  __typename?: 'Query'
  measurementStatistics?: {
    __typename?: 'MeasurementStatistics'
    crane?: {
      __typename?: 'CraneStatistics'
      hoistConditionOverviews?: Array<{
        __typename?: 'HoistConditionOverview'
        cn: string
        latestUpdate?: string | null
        hoistDwpStatus?: {
          __typename?: 'ComponentConditionStatus'
          cn: string
          latestPercentage?: number | null
          status: string
          timestamp?: string | null
          vid: string
        } | null
        lowestConditionStatuses: Array<{
          __typename?: 'ComponentConditionStatus'
          cn: string
          latestPercentage?: number | null
          status: string
          timestamp?: string | null
          vid: string
        }>
      }> | null
    } | null
  } | null
}

export type GetConditionPredictionsQueryVariables = Exact<{
  dlid: Scalars['String']
  cns?: InputMaybe<Array<Scalars['String']> | Scalars['String']>
  startTime: Scalars['String']
  endTime: Scalars['String']
  timeZone: Scalars['String']
  periodType?: InputMaybe<PeriodType>
}>

export type GetConditionPredictionsQuery = {
  __typename?: 'Query'
  measurementStatistics?: {
    __typename?: 'MeasurementStatistics'
    crane?: {
      __typename?: 'CraneStatistics'
      conditionKpis?: Array<{
        __typename?: 'ComponentConditionKpis'
        cn: string
        type?: string | null
        vid: string
        lastConditionChangePercentage?: number | null
        endOfServiceLifeTime?: string | null
        latestUpdate?: string | null
        conditionStatus: {
          __typename?: 'ComponentConditionStatus'
          vid: string
          cn: string
          type?: string | null
          latestPercentage?: number | null
          status: string
          timestamp?: string | null
        }
        nextConditionStatus: {
          __typename?: 'ComponentConditionStatus'
          vid: string
          cn: string
          latestPercentage?: number | null
          status: string
        }
      }> | null
      conditionTrends?: Array<{
        __typename?: 'ComponentConditionTrend'
        cn: string
        type?: string | null
        vid: string
        measurementPeriods: Array<{
          __typename?: 'TrendPeriod'
          periodId: string
          label: string
          replaced?: boolean | null
          value?: number | null
        }>
        predictionPeriods: Array<{
          __typename?: 'TrendPeriod'
          periodId: string
          label: string
          value?: number | null
        }>
        statusLimits: Array<{ __typename?: 'StatusLimit'; status: string; limit: number }>
      }> | null
    } | null
  } | null
}

export type GetConditionStatusesQueryVariables = Exact<{
  dlid: Scalars['String']
  cns?: InputMaybe<Array<Scalars['String']> | Scalars['String']>
  startTime: Scalars['String']
  endTime: Scalars['String']
  timeZone: Scalars['String']
}>

export type GetConditionStatusesQuery = {
  __typename?: 'Query'
  measurementStatistics?: {
    __typename?: 'MeasurementStatistics'
    crane?: {
      __typename?: 'CraneStatistics'
      conditionStatuses?: Array<{
        __typename?: 'ComponentConditionStatus'
        category?: string | null
        cn: string
        type?: string | null
        vid: string
        latestPercentage?: number | null
        status: string
        timestamp?: string | null
      }> | null
    } | null
  } | null
}

export type GetConditionTemperatureQueryVariables = Exact<{
  dlid: Scalars['String']
  cns?: InputMaybe<Array<Scalars['String']> | Scalars['String']>
  startTime: Scalars['String']
  endTime: Scalars['String']
  timeZone: Scalars['String']
  periodType?: InputMaybe<PeriodType>
}>

export type GetConditionTemperatureQuery = {
  __typename?: 'Query'
  measurementStatistics?: {
    __typename?: 'MeasurementStatistics'
    crane?: {
      __typename?: 'CraneStatistics'
      controllerTemperatureKpis?: Array<{
        __typename?: 'ComponentTemperatureKpis'
        cn: string
        latestUpdate?: string | null
        minimumTemperatureCelcius: {
          __typename?: 'ComponentUsage'
          cn: string
          timeframe?: number | null
        }
        minimumTemperatureFahrenheit: {
          __typename?: 'ComponentUsage'
          cn: string
          timeframe?: number | null
        }
        averageTemperatureCelcius: {
          __typename?: 'ComponentUsage'
          cn: string
          timeframe?: number | null
        }
        averageTemperatureFahrenheit: {
          __typename?: 'ComponentUsage'
          cn: string
          timeframe?: number | null
        }
        maximumTemperatureCelcius: {
          __typename?: 'ComponentUsage'
          cn: string
          timeframe?: number | null
        }
        maximumTemperatureFahrenheit: {
          __typename?: 'ComponentUsage'
          cn: string
          timeframe?: number | null
        }
      }> | null
      controllerTemperatureTrends?: Array<{
        __typename?: 'ComponentTemperatureTrend'
        cn: string
        maximumTemperatureTrendCelcius: Array<{
          __typename?: 'TrendPeriod'
          periodId: string
          label: string
          value?: number | null
        }>
        maximumTemperatureTrendFahrenheit: Array<{
          __typename?: 'TrendPeriod'
          periodId: string
          label: string
          value?: number | null
        }>
        averageTemperatureTrendCelcius: Array<{
          __typename?: 'TrendPeriod'
          periodId: string
          label: string
          value?: number | null
        }>
        averageTemperatureTrendFahrenheit: Array<{
          __typename?: 'TrendPeriod'
          periodId: string
          label: string
          value?: number | null
        }>
        minimumTemperatureTrendCelcius: Array<{
          __typename?: 'TrendPeriod'
          periodId: string
          label: string
          value?: number | null
        }>
        minimumTemperatureTrendFahrenheit: Array<{
          __typename?: 'TrendPeriod'
          periodId: string
          label: string
          value?: number | null
        }>
      }> | null
    } | null
  } | null
}

export type GetDailyAlertsQueryVariables = Exact<{
  dlid: Scalars['String']
  startTime: Scalars['String']
  endTime: Scalars['String']
  timeZone: Scalars['String']
}>

export type GetDailyAlertsQuery = {
  __typename?: 'Query'
  periodicEvents?: {
    __typename?: 'PeriodicEvents'
    dlid: string
    periods: Array<{
      __typename?: 'EventPeriod'
      id: string
      label: string
      events: Array<{ __typename?: 'MostFrequentEvent'; c: number; et?: number | null; id: string }>
    }>
  } | null
}

export type StatusInfoFragment = {
  __typename?: 'ComponentConditionStatus'
  cn: string
  type?: string | null
  vid: string
  latestPercentage?: number | null
  status: string
  timestamp?: string | null
}

export type GetEcuFleetConditionQueryVariables = Exact<{
  dlid: Scalars['String']
  cns?: InputMaybe<Array<Scalars['String']> | Scalars['String']>
  startTime: Scalars['String']
  endTime: Scalars['String']
  timeZone: Scalars['String']
}>

export type GetEcuFleetConditionQuery = {
  __typename?: 'Query'
  measurementStatistics?: {
    __typename?: 'MeasurementStatistics'
    ecu?: {
      __typename?: 'CraneStatistics'
      fleet?: Array<{
        __typename?: 'ComponentFleet'
        cn: string
        hoistDwpStatus: {
          __typename?: 'ComponentConditionStatus'
          cn: string
          type?: string | null
          vid: string
          latestPercentage?: number | null
          status: string
          timestamp?: string | null
        }
        brakeServiceLifeStatus: {
          __typename?: 'ComponentConditionStatus'
          cn: string
          type?: string | null
          vid: string
          latestPercentage?: number | null
          status: string
          timestamp?: string | null
        }
        contactorServiceLifeStatus: {
          __typename?: 'ComponentConditionStatus'
          cn: string
          type?: string | null
          vid: string
          latestPercentage?: number | null
          status: string
          timestamp?: string | null
        }
      }> | null
    } | null
  } | null
}

export type GetEventDetailsQueryVariables = Exact<{
  dlid: Scalars['String']
  startTime: Scalars['String']
  endTime: Scalars['String']
  timeZone: Scalars['String']
  search?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  after?: InputMaybe<Scalars['Int']>
  facetFilter?: InputMaybe<FacetFilter>
}>

export type GetEventDetailsQuery = {
  __typename?: 'Query'
  eventDetails?:
    | {
        __typename?: 'EventDetails'
        totalDataCount?: number | null
        data?: Array<{
          __typename?: 'EventData'
          ts: string
          eid: string
          et: number
          r?: number | null
          le: number
          cn?: string | null
          st: number
          me?: number | null
          desc?: string | null
          dur?: number | null
        } | null> | null
        facets?: {
          __typename?: 'Facets'
          le: Array<{ __typename?: 'Facet'; id: number; c: number } | null>
          eid: Array<{
            __typename?: 'EventIdFacet'
            id: string
            c: number
            first: number
            last: number
          } | null>
          et: Array<{ __typename?: 'Facet'; id: number; c: number } | null>
          st: Array<{ __typename?: 'Facet'; id: number; c: number } | null>
        } | null
      }
    | { __typename?: 'PermissionDeniedError' }
    | null
}

export type GetEventDetailsCategoriesQueryVariables = Exact<{
  dlid: Scalars['String']
  startTime: Scalars['String']
  endTime: Scalars['String']
  timeZone: Scalars['String']
  facetFilter?: InputMaybe<FacetFilter>
}>

export type GetEventDetailsCategoriesQuery = {
  __typename?: 'Query'
  eventDetails?:
    | {
        __typename?: 'EventDetails'
        facets?: {
          __typename?: 'Facets'
          eid: Array<{
            __typename?: 'EventIdFacet'
            id: string
            c: number
            first: number
            last: number
          } | null>
          et: Array<{ __typename?: 'Facet'; id: number; c: number; n?: string | null } | null>
          r?: Array<{ __typename?: 'Facet'; id: number; c: number } | null> | null
          le: Array<{ __typename?: 'Facet'; id: number; c: number } | null>
          st: Array<{ __typename?: 'Facet'; id: number; c: number } | null>
        } | null
      }
    | { __typename?: 'PermissionDeniedError' }
    | null
}

export type GetEventTranslationDetailsQueryVariables = Exact<{
  assetType: IotApiAssetType
  language?: InputMaybe<Scalars['String']>
  eventIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>
}>

export type GetEventTranslationDetailsQuery = {
  __typename?: 'Query'
  eventTranslationDetails?: Array<{
    __typename?: 'EventTranslationDetails'
    desc?: string | null
    eid: string
    pmoc?: string | null
    ra?: string | null
  }> | null
}

export type GetLatestEventQueryVariables = Exact<{
  dlid: Scalars['String']
  startTime: Scalars['String']
  endTime: Scalars['String']
  timeZone: Scalars['String']
}>

export type GetLatestEventQuery = {
  __typename?: 'Query'
  eventDetails?:
    | {
        __typename?: 'EventDetails'
        data?: Array<{
          __typename?: 'EventData'
          ts: string
          eid: string
          et: number
          le: number
          st: number
        } | null> | null
        facets?: {
          __typename?: 'Facets'
          le: Array<{ __typename?: 'Facet'; id: number; c: number } | null>
          eid: Array<{
            __typename?: 'EventIdFacet'
            id: string
            c: number
            first: number
            last: number
          } | null>
          et: Array<{ __typename?: 'Facet'; id: number; c: number } | null>
          st: Array<{ __typename?: 'Facet'; id: number; c: number } | null>
        } | null
      }
    | { __typename?: 'PermissionDeniedError' }
    | null
}

export type GetMostFrequentAlertsQueryVariables = Exact<{
  dlid: Scalars['String']
  startTime: Scalars['String']
  endTime: Scalars['String']
  groupByFacet: FacetType
  timeZone: Scalars['String']
}>

export type GetMostFrequentAlertsQuery = {
  __typename?: 'Query'
  mostFrequentEvents?: Array<
    | { __typename?: 'MostFrequentEvent'; id: string; n?: string | null; c: number }
    | { __typename?: 'PermissionDeniedError' }
    | null
  > | null
}

export type GetMostFrequentEventsByEventTypeQueryVariables = Exact<{
  dlid: Scalars['String']
  startTime: Scalars['String']
  endTime: Scalars['String']
  timeZone: Scalars['String']
}>

export type GetMostFrequentEventsByEventTypeQuery = {
  __typename?: 'Query'
  mostFrequentEvents?: Array<
    | { __typename?: 'MostFrequentEvent'; id: string; n?: string | null; c: number }
    | { __typename?: 'PermissionDeniedError' }
    | null
  > | null
}

export type GetPassiveAlertsQueryVariables = Exact<{
  dlid: Scalars['String']
  startTime: Scalars['String']
  endTime: Scalars['String']
  timeZone: Scalars['String']
}>

export type GetPassiveAlertsQuery = {
  __typename?: 'Query'
  eventDetails?:
    | {
        __typename?: 'EventDetails'
        data?: Array<{
          __typename?: 'EventData'
          ts: string
          eid: string
          et: number
          r?: number | null
          le: number
          cn?: string | null
          st: number
          desc?: string | null
          dur?: number | null
        } | null> | null
        facets?: {
          __typename?: 'Facets'
          le: Array<{ __typename?: 'Facet'; id: number; c: number } | null>
          eid: Array<{
            __typename?: 'EventIdFacet'
            id: string
            c: number
            first: number
            last: number
          } | null>
          et: Array<{ __typename?: 'Facet'; id: number; c: number } | null>
          st: Array<{ __typename?: 'Facet'; id: number; c: number } | null>
        } | null
      }
    | { __typename?: 'PermissionDeniedError' }
    | null
}

export type GetPeriodicEventsQueryVariables = Exact<{
  dlid: Scalars['String']
  startTime: Scalars['String']
  endTime: Scalars['String']
  groupByFacet: FacetType
  timeZone: Scalars['String']
  periodType?: InputMaybe<PeriodType>
  facetFilter?: InputMaybe<FacetFilter>
}>

export type GetPeriodicEventsQuery = {
  __typename?: 'Query'
  periodicEvents?: {
    __typename?: 'PeriodicEvents'
    dlid: string
    periods: Array<{
      __typename?: 'EventPeriod'
      id: string
      label: string
      events: Array<{ __typename?: 'MostFrequentEvent'; c: number; et?: number | null; id: string }>
    }>
  } | null
}

export type GetRecommendationsQueryVariables = Exact<{
  dlid: Scalars['String']
  cns?: InputMaybe<Array<Scalars['String']> | Scalars['String']>
  startTime: Scalars['String']
  endTime: Scalars['String']
  timeZone: Scalars['String']
}>

export type GetRecommendationsQuery = {
  __typename?: 'Query'
  measurementStatistics?: {
    __typename?: 'MeasurementStatistics'
    crane?: {
      __typename?: 'CraneStatistics'
      recommendations?: Array<{
        __typename?: 'ComponentRecommendation'
        cn: string
        recommendationId: string
        ts?: string | null
      }> | null
    } | null
  } | null
}

export type GetTotalAlertsQueryVariables = Exact<{
  dlid: Scalars['String']
  startTime: Scalars['String']
  endTime: Scalars['String']
  timeZone: Scalars['String']
}>

export type GetTotalAlertsQuery = {
  __typename?: 'Query'
  mostFrequentEvents?: Array<
    | { __typename?: 'MostFrequentEvent'; id: string; n?: string | null; c: number }
    | { __typename?: 'PermissionDeniedError' }
    | null
  > | null
}

export type GetUsageLoadSpectrumQueryVariables = Exact<{
  dlid: Scalars['String']
  cns?: InputMaybe<Array<Scalars['String']> | Scalars['String']>
  startTime: Scalars['String']
  endTime: Scalars['String']
  timeZone: Scalars['String']
}>

export type GetUsageLoadSpectrumQuery = {
  __typename?: 'Query'
  measurementStatistics?: {
    __typename?: 'MeasurementStatistics'
    crane?: {
      __typename?: 'CraneStatistics'
      loadSpectrums?: Array<{
        __typename?: 'ComponentLoadSpectrum'
        cn: string
        latestUpdate?: string | null
        percentiles?: Array<{
          __typename?: 'LoadPercentile'
          cycles?: number | null
          duration?: number | null
          name: string
        }> | null
        overloads?: {
          __typename?: 'LoadPercentile'
          cycles?: number | null
          duration?: number | null
          name: string
        } | null
      }> | null
    } | null
  } | null
}

export type GetUsageLoadsQueryVariables = Exact<{
  dlid: Scalars['String']
  cns?: InputMaybe<Array<Scalars['String']> | Scalars['String']>
  startTime: Scalars['String']
  endTime: Scalars['String']
  timeZone: Scalars['String']
  periodType?: InputMaybe<PeriodType>
}>

export type GetUsageLoadsQuery = {
  __typename?: 'Query'
  measurementStatistics?: {
    __typename?: 'MeasurementStatistics'
    crane?: {
      __typename?: 'CraneStatistics'
      loadKpis?: Array<{
        __typename?: 'ComponentLoadKpis'
        cn: string
        latestUpdate?: string | null
        totalLoadTons: { __typename?: 'ComponentUsage'; cn: string; timeframe?: number | null }
        averageLoadTons: {
          __typename?: 'ComponentUsage'
          cn: string
          timeframe?: number | null
          designLimit?: number | null
        }
        overloads: { __typename?: 'ComponentUsage'; cn: string; timeframe?: number | null }
        totalCycles: { __typename?: 'ComponentUsage'; cn: string; timeframe?: number | null }
        cyclesPerHour: {
          __typename?: 'ComponentUsage'
          cn: string
          timeframe?: number | null
          designLimit?: number | null
          status?: string | null
        }
      }> | null
      loadTrends?: Array<{
        __typename?: 'ComponentLoadTrend'
        cn: string
        averageLoadTrendTons: Array<{
          __typename?: 'TrendPeriod'
          periodId: string
          label: string
          value?: number | null
        }>
        maximumLoadTrendTons: Array<{
          __typename?: 'TrendPeriod'
          periodId: string
          label: string
          value?: number | null
        }>
        minimumLoadTrendTons: Array<{
          __typename?: 'TrendPeriod'
          periodId: string
          label: string
          value?: number | null
        }>
      }> | null
    } | null
  } | null
}

export type GetUsageOverviewQueryVariables = Exact<{
  dlid: Scalars['String']
  cns?: InputMaybe<Array<Scalars['String']> | Scalars['String']>
  startTime: Scalars['String']
  endTime: Scalars['String']
  timeZone: Scalars['String']
}>

export type GetUsageOverviewQuery = {
  __typename?: 'Query'
  measurementStatistics?: {
    __typename?: 'MeasurementStatistics'
    crane?: {
      __typename?: 'CraneStatistics'
      hoistUsageOverviews?: Array<{
        __typename?: 'HoistUsageOverview'
        cn: string
        latestUpdate?: string | null
        runningHoursPerDay?: {
          __typename?: 'ComponentUsage'
          cn: string
          timeframe?: number | null
          designLimit?: number | null
          status?: string | null
        } | null
        startsPerHour?: {
          __typename?: 'ComponentUsage'
          cn: string
          timeframe?: number | null
          designLimit?: number | null
          status?: string | null
        } | null
        averageLoadTons?: {
          __typename?: 'ComponentUsage'
          cn: string
          timeframe?: number | null
          designLimit?: number | null
        } | null
        cyclesPerHour?: {
          __typename?: 'ComponentUsage'
          cn: string
          timeframe?: number | null
          designLimit?: number | null
          status?: string | null
        } | null
      }> | null
    } | null
  } | null
}

export type GetUsageRunningHoursQueryVariables = Exact<{
  dlid: Scalars['String']
  cns?: InputMaybe<Array<Scalars['String']> | Scalars['String']>
  startTime: Scalars['String']
  endTime: Scalars['String']
  timeZone: Scalars['String']
  periodType?: InputMaybe<PeriodType>
}>

export type GetUsageRunningHoursQuery = {
  __typename?: 'Query'
  measurementStatistics?: {
    __typename?: 'MeasurementStatistics'
    crane?: {
      __typename?: 'CraneStatistics'
      usageKpis?: Array<{
        __typename?: 'ComponentUsageKpis'
        cn: string
        latestUpdate?: string | null
        powerOnHours: { __typename?: 'ComponentUsage'; cn: string; timeframe?: number | null }
        totalRunningHours: { __typename?: 'ComponentUsage'; cn: string; timeframe?: number | null }
        runningHoursPerDay: {
          __typename?: 'ComponentUsage'
          cn: string
          timeframe?: number | null
          designLimit?: number | null
          status?: string | null
        }
        totalStarts: { __typename?: 'ComponentUsage'; cn: string; timeframe?: number | null }
        startsPerHour: {
          __typename?: 'ComponentUsage'
          cn: string
          timeframe?: number | null
          designLimit?: number | null
          status?: string | null
        }
        hoistJoggings: { __typename?: 'ComponentUsage'; cn: string; timeframe?: number | null }
      }> | null
      runningHoursTrends?: Array<{
        __typename?: 'ComponentTrend'
        cn: string
        periods: Array<{
          __typename?: 'TrendPeriod'
          periodId: string
          label: string
          value?: number | null
        }>
      }> | null
    } | null
  } | null
}

export const StatusInfoFragmentDoc = gql`
  fragment StatusInfo on ComponentConditionStatus {
    cn
    type
    vid
    latestPercentage
    status
    timestamp
  }
`
export const GetActiveAlertsDocument = gql`
  query getActiveAlerts(
    $dlid: String!
    $startTime: String!
    $endTime: String!
    $timeZone: String!
  ) {
    eventDetails(
      input: { dlid: $dlid, startTime: $startTime, endTime: $endTime, timeZone: $timeZone }
      facetFilter: { r: [2, 3], st: 1 }
    ) {
      ... on EventDetails {
        data {
          ts
          eid
          et
          r
          le
          cn
          st
          desc
          dur
        }
        facets {
          le {
            id
            c
          }
          eid {
            id
            c
            first
            last
          }
          et {
            id
            c
          }
          st {
            id
            c
          }
        }
      }
    }
  }
`

/**
 * __useGetActiveAlertsQuery__
 *
 * To run a query within a React component, call `useGetActiveAlertsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveAlertsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveAlertsQuery({
 *   variables: {
 *      dlid: // value for 'dlid'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      timeZone: // value for 'timeZone'
 *   },
 * });
 */
export function useGetActiveAlertsQuery(
  baseOptions: Apollo.QueryHookOptions<GetActiveAlertsQuery, GetActiveAlertsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetActiveAlertsQuery, GetActiveAlertsQueryVariables>(
    GetActiveAlertsDocument,
    options,
  )
}
export function useGetActiveAlertsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetActiveAlertsQuery, GetActiveAlertsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetActiveAlertsQuery, GetActiveAlertsQueryVariables>(
    GetActiveAlertsDocument,
    options,
  )
}
export type GetActiveAlertsQueryHookResult = ReturnType<typeof useGetActiveAlertsQuery>
export type GetActiveAlertsLazyQueryHookResult = ReturnType<typeof useGetActiveAlertsLazyQuery>
export type GetActiveAlertsQueryResult = Apollo.QueryResult<
  GetActiveAlertsQuery,
  GetActiveAlertsQueryVariables
>
export const GetBmuOverviewDocument = gql`
  query getBmuOverview(
    $dlid: String!
    $cns: [String!]
    $startTime: String!
    $endTime: String!
    $timeZone: String!
  ) {
    measurementStatistics(
      input: {
        dlid: $dlid
        cns: $cns
        startTime: $startTime
        endTime: $endTime
        timeZone: $timeZone
      }
    ) {
      crane {
        bmuKpis {
          cn
          airGapStatus {
            cn
            vid
            latestPercentage
            status
            timestamp
          }
          airGapStatusMonthlyChange
          boostTime {
            cn
            timeframe
            designLimit
            status
          }
        }
      }
    }
  }
`

/**
 * __useGetBmuOverviewQuery__
 *
 * To run a query within a React component, call `useGetBmuOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBmuOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBmuOverviewQuery({
 *   variables: {
 *      dlid: // value for 'dlid'
 *      cns: // value for 'cns'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      timeZone: // value for 'timeZone'
 *   },
 * });
 */
export function useGetBmuOverviewQuery(
  baseOptions: Apollo.QueryHookOptions<GetBmuOverviewQuery, GetBmuOverviewQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetBmuOverviewQuery, GetBmuOverviewQueryVariables>(
    GetBmuOverviewDocument,
    options,
  )
}
export function useGetBmuOverviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBmuOverviewQuery, GetBmuOverviewQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetBmuOverviewQuery, GetBmuOverviewQueryVariables>(
    GetBmuOverviewDocument,
    options,
  )
}
export type GetBmuOverviewQueryHookResult = ReturnType<typeof useGetBmuOverviewQuery>
export type GetBmuOverviewLazyQueryHookResult = ReturnType<typeof useGetBmuOverviewLazyQuery>
export type GetBmuOverviewQueryResult = Apollo.QueryResult<
  GetBmuOverviewQuery,
  GetBmuOverviewQueryVariables
>
export const GetConditionBmuDocument = gql`
  query getConditionBmu(
    $dlid: String!
    $cns: [String!]
    $startTime: String!
    $endTime: String!
    $timeZone: String!
    $periodType: PeriodType
  ) {
    measurementStatistics(
      input: {
        dlid: $dlid
        cns: $cns
        startTime: $startTime
        endTime: $endTime
        timeZone: $timeZone
        periodType: $periodType
      }
    ) {
      crane {
        bmuKpis {
          cn
          airGapStatus {
            cn
            vid
            latestPercentage
            status
            timestamp
          }
          airGapStatusMonthlyChange
          boostTime {
            cn
            timeframe
            designLimit
            status
          }
          latestUpdate
        }
        bmuTrends {
          cn
          maximumAirGapPercentageTrends {
            periodId
            label
            value
          }
          averageAirGapPercentageTrends {
            periodId
            label
            value
          }
          minimumAirGapPercentageTrends {
            periodId
            label
            value
          }
          statusLimits {
            status
            limit
          }
        }
      }
    }
  }
`

/**
 * __useGetConditionBmuQuery__
 *
 * To run a query within a React component, call `useGetConditionBmuQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConditionBmuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConditionBmuQuery({
 *   variables: {
 *      dlid: // value for 'dlid'
 *      cns: // value for 'cns'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      timeZone: // value for 'timeZone'
 *      periodType: // value for 'periodType'
 *   },
 * });
 */
export function useGetConditionBmuQuery(
  baseOptions: Apollo.QueryHookOptions<GetConditionBmuQuery, GetConditionBmuQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetConditionBmuQuery, GetConditionBmuQueryVariables>(
    GetConditionBmuDocument,
    options,
  )
}
export function useGetConditionBmuLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetConditionBmuQuery, GetConditionBmuQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetConditionBmuQuery, GetConditionBmuQueryVariables>(
    GetConditionBmuDocument,
    options,
  )
}
export type GetConditionBmuQueryHookResult = ReturnType<typeof useGetConditionBmuQuery>
export type GetConditionBmuLazyQueryHookResult = ReturnType<typeof useGetConditionBmuLazyQuery>
export type GetConditionBmuQueryResult = Apollo.QueryResult<
  GetConditionBmuQuery,
  GetConditionBmuQueryVariables
>
export const GetConditionMotorDocument = gql`
  query getConditionMotor(
    $dlid: String!
    $cns: [String!]
    $startTime: String!
    $endTime: String!
    $timeZone: String!
    $periodType: PeriodType
  ) {
    measurementStatistics(
      input: {
        dlid: $dlid
        cns: $cns
        startTime: $startTime
        endTime: $endTime
        timeZone: $timeZone
        periodType: $periodType
      }
    ) {
      crane {
        motorKpis {
          cn
          fastSpeedPercentage {
            cn
            timeframe
          }
          slowSpeedPercentage {
            cn
            timeframe
            designLimit
            status
          }
          overEdHours {
            cn
            timeframe
          }
          overTemperatures {
            cn
            timeframe
          }
          latestUpdate
        }
        motorTrends {
          cn
          overTemperaturePeriods {
            periodId
            label
            value
          }
          overEdHourPeriods {
            periodId
            label
            value
          }
          slowSpeedPercentagePeriods {
            periodId
            label
            value
          }
        }
      }
    }
  }
`

/**
 * __useGetConditionMotorQuery__
 *
 * To run a query within a React component, call `useGetConditionMotorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConditionMotorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConditionMotorQuery({
 *   variables: {
 *      dlid: // value for 'dlid'
 *      cns: // value for 'cns'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      timeZone: // value for 'timeZone'
 *      periodType: // value for 'periodType'
 *   },
 * });
 */
export function useGetConditionMotorQuery(
  baseOptions: Apollo.QueryHookOptions<GetConditionMotorQuery, GetConditionMotorQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetConditionMotorQuery, GetConditionMotorQueryVariables>(
    GetConditionMotorDocument,
    options,
  )
}
export function useGetConditionMotorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetConditionMotorQuery,
    GetConditionMotorQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetConditionMotorQuery, GetConditionMotorQueryVariables>(
    GetConditionMotorDocument,
    options,
  )
}
export type GetConditionMotorQueryHookResult = ReturnType<typeof useGetConditionMotorQuery>
export type GetConditionMotorLazyQueryHookResult = ReturnType<typeof useGetConditionMotorLazyQuery>
export type GetConditionMotorQueryResult = Apollo.QueryResult<
  GetConditionMotorQuery,
  GetConditionMotorQueryVariables
>
export const GetConditionOverviewDocument = gql`
  query getConditionOverview(
    $dlid: String!
    $cns: [String!]
    $startTime: String!
    $endTime: String!
    $timeZone: String!
  ) {
    measurementStatistics(
      input: {
        dlid: $dlid
        cns: $cns
        startTime: $startTime
        endTime: $endTime
        timeZone: $timeZone
      }
    ) {
      crane {
        hoistConditionOverviews {
          cn
          hoistDwpStatus {
            cn
            latestPercentage
            status
            timestamp
            vid
          }
          lowestConditionStatuses {
            cn
            latestPercentage
            status
            timestamp
            vid
          }
          latestUpdate
        }
      }
    }
  }
`

/**
 * __useGetConditionOverviewQuery__
 *
 * To run a query within a React component, call `useGetConditionOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConditionOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConditionOverviewQuery({
 *   variables: {
 *      dlid: // value for 'dlid'
 *      cns: // value for 'cns'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      timeZone: // value for 'timeZone'
 *   },
 * });
 */
export function useGetConditionOverviewQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetConditionOverviewQuery,
    GetConditionOverviewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetConditionOverviewQuery, GetConditionOverviewQueryVariables>(
    GetConditionOverviewDocument,
    options,
  )
}
export function useGetConditionOverviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetConditionOverviewQuery,
    GetConditionOverviewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetConditionOverviewQuery, GetConditionOverviewQueryVariables>(
    GetConditionOverviewDocument,
    options,
  )
}
export type GetConditionOverviewQueryHookResult = ReturnType<typeof useGetConditionOverviewQuery>
export type GetConditionOverviewLazyQueryHookResult = ReturnType<
  typeof useGetConditionOverviewLazyQuery
>
export type GetConditionOverviewQueryResult = Apollo.QueryResult<
  GetConditionOverviewQuery,
  GetConditionOverviewQueryVariables
>
export const GetConditionPredictionsDocument = gql`
  query getConditionPredictions(
    $dlid: String!
    $cns: [String!]
    $startTime: String!
    $endTime: String!
    $timeZone: String!
    $periodType: PeriodType
  ) {
    measurementStatistics(
      input: {
        dlid: $dlid
        cns: $cns
        startTime: $startTime
        endTime: $endTime
        timeZone: $timeZone
        periodType: $periodType
      }
    ) {
      crane {
        conditionKpis {
          cn
          type
          vid
          conditionStatus {
            vid
            cn
            type
            latestPercentage
            status
            timestamp
          }
          lastConditionChangePercentage
          nextConditionStatus {
            vid
            cn
            latestPercentage
            status
          }
          endOfServiceLifeTime
          latestUpdate
        }
        conditionTrends {
          cn
          type
          vid
          measurementPeriods {
            periodId
            label
            replaced
            value
          }
          predictionPeriods {
            periodId
            label
            value
          }
          statusLimits {
            status
            limit
          }
        }
      }
    }
  }
`

/**
 * __useGetConditionPredictionsQuery__
 *
 * To run a query within a React component, call `useGetConditionPredictionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConditionPredictionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConditionPredictionsQuery({
 *   variables: {
 *      dlid: // value for 'dlid'
 *      cns: // value for 'cns'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      timeZone: // value for 'timeZone'
 *      periodType: // value for 'periodType'
 *   },
 * });
 */
export function useGetConditionPredictionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetConditionPredictionsQuery,
    GetConditionPredictionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetConditionPredictionsQuery, GetConditionPredictionsQueryVariables>(
    GetConditionPredictionsDocument,
    options,
  )
}
export function useGetConditionPredictionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetConditionPredictionsQuery,
    GetConditionPredictionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetConditionPredictionsQuery, GetConditionPredictionsQueryVariables>(
    GetConditionPredictionsDocument,
    options,
  )
}
export type GetConditionPredictionsQueryHookResult = ReturnType<
  typeof useGetConditionPredictionsQuery
>
export type GetConditionPredictionsLazyQueryHookResult = ReturnType<
  typeof useGetConditionPredictionsLazyQuery
>
export type GetConditionPredictionsQueryResult = Apollo.QueryResult<
  GetConditionPredictionsQuery,
  GetConditionPredictionsQueryVariables
>
export const GetConditionStatusesDocument = gql`
  query getConditionStatuses(
    $dlid: String!
    $cns: [String!]
    $startTime: String!
    $endTime: String!
    $timeZone: String!
  ) {
    measurementStatistics(
      input: {
        dlid: $dlid
        cns: $cns
        startTime: $startTime
        endTime: $endTime
        timeZone: $timeZone
      }
    ) {
      crane {
        conditionStatuses {
          category
          cn
          type
          vid
          latestPercentage
          status
          timestamp
        }
      }
    }
  }
`

/**
 * __useGetConditionStatusesQuery__
 *
 * To run a query within a React component, call `useGetConditionStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConditionStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConditionStatusesQuery({
 *   variables: {
 *      dlid: // value for 'dlid'
 *      cns: // value for 'cns'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      timeZone: // value for 'timeZone'
 *   },
 * });
 */
export function useGetConditionStatusesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetConditionStatusesQuery,
    GetConditionStatusesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetConditionStatusesQuery, GetConditionStatusesQueryVariables>(
    GetConditionStatusesDocument,
    options,
  )
}
export function useGetConditionStatusesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetConditionStatusesQuery,
    GetConditionStatusesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetConditionStatusesQuery, GetConditionStatusesQueryVariables>(
    GetConditionStatusesDocument,
    options,
  )
}
export type GetConditionStatusesQueryHookResult = ReturnType<typeof useGetConditionStatusesQuery>
export type GetConditionStatusesLazyQueryHookResult = ReturnType<
  typeof useGetConditionStatusesLazyQuery
>
export type GetConditionStatusesQueryResult = Apollo.QueryResult<
  GetConditionStatusesQuery,
  GetConditionStatusesQueryVariables
>
export const GetConditionTemperatureDocument = gql`
  query getConditionTemperature(
    $dlid: String!
    $cns: [String!]
    $startTime: String!
    $endTime: String!
    $timeZone: String!
    $periodType: PeriodType
  ) {
    measurementStatistics(
      input: {
        dlid: $dlid
        cns: $cns
        startTime: $startTime
        endTime: $endTime
        timeZone: $timeZone
        periodType: $periodType
      }
    ) {
      crane {
        controllerTemperatureKpis {
          cn
          minimumTemperatureCelcius {
            cn
            timeframe
          }
          minimumTemperatureFahrenheit {
            cn
            timeframe
          }
          averageTemperatureCelcius {
            cn
            timeframe
          }
          averageTemperatureFahrenheit {
            cn
            timeframe
          }
          maximumTemperatureCelcius {
            cn
            timeframe
          }
          maximumTemperatureFahrenheit {
            cn
            timeframe
          }
          latestUpdate
        }
        controllerTemperatureTrends {
          cn
          maximumTemperatureTrendCelcius {
            periodId
            label
            value
          }
          maximumTemperatureTrendFahrenheit {
            periodId
            label
            value
          }
          averageTemperatureTrendCelcius {
            periodId
            label
            value
          }
          averageTemperatureTrendFahrenheit {
            periodId
            label
            value
          }
          minimumTemperatureTrendCelcius {
            periodId
            label
            value
          }
          minimumTemperatureTrendFahrenheit {
            periodId
            label
            value
          }
        }
      }
    }
  }
`

/**
 * __useGetConditionTemperatureQuery__
 *
 * To run a query within a React component, call `useGetConditionTemperatureQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConditionTemperatureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConditionTemperatureQuery({
 *   variables: {
 *      dlid: // value for 'dlid'
 *      cns: // value for 'cns'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      timeZone: // value for 'timeZone'
 *      periodType: // value for 'periodType'
 *   },
 * });
 */
export function useGetConditionTemperatureQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetConditionTemperatureQuery,
    GetConditionTemperatureQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetConditionTemperatureQuery, GetConditionTemperatureQueryVariables>(
    GetConditionTemperatureDocument,
    options,
  )
}
export function useGetConditionTemperatureLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetConditionTemperatureQuery,
    GetConditionTemperatureQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetConditionTemperatureQuery, GetConditionTemperatureQueryVariables>(
    GetConditionTemperatureDocument,
    options,
  )
}
export type GetConditionTemperatureQueryHookResult = ReturnType<
  typeof useGetConditionTemperatureQuery
>
export type GetConditionTemperatureLazyQueryHookResult = ReturnType<
  typeof useGetConditionTemperatureLazyQuery
>
export type GetConditionTemperatureQueryResult = Apollo.QueryResult<
  GetConditionTemperatureQuery,
  GetConditionTemperatureQueryVariables
>
export const GetDailyAlertsDocument = gql`
  query getDailyAlerts($dlid: String!, $startTime: String!, $endTime: String!, $timeZone: String!) {
    periodicEvents(
      input: {
        dlid: $dlid
        startTime: $startTime
        endTime: $endTime
        timeZone: $timeZone
        first: 91
      }
      type: day
      groupByFacet: r
      filter: { r: [1, 2], st: 2 }
    ) {
      dlid
      periods {
        id
        label
        events {
          c
          et
          id
        }
      }
    }
  }
`

/**
 * __useGetDailyAlertsQuery__
 *
 * To run a query within a React component, call `useGetDailyAlertsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDailyAlertsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDailyAlertsQuery({
 *   variables: {
 *      dlid: // value for 'dlid'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      timeZone: // value for 'timeZone'
 *   },
 * });
 */
export function useGetDailyAlertsQuery(
  baseOptions: Apollo.QueryHookOptions<GetDailyAlertsQuery, GetDailyAlertsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetDailyAlertsQuery, GetDailyAlertsQueryVariables>(
    GetDailyAlertsDocument,
    options,
  )
}
export function useGetDailyAlertsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetDailyAlertsQuery, GetDailyAlertsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetDailyAlertsQuery, GetDailyAlertsQueryVariables>(
    GetDailyAlertsDocument,
    options,
  )
}
export type GetDailyAlertsQueryHookResult = ReturnType<typeof useGetDailyAlertsQuery>
export type GetDailyAlertsLazyQueryHookResult = ReturnType<typeof useGetDailyAlertsLazyQuery>
export type GetDailyAlertsQueryResult = Apollo.QueryResult<
  GetDailyAlertsQuery,
  GetDailyAlertsQueryVariables
>
export const GetEcuFleetConditionDocument = gql`
  query getEcuFleetCondition(
    $dlid: String!
    $cns: [String!]
    $startTime: String!
    $endTime: String!
    $timeZone: String!
  ) {
    measurementStatistics(
      input: {
        dlid: $dlid
        cns: $cns
        startTime: $startTime
        endTime: $endTime
        timeZone: $timeZone
      }
    ) {
      ecu {
        fleet {
          cn
          hoistDwpStatus {
            ...StatusInfo
          }
          brakeServiceLifeStatus {
            ...StatusInfo
          }
          contactorServiceLifeStatus {
            ...StatusInfo
          }
        }
      }
    }
  }
  ${StatusInfoFragmentDoc}
`

/**
 * __useGetEcuFleetConditionQuery__
 *
 * To run a query within a React component, call `useGetEcuFleetConditionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEcuFleetConditionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEcuFleetConditionQuery({
 *   variables: {
 *      dlid: // value for 'dlid'
 *      cns: // value for 'cns'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      timeZone: // value for 'timeZone'
 *   },
 * });
 */
export function useGetEcuFleetConditionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEcuFleetConditionQuery,
    GetEcuFleetConditionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetEcuFleetConditionQuery, GetEcuFleetConditionQueryVariables>(
    GetEcuFleetConditionDocument,
    options,
  )
}
export function useGetEcuFleetConditionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEcuFleetConditionQuery,
    GetEcuFleetConditionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetEcuFleetConditionQuery, GetEcuFleetConditionQueryVariables>(
    GetEcuFleetConditionDocument,
    options,
  )
}
export type GetEcuFleetConditionQueryHookResult = ReturnType<typeof useGetEcuFleetConditionQuery>
export type GetEcuFleetConditionLazyQueryHookResult = ReturnType<
  typeof useGetEcuFleetConditionLazyQuery
>
export type GetEcuFleetConditionQueryResult = Apollo.QueryResult<
  GetEcuFleetConditionQuery,
  GetEcuFleetConditionQueryVariables
>
export const GetEventDetailsDocument = gql`
  query getEventDetails(
    $dlid: String!
    $startTime: String!
    $endTime: String!
    $timeZone: String!
    $search: String
    $first: Int
    $after: Int
    $facetFilter: FacetFilter
  ) {
    eventDetails(
      input: {
        dlid: $dlid
        startTime: $startTime
        endTime: $endTime
        timeZone: $timeZone
        first: $first
        after: $after
      }
      search: $search
      facetFilter: $facetFilter
    ) {
      ... on EventDetails {
        data {
          ts
          eid
          et
          r
          le
          cn
          st
          me
          desc
          dur
        }
        facets {
          le {
            id
            c
          }
          eid {
            id
            c
            first
            last
          }
          et {
            id
            c
          }
          st {
            id
            c
          }
        }
        totalDataCount
      }
    }
  }
`

/**
 * __useGetEventDetailsQuery__
 *
 * To run a query within a React component, call `useGetEventDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventDetailsQuery({
 *   variables: {
 *      dlid: // value for 'dlid'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      timeZone: // value for 'timeZone'
 *      search: // value for 'search'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      facetFilter: // value for 'facetFilter'
 *   },
 * });
 */
export function useGetEventDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<GetEventDetailsQuery, GetEventDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetEventDetailsQuery, GetEventDetailsQueryVariables>(
    GetEventDetailsDocument,
    options,
  )
}
export function useGetEventDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetEventDetailsQuery, GetEventDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetEventDetailsQuery, GetEventDetailsQueryVariables>(
    GetEventDetailsDocument,
    options,
  )
}
export type GetEventDetailsQueryHookResult = ReturnType<typeof useGetEventDetailsQuery>
export type GetEventDetailsLazyQueryHookResult = ReturnType<typeof useGetEventDetailsLazyQuery>
export type GetEventDetailsQueryResult = Apollo.QueryResult<
  GetEventDetailsQuery,
  GetEventDetailsQueryVariables
>
export const GetEventDetailsCategoriesDocument = gql`
  query getEventDetailsCategories(
    $dlid: String!
    $startTime: String!
    $endTime: String!
    $timeZone: String!
    $facetFilter: FacetFilter
  ) {
    eventDetails(
      input: { dlid: $dlid, startTime: $startTime, endTime: $endTime, timeZone: $timeZone }
      facetFilter: $facetFilter
    ) {
      ... on EventDetails {
        facets {
          eid {
            id
            c
            first
            last
          }
          et {
            id
            c
            n
          }
          r {
            id
            c
          }
          le {
            id
            c
          }
          st {
            id
            c
          }
        }
      }
    }
  }
`

/**
 * __useGetEventDetailsCategoriesQuery__
 *
 * To run a query within a React component, call `useGetEventDetailsCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventDetailsCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventDetailsCategoriesQuery({
 *   variables: {
 *      dlid: // value for 'dlid'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      timeZone: // value for 'timeZone'
 *      facetFilter: // value for 'facetFilter'
 *   },
 * });
 */
export function useGetEventDetailsCategoriesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEventDetailsCategoriesQuery,
    GetEventDetailsCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetEventDetailsCategoriesQuery, GetEventDetailsCategoriesQueryVariables>(
    GetEventDetailsCategoriesDocument,
    options,
  )
}
export function useGetEventDetailsCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEventDetailsCategoriesQuery,
    GetEventDetailsCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetEventDetailsCategoriesQuery,
    GetEventDetailsCategoriesQueryVariables
  >(GetEventDetailsCategoriesDocument, options)
}
export type GetEventDetailsCategoriesQueryHookResult = ReturnType<
  typeof useGetEventDetailsCategoriesQuery
>
export type GetEventDetailsCategoriesLazyQueryHookResult = ReturnType<
  typeof useGetEventDetailsCategoriesLazyQuery
>
export type GetEventDetailsCategoriesQueryResult = Apollo.QueryResult<
  GetEventDetailsCategoriesQuery,
  GetEventDetailsCategoriesQueryVariables
>
export const GetEventTranslationDetailsDocument = gql`
  query getEventTranslationDetails(
    $assetType: IotApiAssetType!
    $language: String
    $eventIds: [String!]
  ) {
    eventTranslationDetails(assetType: $assetType, language: $language, eventIds: $eventIds) {
      desc
      eid
      pmoc
      ra
    }
  }
`

/**
 * __useGetEventTranslationDetailsQuery__
 *
 * To run a query within a React component, call `useGetEventTranslationDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventTranslationDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventTranslationDetailsQuery({
 *   variables: {
 *      assetType: // value for 'assetType'
 *      language: // value for 'language'
 *      eventIds: // value for 'eventIds'
 *   },
 * });
 */
export function useGetEventTranslationDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEventTranslationDetailsQuery,
    GetEventTranslationDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetEventTranslationDetailsQuery, GetEventTranslationDetailsQueryVariables>(
    GetEventTranslationDetailsDocument,
    options,
  )
}
export function useGetEventTranslationDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEventTranslationDetailsQuery,
    GetEventTranslationDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetEventTranslationDetailsQuery,
    GetEventTranslationDetailsQueryVariables
  >(GetEventTranslationDetailsDocument, options)
}
export type GetEventTranslationDetailsQueryHookResult = ReturnType<
  typeof useGetEventTranslationDetailsQuery
>
export type GetEventTranslationDetailsLazyQueryHookResult = ReturnType<
  typeof useGetEventTranslationDetailsLazyQuery
>
export type GetEventTranslationDetailsQueryResult = Apollo.QueryResult<
  GetEventTranslationDetailsQuery,
  GetEventTranslationDetailsQueryVariables
>
export const GetLatestEventDocument = gql`
  query getLatestEvent($dlid: String!, $startTime: String!, $endTime: String!, $timeZone: String!) {
    eventDetails(
      input: {
        dlid: $dlid
        startTime: $startTime
        endTime: $endTime
        timeZone: $timeZone
        first: 1
      }
    ) {
      ... on EventDetails {
        data {
          ts
          eid
          et
          le
          st
        }
        facets {
          le {
            id
            c
          }
          eid {
            id
            c
            first
            last
          }
          et {
            id
            c
          }
          st {
            id
            c
          }
        }
      }
    }
  }
`

/**
 * __useGetLatestEventQuery__
 *
 * To run a query within a React component, call `useGetLatestEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestEventQuery({
 *   variables: {
 *      dlid: // value for 'dlid'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      timeZone: // value for 'timeZone'
 *   },
 * });
 */
export function useGetLatestEventQuery(
  baseOptions: Apollo.QueryHookOptions<GetLatestEventQuery, GetLatestEventQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetLatestEventQuery, GetLatestEventQueryVariables>(
    GetLatestEventDocument,
    options,
  )
}
export function useGetLatestEventLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetLatestEventQuery, GetLatestEventQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetLatestEventQuery, GetLatestEventQueryVariables>(
    GetLatestEventDocument,
    options,
  )
}
export type GetLatestEventQueryHookResult = ReturnType<typeof useGetLatestEventQuery>
export type GetLatestEventLazyQueryHookResult = ReturnType<typeof useGetLatestEventLazyQuery>
export type GetLatestEventQueryResult = Apollo.QueryResult<
  GetLatestEventQuery,
  GetLatestEventQueryVariables
>
export const GetMostFrequentAlertsDocument = gql`
  query getMostFrequentAlerts(
    $dlid: String!
    $startTime: String!
    $endTime: String!
    $groupByFacet: FacetType!
    $timeZone: String!
  ) {
    mostFrequentEvents(
      input: { dlid: $dlid, startTime: $startTime, endTime: $endTime, timeZone: $timeZone }
      groupByFacet: $groupByFacet
      filter: { r: [2, 3] }
    ) {
      ... on MostFrequentEvent {
        id
        n
        c
      }
    }
  }
`

/**
 * __useGetMostFrequentAlertsQuery__
 *
 * To run a query within a React component, call `useGetMostFrequentAlertsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMostFrequentAlertsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMostFrequentAlertsQuery({
 *   variables: {
 *      dlid: // value for 'dlid'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      groupByFacet: // value for 'groupByFacet'
 *      timeZone: // value for 'timeZone'
 *   },
 * });
 */
export function useGetMostFrequentAlertsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMostFrequentAlertsQuery,
    GetMostFrequentAlertsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetMostFrequentAlertsQuery, GetMostFrequentAlertsQueryVariables>(
    GetMostFrequentAlertsDocument,
    options,
  )
}
export function useGetMostFrequentAlertsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMostFrequentAlertsQuery,
    GetMostFrequentAlertsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetMostFrequentAlertsQuery, GetMostFrequentAlertsQueryVariables>(
    GetMostFrequentAlertsDocument,
    options,
  )
}
export type GetMostFrequentAlertsQueryHookResult = ReturnType<typeof useGetMostFrequentAlertsQuery>
export type GetMostFrequentAlertsLazyQueryHookResult = ReturnType<
  typeof useGetMostFrequentAlertsLazyQuery
>
export type GetMostFrequentAlertsQueryResult = Apollo.QueryResult<
  GetMostFrequentAlertsQuery,
  GetMostFrequentAlertsQueryVariables
>
export const GetMostFrequentEventsByEventTypeDocument = gql`
  query getMostFrequentEventsByEventType(
    $dlid: String!
    $startTime: String!
    $endTime: String!
    $timeZone: String!
  ) {
    mostFrequentEvents(
      input: { dlid: $dlid, startTime: $startTime, endTime: $endTime, timeZone: $timeZone }
      groupByFacet: et
      filter: { r: [2, 3] }
    ) {
      ... on MostFrequentEvent {
        id
        n
        c
      }
    }
  }
`

/**
 * __useGetMostFrequentEventsByEventTypeQuery__
 *
 * To run a query within a React component, call `useGetMostFrequentEventsByEventTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMostFrequentEventsByEventTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMostFrequentEventsByEventTypeQuery({
 *   variables: {
 *      dlid: // value for 'dlid'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      timeZone: // value for 'timeZone'
 *   },
 * });
 */
export function useGetMostFrequentEventsByEventTypeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMostFrequentEventsByEventTypeQuery,
    GetMostFrequentEventsByEventTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetMostFrequentEventsByEventTypeQuery,
    GetMostFrequentEventsByEventTypeQueryVariables
  >(GetMostFrequentEventsByEventTypeDocument, options)
}
export function useGetMostFrequentEventsByEventTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMostFrequentEventsByEventTypeQuery,
    GetMostFrequentEventsByEventTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetMostFrequentEventsByEventTypeQuery,
    GetMostFrequentEventsByEventTypeQueryVariables
  >(GetMostFrequentEventsByEventTypeDocument, options)
}
export type GetMostFrequentEventsByEventTypeQueryHookResult = ReturnType<
  typeof useGetMostFrequentEventsByEventTypeQuery
>
export type GetMostFrequentEventsByEventTypeLazyQueryHookResult = ReturnType<
  typeof useGetMostFrequentEventsByEventTypeLazyQuery
>
export type GetMostFrequentEventsByEventTypeQueryResult = Apollo.QueryResult<
  GetMostFrequentEventsByEventTypeQuery,
  GetMostFrequentEventsByEventTypeQueryVariables
>
export const GetPassiveAlertsDocument = gql`
  query getPassiveAlerts(
    $dlid: String!
    $startTime: String!
    $endTime: String!
    $timeZone: String!
  ) {
    eventDetails(
      input: { dlid: $dlid, startTime: $startTime, endTime: $endTime, timeZone: $timeZone }
      facetFilter: { r: [2, 3], st: 2 }
    ) {
      ... on EventDetails {
        data {
          ts
          eid
          et
          r
          le
          cn
          st
          desc
          dur
        }
        facets {
          le {
            id
            c
          }
          eid {
            id
            c
            first
            last
          }
          et {
            id
            c
          }
          st {
            id
            c
          }
        }
      }
    }
  }
`

/**
 * __useGetPassiveAlertsQuery__
 *
 * To run a query within a React component, call `useGetPassiveAlertsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPassiveAlertsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPassiveAlertsQuery({
 *   variables: {
 *      dlid: // value for 'dlid'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      timeZone: // value for 'timeZone'
 *   },
 * });
 */
export function useGetPassiveAlertsQuery(
  baseOptions: Apollo.QueryHookOptions<GetPassiveAlertsQuery, GetPassiveAlertsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetPassiveAlertsQuery, GetPassiveAlertsQueryVariables>(
    GetPassiveAlertsDocument,
    options,
  )
}
export function useGetPassiveAlertsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPassiveAlertsQuery, GetPassiveAlertsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetPassiveAlertsQuery, GetPassiveAlertsQueryVariables>(
    GetPassiveAlertsDocument,
    options,
  )
}
export type GetPassiveAlertsQueryHookResult = ReturnType<typeof useGetPassiveAlertsQuery>
export type GetPassiveAlertsLazyQueryHookResult = ReturnType<typeof useGetPassiveAlertsLazyQuery>
export type GetPassiveAlertsQueryResult = Apollo.QueryResult<
  GetPassiveAlertsQuery,
  GetPassiveAlertsQueryVariables
>
export const GetPeriodicEventsDocument = gql`
  query getPeriodicEvents(
    $dlid: String!
    $startTime: String!
    $endTime: String!
    $groupByFacet: FacetType!
    $timeZone: String!
    $periodType: PeriodType
    $facetFilter: FacetFilter
  ) {
    periodicEvents(
      input: {
        dlid: $dlid
        startTime: $startTime
        endTime: $endTime
        timeZone: $timeZone
        first: 50
      }
      type: $periodType
      groupByFacet: $groupByFacet
      filter: $facetFilter
    ) {
      dlid
      periods {
        id
        label
        events {
          c
          et
          id
        }
      }
    }
  }
`

/**
 * __useGetPeriodicEventsQuery__
 *
 * To run a query within a React component, call `useGetPeriodicEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPeriodicEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPeriodicEventsQuery({
 *   variables: {
 *      dlid: // value for 'dlid'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      groupByFacet: // value for 'groupByFacet'
 *      timeZone: // value for 'timeZone'
 *      periodType: // value for 'periodType'
 *      facetFilter: // value for 'facetFilter'
 *   },
 * });
 */
export function useGetPeriodicEventsQuery(
  baseOptions: Apollo.QueryHookOptions<GetPeriodicEventsQuery, GetPeriodicEventsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetPeriodicEventsQuery, GetPeriodicEventsQueryVariables>(
    GetPeriodicEventsDocument,
    options,
  )
}
export function useGetPeriodicEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPeriodicEventsQuery,
    GetPeriodicEventsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetPeriodicEventsQuery, GetPeriodicEventsQueryVariables>(
    GetPeriodicEventsDocument,
    options,
  )
}
export type GetPeriodicEventsQueryHookResult = ReturnType<typeof useGetPeriodicEventsQuery>
export type GetPeriodicEventsLazyQueryHookResult = ReturnType<typeof useGetPeriodicEventsLazyQuery>
export type GetPeriodicEventsQueryResult = Apollo.QueryResult<
  GetPeriodicEventsQuery,
  GetPeriodicEventsQueryVariables
>
export const GetRecommendationsDocument = gql`
  query getRecommendations(
    $dlid: String!
    $cns: [String!]
    $startTime: String!
    $endTime: String!
    $timeZone: String!
  ) {
    measurementStatistics(
      input: {
        dlid: $dlid
        cns: $cns
        startTime: $startTime
        endTime: $endTime
        timeZone: $timeZone
      }
    ) {
      crane {
        recommendations {
          cn
          recommendationId
          ts
        }
      }
    }
  }
`

/**
 * __useGetRecommendationsQuery__
 *
 * To run a query within a React component, call `useGetRecommendationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecommendationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecommendationsQuery({
 *   variables: {
 *      dlid: // value for 'dlid'
 *      cns: // value for 'cns'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      timeZone: // value for 'timeZone'
 *   },
 * });
 */
export function useGetRecommendationsQuery(
  baseOptions: Apollo.QueryHookOptions<GetRecommendationsQuery, GetRecommendationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetRecommendationsQuery, GetRecommendationsQueryVariables>(
    GetRecommendationsDocument,
    options,
  )
}
export function useGetRecommendationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRecommendationsQuery,
    GetRecommendationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetRecommendationsQuery, GetRecommendationsQueryVariables>(
    GetRecommendationsDocument,
    options,
  )
}
export type GetRecommendationsQueryHookResult = ReturnType<typeof useGetRecommendationsQuery>
export type GetRecommendationsLazyQueryHookResult = ReturnType<
  typeof useGetRecommendationsLazyQuery
>
export type GetRecommendationsQueryResult = Apollo.QueryResult<
  GetRecommendationsQuery,
  GetRecommendationsQueryVariables
>
export const GetTotalAlertsDocument = gql`
  query getTotalAlerts($dlid: String!, $startTime: String!, $endTime: String!, $timeZone: String!) {
    mostFrequentEvents(
      input: { dlid: $dlid, startTime: $startTime, endTime: $endTime, timeZone: $timeZone }
      groupByFacet: r
      filter: { r: [2, 3] }
    ) {
      ... on MostFrequentEvent {
        id
        n
        c
      }
    }
  }
`

/**
 * __useGetTotalAlertsQuery__
 *
 * To run a query within a React component, call `useGetTotalAlertsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTotalAlertsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTotalAlertsQuery({
 *   variables: {
 *      dlid: // value for 'dlid'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      timeZone: // value for 'timeZone'
 *   },
 * });
 */
export function useGetTotalAlertsQuery(
  baseOptions: Apollo.QueryHookOptions<GetTotalAlertsQuery, GetTotalAlertsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetTotalAlertsQuery, GetTotalAlertsQueryVariables>(
    GetTotalAlertsDocument,
    options,
  )
}
export function useGetTotalAlertsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTotalAlertsQuery, GetTotalAlertsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetTotalAlertsQuery, GetTotalAlertsQueryVariables>(
    GetTotalAlertsDocument,
    options,
  )
}
export type GetTotalAlertsQueryHookResult = ReturnType<typeof useGetTotalAlertsQuery>
export type GetTotalAlertsLazyQueryHookResult = ReturnType<typeof useGetTotalAlertsLazyQuery>
export type GetTotalAlertsQueryResult = Apollo.QueryResult<
  GetTotalAlertsQuery,
  GetTotalAlertsQueryVariables
>
export const GetUsageLoadSpectrumDocument = gql`
  query getUsageLoadSpectrum(
    $dlid: String!
    $cns: [String!]
    $startTime: String!
    $endTime: String!
    $timeZone: String!
  ) {
    measurementStatistics(
      input: {
        dlid: $dlid
        cns: $cns
        startTime: $startTime
        endTime: $endTime
        timeZone: $timeZone
      }
    ) {
      crane {
        loadSpectrums {
          cn
          percentiles {
            cycles
            duration
            name
          }
          overloads {
            cycles
            duration
            name
          }
          latestUpdate
        }
      }
    }
  }
`

/**
 * __useGetUsageLoadSpectrumQuery__
 *
 * To run a query within a React component, call `useGetUsageLoadSpectrumQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsageLoadSpectrumQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsageLoadSpectrumQuery({
 *   variables: {
 *      dlid: // value for 'dlid'
 *      cns: // value for 'cns'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      timeZone: // value for 'timeZone'
 *   },
 * });
 */
export function useGetUsageLoadSpectrumQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUsageLoadSpectrumQuery,
    GetUsageLoadSpectrumQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetUsageLoadSpectrumQuery, GetUsageLoadSpectrumQueryVariables>(
    GetUsageLoadSpectrumDocument,
    options,
  )
}
export function useGetUsageLoadSpectrumLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUsageLoadSpectrumQuery,
    GetUsageLoadSpectrumQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetUsageLoadSpectrumQuery, GetUsageLoadSpectrumQueryVariables>(
    GetUsageLoadSpectrumDocument,
    options,
  )
}
export type GetUsageLoadSpectrumQueryHookResult = ReturnType<typeof useGetUsageLoadSpectrumQuery>
export type GetUsageLoadSpectrumLazyQueryHookResult = ReturnType<
  typeof useGetUsageLoadSpectrumLazyQuery
>
export type GetUsageLoadSpectrumQueryResult = Apollo.QueryResult<
  GetUsageLoadSpectrumQuery,
  GetUsageLoadSpectrumQueryVariables
>
export const GetUsageLoadsDocument = gql`
  query getUsageLoads(
    $dlid: String!
    $cns: [String!]
    $startTime: String!
    $endTime: String!
    $timeZone: String!
    $periodType: PeriodType
  ) {
    measurementStatistics(
      input: {
        dlid: $dlid
        cns: $cns
        startTime: $startTime
        endTime: $endTime
        timeZone: $timeZone
        periodType: $periodType
      }
    ) {
      crane {
        loadKpis {
          cn
          totalLoadTons {
            cn
            timeframe
          }
          averageLoadTons {
            cn
            timeframe
            designLimit
          }
          overloads {
            cn
            timeframe
          }
          totalCycles {
            cn
            timeframe
          }
          cyclesPerHour {
            cn
            timeframe
            designLimit
            status
          }
          latestUpdate
        }
        loadTrends {
          cn
          averageLoadTrendTons {
            periodId
            label
            value
          }
          maximumLoadTrendTons {
            periodId
            label
            value
          }
          minimumLoadTrendTons {
            periodId
            label
            value
          }
        }
      }
    }
  }
`

/**
 * __useGetUsageLoadsQuery__
 *
 * To run a query within a React component, call `useGetUsageLoadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsageLoadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsageLoadsQuery({
 *   variables: {
 *      dlid: // value for 'dlid'
 *      cns: // value for 'cns'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      timeZone: // value for 'timeZone'
 *      periodType: // value for 'periodType'
 *   },
 * });
 */
export function useGetUsageLoadsQuery(
  baseOptions: Apollo.QueryHookOptions<GetUsageLoadsQuery, GetUsageLoadsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetUsageLoadsQuery, GetUsageLoadsQueryVariables>(
    GetUsageLoadsDocument,
    options,
  )
}
export function useGetUsageLoadsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUsageLoadsQuery, GetUsageLoadsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetUsageLoadsQuery, GetUsageLoadsQueryVariables>(
    GetUsageLoadsDocument,
    options,
  )
}
export type GetUsageLoadsQueryHookResult = ReturnType<typeof useGetUsageLoadsQuery>
export type GetUsageLoadsLazyQueryHookResult = ReturnType<typeof useGetUsageLoadsLazyQuery>
export type GetUsageLoadsQueryResult = Apollo.QueryResult<
  GetUsageLoadsQuery,
  GetUsageLoadsQueryVariables
>
export const GetUsageOverviewDocument = gql`
  query getUsageOverview(
    $dlid: String!
    $cns: [String!]
    $startTime: String!
    $endTime: String!
    $timeZone: String!
  ) {
    measurementStatistics(
      input: {
        dlid: $dlid
        cns: $cns
        startTime: $startTime
        endTime: $endTime
        timeZone: $timeZone
      }
    ) {
      crane {
        hoistUsageOverviews {
          cn
          runningHoursPerDay {
            cn
            timeframe
            designLimit
            status
          }
          startsPerHour {
            cn
            timeframe
            designLimit
            status
          }
          averageLoadTons {
            cn
            timeframe
            designLimit
          }
          cyclesPerHour {
            cn
            timeframe
            designLimit
            status
          }
          latestUpdate
        }
      }
    }
  }
`

/**
 * __useGetUsageOverviewQuery__
 *
 * To run a query within a React component, call `useGetUsageOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsageOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsageOverviewQuery({
 *   variables: {
 *      dlid: // value for 'dlid'
 *      cns: // value for 'cns'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      timeZone: // value for 'timeZone'
 *   },
 * });
 */
export function useGetUsageOverviewQuery(
  baseOptions: Apollo.QueryHookOptions<GetUsageOverviewQuery, GetUsageOverviewQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetUsageOverviewQuery, GetUsageOverviewQueryVariables>(
    GetUsageOverviewDocument,
    options,
  )
}
export function useGetUsageOverviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUsageOverviewQuery, GetUsageOverviewQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetUsageOverviewQuery, GetUsageOverviewQueryVariables>(
    GetUsageOverviewDocument,
    options,
  )
}
export type GetUsageOverviewQueryHookResult = ReturnType<typeof useGetUsageOverviewQuery>
export type GetUsageOverviewLazyQueryHookResult = ReturnType<typeof useGetUsageOverviewLazyQuery>
export type GetUsageOverviewQueryResult = Apollo.QueryResult<
  GetUsageOverviewQuery,
  GetUsageOverviewQueryVariables
>
export const GetUsageRunningHoursDocument = gql`
  query getUsageRunningHours(
    $dlid: String!
    $cns: [String!]
    $startTime: String!
    $endTime: String!
    $timeZone: String!
    $periodType: PeriodType
  ) {
    measurementStatistics(
      input: {
        dlid: $dlid
        cns: $cns
        startTime: $startTime
        endTime: $endTime
        timeZone: $timeZone
        periodType: $periodType
      }
    ) {
      crane {
        usageKpis {
          cn
          powerOnHours {
            cn
            timeframe
          }
          totalRunningHours {
            cn
            timeframe
          }
          runningHoursPerDay {
            cn
            timeframe
            designLimit
            status
          }
          totalStarts {
            cn
            timeframe
          }
          startsPerHour {
            cn
            timeframe
            designLimit
            status
          }
          hoistJoggings {
            cn
            timeframe
          }
          latestUpdate
        }
        runningHoursTrends {
          cn
          periods {
            periodId
            label
            value
          }
        }
      }
    }
  }
`

/**
 * __useGetUsageRunningHoursQuery__
 *
 * To run a query within a React component, call `useGetUsageRunningHoursQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsageRunningHoursQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsageRunningHoursQuery({
 *   variables: {
 *      dlid: // value for 'dlid'
 *      cns: // value for 'cns'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      timeZone: // value for 'timeZone'
 *      periodType: // value for 'periodType'
 *   },
 * });
 */
export function useGetUsageRunningHoursQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUsageRunningHoursQuery,
    GetUsageRunningHoursQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetUsageRunningHoursQuery, GetUsageRunningHoursQueryVariables>(
    GetUsageRunningHoursDocument,
    options,
  )
}
export function useGetUsageRunningHoursLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUsageRunningHoursQuery,
    GetUsageRunningHoursQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetUsageRunningHoursQuery, GetUsageRunningHoursQueryVariables>(
    GetUsageRunningHoursDocument,
    options,
  )
}
export type GetUsageRunningHoursQueryHookResult = ReturnType<typeof useGetUsageRunningHoursQuery>
export type GetUsageRunningHoursLazyQueryHookResult = ReturnType<
  typeof useGetUsageRunningHoursLazyQuery
>
export type GetUsageRunningHoursQueryResult = Apollo.QueryResult<
  GetUsageRunningHoursQuery,
  GetUsageRunningHoursQueryVariables
>
