import {loginActions} from './constants'
import auth from '../lib/auth/auth'
import i18n from 'i18next'
import { languages } from '../App'

const requestLogin = () => ({
  type: loginActions.LOGIN_REQUEST
})

const loginSuccess = (profile) => ({
  type: loginActions.LOGIN_SUCCESS,
  profile
})

const receiveLogout = () => ({
  type: loginActions.LOGOUT
})

const requestProfileChange = () => ({
  type: loginActions.CHANGE_PROFILE_REQUEST
})

const profileChangeSuccess = profile => ({
  type: loginActions.CHANGE_PROFILE_SUCCESS,
  profile
})

const profileChangeError = (message = '') => ({
  type: loginActions.CHANGE_PROFILE_FAILURE,
  message
})


export const login = () => dispatch => {
  dispatch(requestLogin())
  auth.login((isAutheticated, profile) => {
    if (!isAutheticated) {
      dispatch(logout())
      return
    }

    const language = auth.getLanguage()
    if (language) {
      dispatch(setLanguage(language))
    }

    dispatch(loginSuccess(profile))
  })
}

export const logout = () => dispatch => {
  auth.logout()
  dispatch(receiveLogout())
}

export const handleAuthCallback = (location) => dispatch => {
  auth.handleAuthentication(location)
}

export const setLanguage = (language, localUpdate) => dispatch => {
  if (!Object.keys(languages).includes(language)) {
    return
  }
  if (!localUpdate && auth.getLanguage() !== language) {
    dispatch(requestProfileChange())
    auth.changeProfile({ language }, (err, { idTokenPayload }) => {
      if (!err) {
        i18n.changeLanguage(language)
        dispatch(profileChangeSuccess(idTokenPayload))
      } else {
        dispatch(profileChangeError(`${err.error}: ${err.errorDescription}`))
      }
    })
  } else {
    i18n.changeLanguage(language)
  }
}
