import * as React from 'react'
import { connect } from 'react-redux'
import { translateWithPrefix } from '../../translations/initTranslations'

import {
  toggleCustomerSelection
} from '../../actions/common'
import { fetchAllCustomers, fetchCustomers, searchCustomers } from '../../actions/customers'
import Select from './Select'

class CustomerSelect extends React.Component {
  constructor(props) {
    super(props)

    this.onSelectChange = this.onSelectChange.bind(this)
    this.onMenuScrollToBottom = this.onMenuScrollToBottom.bind(this)
    this.onInputChange = this.onInputChange.bind(this)

    this.state = {
      value: props.value,
      inputValue: props.value,
      searchCustomerIds: [],
      selectedCustomerIds: []
    }
  }

  static defaultProps = {
    isMulti: true,
    autoFocus: false
  }

  updateValue(value) {
    this.setState({
      value: value
    })
    const { onChange } = this.props
    if (onChange) {
      onChange(value)
    }
  }

  onSelectChange(options, selectAll) {
    let selectedValues = []
    if (Array.isArray(options)) {
      selectedValues = options.map(o => o.value)
      this.setState({
        selectedCustomerIds: selectedValues
      })
      // Must check if there is something in the input field to prevent selecting unfiltered options
      if (selectAll === true && this.props.auth.isAuthenticated && this.state.inputValue.length === 0) {
        this.props.fetchAllCustomers()
      }
    } else {
      selectedValues = options.value
      this.setState({
        selectedCustomerIds: [selectedValues],
        inputValue: ''
      })
    }
    this.updateValue(selectedValues)
  }

  onMenuScrollToBottom() {
    if (this.state.inputValue.length === 0 && this.props.auth.isAuthenticated) {
      this.props.fetchCustomers()
    }
  }

  onInputChange(inputValue, action) {
    // Filter to keep the input value unless the input changes
    if (action.action === 'input-change') {
      this.setState({
        selectedCustomerIds: [],
        inputValue
      })
      if (inputValue.length > 0 && this.props.auth.isAuthenticated) {
        this.props.searchCustomers(inputValue.toLowerCase())
      }
      if (this.state.selectedCustomerIds.length === 0) {
        this.updateValue(inputValue)
      }
    }
  }

  getPlaceholder() {
    const { isMulti, t } = this.props
    return isMulti || this.state.selectedCustomerIds.length === 0 ? t('search_customer') : this.state.selectedCustomerIds[0]
  }

  render() {
    const { customers, customerSearch} = this.props
    const { inputValue, value } = this.state

    return (
      <Select
        showSelectAllButtons={this.props.isMulti && (inputValue.length ? customerSearch.result : customers).length > 1}
        isMulti={this.props.isMulti}
        controlShouldRenderValue={false}
        name="select-customer"
        onChange={this.onSelectChange}
        autoFocus={this.props.autoFocus}
        value={value}
        options={(inputValue.length ? customerSearch.result : customers).map(c => ({ value: c.n, label: c.n }))}
        placeholder={this.getPlaceholder()}
        inputValue={inputValue}
        onInputChange={this.onInputChange}
        onMenuScrollToBottom={this.onMenuScrollToBottom}
        hasMore={customerSearch.more}
        isLoading={customerSearch.isLoading}
      />
    )
  }
}

function mapStateToProps(state) {
  const { auth, customers, customerSearch, isCustomerSelectionVisible } = state
  return {
    auth,
    customers,
    customerSearch,
    isCustomerSelectionVisible
  }
}

function mapDispatchToProps(dispatch) {
  return {
    toggleCustomerSelection: (isVisible) => dispatch(toggleCustomerSelection(isVisible)),
    fetchAllCustomers: () => dispatch(fetchAllCustomers()),
    fetchCustomers: () => dispatch(fetchCustomers()),
    searchCustomers: (query, property) => dispatch(searchCustomers(query, property)),
  }
}

export default translateWithPrefix('filter')(connect(mapStateToProps, mapDispatchToProps)(CustomerSelect))
