import * as React from 'react'
import ReactSelect, { components } from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'

import './Select.css'

const styles = {
  control: (base) => ({
    ...base,
  }),
  clearIndicator: (base) => ({
    ...base,
    display: 'none'
  })
}

const theme = (theme) => ({
  ...theme,
  borderRadius: 0,
  spacing: {
    ...theme.spacing,
    menuGutter: 2
  }
})

const Menu = ({ children, selectProps, ...otherProps }) => (
  <components.Menu {...otherProps}>
    {selectProps.showSelectAllButtons
      && (
        <div className="react-select__select-all">
          <button
            className="button button--ghost"
            onClick={() => selectProps.onChange(selectProps.options, true)}
          >
            Select all
          </button>
          <span className="react-select__button-divider"></span>
          <button
            className="button button--ghost"
            onClick={() => selectProps.onChange([])}
          >
            Clear all
          </button>
        </div>
      )}
    {children}
  </components.Menu>
)

const SingleValue = ({ children, selectProps, ...props }) => (
  <components.SingleValue {...props}>
    {selectProps.selectIcon && <FontAwesomeIcon icon={selectProps.selectIcon} className="react-select-icon" />}
    {children}
  </components.SingleValue>
)

const Option = selectProps => (
  <components.Option {...selectProps}>
    <input
      type="checkbox"
      checked={selectProps.isSelected}
      onChange={() => {}}
    />
    <label>{selectProps.label}</label>
  </components.Option>
)

class Select extends React.Component {
  constructor() {
    super();

    this.selectElementRef = React.createRef();
  }

  state = {
    isOpen: false
  }

  componentDidUpdate() {
    if (this.props.autoFocus) {
      this.selectElementRef.current.focus()
    }
  }

  render() {
    const { isMulti, ...props } = this.props

    return (
      <ReactSelect
        className={'react-select-container' + (this.state.isOpen ? ' react-select-container--is-open' : '')}
        classNamePrefix='react-select'
        ref={this.selectElementRef}
        styles={styles}
        theme={theme}
        maxMenuHeight={window.innerHeight * 0.5 || 400}
        onMenuOpen={() => { this.setState({ isOpen: true }) }}
        onMenuClose={() => { this.setState({ isOpen: false }) }}
        components={isMulti ? { Menu, Option } : { SingleValue }}
        isMulti={isMulti}
        hideSelectedOptions={false}
        closeMenuOnSelect={!isMulti}
        backspaceRemovesValue={false}
        {...props}
      />
    )
  }
}

Select.propTypes = {
  onChange: PropTypes.func,
  onMenuScrollToBottom: PropTypes.func,
  hasMore: PropTypes.bool
}

Select.defaultProps = {
  onChange: () => {},
  onMenuScrollToBottom: () => {},
  hasMore: false
}

export default Select
