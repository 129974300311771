import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faTimes
} from '@fortawesome/pro-solid-svg-icons'
import * as React from 'react'
import PropTypes from 'prop-types'

import './FilterBar.css'


class FilterBar extends React.Component {
  render() {
    return (
      <div className={`yd-filterbar ${this.props.isVisible ? 'yd-filterbar--visible' : 'yd-filterbar--hidden'}`}>
        {this.props.children}
        <button
          className="button button--ghost yd-filterbar-close"
          onClick={() => this.props.onClose()}
          type="button"
        >
          <FontAwesomeIcon icon={faTimes}/>
        </button>
      </div>
    )
  }
}

FilterBar.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}

export default FilterBar
