import * as React from 'react'
import DayPickerInput from 'react-day-picker/DayPickerInput'

import './DayPicker.css'
import auth from '../../lib/auth/auth'

const WEEKDAYS_SHORT = {
  en: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
  de: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
}

const MONTHS = {
  en: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  de: [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
  ]
}

class DayPicker extends React.Component {

  render() {
    const locale = auth.getLanguage()
    const dayPickerProps = {
      ...(this.props.dayPickerProps || {}),
      locale,
      months: MONTHS[locale],
      weekdaysShort: WEEKDAYS_SHORT[locale]
    }

    return (
      <DayPickerInput
        {...this.props}
        dayPickerProps={dayPickerProps}
      />
    )
  }
}

export default DayPicker
