import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { dateToMeasurementMonthlyKey, measurementKeyToDate } from '../../actions/measurements_v2'
import { buildTrendChart } from './trendChart'
import { format } from "date-fns"
import { translateWithPrefix } from '../../translations/initTranslations'
import { formatDate } from '../../lib/date-helpers'

const chartStyles = {
  yAxis: {
    title: {
      align: 'low',
      textAlign: 'right',
      rotation: 0,
      x: 0,
      y: 30
    }
  },
  xAxis: {
    labelsStyle: {
      fontSize: 12,
      width: 10
    },
  }
}

const chartOptions = {
  nowLine: undefined,
  lineSeriesYAxisMax: 100,
  valueDecimals: 1,
  markerDisabled: true,
  legendItemClick: (event) => event.target.symbol !== 'diamond',
  xLabelFormatter: function () { return format(measurementKeyToDate(this.value || this.x), 'yyyy') },
  yLabelFormatter: function () { return this.value + '%' }
}

const plotLines = {
  defaultStyle: {
    color: '#000000',
    width: 1
  },
  limitYellow: {
    color: '#ff9900'
  },
  limitRed: {
    color: '#f71c1c'
  },
  now: {
    color: "#000000",
    width: 2
  }
}

const replacedMarker = {
  enabled: true,
  fillColor: '#000000',
  lineColor: '#000000',
  lineWidth: 1,
  symbol: 'diamond',
  radius: 6
}
const endOfServiceLifeMarker = {
  enabled: true,
  fillColor: '#f71c1c',
  lineColor: '#000000',
  lineWidth: 1,
  symbol: 'diamond',
  radius: 6
}

class DWPChart extends React.Component {
  mapDataToSeries() {
    const data = {
      categories: [],
      series: [],
      columnSeries: [],
      step: 24 // default
    }

    if (this.props.data) {
      // Convert to highcharts formatted data for given time frame

      data.categories = this.props.range
      const currentMonthlyKey = dateToMeasurementMonthlyKey(new Date())
      for (const serie of this.props.data) {
        const ds = {
          name: serie.name,
          data: null
        }
        // previous value
        let pv = undefined
        ds.data = data.categories.reduce((data, dk) => {
          //console.info('chart serie',serie)
          let v = serie.trend.mc[dk]
          let replaced = serie.replaced_keys.some((k) => k === dk)
          let endOfServiceLife = false
          if (v === undefined && currentMonthlyKey < dk && pv !== undefined) {
            // no data and future date, use delta
            v = pv + serie.trend.delta * (365 / 12) // delta is daily-average, convert to monthly average
            v = Math.max(v, 0)
            if (v === 0 && pv > 0) {
              endOfServiceLife = true
            }
          } else if (v === undefined) {
            // use previous value
            v = pv
          }
          pv = v
          // Add value to series and markers for this data point, if set
          data.push({
            y: v,
            name: formatDate(measurementKeyToDate(dk), 'MMM yyyy'),
            ...(replaced ? { marker: replacedMarker } : {}),
            ...(endOfServiceLife ? { marker: endOfServiceLifeMarker } : {})
          })
          return data
        }, [])
        data.series.push(ds)
      }
      data.step = Math.floor(data.categories.length / 7)
    }

    //console.info('used chart data', this.props.data)

    return data
  }

  addPlotLine(axis, line, value) {
    axis.plotLines = axis.plotLines || []

    axis.plotLines.push(
      { ...plotLines.defaultStyle, ...line, value: value }
    )
  }

  addReplacedLegend(series) {
    const { t } = this.props
    series.push({
      name: t('component_replaced_legend'),
      data: [],
      color: '#ffffff',
      marker: replacedMarker
    });
  }

  render() {
    const data = this.mapDataToSeries()
    this.addReplacedLegend(data.series)

    let chart = buildTrendChart(data, this.props.titles || [], { ...chartOptions, step: data.step }, chartStyles)
    this.addPlotLine(chart.xAxis, plotLines.now,
      chart.xAxis.categories.indexOf(dateToMeasurementMonthlyKey(new Date())))

    // Use only the first measurement as base line for limits
    if (this.props.data && this.props.data.length > 0) {
      const mmeta = this.props.data[0].mmeta
      if (mmeta) {
        if (mmeta.red_upper_limit) {
          this.addPlotLine(chart.yAxis[0], plotLines.limitRed, mmeta.red_upper_limit)
        }
        if (mmeta.yellow_upper_limit) {
          this.addPlotLine(chart.yAxis[0], plotLines.limitYellow, mmeta.yellow_upper_limit)
        }
      }
    }

    //console.info('generated:', chart)
    return (
      <React.Fragment>
        <HighchartsReact highcharts={Highcharts} options={chart} />
      </React.Fragment>
    )
  }
}

export default translateWithPrefix('asset')(DWPChart)
