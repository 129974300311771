const mockAuthResult = {
  accessToken: 'mock-access-token',
  idToken: 'mock-id-token',
  expiresIn: 60 * 60 * 24 * 7, // one week yay
  idTokenPayload: {}
}

mockAuthResult.idTokenPayload = JSON.parse(localStorage.getItem('auth.mock.idTokenPayload') || '{}')

export const createAuth0InstanceMock = () => {
  const mockAuth0 = {
    isAuthenticated: (cb) => {
      cb(true, {})
    },
    getAccessToken: () => ({}),
    authorize: () => {
      console.info('MOCK AUTH authorize')
      window.location.href = 'http://devbox.local:3000/callback'
      return
    },
    logout: () => {
      console.info('MOCK AUTH logout')
      setTimeout(() => {
        window.location.href = 'http://devbox.local:3000'
      }, 400)
      return
    },
    checkSession: (options, cb) => {
      const ns_options = {}
      for (const key of Object.keys(options)) {
        ns_options['https://statuscontrol.demagcranes.com/' + key] = options[key]
      }
      mockAuthResult.idTokenPayload = {...mockAuthResult.idTokenPayload, ...ns_options}
      localStorage.setItem('auth.mock.idTokenPayload', JSON.stringify(mockAuthResult.idTokenPayload))
      console.info('MOCK AUTH checkSession', mockAuthResult)
      cb(undefined, mockAuthResult)
    },
    parseHash: (cb) => {
      console.info('MOCK AUTH parseHash')
      cb(undefined, mockAuthResult)
    },
    client: {
      userInfo: (access_token, cb) => {
        console.info('MOCK AUTH client.userInfo')

        cb(undefined, {
          "email_verified": true,
          "email": "mock.account@konecranes.com",
          "updated_at": "2018-09-27T15:54:40.545Z",
          "name": "Mock Account",
          "picture": "",
          "user_id": "auth0|58454...",
          "nickname": "mock.account",
          "created_at": "2018-09-27T15:54:40.545Z",
          "sub": "auth0|58454..."
        })
      }
    }
  }
  return mockAuth0
}
