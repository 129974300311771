import { customerActions } from '../actions/constants'

export default (state, action) => {
  switch (action.type) {
    case customerActions.GET_CUSTOMERS:
      return {
        ...state,
        customerSearch: {
          ...state.customerSearch,
          isLoading: true
        }
      }

    case customerActions.SET_CUSTOMERS:
      return {
        ...state,
        customers: [...state.customers, ...(action.customers || [])],
        customerSearch: {
          ...state.customerSearch,
          cursor: action.cursor,
          more: action.more,
          isLoading: false
        }
      }

    case customerActions.SET_SEARCH_CUSTOMERS:
      return {
        ...state,
        customerSearch: {
          ...state.customerSearch,
          result: action.customers,
          isLoading: false
        }
      }

    case customerActions.GET_ASSETS_FOR_CUSTOMER:
      {
        const { customerId } = action
        return {
          ...state,
          assetsByCustomerId: {
            ...state.assetsByCustomerId,
            [customerId]: {
              loading: true
            }
          }
        }
      }

    case customerActions.SET_ASSETS_FOR_CUSTOMER:
      {
        const { customerId, assets } = action
        return {
          ...state,
          assetsByCustomerId: {
            ...state.assetsByCustomerId,
            [customerId]: assets
          }
        }
      }

    default:
      return {
        ...state
      }
  }
}
