import { store } from '../store'
import { dataLakeAPI } from '../lib/api'
import {
  addDataLakeLoading,
  removeDataLakeLoading,
  setDataLakeData,
  setDataLakeError,
} from './datalake'
import {
  DLTYPE_TRANSLATIONS,
  DLTYPE_MEASUREMENTS,
  DLTYPE_LATEST_MEASUREMENTS,
  DLTYPE_MEASUREMENTS_SUMMARY,
  DLTYPE_TIMEFRAME_SUMMARY,
  DLTYPE_ALERTS,
  DLTYPE_EVENT_TRANSLATIONS,
  isLoadingPending,
  isLoaded,
  getRequestUrl,
} from '../lib/measurement'
import { isEcu } from '../lib/asset-helpers'
import { getDataFromIotApi } from '../lib/iot-client'

export const loadTranslations = () => (dispatch) => {
  dispatch(loadFromDataLake(DLTYPE_TRANSLATIONS, null, { id: 'measurements' }))
  dispatch(loadFromDataLake(DLTYPE_TRANSLATIONS, null, { id: 'components' }))
}

export const loadMeasurements = (asset, mId, year) => (dispatch) => {
  dispatch(loadFromDataLake(DLTYPE_MEASUREMENTS, asset, { mId, year }))
}

export const loadLatestMeasurements = (asset) => (dispatch) => {
  const sd = ''
  const ed = ''
  dispatch(loadFromDataLake(DLTYPE_LATEST_MEASUREMENTS, asset, { sd, ed }))
}

export const loadMeasurementsSummary = (asset) => (dispatch) => {
  dispatch(loadFromDataLake(DLTYPE_MEASUREMENTS_SUMMARY, asset))
}

export const loadTimeframeSummary = (asset, sd, ed) => (dispatch) => {
  dispatch(loadFromDataLake(DLTYPE_TIMEFRAME_SUMMARY, asset, { sd, ed }))
}

export const loadAlerts = (asset, sd, ed) => (dispatch) => {
  dispatch(loadFromDataLake(DLTYPE_ALERTS, asset, { sd, ed }))
}

export const loadEventTranslation =
  (eventId, lang, versionId, assetId) => (dispatch) => {
    if (versionId === 'latest') {
      versionId = 'LATEST'
    }
    dispatch(
      loadFromDataLake(DLTYPE_EVENT_TRANSLATIONS, null, {
        eventId,
        lang,
        versionId,
        id: assetId,
      })
    )
  }

const dlTypeRequireIotApi = [DLTYPE_LATEST_MEASUREMENTS, DLTYPE_MEASUREMENTS_SUMMARY]

const isIotApiRequest = (asset, dltype) => isEcu(asset) && dlTypeRequireIotApi.includes(dltype)

const loadFromDataLake = (dltype, asset, params = {}) => {
  const state = store.getState()
  const reqUrl = getRequestUrl(dltype, asset, params)

  // check if pending or loaded
  if (
    isLoadingPending(state, dltype, asset, params) ||
    isLoaded(state, dltype, asset, params)
  ) {
    return () => {}
  }

  return async (dispatch) => {
    dispatch(addDataLakeLoading(reqUrl))
    const res = isIotApiRequest(asset, dltype)
      ? await getDataFromIotApi(asset, dltype)
      : await dataLakeAPI.get(reqUrl)

    switch (res.status) {
      case 200:
        dispatch(setDataLakeData(dltype, res.data, asset, params))
        break
      case 204:
      case 404:
        dispatch(setDataLakeData(dltype, null, asset, params))
        break
      default:
        dispatch(setDataLakeError(dltype, asset, params))
        break
    }
    dispatch(removeDataLakeLoading(reqUrl))
  }
}
