import * as React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { ApolloProvider } from '@apollo/client'

import client from './lib/apollo-client'

import { history } from './store'
import App from './App'
import PrivateRoute from './components/PrivateRoute/PrivateRoute'
import MixedRoute from './components/PrivateRoute/MixedRoute'

import Error404 from './pages/Error/Error404'
import Callback from './pages/Callback/Callback'
import Logout from './pages/Logout/Logout'
import Activation from './pages/Activation/Activation'
import Overview from './pages/Overview/Overview'
import Asset from './pages/Asset/Asset'

const ScrollToTop = () => {
  window.scrollTo(0, 0)
  return null
}

class Root extends React.PureComponent {
  render() {
    return (
      <ConnectedRouter history={history}>
        <App>
          <ApolloProvider client={client}>
            <Switch>
              <Route component={ScrollToTop} />
            </Switch>
            <Switch>
              <Route exact path="/" render={() => <Redirect to="/overview" />} />
              <Route path="/callback" component={Callback} />
              <Route path="/logout" component={Logout} />
              <MixedRoute path="/activation" component={Activation} />
              <PrivateRoute path="/overview" component={Overview} />
              <PrivateRoute path="/customer/:customerId/location/:locationId/asset/:assetId" component={Asset} />
              <Route component={Error404} />
            </Switch>
          </ApolloProvider>
        </App>
      </ConnectedRouter>
    )
  }
}

export default Root
