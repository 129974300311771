import * as React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons'

import './HelpLink.css'
import { getHelpLink } from '../../lib/link-helpers'

export const HELP_ITEM = {
  CONDITION: 'X140717',
  OPERATION_STATISTICS: 'X140718'
}

class HelpLink extends React.Component {

  render() {
    const { to, title } = this.props

    return (
      <div className="yd-help-link-container">
        <a
          className="button button--ghost-light"
          href={`${getHelpLink()}${to}.html`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faInfoCircle} className="yd-help-link-icon" />
          {title}
        </a>
      </div>
    )
  }
}

HelpLink.propTypes = {
  to: PropTypes.oneOf(Object.values(HELP_ITEM)).isRequired,
  title: PropTypes.string.isRequired
}

export default HelpLink
