import * as React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faSearch } from '@fortawesome/pro-solid-svg-icons'
import { withRouter } from 'react-router-dom'
import { translateWithPrefix } from '../../translations/initTranslations'

import './Toolbar.css'
import Select from '../Select/Select'

class Toolbar extends React.Component {

  render() {
    const { title, titleDetails, linkIcon, searchIcon, link, history } = this.props

    return (
      <div className="yd-toolbar-row">
        <div className="yd-toolbar">
          <div className="yd-toolbar-title-container">
            <button
              className="button yd-toolbar-link"
              onClick={() => link ? history.push(link) : null}
            >
              <FontAwesomeIcon icon={linkIcon} />
            </button>
            <h3>
              {title}
              {titleDetails &&
                <span className="yd-toolbar-title-details">{titleDetails}</span>
              }
            </h3>
          </div>
          {this.props.selectProps && (
            <div className="sc-toolbar-select-container">
              <div className="sc-toolbar-select-filter">
                <Select
                  {...this.props.selectProps}
                />
              </div>
              <div className="sc-toolbar-select-icon">
                <FontAwesomeIcon icon={searchIcon} />
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

Toolbar.propTypes = {
  title: PropTypes.string,
  titleDetails: PropTypes.string,
  linkIcon: PropTypes.object,
  link: PropTypes.string
}

Toolbar.defaultProps = {
  title: 'StatusControl',
  titleDetails: null,
  linkIcon: faHome,
  searchIcon: faSearch,
  link: '/overview'
}

export default translateWithPrefix('common')(withRouter(Toolbar))
