import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import './AccordionGroup.css'
import Accordion from '../Accordion/Accordion'
import { translateWithPrefix } from '../../translations/initTranslations'
import { mergeAccordionStates } from '../../actions/common'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faPlusCircle,
  faMinusCircle
} from '@fortawesome/pro-solid-svg-icons'

class AccordionGroup extends React.Component {
  constructor(props) {
    super(props)
    const { accordionStates } = props
    this.state = {
      isOpen: this.props.accordions
        .reduce((acc, cur) => ({ ...acc, [cur.id]: accordionStates[cur.id] || false }), {})
    }
    this.onExpandAllClick = this.onExpandAllClick.bind(this)
    this.onCollapseAllClick = this.onCollapseAllClick.bind(this)
    this.onToggleSection = this.onToggleSection.bind(this)
  }

  mapValues(obj, value) {
    return Object.keys(obj)
      .reduce((acc, cur) => { acc[cur] = value; return acc; }, {})
  }

  saveAccordionStates(states) {
    if (this.props.rememberStates) {
      this.props.mergeAccordionStates(states)
    }
  }

  onExpandAllClick() {
    const isOpen = this.mapValues(this.state.isOpen, true)
    this.setState({
      ...this.state,
      isOpen
    })
    this.saveAccordionStates(isOpen)
  }

  onCollapseAllClick() {
    const isOpen = this.mapValues(this.state.isOpen, false)
    this.setState({
      ...this.state,
      isOpen
    })
    this.saveAccordionStates(isOpen)
  }

  onToggleSection(id) {
    let isOpen = { ...this.state.isOpen }
    isOpen[id] = !isOpen[id]
    this.setState({
      ...this.state,
      isOpen: isOpen
    })
    this.saveAccordionStates(isOpen)
  }

  componentDidUpdate(prevProps) {
    if (this.props.accordions.length !== prevProps.accordions.length) {
      const { accordionStates } = this.props
      this.setState({
        ...this.state,
        isOpen: this.props.accordions
          .reduce((acc, cur) => ({ ...acc, [cur.id]: accordionStates[cur.id] || false }), {})
      })
    }
  }

  render() {
    const { accordions, header, t } = this.props
    const { isOpen } = this.state
    const allOpen = Object.keys(isOpen).every(key => isOpen[key] === true)
    const classes = `accordiongroup`
    const buttonClasses = `accordiongroup-button`

    let toggleBtn = null;
    if (accordions.length > 1) {
      toggleBtn = !allOpen ?
        <div className={buttonClasses} onClick={this.onExpandAllClick}><FontAwesomeIcon icon={faPlusCircle}/> {t('expand_all')}</div> :
        <div className={buttonClasses} onClick={this.onCollapseAllClick}><FontAwesomeIcon icon={faMinusCircle}/> {t('collapse_all')}</div>
    }

    return (
      <div className={classes}>
        <h3 className={`${header ? 'yd-header-border ' : ''}yd-header--main`}>{header || ''}{toggleBtn}</h3>
        <div className="clearfix"></div>
        {accordions
          .map((acc) => {
            return acc.content ? (
              <Accordion
                {...acc}
                key={acc.id}
                isOpen={isOpen[acc.id]}
                onToggle={this.onToggleSection}
              >
              </Accordion>
            ) : (null)
          }
          )}
      </div>
    )
  }
}

AccordionGroup.propTypes = {
  accordions: PropTypes.arrayOf(PropTypes.object.isRequired),
  rememberStates: PropTypes.bool
}

AccordionGroup.defaultProps = {
  rememberStates: true
}

const mapStateToProps = state => {
  return {
    accordionStates: state.accordionStates
  }
}

const mapDispatchToProps = dispatch => {
  return {
    mergeAccordionStates: (states) => dispatch(mergeAccordionStates(states))
  }
}

export default translateWithPrefix('common')(connect(mapStateToProps, mapDispatchToProps)(AccordionGroup))
