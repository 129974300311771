export const STATUS = {
  ALERT: 'status_alert',
  WARNING: 'status_warning',
  OK: 'status_ok',
  OFFLINE: 'status_offline'
}

export const VALUES = {
  STATUS: 'common.status',
  HOIST_DWP: 'asset.hoist_dwp',
  TROLLEY_SERVICE_LIFE: 'asset.trolley_service_life',
  BRAKE_SERVICE_LIFE: 'asset.brake_service_life',
  CONTACTOR_SERVICE_LIFE: 'asset.contactor_service_life',
  OPERATION_HOURS: 'asset.operation_hours',
  DRIVE_TIME: 'asset.drive_time',
  LOAD: 'asset.load',
  EVENTS_AND_ALERTS: 'asset.events_and_alerts'
}

export const VALUE_GROUPS = {
  'common.status': VALUES.STATUS,
  'asset.condition': [VALUES.HOIST_DWP, VALUES.TROLLEY_SERVICE_LIFE, VALUES.BRAKE_SERVICE_LIFE, VALUES.CONTACTOR_SERVICE_LIFE],
  //'asset:operation_statistics': [VALUES.OPERATION_HOURS, /* VALUES.LOAD, */ VALUES.EVENTS_AND_ALERTS]
}

export const MEASUREMENT_TYPE = {
  BRAKE_SERVICE_LIFE: 'V_1',
  CONTACTOR_K1_SERVICE_LIFE: 'V_2',
  CONTACTOR_K2_SERVICE_LIFE: 'V_3',
  CONTACTOR_K3_SERVICE_LIFE: 'V_4',
  TROLLEY_SERVICE_LIFE: 'V_5',
  HOIST_DWP: 'V_6',
  DRIVE_TIME: 'V_7',
  EMERGENCY_STOPS: 'V_8',
  MAX_SLIP: 'V_9',
  MAX_ROTATION: 'V_10',
  BRAKE: 'V_11',
  BRAKING_DISTANCE: 'V_12',
  ROPE_DRUM_BRAKE: 'V_13',
  TROLLEY_TRAVEL_PATH: 'V_14',
  OPERATION_HOURS: 'V_15',
  CONTACTOR_K1_SERVICE_LIFE_HOIST: 'V_20',
  CONTACTOR_K1_SERVICE_LIFE_CRAB: 'V_21',
  CONTACTOR_K1_SERVICE_LIFE_CRANE: 'V_22',
  CONTACTOR_K2_SERVICE_LIFE_HOIST: 'V_30',
  CONTACTOR_K2_SERVICE_LIFE_CRAB: 'V_31',
  CONTACTOR_K2_SERVICE_LIFE_CRANE: 'V_32',
  CONTACTOR_K3_SERVICE_LIFE_HOIST: 'V_40',
  CONTACTOR_K3_SERVICE_LIFE_CRAB: 'V_41',
  CONTACTOR_K3_SERVICE_LIFE_CRANE: 'V_42',
  CONTROLS_DWP: 'V_316',
  BRAKE_SERVICE_LIFE_HOIST: 'V_50',
  BRAKE_SERVICE_LIFE_CRAB: 'V_51',
  BRAKE_SERVICE_LIFE_CRANE: 'V_52',
  OVERLOADS: 'V_103',
  LOAD_SPECTRUM_00_10: 'V_200',
  LOAD_SPECTRUM_10_20: 'V_201',
  LOAD_SPECTRUM_20_30: 'V_202',
  LOAD_SPECTRUM_30_40: 'V_203',
  LOAD_SPECTRUM_40_50: 'V_204',
  LOAD_SPECTRUM_50_60: 'V_205',
  LOAD_SPECTRUM_60_70: 'V_206',
  LOAD_SPECTRUM_70_80: 'V_207',
  LOAD_SPECTRUM_80_90: 'V_208',
  LOAD_SPECTRUM_90_100: 'V_209',
  LOAD_SPECTRUM_100_110: 'V_210',
  LOAD_SPECTRUM_110_120: 'V_211',
  LOAD_SPECTRUM_OVER_120: 'V_212'
}

// Map grouping of measurement values
export const MEASUREMENT_VALUES = {
  [VALUES.HOIST_DWP]: [MEASUREMENT_TYPE.HOIST_DWP],
  [VALUES.TROLLEY_SERVICE_LIFE]: [MEASUREMENT_TYPE.TROLLEY_SERVICE_LIFE],
  [VALUES.BRAKE_SERVICE_LIFE]: [
    MEASUREMENT_TYPE.BRAKE_SERVICE_LIFE_HOIST,
    MEASUREMENT_TYPE.BRAKE_SERVICE_LIFE_CRAB,
    MEASUREMENT_TYPE.BRAKE_SERVICE_LIFE_CRANE,
    MEASUREMENT_TYPE.BRAKE_SERVICE_LIFE
  ],
  [VALUES.CONTACTOR_SERVICE_LIFE]: [
    MEASUREMENT_TYPE.CONTACTOR_K1_SERVICE_LIFE_HOIST,
    MEASUREMENT_TYPE.CONTACTOR_K1_SERVICE_LIFE_CRAB,
    MEASUREMENT_TYPE.CONTACTOR_K1_SERVICE_LIFE_CRANE,
    MEASUREMENT_TYPE.CONTACTOR_K2_SERVICE_LIFE_HOIST,
    MEASUREMENT_TYPE.CONTACTOR_K2_SERVICE_LIFE_CRAB,
    MEASUREMENT_TYPE.CONTACTOR_K2_SERVICE_LIFE_CRANE,
    MEASUREMENT_TYPE.CONTACTOR_K3_SERVICE_LIFE_HOIST,
    MEASUREMENT_TYPE.CONTACTOR_K3_SERVICE_LIFE_CRAB,
    MEASUREMENT_TYPE.CONTACTOR_K3_SERVICE_LIFE_CRANE,
    MEASUREMENT_TYPE.CONTROLS_DWP
  ],
  [VALUES.OPERATION_HOURS]: [MEASUREMENT_TYPE.OPERATION_HOURS],
  [VALUES.DRIVE_TIME]: [MEASUREMENT_TYPE.DRIVE_TIME],
  [VALUES.LOAD]: [
    MEASUREMENT_TYPE.LOAD_SPECTRUM_00_10,
    MEASUREMENT_TYPE.LOAD_SPECTRUM_10_20,
    MEASUREMENT_TYPE.LOAD_SPECTRUM_20_30,
    MEASUREMENT_TYPE.LOAD_SPECTRUM_30_40,
    MEASUREMENT_TYPE.LOAD_SPECTRUM_40_50,
    MEASUREMENT_TYPE.LOAD_SPECTRUM_50_60,
    MEASUREMENT_TYPE.LOAD_SPECTRUM_60_70,
    MEASUREMENT_TYPE.LOAD_SPECTRUM_70_80,
    MEASUREMENT_TYPE.LOAD_SPECTRUM_80_90,
    MEASUREMENT_TYPE.LOAD_SPECTRUM_90_100,
    MEASUREMENT_TYPE.LOAD_SPECTRUM_100_110,
    MEASUREMENT_TYPE.LOAD_SPECTRUM_110_120,
    MEASUREMENT_TYPE.LOAD_SPECTRUM_OVER_120
  ],
  [VALUES.EVENTS_AND_ALERTS]: [
    MEASUREMENT_TYPE.EMERGENCY_STOPS,
    MEASUREMENT_TYPE.MAX_SLIP,
    MEASUREMENT_TYPE.MAX_ROTATION,
    MEASUREMENT_TYPE.BRAKE,
    MEASUREMENT_TYPE.BRAKING_DISTANCE,
    MEASUREMENT_TYPE.ROPE_DRUM_BRAKE,
    MEASUREMENT_TYPE.OVERLOADS]
}

export const MEASUREMENT_TRANSLATION_KEYS = {
  [MEASUREMENT_TYPE.CONTACTOR_K1_SERVICE_LIFE]: 'contactor_k1_service_life',
  [MEASUREMENT_TYPE.CONTACTOR_K1_SERVICE_LIFE_HOIST]: 'contactor_k1_service_life',
  [MEASUREMENT_TYPE.CONTACTOR_K1_SERVICE_LIFE_CRAB]: 'contactor_k1_service_life',
  [MEASUREMENT_TYPE.CONTACTOR_K1_SERVICE_LIFE_CRANE]: 'contactor_k1_service_life',
  [MEASUREMENT_TYPE.CONTACTOR_K2_SERVICE_LIFE]: 'contactor_k2_service_life',
  [MEASUREMENT_TYPE.CONTACTOR_K2_SERVICE_LIFE_HOIST]: 'contactor_k2_service_life',
  [MEASUREMENT_TYPE.CONTACTOR_K2_SERVICE_LIFE_CRAB]: 'contactor_k2_service_life',
  [MEASUREMENT_TYPE.CONTACTOR_K2_SERVICE_LIFE_CRANE]: 'contactor_k2_service_life',
  [MEASUREMENT_TYPE.CONTACTOR_K3_SERVICE_LIFE]: 'contactor_k3_service_life',
  [MEASUREMENT_TYPE.CONTACTOR_K3_SERVICE_LIFE_HOIST]: 'contactor_k3_service_life',
  [MEASUREMENT_TYPE.CONTACTOR_K3_SERVICE_LIFE_CRAB]: 'contactor_k3_service_life',
  [MEASUREMENT_TYPE.CONTACTOR_K3_SERVICE_LIFE_CRANE]: 'contactor_k3_service_life',
  [MEASUREMENT_TYPE.HOIST_DWP]: 'hoist_dwp',
  [MEASUREMENT_TYPE.TROLLEY_SERVICE_LIFE]: 'trolley_service_life',
  [MEASUREMENT_TYPE.BRAKE_SERVICE_LIFE]: 'brake_service_life',
  [MEASUREMENT_TYPE.BRAKE_SERVICE_LIFE_HOIST]: 'brake_service_life',
  [MEASUREMENT_TYPE.BRAKE_SERVICE_LIFE_CRAB]: 'brake_service_life',
  [MEASUREMENT_TYPE.BRAKE_SERVICE_LIFE_CRANE]: 'brake_service_life',
}
