import { dataLakeAPI } from '../lib/api'
import { customerActions } from './constants'
import { addFilter, setFilter } from './common'
import { store } from '../store'
import { mapSearchResult, findCustomer } from '../lib/customer-helpers'

const getCustomers = () => ({
  type: customerActions.GET_CUSTOMERS
})

const setCustomers = (customers, cursor, more) => ({
  type: customerActions.SET_CUSTOMERS,
  customers,
  cursor,
  more
})

const setSearchCustomers = (customers) => ({
  type: customerActions.SET_SEARCH_CUSTOMERS,
  customers
})

const getAssetsForCustomerId = (customerId) => ({
  type: customerActions.GET_ASSETS_FOR_CUSTOMER,
  customerId
})

const setAssetsForCustomerId = (customerId, assets) => ({
  type: customerActions.SET_ASSETS_FOR_CUSTOMER,
  customerId,
  assets
})

export const getAssetsByCustomerId = (customerId) => {
  const state = store.getState()
  const customer = findCustomer(state, customerId)

  return !state.assetsByCustomerId[customerId] && customer
    ? async (dispatch) => {
      const assets = []
      dispatch(getAssetsForCustomerId(customerId))

      const resLocations = await dataLakeAPI.get(`/customers/${customer.id}/locations`)
      if (resLocations.status === 200) {
        for (const location of resLocations.data) {
          const resAssets = await dataLakeAPI.get(`/customers/${customer.id}/locations/${location.id}/assets`)
          if (resAssets.status === 200) {
            assets.push.apply(
              assets,
              resAssets.data.reduce((result, asset) => result.concat(asset.et === 'crane' ? [{ ...asset, customer, location }] : []), [])
            )
          }
        }
      }
      dispatch(setAssetsForCustomerId(customerId, assets))
    }
    : () => { }
}

export const fetchAllCustomers = () => {
  const { customers, customerSearch } = store.getState()
  let hasMore = customerSearch.more
  return (customers.length === 0 || hasMore)
    ? async (dispatch) => {
      dispatch(getCustomers())
      const data = {
        type: 'customer',
        cname_lowercase: '*'
      }
      do {
        const resCustomers = await dataLakeAPI.post(`/search?cursor=${customerSearch.cursor}`, data)
        if (resCustomers.status === 200 && resCustomers.data.hits) {
          const { cursor, hit, found, start } = resCustomers.data.hits
          const newCustomers = mapSearchResult(hit)
          hasMore = start + newCustomers.length < found
          dispatch(addFilter('customerId', newCustomers.map(c => c.id)))
          dispatch(setCustomers(newCustomers, cursor, hasMore))
        } else {
          dispatch(setCustomers([]))
          break
        }
      } while (hasMore)
    }
    : () => { }
}

export const fetchCustomers = () => {
  const { customers, customerSearch } = store.getState()
  return (customers.length === 0 || customerSearch.more)
    ? async (dispatch) => {
      dispatch(getCustomers())
      const data = {
        type: 'customer',
        cname_lowercase: '*'
      }
      const resCustomers = await dataLakeAPI.post(`/search?cursor=${customerSearch.cursor}`, data)
      if (resCustomers.status === 200 && resCustomers.data.hits) {
        const { cursor, hit, found, start } = resCustomers.data.hits
        const newCustomers = mapSearchResult(hit)
        if (customers.length === 0 && newCustomers.length === 1) {
          dispatch(setFilter('customerId', [newCustomers[0].id]))
          dispatch(getAssetsByCustomerId([newCustomers[0].id]))
        }
        dispatch(setCustomers(newCustomers, cursor, start + newCustomers.length < found))
      } else {
        dispatch(setCustomers([]))
      }
    }
    : () => { }
}

export const searchCustomers = (query, property = 'cname_lowercase') => {
  return async (dispatch) => {
    dispatch(getCustomers())
    const data = {
      type: 'customer',
      [property]: `*${query}*`
    }
    // encodeWhitespace=true is needed to process whitespaces in the search query
    const resCustomers = await dataLakeAPI.post('/search?encodeWhitespace=true', data)
    if (resCustomers.status === 200 && resCustomers.data.hits) {
      const { hit } = resCustomers.data.hits
      const customers = mapSearchResult(hit)
      dispatch(setSearchCustomers(customers))
    } else {
      dispatch(setSearchCustomers([]))
    }
  }
}