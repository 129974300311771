import React, { Component } from 'react'
import { connect } from 'react-redux'

import { handleAuthCallback } from '../../actions/user'
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner'


class Callback extends Component {

  componentDidMount () {
    const { location, handleAuthCallback } = this.props
    handleAuthCallback(location)
  }

  render () {
    const { error } = this.props.auth

    return (
      <h1 style={{ textAlign: 'center', marginTop: '40px' }}>
        {error}
        <LoadingSpinner loading={!error} />
      </h1>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleAuthCallback: (location) => dispatch(handleAuthCallback(location))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Callback)