import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import {buildBarChart} from './barChart'

const chartStyles = {
  colors: [ '#486280' ]
}

const chartOptions = {
  colorByPoint: true,
  legend: false
}

export default class AssetBarChart extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      chart: this.createChartDataFromProps()
    }
  }

  componentDidMount() {
    // fix to bar chart not rendering on show/hide of accordion
    this.setState({ chart: this.createChartDataFromProps() })
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.setState({ chart: this.createChartDataFromProps() })
    }
  }

  createChartDataFromProps() {
    return buildBarChart(this.mapDataToSeries(), chartOptions, chartStyles)
  }

  mapDataToSeries() {
    const data = {
      categories: [],
      series: {
        data: []
      }
    }

    if (this.props.data) {
      data.categories = this.props.data.map((d) => d.name)
      data.series.data = this.props.data.map((d) => Math.round(d.info[0] / 3600))
    }

    return data
  }

  render() {
    return (
      <div style={{height: '200px'}}>
        <HighchartsReact
          options={this.state.chart}
          highcharts={Highcharts}
          allowChartUpdate={true}
          updateArgs={[true,true,true]}
          callback={(chart) => {}}
        />
      </div>
    )
  }
}
