import React, { useState } from 'react'
import {
  StatisticsCondition,
  resolveTimeframe,
  TIME_PERIODS,
} from '@kc/alphabrands-component-library'

import {
  useGetConditionBmuQuery, useGetConditionMotorQuery,
  useGetConditionPredictionsQuery,
  useGetConditionStatusesQuery, useGetConditionTemperatureQuery,
  useGetRecommendationsQuery,
} from '../../generated/graphql'

import { getQueryVariables } from './getQueryVariables'
import { parseQueryString } from '../../lib/query-string'
import Backlink from '../../components/Backlink/Backlink'

const getNavigationOptions = (location) => {
  const queryParam = parseQueryString(location.search) || {}
  return {
    initialCategory: queryParam?.category,
    sectionToScrollTo: queryParam?.open,
  }
}

const StatisticsConditionPage = ({ asset, options, location }) => {
  const [timeframe, onTimeframeChange] = useState(resolveTimeframe(TIME_PERIODS.LAST_12_MONTHS))

  const data = {
    recommendations: useGetRecommendationsQuery(getQueryVariables(asset, timeframe, 5)),
    conditionStatuses: useGetConditionStatusesQuery(getQueryVariables(asset, timeframe, 6)),
    conditionPredictions: useGetConditionPredictionsQuery(getQueryVariables(asset, timeframe, 7)),
    bmu: useGetConditionBmuQuery(getQueryVariables(asset, timeframe, 8)),
    motorCondition: useGetConditionMotorQuery(getQueryVariables(asset, timeframe, 9)),
    controllerTemperature: useGetConditionTemperatureQuery(getQueryVariables(asset, timeframe, 10)),
  }

  const navigationOptions = getNavigationOptions(location)

  return (
    <>
      <Backlink text={options.t('statuscontrol:common.back', 'Back')}/>
      <StatisticsCondition
        data={data}
        options={{
          ...options,
          ...navigationOptions,
          timeframe,
          onTimeframeChange,
      }} />
    </>
  )
}

export default StatisticsConditionPage
