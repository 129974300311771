import React from 'react'
import {
  resolveTimeframe,
  StatisticsOverview,
  TIME_PERIODS,
} from '@kc/alphabrands-component-library'

import {
  useGetActiveAlertsQuery,
  useGetDailyAlertsQuery,
  useGetTotalAlertsQuery,
  useGetPassiveAlertsQuery,
  useGetLatestEventQuery,
  useGetRecommendationsQuery,
  useGetConditionOverviewQuery, useGetBmuOverviewQuery, useGetUsageOverviewQuery,
} from '../../generated/graphql'

import { getQueryVariables } from './getQueryVariables'

const StatisticsOverviewPage = ({ asset, options }) => {
  const timeframe = resolveTimeframe(TIME_PERIODS.LAST_3_MONTHS)

  const alertVariables = getQueryVariables(asset, timeframe)
  const passiveAlertsTimeframe = resolveTimeframe(TIME_PERIODS.LAST_DAY)
  const passiveAlertsVariables = getQueryVariables(asset, passiveAlertsTimeframe)
  const data = {
    recommendations: useGetRecommendationsQuery(getQueryVariables(asset, timeframe, 1)),
    conditionOverview: useGetConditionOverviewQuery(getQueryVariables(asset, timeframe, 2)),
    bmuOverview: useGetBmuOverviewQuery(getQueryVariables(asset, timeframe, 3)),
    usageOverview: useGetUsageOverviewQuery(getQueryVariables(asset, timeframe, 4)),
    latestEvent: useGetLatestEventQuery(alertVariables),
    totalAlerts: useGetTotalAlertsQuery(alertVariables),
    activeAlerts: useGetActiveAlertsQuery(alertVariables),
    passiveAlerts: useGetPassiveAlertsQuery(passiveAlertsVariables),
    dailyAlerts: useGetDailyAlertsQuery(alertVariables),
  }

  return (
    <div className="statistic-overview">
      <StatisticsOverview data={data} options={{...options, showNewFeatures: false}} />
    </div>
  )
}

export default StatisticsOverviewPage
