import * as React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle as faCircleSolid, faGlobeAmericas, faMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons'
import { faCircle } from '@fortawesome/free-regular-svg-icons'
import { translateWithPrefix } from '../../translations/initTranslations'
import ClampLines from 'react-clamp-lines'
import i18nIsoCountries from 'i18n-iso-countries'

import AccordionGroup from '../../components/AccordionGroup/AccordionGroup'
import { getFilteredAssets } from '../../lib/asset'
import { loadLatestMeasurements, loadMeasurementsSummary, loadTranslations } from '../../actions/measurements'
import {
  DLTYPE_LATEST_MEASUREMENTS,
  DLTYPE_MEASUREMENTS_SUMMARY,
  isLoadingPending,
  getDataFromState,
} from '../../lib/measurement'
import {
  STATUS,
  VALUES,
  VALUE_GROUPS,
  MEASUREMENT_VALUES
} from '../../lib/measurement-constants'
import {
  getStatus,
  getStatusClass,
  isMeasurementValueExpired,
  formattedConditionValue
} from '../../lib/measurement-helpers'
import auth from '../../lib/auth/auth'

import './Overview.css'
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner'
import Select from '../../components/Select/Select'
import { getAllCustomers } from '../../lib/customer-helpers'
import { getEquipmentId } from '../../lib/asset-helpers'

const SORT = {
  PRIORITY: 'sort_priority',
  ALPHABETICAL: 'sort_alphabetical'
}

class Overview extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      statusFilter: [],
      sort: SORT.PRIORITY,
      value: VALUES.STATUS
    }
    this.toggleStatusFilter = this.toggleStatusFilter.bind(this)
    this.handleSortChange = this.handleSortChange.bind(this)
    this.handleValueChange = this.handleValueChange.bind(this)
    props.loadTranslations()
    this.loadMeasurements(getFilteredAssets(props), this.state.value)
  }


  componentDidUpdate(prevProps) {
    const nextAssets = getFilteredAssets(this.props)
    const prevAssets = getFilteredAssets(prevProps)

    const nextIds = nextAssets.map(a => getEquipmentId(a)).sort()
    const prevIds = prevAssets.map(a => getEquipmentId(a)).sort()
    const changed = !(nextIds.length === prevIds.length && nextIds.every((v, i) => v === prevIds[i]))

    if (nextAssets.length && changed) {
      this.loadMeasurements(nextAssets, this.state.value)
    }
  }

  isSummary(value) {
    return (value || this.state.value) === VALUES.STATUS
  }

  toggleStatusFilter(status) {
    const { statusFilter } = this.state
    const selected = statusFilter.includes(status)
    this.setState({
      ...this.state,
      statusFilter: selected
        ? statusFilter.filter(s => s !== status)
        : statusFilter.concat([status])
    })
  }

  getSelectOptions(type) {
    const { t, tWithoutPrefix } = this.props
    switch (type) {
      case 'sort':
        return Object.keys(SORT).map(key => ({ label: t(SORT[key]), value: SORT[key] }))
      case 'value':
        return Object.keys(VALUE_GROUPS).reduce((result, key) => {
          if (Array.isArray(VALUE_GROUPS[key])) {
            const group = { label: tWithoutPrefix(key), value: key }
            group.options = VALUE_GROUPS[key].map(key2 => ({ label: tWithoutPrefix(key2), value: key2 }))
            result.push(group)
          } else {
            result.push({ label: tWithoutPrefix(VALUE_GROUPS[key]), value: VALUE_GROUPS[key] })
          }
          return result
        }, [])
      default:
        return []
    }
  }

  handleSortChange(selected) {
    this.setState({
      ...this.state,
      sort: selected.value
    })
  }

  handleValueChange(selected) {
    this.setState({
      ...this.state,
      value: selected.value
    })
    this.loadMeasurements(getFilteredAssets(this.props), selected.value)
  }

  loadMeasurements(assets, value) {
    const { loadLatestMeasurements, loadMeasurementsSummary } = this.props
    assets.forEach(asset => {
      this.isSummary(value) ? loadMeasurementsSummary(asset) : loadLatestMeasurements(asset)
    })
  }

  isLoadingAsset(asset) {
    return isLoadingPending(
      this.props,
      this.isSummary() ? DLTYPE_MEASUREMENTS_SUMMARY : DLTYPE_LATEST_MEASUREMENTS,
      asset
    )
  }

  getMeasurementTranslations() {
    const { translations } = this.props
    if (!translations || !Array.isArray(translations.measurements)) {
      return []
    }
    return translations.measurements
  }

  getMeasurementTypes(value) {
    return this.getMeasurementTranslations().filter(m =>
      (MEASUREMENT_VALUES[value] || []).includes(m.measurement_type_id)
    )
  }

  getMeasurementData(asset) {
    const mIds = this.getMeasurementTypes(this.state.value).map(m => m.measurement_type_id)
    const isSummary = this.isSummary()
    const measurementsForAsset = getDataFromState(
      this.props,
      isSummary ? DLTYPE_MEASUREMENTS_SUMMARY : DLTYPE_LATEST_MEASUREMENTS,
      asset
    )

    let measurement, value, date
    if (measurementsForAsset) {
      value = Number.MAX_VALUE
      if (isSummary) {
        if (measurementsForAsset.wc_30) {
          measurement = this.getMeasurementTranslations()
            .find(m => m.measurement_type_id === measurementsForAsset.wc_30.vid)
          value = measurementsForAsset.wc_30.v
        }
      } else {
        const worstMeasurement = measurementsForAsset
          .filter(m => mIds.includes(m.vid))
          .sort((a, b) => a.v - b.v)[0]
        if (worstMeasurement) {
          measurement = this.getMeasurementTranslations()
            .find(m => m.measurement_type_id === worstMeasurement.vid)
          if (!isMeasurementValueExpired(measurement, new Date(worstMeasurement.d))) {
            date = worstMeasurement.d
            value = worstMeasurement.v
          }
        }
      }
    }
    const status = getStatus(value, measurement, date ? new Date(date) : undefined)
    return {
      measurement,
      value: !measurement || !status || status === STATUS.OFFLINE ? Math.MAX_VALUE : value,
      date,
      status
    }
  }

  getDataForAssets(assets) {
    return assets.reduce((result, asset) => {
      result[asset.id] = this.getMeasurementData(asset)
      return result
    }, {})
  }

  getAssetCardsForCustomer(assetsForCustomer, dataForAssets) {
    const { t, tWithoutPrefix } = this.props
    const { statusFilter, sort } = this.state
    const isSummary = this.state.value === VALUES.STATUS

    return assetsForCustomer
      .filter(asset => dataForAssets[asset.id])
      .sort((a, b) => {
        switch (sort) {
          case SORT.PRIORITY:
            const getValue = (x) => {
              if (dataForAssets[x.id] && !isNaN(dataForAssets[x.id].value)) {
                return dataForAssets[x.id].value
              }
              return Number.MAX_VALUE
            }
            return getValue(a) - getValue(b)
          case SORT.ALPHABETICAL:
            if (a.ni < b.ni) { return -1 }
            if (a.ni > b.ni) { return 1 }
            return 0
          default:
            return 0
        }
      })
      .map(asset => {
        const isLoading = this.isLoadingAsset(asset)
        const { status, value, date, measurement } = dataForAssets[asset.id]
        const dontShow = statusFilter.length && !statusFilter.includes(status)
        return dontShow ? null : (
          <Link to={`/customer/${asset.customer.id}/location/${asset.location.id}/asset/${getEquipmentId(asset)}/statistics`} key={asset.id} className="yd-card-link">
            <div className="yd-card">
              <h2 className="yd-card-header" title={asset.ni}>
                <ClampLines text={asset.ni || t('unnamed_asset')} lines={2} buttons={false} />
              </h2>

              <div className="yd-card-meta">
                <ul>
                  <li>{asset.ty}</li>
                  <li>{asset.sn || t('unknown_serial_number')}</li>
                  <li title={asset.customer.n} className="truncate"><FontAwesomeIcon icon={faGlobeAmericas} /> {asset.customer.n}</li>
                  <li title={asset.location.n} className="truncate"><FontAwesomeIcon icon={faMapMarkerAlt} /> {asset.location.n}</li>
                </ul>
              </div>

              <div className="yd-status">
                <small>{date || t('status')}</small>
                {!isLoading && isSummary &&
                  <p className={`yd-status-message yd-status--${getStatusClass(status)}`}>
                    <FontAwesomeIcon icon={faCircle} size="lg" /> {t(status)}
                  </p>
                }
                {!isLoading && !isSummary &&
                  <p className={`yd-status-value yd-status-value--${getStatusClass(status)}`}>
                    {(value === undefined || value === Number.MAX_VALUE || !measurement) ? t('status_offline') : `${formattedConditionValue(value)} ${tWithoutPrefix(this.state.value)}`}
                  </p>
                }
                {isLoading &&
                  <p className={`yd-status-loading`}>
                    <LoadingSpinner size="lg" loading={isLoading} />
                  </p>
                }
              </div>

            </div>
          </Link>
        )
      })
      .filter(card => card !== null)
  }

  renderFilters() {
    const { t } = this.props
    const { statusFilter } = this.state
    const valueOptions = this.getSelectOptions('value')
    const sortOptions = this.getSelectOptions('sort')

    return (
      <div className="yd-overview-filters">
        <div className="yd-overview-status-filters">
          {
            Object.values(STATUS).map(status => {
              const selected = statusFilter.includes(status)
              const classNames = `yd-overview-filter-label yd-overview-filter-label--${getStatusClass(status)} ${selected ? 'yd-overview-filter-label--selected' : ''}`
              return (
                <div
                  key={status}
                  onClick={() => this.toggleStatusFilter(status)}
                  className={classNames}>
                  <FontAwesomeIcon icon={faCircleSolid} /> {t(status)}
                </div>
              )
            })
          }
        </div>
        <div className="yd-overview-value">
          <Select
            isMulti={false}
            isClearable={false}
            isSearchable={false}
            onChange={this.handleValueChange}
            options={valueOptions}
            value={valueOptions.reduce((a, c) => a.concat(c, c.options || []), []).find(opt => opt.value === this.state.value)}
          />
        </div>
        <div className="yd-overview-sort">
          <Select
            isMulti={false}
            isClearable={false}
            isSearchable={false}
            onChange={this.handleSortChange}
            options={sortOptions}
            value={sortOptions.find(opt => opt.value === this.state.sort)}
          />
        </div>
      </div>
    )
  }

  renderAssetCards() {
    const { t, assetsByCustomerId, filters } = this.props
    const customers = getAllCustomers(this.props)
    const customerIds = (filters || {}).customerId || []
    const showCustomerAccordions = customers.length > 1

    const customerAccordions = customerIds.map(customerId => {
      const customer = customers.find(c => c.id === customerId)
      if (!customer) {
        return null
      }
      const accordionId = `overview-customer-${customerId}`
      const assetsForCustomer = Array.isArray(assetsByCustomerId[customerId])
        ? assetsByCustomerId[customerId]
        : []
      const dataForAssets = this.getDataForAssets(assetsForCustomer)
      const cards = this.getAssetCardsForCustomer(assetsForCustomer, dataForAssets)
      const statusForCustomer = getStatusClass(
        Object.keys(dataForAssets).length
          ? Object.values(dataForAssets)
            .sort((a, b) => a.value > b.value)[0].status
          : null
      )
      const loading = assetsForCustomer.filter(asset => this.isLoadingAsset(asset)).length > 0
      const noCards = (
        <div key={customerId} className="yd-cards__empty">
          {!loading && t('no_assets')}
          <LoadingSpinner loading={loading} />
        </div>
      )

      return {
        id: accordionId,
        title: customer.n,
        content: cards.length ? cards : noCards,
        status: statusForCustomer,
        large: true,
        titleExtra: i18nIsoCountries.getName(customer.cy, auth.getLanguage()),
        faIcon: faGlobeAmericas,
        loading: loading
      }
    }).filter(acc => acc && acc.id)

    return (
      <div className="yd-cards">
        {
          !showCustomerAccordions
            ? customerAccordions.filter(acc => acc).map(acc => acc.content)
            : <AccordionGroup accordions={customerAccordions}>
              </AccordionGroup>
        }
      </div>
    )
  }

  render() {
    const { filters, t, customers, customerSearch, assetsByCustomerId } = this.props
    const loading = (customerSearch.isLoading && !customers.length) || Object.values(assetsByCustomerId).filter(a => a.loading).length !== 0
    const isCustomerSelected = filters && filters.customerId && filters.customerId.length > 0

    return (
      <div>
        <div className="flex-row">
          <main>
            {loading && <p className="yd-select-customer-text"><LoadingSpinner loading={loading} /></p>}
            {!loading && customers.length === 0 && <p className="yd-select-customer-text">{t('no_customers')}</p>}
            {!loading && customers.length > 0 && !isCustomerSelected && <p className="yd-select-customer-text">{t('select_customer')}</p>}
            {!loading && isCustomerSelected &&
              <div>
                <h2 className="yd-header-border yd-header--main">{t('assets')}</h2>
                {this.renderFilters()}
                {this.renderAssetCards()}
              </div>
            }
          </main>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    customers: state.customers,
    assetsByCustomerId: state.assetsByCustomerId,
    filters: state.filters,
    translations: state.translations,
    [DLTYPE_LATEST_MEASUREMENTS]: state[DLTYPE_LATEST_MEASUREMENTS],
    [DLTYPE_MEASUREMENTS_SUMMARY]: state[DLTYPE_MEASUREMENTS_SUMMARY],
    pendingUrls: state.pendingUrls,
    customerSearch: state.customerSearch
  }
}

const mapDispatchToProps = dispatch => {
  return {
    loadLatestMeasurements: (asset, mId) => dispatch(loadLatestMeasurements(asset, mId)),
    loadMeasurementsSummary: (asset, mId) => dispatch(loadMeasurementsSummary(asset, mId)),
    loadTranslations: () => dispatch(loadTranslations())
  }
}

export default translateWithPrefix('common')(connect(mapStateToProps, mapDispatchToProps)(Overview))
