export const mapSearchResult = (hit) => {
  return hit.map(item => ({
    ...item.fields,
    id: item.fields.cid,
    n: item.fields.cname
  }))
}

export const getAllCustomers = (state) => {
  const { customers, customerSearch } = state
  const combinedList = [...customers, ...customerSearch.result]
  return combinedList.filter((v, i) => combinedList.findIndex(t => t.id === v.id) === i)
}

export const findCustomer = (state, customerId) => {
  return getAllCustomers(state).find(c => c.id === customerId)
}