import { isValid } from "date-fns"
import { mapTimePeriodToTimeRange } from "../lib/timeframe-helpers"
import {getEquipmentId} from '../lib/asset-helpers'

export function offsetDate(date, offsetDays) {
  date.setDate(date.getDate() + offsetDays)
  return date
}

export const LOOKUP_PREVIOUS = -1
export const LOOKUP_NEXT = 1

export function lookupDateKey(data, date, step) {
  const d = new Date(date.valueOf())
  let key = dateToMeasurementDailyKey(offsetDate(d, step))
  let fkey = data.dc_keys[0]
  let lkey = data.dc_keys[data.dc_keys.length - 1]
  while (step && (data.dc[key] === undefined || data.dc[key] === null)) {
    // check key out-of-bounds with simple string key comparsion (keys need to be sortable)
    if (step > 0) {
      if (lkey <= key) {
        key = lkey
        break
      }
    } else {
      if (fkey >= key) {
        key = fkey
        break
      }
    }
    key = dateToMeasurementDailyKey(offsetDate(d, step))
  }
  return key
}

export function dateToMeasurementDailyKey(date) {
  let s = 'd'
  s += String(date.getFullYear()).slice(-2)
  let m = date.getMonth() + 1
  s += m < 10 ? '0' + String(m) : String(m)
  let d = date.getDate()
  s += d < 10 ? '0' + String(d) : String(d)
  return s
}

export function measurementYearlyKey(year) {
  return 'y' + year
}

export function measurementMonthlyKey(year, month) {
  let s = 'm'
  s += String(year < 100 ? year + 2000 : year).slice(-2)
  s += month < 10 ? '0' + String(month) : String(month)
  return s
}

export function dateToMeasurementMonthlyKey(date) {
  return measurementMonthlyKey(date.getFullYear(), date.getMonth() + 1)
}

export function generateMeasurementMonthlyKeysRange(date, start, end) {
  let count = (end - start) || 48
  if (count < 0) {
    return []
  }
  let year = date.getFullYear()
  let month = date.getMonth()
  let months = year * 12 + month
  if (start < 0) {
    // add history
    months -= -start - 1
  }
  const range = []
  while (count--) {
    range.push(measurementMonthlyKey(Math.floor(months / 12), months % 12 + 1))
    months++
  }
  return range
}

export function generateMeasurementYearRange(date, start, end) {
  let count = (end - start) || 4
  if (count < 0) {
    return []
  }
  let year = date.getFullYear()
  if (start < 0) {
    // add history
    year -= -start - 1
  }
  const range = []
  while (count--) {
    range.push(year)
    year++
  }
  return range
}

export function generateMeasurementDailyKeysRange(date, start, end) {
  let count = (end - start) || 7
  if (count < 0) {
    return []
  }
  const from = new Date(date.valueOf())
  if (start < 0) {
    // history
    from.setDate(date.getDate() - -start + 1)
  }
  const range = []
  while (count--) {
    range.push(dateToMeasurementDailyKey(from))
    from.setDate(from.getDate() + 1)
  }
  return range
}

export function measurementKeyToDate(key) {
  // Quick way without date-fns overhead
  const d = key.slice(1)
  let date = null
  switch (key[0]) {
    case 'd':
      if (d.length === 6) {
        // format yymmdd
        date = new Date(`20${d.slice(0, 2)}-${d.slice(2, 4)}-${d.slice(4, 6)}`)
      } else if (d.length === 8) {
        // format yyyymmdd
        date = new Date(`${d.slice(0, 4)}-${d.slice(4, 6)}-${d.slice(6, 8)}`)
      }
      date.setHours(23, 59, 59, 999)
      break
    case 'm':
      if (d.length === 4) {
        // format yymm
        date = new Date(`20${d.slice(0, 2)}-${d.slice(2, 4)}-01`)
      } else if (d.length === 6) {
        // format yyyymm
        date = new Date(`${d.slice(0, 4)}-${d.slice(4, 6)}-${d.slice(6, 8)}`)
      }
      date.setMonth(date.getMonth() + 1, 0)
      date.setHours(23, 59, 59, 999)
      break
    default:
  }
  return date
}

export function preprocessMeasurements(measurementsData, measurementId, timeFrame, asset) {
  const { measurements, latestMeasurements } = measurementsData
  // FIXME: method is called quite many times, memoize or something else would be good to have
  if (!measurements[measurementId] || measurements[measurementId].error) {
    return {}
  }
  //console.time('preprocessMeasurements.' + measurementId)
  const all = {}
  const m = measurements[measurementId]

  if (!m) {
    return {}
  }
  // NOTE: Commented out due to all are loaded now in one call
  // if (timeFrame) {
  //   // Check if we have all data for the requested time frame before processing
  //   const years = Object.keys(m)
  //   if (timeFrame.years().some((y) => { return !years.includes(measurementYearlyKey(y))})) {
  //     return {}
  //   }
  // }

  for (const year in m) {
    const components = m[year]
    if (!components) {
      // usually result of null (of response 404)
      continue
    }
    // reduce all yearly collections to single set
    for (const componentId in components) {
      if (componentId === 'error' && components[componentId] === true) {
        continue
      }
      const c = all[componentId] = all[componentId] || {}

      c.latest_mdata = undefined
      if (latestMeasurements) {
        c.latest_mdata = latestMeasurements.find(m => m.vid === measurementId && m.cn === componentId)
      }
      // copy/merge daily and monthly data to new collection
      c.dc = c.dc || {}
      Object.assign(c.dc, components[componentId].dc)

      c.mc = c.mc || {}
      Object.assign(c.mc, components[componentId].mc)

      // copy yearly data
      c.yc = c.yc || {}
      c.yc[year] = components[componentId].c
      // calculate all time average
      if (c.avg === undefined) {
        c.avg = Number(components[componentId].c)
      } else {
        c.avg = (c.avg + Number(components[componentId].c)) / 2
      }
    }
  }
  // Create some additional helper data
  for (const componentId in all) {
    const c = all[componentId]
    c.dc_keys = Object.keys(c.dc).sort() // possibly not needed
    c.dc_first = c.dc_keys[0]
    c.dc_latest = c.dc_keys[c.dc_keys.length - 1]

    c.mc_keys = Object.keys(c.mc).sort() // possibly not needed
    c.mc_first = c.mc_keys[0]
    c.mc_latest = c.mc_keys[c.mc_keys.length - 1]

    c.yc_keys = Object.keys(c.yc).sort() // possibly not needed
    c.yc_first = c.yc_keys[0]
    c.yc_latest = c.yc_keys[c.yc_keys.length - 1]

    // Latest value as short cut
    c.latest = c.yc[c.yc_latest]

    // Delta for prediction values
    if (c.latest_mdata) {
      c.delta = c.latest_mdata.dlt || 0
    } else if (c.dc_keys.length > 0) {
      // Fallback to data available
      c.delta = Math.min(0, (c.dc[c.dc_latest] - c.dc[c.dc_first]) / c.dc_keys.length)
    } else {
      c.delta = 0
    }

    // replace X and ROOT to serial number from measurements response (fallback to equipmentId)
    if (componentId === 'X' || componentId === 'ROOT') {
      all[measurementsData.measurements.sn || getEquipmentId(asset)] = all[componentId]

      delete all[componentId]
    }
  }
  //console.info('all', all)
  //console.timeEnd('preprocessMeasurements.' + measurementId)
  return all
}

export class TimeFrame {
  constructor(startDate, endDate) {
    this.now = new Date()
    this.days = Math.ceil((endDate - startDate) / 86400000)
    this.startDate = startDate
    this.endDate = endDate
    this.startDailyKey = dateToMeasurementDailyKey(startDate)
    this.endDailyKey = dateToMeasurementDailyKey(endDate)
    this.startMonthlyKey = dateToMeasurementMonthlyKey(startDate)
    this.endMonthlyKey = dateToMeasurementMonthlyKey(endDate)
  }

  years() {
    const years = [this.startDate.getFullYear()]
    const c = Math.floor(this.days / 365)
    for (let i = 0; i < c; i++) {
      years.push(years[0] + i + 1)
    }
    return years
  }
}

export const getTimeFrame = (timeFilter) => {
  if (!timeFilter) {
    return null
  }

  const timeRange = mapTimePeriodToTimeRange(timeFilter) || {}
  const { startDate, endDate } = timeRange
  if (!startDate || !endDate || !isValid(startDate) || !isValid(endDate)) {
    return null
  }

  return new TimeFrame(startDate, endDate)
}
