import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faTimesCircle
} from '@fortawesome/free-regular-svg-icons'
import React from 'react'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'

import './Modal.css'


class Modal extends React.Component {
  UNSAFE_componentWillMount() {
    ReactModal.setAppElement('#root')
  }

  render() {
    return (
      <div>
        <ReactModal
          isOpen={this.props.isOpen}
          contentLabel={this.props.title}
          onRequestClose={this.props.onClose}
          overlayClassName="overlay"
          className="modal"
        >
        <div>
            <h1 className="modal-header">{this.props.title}</h1>
            <button
              className="button button--ghost modal-close"
              onClick={() => this.props.onClose()}
              type="button"
            >
              <FontAwesomeIcon icon={faTimesCircle} size="lg" />
            </button>
            </div>
            <div className="modal-content">
              {this.props.children}
            </div>
        </ReactModal>
      </div>
    )
  }
}

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
}

export default Modal
