import { format } from 'date-fns'
import { languages } from '../App'
import auth from './auth/auth'

const addEnglishDialect = (language) => language === 'en'? 'en-GB' : language

const locales = Object.keys(languages).reduce((acc, cur) => ({ ...acc, [cur]: require(`date-fns/locale/${addEnglishDialect(cur)}`).default }), {})

export const formatDate = (date, formatStr) => format(date, formatStr, { locale: locales[auth.getLanguage()]})

