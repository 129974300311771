import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faChevronDown, faSpinner } from '@fortawesome/pro-solid-svg-icons'
import { format } from 'date-fns'

import './ListAccordionGroup.css'

class ListAccordionGroup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: 'boolean' === typeof props.isOpen ? props.isOpen : true
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.isOpen !== prevProps.isOpen) {
      this.setState({
        ...this.state,
        isOpen: this.props.isOpen
      })
    }
  }

  render() {
    const { title, titleExtra, date, showDate, status, loading } = this.props
    const { isOpen } = this.state
    const classes = `list-accordion-group ${!isOpen ? 'list-accordion-group__closed' : ''}`
    const icon = isOpen ? faChevronUp : faChevronDown

    return (
      <div className={classes}>
        <div className="list-accordion-group-datetime">
          {showDate && <div>{format(date, 'MMM d, yyyy')}</div>}
        </div>
        <div className="list-accordion-group-title" onClick={() => this.setState({ ...this.state, isOpen: !this.state.isOpen })}>
          <span className={`list-accordion-group-status list-accordion-group-status__${status.toLowerCase()}`}></span>
          <span className="list-accordion-group-title-text truncate">
            {titleExtra && <span className="list-accordion-group-title-extra truncate">{titleExtra}</span>}
            {title}
          </span>
          <div className="list-accordion-group-toggle"><FontAwesomeIcon icon={loading ? faSpinner : icon} spin={loading} /></div>
        </div>
        {isOpen &&
          <div className="list-accordion-group-content">
            {this.props.children}
          </div>
        }
      </div>
    )
  }
}

ListAccordionGroup.propTypes = {
  title: PropTypes.string,
  titleExtra: PropTypes.string,
  isOpen: PropTypes.bool,
  status: PropTypes.string,
  loading: PropTypes.bool,
  date: PropTypes.instanceOf(Date),
  showDate: PropTypes.bool
}

ListAccordionGroup.defaultProps = {
  title: '',
  titleExtra: null,
  isOpen: false,
  status: 'normal',
  loading: false,
  date: null,
  showDate: true
}

export default ListAccordionGroup
