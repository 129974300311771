import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { buildTrendChart } from './trendChart'

const chartStyles = {

}

const chartOptions = {
  labelsLeft: true,
  legendGray: true,
  yLabelFormatter: function () { return this.value + '%'; },
  valueDecimals: 1
}

const mapSeries = (input) => {
  return input.map((component) => {
    const total = component.values.reduce((acc, val) => acc + val, 0)
    const percentages = component.values.map((val) => 100 * (val / total))
    return {
      name: component.name,
      yAxis: 0,
      data: percentages,
      stack: "seconds"
    }
  })
}

export default class LoadSpectrumChart extends React.Component {

  mapDataToSeries() {
    const categories = this.props.categories || []
    const columnSeries = this.props.data ? mapSeries(this.props.data) : []

    return {
      categories: categories,
      columnSeries: columnSeries
    }
  }

  getOptions() {
    const data = this.mapDataToSeries()
    return buildTrendChart(data, this.props.titles || [], chartOptions, chartStyles)
  }

  render() {
    return (
      <React.Fragment key={Math.random()}>
        <HighchartsReact highcharts={Highcharts} options={this.getOptions()} ref="chartComponent" />
      </React.Fragment>
    )
  }
}
