import { dataLakeActions } from './constants'

export const addDataLakeLoading = (reqUrl) => ({
  reqUrl,
  type: dataLakeActions.DATALAKE_REQUEST_ADD,
})

export const removeDataLakeLoading = (reqUrl) => ({
  reqUrl,
  type: dataLakeActions.DATALAKE_REQUEST_REMOVE,
})

export const setDataLakeData = (dltype, data, asset, params) => {
  return {
    dltype,
    data,
    asset,
    ...params,
    type: dataLakeActions.SET_DATALAKE_DATA,
  }
}

export const setDataLakeError = (dltype, asset, params) => {
  return {
    dltype,
    asset,
    ...params,
    type: dataLakeActions.SET_DATALAKE_ERROR,
  }
}
