import * as React from 'react'

import getPath from './get-path'
import HeaderContainer from './HeaderContainer'
import HeaderMenu from './HeaderMenu'

import './Header.css'

const TOGGLES = {
  MENU: 'menu_state',
  PROFILE: 'profile_state',
}

class HeaderView extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      [TOGGLES.MENU]: false,
      [TOGGLES.PROFILE]: false,
    }
    this.handleKeyAndMouse = this.handleKeyAndMouse.bind(this)
    this.handleToggle = this.handleToggle.bind(this)
    this.handleProfileClick = this.handleProfileClick.bind(this)
  }

  UNSAFE_componentWillMount() {
    document.addEventListener('click', this.handleKeyAndMouse)
    document.addEventListener('keydown', this.handleKeyAndMouse)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleKeyAndMouse)
    document.removeEventListener('keydown', this.handleKeyAndMouse)
  }

  handleKeyAndMouse(e) {
    let closeSelections = false

    // handle keyboard event
    if (e instanceof KeyboardEvent) {
      if (e.key === 'Escape') {
        closeSelections = true
      }
    }

    // handle mouse event
    if (e instanceof MouseEvent) {
      let filterBarOrButtonClick = false
      getPath(e).forEach(el => {
        const { classList } = el
        if (!classList) {
          return
        }
        if (classList.contains('yd-filterbar') && el.classList.contains('yd-filterbar--visible')) {
          filterBarOrButtonClick = true
        }
        if (classList.contains('yd-header__customer-selection') ||
          classList.contains('yd-header__menu-toggle') ||
          classList.contains('yd-header__menu-item-toggle')) {
          filterBarOrButtonClick = true
        }
      })
      if (!filterBarOrButtonClick) {
        closeSelections = true
      }
    }

    // close selections
    if (closeSelections) {
      this.closeSelections()
    }
  }

  closeSelections() {
    if (this.props.isCustomerSelectionVisible) {
      this.props.toggleCustomerSelection(false)
    }
    Object.keys(TOGGLES).forEach(toggle => {
      if (this.state[TOGGLES[toggle]]) {
        this.handleToggle(TOGGLES[toggle])
      }
    })
  }

  handleToggle(property) {
    this.setState({
      ...this.state,
      [property]: !this.state[property],
    })
  }

  handleProfileClick(e) {
    e.preventDefault()
    this.handleToggle(TOGGLES.PROFILE)
  }

  handleLanguageChange(selectedLanguage) {
    const { setLanguage } = this.props
    setLanguage(selectedLanguage.value)
  }

  render() {
    const {
      t,
      language,
      children,
      profile,
      updatingProfile,
      showActivation,
      activationLink,
      helpLink,
      termsAndConditionsLink,
      notificationSettingsLink,
      handleLanguageChange,
      languages,
    } = this.props
    return (
      <div className="yd-header">
        <HeaderContainer t={t} toggleMenu={() => this.handleToggle(TOGGLES.MENU)}>
          <HeaderMenu
            t={t}
            showActivation={showActivation}
            activationLink={activationLink}
            helpLink={helpLink}
            termsAndConditionsLink={termsAndConditionsLink}
            notificationSettingsLink={notificationSettingsLink}
            profile={profile}
            updatingProfile={updatingProfile}
            showMenu={this.state[TOGGLES.MENU]}
            showProfile={this.state[TOGGLES.PROFILE]}
            toggleProfile={() => this.handleToggle(TOGGLES.PROFILE)}
            handleProfileClick={this.handleProfileClick.bind(this)}
            candidateLanguage={language}
            languages={languages}
            handleLanguageChange={handleLanguageChange}
          />
          {children}
        </HeaderContainer>
      </div>
    )
  }
}

export default HeaderView
