import * as React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/pro-solid-svg-icons'

class LoadingSpinner extends React.Component {
  render() {
    const { loading, className, size } = this.props
    if (!loading) {
      return null
    }
    return (
      <FontAwesomeIcon
        className={className}
        icon={faSpinner}
        size={size}
        spin
      />
    )
  }
}

LoadingSpinner.propTypes = {
  loading: PropTypes.bool,
  className: PropTypes.string,
  size: PropTypes.string
}

LoadingSpinner.defaultProps = {
  loading: false,
  className: '',
  size: '1x'
}


export default LoadingSpinner
