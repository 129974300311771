import filterReducer from './filters'
import customersReducer from './customers'
import datalakeReducer from './datalake'
import userReducer from './user'
import storageReducer from './storage'
import {
  uiActions,
  messageActions
} from '../actions/constants'
import { DEFAULT_TIME_FILTER } from '../lib/timeframe-constants'

export const appDefaultState = {
  auth: {
    isAuthenticated: false,
  },
  profile: null,
  filters: {
    time: DEFAULT_TIME_FILTER
  },
  isCustomerSelectionVisible: false,
  isMainMenuEnabled: false,
  isTimeFrameSelectionVisible: false,
  messages: [],
  favoriteCustomerIds: [],
  customers: [],
  customerSearch: {
    cursor: 'initial',
    more: true,
    result: [],
    isLoading: false
  },
  assetsByCustomerId: {},
  translations: {},
  accordionStates: {}
}

const defaultReducer = (state = appDefaultState, action) => {
  switch (action.type) {
    case messageActions.ADD_MESSAGE:
      return {
        ...state,
        messages: state.messages.concat(action.message)
      }

    case messageActions.REMOVE_MESSAGE:
      let i = -1
      return {
        ...state,
        messages: state.messages.filter(() => {
          ++i
          return i !== action.index
        })
      }

    case uiActions.TOGGLE_CUSTOMER_SELECTION:
      return {
        ...state,
        isCustomerSelectionVisible: action.isVisible
      }

    case uiActions.TOGGLE_MAIN_MENU:
      return {
        ...state,
        isMainMenuEnabled: true,
        isMainMenuVisible: action.isVisible
      }

    case uiActions.DISABLE_MAIN_MENU:
      return {
        ...state,
        isMainMenuEnabled: false
      }

    case uiActions.TOGGLE_TIMEFRAME_SELECTION:
      return {
        ...state,
        isTimeFrameSelectionVisible: action.isVisible
      }

    case uiActions.MERGE_ACCORDION_STATES:
      return {
        ...state,
        accordionStates: {
          ...state.accordionStates,
          ...action.states
        }
      }

    default:
      return {
        ...state
      }
  }
}

export default [
  defaultReducer,
  filterReducer,
  customersReducer,
  datalakeReducer,
  userReducer,
  storageReducer
]
