import { loginActions } from '../actions/constants'
import appDefaultState from './index'

export default (state, action) => {
  switch (action.type) {
    case loginActions.LOGIN_REQUEST:
      return {
        ...state,
        auth: {
          isAuthenticated: false
        }
      }

    case loginActions.LOGIN_SUCCESS:
      return {
        ...state,
        auth: {
          isAuthenticated: true,
        },
        profile: action.profile
      }

    case loginActions.LOGIN_FAILURE:
      return {
        ...state,
        auth: {
          isAuthenticated: false,
          error: action.message
        },
        profile: null
      }

    case loginActions.LOGOUT:
      return {
        ...appDefaultState,
        auth: {
          isAuthenticated: false,
          isLoggingOut: true
        },
        profile: null
      }

    case loginActions.CHANGE_PROFILE_REQUEST:
      return {
        ...state,
        updatingProfile: true
      }

    case loginActions.CHANGE_PROFILE_SUCCESS:
      return {
        ...state,
        updatingProfile: false,
        profile: { ...state.profile, ...action.profile }
      }

    case loginActions.CHANGE_PROFILE_FAILURE:
      return {
        ...state,
        updatingProfile: false,
        updatingProfileError: action.message
      }

    default:
      return { ...state }
  }
}
