import { dataLakeActions } from '../actions/constants'
import { setDataToState, setErrorToState } from '../lib/measurement'

export default (state, action) => {
  switch (action.type) {

    case dataLakeActions.DATALAKE_REQUEST_ADD:
      return {
        ...state,
        pendingUrls: state.pendingUrls
          ? [...state.pendingUrls, action.reqUrl]
          : [action.reqUrl]
      }

    case dataLakeActions.DATALAKE_REQUEST_REMOVE:
      return {
        ...state,
        pendingUrls: (state.pendingUrls || []).filter(url => url !== action.reqUrl)
      }

    case dataLakeActions.SET_DATALAKE_DATA:
      return setDataToState(state, action)

    case dataLakeActions.SET_DATALAKE_ERROR:
      return setErrorToState(state, action)

    default:
      return state
  }
}
