import * as React from 'react'
import { connect } from 'react-redux'

import { logout } from '../../actions/user'


class Logout extends React.PureComponent {
  UNSAFE_componentWillMount() {
    const { logout } = this.props
    logout()
  }

  render() {
    return null
  }
}

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout())
})

export default connect(mapStateToProps, mapDispatchToProps)(Logout)
