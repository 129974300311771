import * as React from 'react'

import StatisticsDmr from './StatisticsDmr'
import StatisticsEcu from '../Statistics/StatisticsEcu'
import { isEcu } from '../../lib/asset-helpers'

const StatisticsSelector = (props) => {
  if (!props.asset) {
    return null
  }

  if (isEcu(props.asset)) {
    return <StatisticsEcu {...props} />
  }

  return <StatisticsDmr {...props} />
}

export default StatisticsSelector
