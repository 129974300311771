import * as React from 'react'
import { Route } from 'react-router-dom'
import { connect } from 'react-redux'
import { login } from '../../actions/user'
import authLib from '../../lib/auth/auth'

class MixedRoute extends React.PureComponent {

  componentDidMount() {
    const { login, auth } = this.props

    const isAuthenticatedInSso = authLib.isAutheticatedInSso()
    if (isAuthenticatedInSso && !auth.isAuthenticated && !auth.isLoggingOut) {
      login()
    }
  }

  render() {
    return <Route {...this.props} />
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    login: () => dispatch(login())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MixedRoute)
