import * as React from 'react'


class error404 extends React.Component {
  render() {
    return (
      <div className="flex-row">
        <main>
          <h1>Error 404</h1>
          <h2>Page does not exists</h2>
        </main>
      </div>
    )
  }
}

export default error404
