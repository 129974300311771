import * as React from 'react'
import { connect } from 'react-redux'
import { translateWithPrefix } from '../../translations/initTranslations'
import { format } from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle as faCircleSolid, faExpandAlt as faExpand } from '@fortawesome/pro-solid-svg-icons'
import './Alerts.css'
import './Info.css'
import { loadEventTranslation } from '../../actions/measurements'
import { getDataFromState, isLoadingPending, DLTYPE_EVENT_TRANSLATIONS } from '../../lib/measurement'
import Modal from '../../components/Modal/Modal'

class AlertDetails extends React.Component {

	constructor(props) {
		super(props)
		this.translation = this.getDefaultTranslation()
		this.state = { modalOpen: false }
		this.modalClicked = this.modalClicked.bind(this)
		this.modalClosed = this.modalClosed.bind(this)
		this.isModalOpen = this.isModalOpen.bind(this)
	}

	componentDidMount() {
		this.loadTranslation(this.props.eventId, this.props.lang, this.props.versionId)
	}

	loadTranslation() {
		const { loadEventTranslation } = this.props
		const { eventId, lang, versionId } = this.props
		const isLoading = isLoadingPending(
	      this.props,
	      DLTYPE_EVENT_TRANSLATIONS,
	      null,
	      { eventId, lang, versionId }
	    )

		if (!isLoading) {
			loadEventTranslation()
		}
	}

	getTextWithLinebreaks(text) {
		return text.split('\n').map((t, i) => <div key={i}>{t}</div>)
	}

	getDefaultTranslation() {
		return {
			possible_malfunction_or_causes: '',
			description: '',
			control_system_behaviour: '',
		}
	}

	modalClicked() {
		this.setState({modalOpen: true})
	}

	modalClosed() {
		this.setState({modalOpen: false})
	}

	isModalOpen() {
		if (this.state) {
			const { modalOpen } = this.state
			return modalOpen
		} else {
			return false
		}
	}


	render() {
	    const { t, translation, eventId, date, showDate, status, title, classes } = this.props
		return (<div>
					<div onClick={this.modalClicked} className={classes}>
						{ showDate &&
							<div className="alert-details-date-time">
								<div>
									<span>{format(new Date(date), 'MMM d, yyyy')}</span>
								</div>
								<div>
									<span>{format(date, 'HH:mm')}</span>
								</div>
							</div>
						}
						{ !showDate &&
						<div className="alert-details-time">
							<span>{format(date, 'HH:mm')}</span>
						</div>
						}
						<div className="alert-details-header">
							<div className={`alert-details-title status__${status ? status.toLowerCase() : 'normal'}`}>
								<div className="alert-details-component">{ title }</div>
								<div className="alert-details-header-content">
									<div className="yd-alert-diagnostic">
									  <div className="yd-alert-diagnostic-title">{t('alert_error_code')} {eventId}</div>
									  <div className="yd-alert-diagnostic-description"> {translation && translation.description ? this.getTextWithLinebreaks(translation.description) : ''}</div>
									  <div className="list-accordion-group-toggle"><FontAwesomeIcon icon={faExpand} /></div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div>
						<Modal
							isOpen={this.isModalOpen()}
							contentLabel="foo"
							title={t(`alert_title_${status}`)}
							onClose={this.modalClosed}>
							<div className="flex-row">
								<table className="table-two-cols">
									<tbody>
										<tr>
											<th>{t('date')}</th>
											<td>{ format(date, 'MMM d, yyyy') }</td>
										</tr>
										<tr>
											<th>{t('time')}</th>
											<td>{ format(date, 'HH:mm') }</td>
										</tr>
										<tr>
											<th className="alert-details-alert-type">{t('type')}
												<span className={`alert-details-alert-type--${status}`}><FontAwesomeIcon icon={faCircleSolid} /></span>
											</th>
											<td>{t(`alert_title_${status}`)}</td>
										</tr>
										<tr>
											<th>{t('alert_error_code')}</th>
											<td>{ eventId }</td>
										</tr>
										<tr>
											<th>{t('alert_description')}</th>
											<td>{translation && translation.description ? this.getTextWithLinebreaks(translation.description) : ''}</td>
										</tr>
										<tr>
											<th>{t('alert_possible_cause')}</th>
											<td>{translation && translation.possible_malfunction_or_causes ? this.getTextWithLinebreaks(translation.possible_malfunction_or_causes) : ''}</td>
										</tr>
										<tr>
											<th>{t('alert_control_system_behaviour')}</th>
											<td>{(translation && translation.behaviour_of_control_system) ? this.getTextWithLinebreaks(translation.behaviour_of_control_system) : ''}</td>
										</tr>
									</tbody>
								</table>
							</div>
			        	</Modal>
			      	</div>
				</div>)
	}
}

const mapStateToProps = (state, ownProps) => {
	const { eventId, lang, versionId, modalOpen } = ownProps
	let versionIdToUse
	if (!versionId || versionId === 'latest') {
		versionIdToUse = 'LATEST'
	} else {
		versionIdToUse = versionId
	}
  	return {
	    eventId,
	    lang,
	    versionId,
	    modalOpen,
	    translation: getDataFromState(state, DLTYPE_EVENT_TRANSLATIONS, null, { eventId, lang, versionId: versionIdToUse })
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loadEventTranslation: () => dispatch(loadEventTranslation(ownProps.eventId, ownProps.lang, ownProps.versionId, ownProps.assetId))
  }
}

export default translateWithPrefix('asset')(connect(mapStateToProps, mapDispatchToProps)(AlertDetails))
