// configuration
const stage = process.env.REACT_APP_STAGE || 'local'
const isLocal = stage === 'local'
const isMock = stage === 'mock'
const emulatedStage = isLocal || isMock ? 'qa' : undefined

const getTermsAndConditionsDomain = () => {
  switch (stage) {
    case 'prod':
    case 'pilot':
      return 'https://eukcrgcidmauth0.blob.core.windows.net/customersso/assets/demag/terms-and-conditions/status-control'
    case 'qa':
      return 'https://eukcrgcidmauth0.blob.core.windows.net/customersso-qa/assets/demag/terms-and-conditions/status-control'
    default:
      return 'https://eukcrgcidmauth0.blob.core.windows.net/customersso-dev/assets/demag/terms-and-conditions/status-control'
  }
}

const getDataLakeApiUrl = (stage, domain) => {
  switch (stage) {
    case 'mock':
      return 'http://localhost:9000'
    case 'local':
      return `${getDomain(emulatedStage)}/cdl-api-v3`
    default:
      return `${domain}/cdl-api-v3`
  }
}

const getProcessLayerApiUrl = (stage) => {
  switch (stage) {
    case 'mock':
      return 'http://localhost:9000'
    case 'local':
      return `https://api.qa.process-layer.konecranes.io`
    default:
      return `https://api.${stage}.process-layer.konecranes.io`
  }
}

const getStatusControlApiKey = (stage) => {
  switch (stage) {
    case 'prod':
    case 'pilot':
      return 'aN9b5eFcaf891KSMtuoj946qIlwLuiTgaPLG47r5'
    case 'local':
    case 'qa':
      return '5drHyXSydt190LKPVJbk37yS9jjveLWv1noIvrol'
    case 'dev':
      return 'bCC24cyjtplTXojdYyn39lNKdnh6Ubw75xcLDn63'
    default:
      return null
  }
}

const getDomain = (stage) => {
  switch (stage) {
    case 'mock':
    case 'local':
      return 'http://devbox.local:3000'
    case 'prod':
      return 'https://statuscontrol.demagcranes.com'
    default:
      return `https://statuscontrol-${stage}.demagcranes.com`
  }
}

const getGoogleSiteKey = (stage) => {
  switch (stage) {
    case 'prod':
      return '6LfFaHcUAAAAAAFIyO7cBOKNbIaXKff6iOX67DLy'
    default:
      return '6LdLaXcUAAAAALgAv8fVNmQbNyM2DtrG6FCqwLiF'
  }
}

const domain = getDomain(stage)
const config = {
  TERMS_AND_CONDITIONS_DOMAIN: getTermsAndConditionsDomain(stage),
  STATUS_CONTROL_API_KEY: getStatusControlApiKey(stage),
  DATALAKE_API_URL: getDataLakeApiUrl(stage, domain),
  PROCESS_LAYER_API_URL: getProcessLayerApiUrl(stage),
  ACTIVATION_API_URL: isMock ? 'localhost:9000' : `${isLocal ? getDomain(emulatedStage) : domain}/api`,
  GOOGLE_RECAPTCHA_SITE_KEY: getGoogleSiteKey(stage),
}

export default {
  ...config,
  stage,
  emulatedStage,
}
