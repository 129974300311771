import { storageActions, YD_USER_DATA_KEY } from '../actions/constants'

export default (state, action) => {
  switch (action.type) {
    case storageActions.SET_FAVORITE_CUSTOMER_IDS:
      const { favoriteCustomerIds } = action
      // store also on local storage by extending user data
      if (state.user) {
        localStorage.setItem(YD_USER_DATA_KEY, JSON.stringify({
          ...state.user,
          favoriteCustomerIds
        }))
      }
      return {
        ...state,
        favoriteCustomerIds
      }
    default:
      return state
  }
}
