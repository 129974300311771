export const getFilteredAssets = (state) => {
  const { assetsByCustomerId, filters } = state
  const { customerId } = filters
  if (!customerId || !Object.keys(assetsByCustomerId).length) {
    return []
  }

  return Object.keys(assetsByCustomerId)
    .reduce((result, key) => {
      if (Array.isArray(assetsByCustomerId[key])) {
        result.push.apply(
          result,
          customerId.includes(key) ? assetsByCustomerId[key] : []
        )
      }
      return result
    }, [])
}
