import * as React from 'react'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAngleRight,
  faAngleLeft,
  faCheck,
  faPlusCircle
} from '@fortawesome/pro-solid-svg-icons'
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { translateWithPrefix } from '../../translations/initTranslations'
import uuid from 'uuid/v4'
import cloneDeep from 'lodash/cloneDeep'
import ReCAPTCHA from "react-google-recaptcha"

import './Activation.css'
import { steps } from './Steps'
import { activationAPI } from '../../lib/api'
import { addMessage } from '../../actions/messages'
import config from '../../config'
import auth from '../../lib/auth/auth'
import Select from '../../components/Select/Select'
import CustomerSelect from '../../components/Select/CustomerSelect'
import Toolbar from '../../components/Toolbar/Toolbar'
import Accordion from '../../components/Accordion/Accordion'

// if false, then honeypot method used
const useGoogleRecaptcha = false

class Activation extends React.Component {

  constructor(props) {
    super(props)
    this.state = this.getDefaultState()
    this.generateChildFieldGroups(this.state.steps)
    this.onSubmit = this.onSubmit.bind(this)
    this.onFieldValueChanged = this.onFieldValueChanged.bind(this)
    this.onRecaptchaChanged = this.onRecaptchaChanged.bind(this)
  }

  getDefaultState() {
    return {
      stepIndex: 0,
      steps: cloneDeep(steps),
      posting: false,
      success: false,
      recaptchaValue: null,
      important: '' // honeypot field
    }
  }

  /*
   * Generate fieldGroups with parent reference for nested steps
   */
  generateChildFieldGroups(steps) {
    for (const step of steps) {
      if (step.hasChildren) {
        for (const section of step.sections) {
          for (const groupId of Object.keys(section.fieldGroups || {})) {
            this.createFirstChild(step.title, groupId)
          }
        }
      }
    }
  }

  onSubmit() {
    const validationResult = this.validate()
    if (validationResult.errorCount ||
      (useGoogleRecaptcha && !this.state.recaptchaValue) ||
      (!useGoogleRecaptcha && this.state.important.length)) {
      this.setState({
        ...this.state,
        stepIndex: validationResult.stepIndex,
        recaptchaError: !this.state.recaptchaValue
      })
    } else {
      const { t, addErrorMessage } = this.props
      this.setState({
        ...this.state,
        posting: true
      })
      activationAPI.post(`/activation?language=${auth.getLanguage()}`, this.getFormData())
        .then((response) => {
          if (response.status !== 200) {
            addErrorMessage(t('activation_failed'))
          }
          this.setState({
            ...this.state,
            posting: false,
            success: response.status === 200
          })
        })
    }
  }

  hideSections(requestFor) {
    const hideSections = []
    switch (requestFor.value) {
      case 'crane_activation':
        hideSections.push('customer_contact_person', 'distributor_contact_person')
        break
      case 'user_access':
        hideSections.push('location_information', 'equipment_information')
        break
      default:
        break
    }
    this.state.steps.forEach(step => {
      step.sections = step.sections.map(s => ({ ...s, hidden: hideSections.includes(s.title) }))
      step.hidden = step.sections.filter(s => s.hidden !== true).length === 0
    })
  }

  onFieldValueChanged(section, field, value) {
    field.value = value
    delete field.error
    delete section.error

    // if request for, hide some sections based on the selection
    if (field.title === 'request_for' && value && typeof value === 'object' && value.constructor === Object) {
      this.hideSections(value)
    }

    this.setState({ ...this.state })
  }

  onGroupAdd(step, section, parentId) {
    const groupId = uuid()
    section.fieldGroups[groupId] = section.fieldGroupFields.map(f => ({ ...f, value: '', parentId: parentId}))
    if (step.hasChildren) {
      this.createFirstChild(step.title, groupId)
    }
    this.setState({ ...this.state })
  }

  onGroupRemove(step, section, groupId) {
    // If it's possible for the fieldGroups of this step to have children, remove them
    if (step.hasChildren) {
      this.removeChildren(step.title, groupId)
    }
    delete section.fieldGroups[groupId]
    this.setState({ ...this.state })
  }

  onRecaptchaChanged(value) {
    this.setState({
      ...this.state,
      recaptchaValue: value,
      recaptchaError: false
    })
  }

  getFormData() {
    const { steps } = this.state
    // Steps containing child fieldGroups will be handled separately
    return steps.filter(step => !step.parentStep).reduce((result, step) => {
      step.sections.forEach(section => {
        if (section.fieldGroups) {
          const groupIdsWithValues = Object.keys(section.fieldGroups)
            .filter(groupId => section.fieldGroups[groupId].filter(f => f.value && (f.value.length || f.value.label)).length > 0)
          result[section.title] = groupIdsWithValues.map(groupId => {
            let fieldGroupData = {}
            // If there's child fieldGroups, collect data from them first
            if (step.hasChildren) {
              fieldGroupData.children = this.findGroupChildren(steps, groupId).map(fieldGroup => fieldGroup.reduce((result, field) => {
                result[field.title] = field.value
                return result
              }, {}))
            }
            // Collect values from fieldGroups
            return section.fieldGroups[groupId].reduce((result, field) => {
              result[field.title] = field.value
              return result
            }, fieldGroupData)
          }, {})
        } else {
          result[section.title] = section.fields.reduce((result, field) => {
            result[field.title] = field.value
            return result
          }, {})
        }
        return result
      }, {})
      return result
    }, {})
  }

  validate() {
    const { steps } = this.state
    let errorCount = 0
    let stepIndex = -1
    const defaultGetError = (value) => {
      return !value || (typeof value === 'string' && value.trim().length === 0)
        ? 'field_required'
        : null
    }
    const getFieldError = (field) => {
      const fn = field.getError || defaultGetError
      return fn(field.value)
    }
    const addError = (error, step) => {
      if (error) {
        errorCount++
        if (stepIndex === -1) {
          stepIndex = steps.indexOf(step)
        }
      }
    }

    for (const step of steps) {
      for (const section of step.sections) {
        if (section.hidden) {
          continue
        }
        const fields = section.fields || [].concat(...Object.values(section.fieldGroups))

        for (const field of fields) {
          if (field.required === false) {
            continue
          }
          field.error = getFieldError(field)
          addError(field.error, step)
        }
      }
    }
    return {
      errorCount,
      stepIndex: stepIndex !== -1 ? stepIndex : this.state.stepIndex
    }
  }

  renderStepTabs() {
    const { t } = this.props
    const { stepIndex, steps } = this.state

    return (
      <ol className="yd-activation-steps">
        {
          steps.map((step, i) => {
            return step.hidden ? null : (
              <li
                key={step.title}
                className={stepIndex === i ? 'yd-activation-selected' : ''}
                onClick={() => this.setState({ ...this.state, stepIndex: i })}>
                <p className="button">{t(step.title)}</p>
              </li>
            )
          })
        }
      </ol>
    )
  }

  getFieldKey(step, section, field, groupId) {
    return `${step.title}-${section.title}-${field.title}${groupId ? `-${groupId}` : ''}`
  }

  getLabelTranslation (t, tWithoutPrefix, namespace, value) {
    return namespace ? tWithoutPrefix(`${namespace}:${value}`) : t(value)
  }

  renderField(step, section, field, groupId) {
    const { auth, t, tWithoutPrefix } = this.props
    const key = this.getFieldKey(step, section, field, groupId)
    const options = (field.data || [])
      .map(value => ({
        label: this.getLabelTranslation(t, tWithoutPrefix, field.namespace, value),
        value
      }))
      .sort((a, b) => {
        if (a.label < b.label) return -1
        if (a.label > b.label) return 1
        return 0
      })
    const selectValue = field.value && {
      label: this.getLabelTranslation(t, tWithoutPrefix, field.namespace, field.value.value),
      value: field.value.value,
    }

    const requiredText = field.required === false ? '' : '*'
    const fieldStyles = {
      position: 'relative',
      ...field.hidden && {
        visibility: 'hidden',
        width: 0,
        position: 'absolute'
      }
    }

    return (
      <div key={key} style={fieldStyles}>
        <div className={`yd-element-container${field.fraction ? `-${field.fraction}` : ''}`}>
          <label htmlFor={key}>{t(field.title)} {requiredText}</label>
          {field.title === 'company_name' ?
            auth.isAuthenticated ?
            <CustomerSelect
              value={field.value}
              isMulti={false}
              onChange={(value) => this.onFieldValueChanged(section, field, value)}
            />
            : <input
              type="text"
              id={`${field.title}-${groupId}`}
              name={field.title}
              value={field.value}
              onChange={(e) => this.onFieldValueChanged(section, field, e.target.value)}
            />
          : field.data &&
            <Select
              options={options}
              value={selectValue}
              onFocus={() => this.onFieldValueChanged(section, field, selectValue)}
              onChange={(value) => this.onFieldValueChanged(section, field, value)}
            />
          }
          {!field.data && field.textarea &&
            <textarea
              type="text"
              id={`${field.title}-${groupId}`}
              name={field.title}
              value={field.value}
              onChange={(e) => this.onFieldValueChanged(section, field, e.target.value)}
            />
          }
          {!field.data && !field.textarea &&
            <input
              type="text"
              id={`${field.title}-${groupId}`}
              name={field.title}
              value={field.value}
              onChange={(e) => this.onFieldValueChanged(section, field, e.target.value)}
            />
          }
          {field.error &&
            <div className="error--message">
              {t(field.error)}
            </div>
          }
        </div>
        {field.info &&
          <div className="yd-element-container">
            <span className="yd-infotext">{t(field.info)}</span>
          </div>
        }
      </div>
    )
  }

  renderStepContent() {
    const { stepIndex, steps } = this.state
    const step = steps[stepIndex]
    const parentTitles = this.findStepParentTitles(step)

    return (
      <form className={`yd-frm-newcustomer yd-frm-step${stepIndex}`}>
        {
          // If there's no parent titles, just iterate once with undefined title and omit accordion
          (Object.keys(parentTitles).length > 0 ?
            Object.keys(parentTitles) : [undefined]).map(parentId => {
              const title = parentTitles[parentId]
              // If title comes from a parent, it is rendered by Accordion component instead of separate legend component
              const stepFields = this.renderStepFields(step, parentId, title === undefined)
              return (
                (title &&
                  <Accordion
                     key={`accordion-${parentId}`}
                     title={title}
                     isOpen={true}
                     simple={true}
                     content={stepFields}/>) || stepFields
                )
            })
        }
        <div className="clearfix">
          {useGoogleRecaptcha &&
            <ReCAPTCHA
              className={`yd-recaptcha ${
                stepIndex === steps.length - 1 ? 'yd-recaptcha--visible' : ''
                } ${
                this.state.recaptchaError ? 'yd-recaptcha--error' : ''
                }`
              }
              sitekey={config.GOOGLE_RECAPTCHA_SITE_KEY}
              onChange={this.onRecaptchaChanged}
            />
          }
          {!useGoogleRecaptcha &&
            <div id="important-field-container">
              <input
                id="important-field" // honeypot method field
                type="text"
                name="important-field"
                value={this.state.important}
                onChange={(e) => this.setState({ ...this.state, important: e.target.value })}
              />
            </div>
          }
        </div>
      </form>
    )
  }

  renderStepFields(step, parentId, renderTitle) {
    const { t } = this.props
    return (
      step.sections
        .filter(section => section.hidden !== true)
        .map((section) => {
          let sectionTitle = t(section.title)
          sectionTitle = sectionTitle.split('.')[1] === section.title ? undefined : sectionTitle
          return (
            <div className="yd-frm-section clearfix" key={section.title}>
              {renderTitle && sectionTitle && <legend>{sectionTitle}</legend>}
              {section.titleExtra &&
                <div className="yd-frm-section-title-extra">{t(section.titleExtra)}</div>
              }
              {
                (section.fields || []).map(field => {
                  return this.renderField(step, section, field)
                })
              }
              {
                Object.keys(section.fieldGroups || {})
                .filter(groupId => {
                  const firstField = section.fieldGroups[groupId][0]
                  return firstField.parentId === undefined || firstField.parentId === parentId
                })
                .map((groupId) => (
                  <div key={groupId}>
                    <div className="yd-element-group clearfix">
                      {
                        section.fieldGroups[groupId]
                        .map(field => {
                          return this.renderField(step, section, field, groupId)
                        })
                      }
                      <FontAwesomeIcon
                        icon={faTrashAlt}
                        className="button-icon button-trash-can"
                        onClick={() => {
                          /*
                           * If this item may have children in a further step
                           * they must be removed as well to retain clean state
                           * TODO: REFACTOR!
                           */
                          this.onGroupRemove(step, section, groupId)
                        }}
                      />
                    </div>
                    <div className="yd-element-group-divider"></div>
                  </div>
                ))
              }
              {section.fieldGroups && section.addGroupText &&
                <p
                  className="yd-add-element-group"
                  onClick={() => {
                    this.onGroupAdd(step, section, parentId)
                  }}
                >
                  <FontAwesomeIcon icon={faPlusCircle} /> {t(section.addGroupText)}
                </p>
              }
              {section.error &&
                <div className="error--message error--message-right">
                  {t(section.error, { indicator: '(*)' })}
                </div>
              }
            </div>
          )
      })
    )
  }

  /*
   * Find all fieldGroups from steps with parentId matching groupId
  */
  findGroupChildren(steps, parentId) {
    const children = []
    for (const step of steps) {
      if (step.parentStep) {
        for (const section of step.sections) {
          if (section.fieldGroups) {
            for (const groupId of Object.keys(section.fieldGroups)) {
              if (section.fieldGroups[groupId][0].parentId === parentId) {
                children.push(section.fieldGroups[groupId])
              }
            }
          }
        }
      }
    }
    return children
  }

  findStepParentTitles(child) {
    const { steps } = this.state
    for (const step of steps) {
      if (step.title === child.parentStep) {
        return step.childTitleGenerator(step)
      }
    }
    return {}
  }

  createFirstChild(parentTitle, parentId) {
    const { steps } = this.state
    for (const step of steps) {
      if (step.parentStep === parentTitle) {
        for (const section of step.sections) {
          if (!!section.fieldGroups) {
            const newChild = section.fieldGroupFields.map(f => ({...f, parentId: parentId}))
            section.fieldGroups[uuid()] = newChild
          }
        }
      }
    }
  }

  /*
   * Remove any child fieldGroup of specific parent fieldGroup from steps in state
   */
  removeChildren(parentTitle, parentId) {
    const { steps } = this.state
    for (const step of steps) {
      if (step.parentStep === parentTitle) {
        for (const section of step.sections) {
          if (!!section.fieldGroups) {
            for (const groupId of Object.keys(section.fieldGroups)) {
              // Assuming all fields of a fieldGroup have same parent
              if (section.fieldGroups[groupId][0].parentId === parentId) {
                delete section.fieldGroups[groupId]
              }
            }
          }
        }
      }
    }
  }

  renderNextPreviousAndSubmit() {
    const { t } = this.props
    const { stepIndex, steps } = this.state

    const onStepIndexChange = (diff) => {
      let newStepIndex = this.state.stepIndex + diff
      while (steps[newStepIndex] && steps[newStepIndex].hidden === true) {
        newStepIndex += diff > 0 ? 1 : -1
      }
      this.setState({ ...this.state, stepIndex: newStepIndex })
    }

    return (
      <div className="yd-prevnext-nav">
        {stepIndex > 0 &&
          <button className="button button--ghost yd-prevnext-prev" onClick={() => onStepIndexChange(-1)}>
            <FontAwesomeIcon icon={faAngleLeft} /> {t('previous')}
          </button>
        }
        {stepIndex !== steps.length - 1 &&
          <button className="button button--ghost yd-prevnext-next" onClick={() => onStepIndexChange(1)}>
            {t('next')} <FontAwesomeIcon icon={faAngleRight} />
          </button>
        }
        {stepIndex === steps.length - 1 &&
          <button type="submit" className="button button--ghost yd-prevnext-next" onClick={this.onSubmit}>
            {t('submit')} <FontAwesomeIcon className="button-icon" icon={faCheck} />
          </button>
        }
      </div>
    )
  }

  render() {
    const { t } = this.props

    return (
      <div>
        <Toolbar title={t('activation')} />
        <main className='activation'>
          <div className="yd-activation">
            {!this.state.success &&
              <div>
                <h1 className="yd-activation--header">{t('new_customer')} / {t('activation')}</h1>
                {this.renderStepTabs()}
                {this.renderStepContent()}
                <div className="clearfix"></div>
                {this.renderNextPreviousAndSubmit()}
              </div>
            }
            {this.state.success &&
              <div className="yd-activation-success">
                <h1 className="yd-activation--header">{t('new_customer')} / {t('activation')}</h1>
                <div className="yd-activation-success-header">{t('activation_success_line1')}</div>
                <div className="yd-activation-success-header">{t('activation_success_line2')}</div>
                <button
                  className="button yd-activation-new-button"
                  onClick={() => this.setState(this.getDefaultState())}>
                  {t('new_customer')} / {t('activation')} <FontAwesomeIcon icon={faAngleRight} />
                </button>
              </div>
            }
          </div>
        </main>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addErrorMessage: (message) => dispatch(addMessage(message, 'error')),
  }
}

export default translateWithPrefix('activation')(connect(mapStateToProps, mapDispatchToProps)(Activation))
