import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faChevronDown, faSpinner } from '@fortawesome/pro-solid-svg-icons'
import './Accordion.css'

class Accordion extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: 'boolean' === typeof props.isOpen ? props.isOpen : true
    }
    this.onTitleClick = this.onTitleClick.bind(this)
  }

  onTitleClick() {
    this.setState({
      ...this.state,
      isOpen: !this.state.isOpen
    })
    this.props.onToggle(this.props.id)
  }

  componentDidUpdate(prevProps) {
    if (this.props.isOpen !== prevProps.isOpen) {
      this.setState({
        ...this.state,
        isOpen: this.props.isOpen
      })
    }
  }

  render() {
    const { title, titleExtra, content, status, large, simple, loading, faIcon } = this.props
    const { isOpen } = this.state
    const classes = `accordion ${!isOpen ? 'accordion__closed' : ''}`
    const titleClasses = `accordion-title
      ${large ? 'accordion-title--large' : ''}
      ${titleExtra ? 'accordion-title--has-extra' : ''}
      ${simple ? 'accordion-title--simple' : ''}
      ${faIcon ? 'accordion-title--has-icon' : ''}
    `
    const chevronIcon = isOpen ? faChevronUp : faChevronDown

    return (
      <div className={classes}>
        <div className={titleClasses} onClick={this.onTitleClick}>
          {!simple && <span className={`accordion-title-status accordion-title-status__${(status || 'offline').toLowerCase()}`}></span>}
          { faIcon && <span className="accordion-title-icon"><FontAwesomeIcon icon={faIcon} /></span> }
          <span className="accordion-title-text truncate">
            { title }
            { titleExtra && <span className="accordion-title-extra truncate">{titleExtra}</span> }
          </span>
          <div className="accordion-toggle"><FontAwesomeIcon icon={loading ? faSpinner : chevronIcon} spin={loading} /></div>
        </div>
        { isOpen &&
          <div className="accordion-content">
            { content }
          </div>
        }
      </div>
    )
  }
}

Accordion.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  titleExtra: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isOpen: PropTypes.bool,
  status: PropTypes.string,
  large: PropTypes.bool,
  loading: PropTypes.bool,
  faIcon: PropTypes.object,
  onToggle: PropTypes.func
}

Accordion.defaultProps = {
  id: '',
  title: '',
  titleExtra: null,
  content: null,
  isOpen: false,
  status: 'normal',
  large: false,
  loading: false,
  faIcon: null,
  onToggle: () => {}
}

export default Accordion
