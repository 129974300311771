import * as React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSignOutAlt,
  faUser,
  faQuestionCircle,
  faRocket,
  faPlus,
  faMinus,
  faBell,
} from '@fortawesome/pro-solid-svg-icons'
import { faCheckSquare } from '@fortawesome/free-regular-svg-icons'

import './Header.css'

import Select from '../Select/Select'

const HeaderMenuContent = ({
  t,
  profile,
  updatingProfile,
  candidateLanguage,
  languages,
  handleLanguageChange,
  handleProfileClick,
  showMenu,
  showProfile,
  toggleProfile,
  showActivation,
  activationLink,
  helpLink,
  termsAndConditionsLink,
  notificationSettingsLink,
  isMobile = false,
}) => {
  const lang = Object.keys(languages).includes(candidateLanguage) ? candidateLanguage : 'en'
  const langOptions = Object.keys(languages).map(lang => ({ label: languages[lang], value: lang }))
  const showMenuContent = (isMobile && showMenu) || !isMobile

  return !showMenuContent ? null : (
    <ul className={`yd-header__menu${isMobile ? '-mobile' : ''}`}>
      <li>
        {showActivation &&
        <Link to={activationLink} className="button button--ghost">
          <FontAwesomeIcon icon={faRocket} size="lg"/> {t('activation')}
        </Link>
        }
      </li>
      <li>
        <Link to={helpLink} target="_blank" className="button button--ghost">
          <FontAwesomeIcon icon={faQuestionCircle} size="lg"/> {t('help')}
        </Link>
      </li>
      {profile &&
      <li className="yd-header__menu-item-toggle">
        <button className="button button--ghost" onClick={handleProfileClick}>
          <FontAwesomeIcon icon={faUser} size="lg"/> {t('user_profile')}
          {isMobile &&
          <FontAwesomeIcon
            size="lg"
            className="yd-header__profile-indicator"
            icon={showProfile ? faMinus : faPlus}
          />
          }
        </button>
        {showProfile &&
        <div className="yd-header__collapsable">
          <div className="yd-header__profile-name"><FontAwesomeIcon icon={faUser} size="lg"/> {profile.name}</div>
          <div className="yd-header__profile-email">{profile.email}</div>
          <div className="yd-header__profile-link">
            <Link
              to="/logout"
              onClick={() => toggleProfile}
            >
              <FontAwesomeIcon icon={faSignOutAlt} size="lg"/> {t('logout')}
            </Link>
          </div>
          <div className="yd-header__profile-link">
            <a
              href={termsAndConditionsLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faCheckSquare} size="lg"/> {t('terms_and_conditions')}
            </a>
          </div>
          {notificationSettingsLink && <div className="yd-header__profile-link">
            <a href={notificationSettingsLink}>
              <FontAwesomeIcon icon={faBell} size="lg"/> {t('notification_settings')}
            </a>
          </div>
          }
          <div className="yd-header__profile-language">
            <Select
              isMulti={false}
              isSearchable={false}
              isClearable={false}
              isLoading={updatingProfile}
              disabled={updatingProfile}
              onChange={(selectedLanguage) => handleLanguageChange(selectedLanguage)}
              options={langOptions}
              value={langOptions.find(opt => opt.value === lang)}
            />
          </div>
        </div>
        }
      </li>
      }
    </ul>
  )
}

const HeaderMenu = (props) =>
  <React.Fragment>
    <HeaderMenuContent {...props} />
    <HeaderMenuContent {...props} isMobile/>
  </React.Fragment>
export default HeaderMenu
