
export const TIME_PERIOD = {
  LAST_24_HOURS: 'last_24_hours',
  LAST_7_DAYS: 'last_7_days',
  LAST_30_DAYS: 'last_30_days',
  LAST_6_MONTHS: 'last_6_months',
  LAST_12_MONTHS: 'last_12_months',
  CUSTOM: 'custom'
}

export const DEFAULT_TIME_FILTER = {
  selection: TIME_PERIOD.LAST_30_DAYS
}
