import React, { useState } from 'react'
import { StatisticsUsage, resolveTimeframe, TIME_PERIODS } from '@kc/alphabrands-component-library'

import {
  useGetRecommendationsQuery,
  useGetUsageLoadSpectrumQuery,
  useGetUsageLoadsQuery,
  useGetUsageRunningHoursQuery,
} from '../../generated/graphql'

import { getQueryVariables } from './getQueryVariables'
import Backlink from '../../components/Backlink/Backlink'

const StatisticsUsagePage = ({ asset, options }) => {
  const [timeframe, onTimeframeChange] = useState(resolveTimeframe(TIME_PERIODS.LAST_3_MONTHS))

  const data = {
    recommendations: useGetRecommendationsQuery(getQueryVariables(asset, timeframe, 14)),
    runningHours: useGetUsageRunningHoursQuery(getQueryVariables(asset, timeframe, 11)),
    loads: useGetUsageLoadsQuery(getQueryVariables(asset, timeframe, 12)),
    loadSpectrum: useGetUsageLoadSpectrumQuery(getQueryVariables(asset, timeframe, 13)),
  }

  return (
    <>
      <Backlink text={options.t('statuscontrol:common.back', 'Back')}/>
      <StatisticsUsage
        data={data}
        options={{
          ...options,
          timeframe,
          onTimeframeChange
        }}
      />
    </>
  )
}

export default StatisticsUsagePage
