import uuid from 'uuid/v4'
import isEmail from 'validator/lib/isEmail'
import isMobilePhone from 'validator/lib/isMobilePhone'
import i18nIsoCountries from 'i18n-iso-countries'

const countryList = Object.keys(i18nIsoCountries.getAlpha3Codes())
const hoistTypeList = ['dr_dmr', 'dvr']

const siteFields = (name, info) => ([
  {
    title: name,
    info,
    value: ''
  },
  ...siteAddressFields
])

const siteAddressFields = [
  {
    title: 'address',
    value: ''
  },
  {
    title: 'postal_code',
    required: true,
    value: ''
  },
  {
    title: 'city',
    value: ''
  },
  {
    title: 'country',
    data: countryList,
    namespace: 'countries',
    value: ''
  }
]

const equipmentInformationFields = [
  {
    title: 'equipment_id_or_name',
    fraction: 'two',
    value: ''
  },
  {
    title: 'model',
    required: false,
    value: '',
    hidden: true
  },
  {
    title: 'crane_serial_number',
    fraction: 'two',
    required: false,
    value: '',
  },
  {
    title: 'hoist_serial_number',
    fraction: 'three',
    value: ''
  },
  {
    title: 'modem_imei_code',
    fraction: 'three',
    required: false,
    value: ''
  },
  {
    title: 'hoist_diagnostics_type',
    fraction: 'three',
    required: true,
    data: hoistTypeList,
    value: ''
  }
]

const personFields = [
  {
    title: 'first_name',
    value: ''
  },
  {
    title: 'last_name',
    value: ''
  },
  {
    title: 'email',
    getError: (value) => !isEmail(value) ? 'not_valid_email' : null,
    value: ''
  },
  {
    title: 'mobile_phone_number',
    getError: (value) => !isMobilePhone(value, "any", { strictMode: true }) ? 'not_valid_mobile_phone_number' : null,
    value: ''
  }
]

export const steps = [
  {
    title: 'customer_information',
    sections: [
      {
        title: 'request_for_section',
        fields: [
          {
            title: 'request_for',
            data: ['user_access', 'crane_activation', 'user_access_and_crane_activation'],
            value: { label: 'user_access_and_crane_activation', value: 'user_access_and_crane_activation' }
          }
        ]
      },
      {
        title: 'customer_information',
        fields: [
          {
            title: 'company_name',
            info: 'company_info',
            data: [],
            value: ''
          },
          ...siteAddressFields
        ]
      }
    ]
  },
  {
    title: 'location_information',
    hasChildren: true,
    /*
     * Collect user-entered location titles to generate headings
     * for child steps (Equipment in this case)
     *
     * Implemented as a function with explicit self object reference
     * instead of a getter due to this whole structure being cloned for
     * use to ensure clean initialization and getters can't be
     * cloned as intented
     */
    childTitleGenerator: (self) => {
      return self.sections.reduce((acc, section) => {
        for (const groupId of Object.keys(section.fieldGroups)) {
          const fieldGroup = section.fieldGroups[groupId]
          for (const field of fieldGroup) {
            if (field.title === 'location_name') {
              acc[groupId] = (field.value ? field.value : ' ')
            }
          }
        }
        return acc
      }, {})
    },
    sections: [
      {
        title: 'location_information',
        addGroupText: 'add_location',
        fieldGroupFields: siteFields('location_name', 'location_info'),
        fieldGroups: {
          [uuid()]: siteFields('location_name', 'location_info').map(f => ({...f}))
        }
      }
    ]
  },
  {
    title: 'equipment_details',
    parentStep: 'location_information',
    sections: [
      {
        title: 'equipment_information',
        addGroupText: 'add_equipment',
        fieldGroupFields: equipmentInformationFields,
        fieldGroups: {} // Will be initialized by Activation form to connect with parent
      }
    ]
  },
  {
    title: 'contact_information',
    sections: [
      {
        title: 'customer_contact_person',
        addGroupText: 'add_contact',
        fieldGroupFields: personFields,
        fieldGroups: {
          [uuid()]: personFields.map(f => ({...f}))
        }
      },
      {
        title: 'distributor_contact_person',
        titleExtra: 'activation_submitter',
        addGroupText: 'add_contact',
        fieldGroupFields: personFields,
        fieldGroups: {
          [uuid()]: personFields.map(f => ({...f}))
        }
      },
      {
        title: 'additional_comments',
        fields: [
          {
            title: 'comment',
            required: false,
            textarea: true,
            value: ''
          }
        ]
      }
    ]
  }
]
