import { filterActions, uiActions } from './constants'
import { store } from '../store'
import { appDefaultState } from '../reducers'

export const toggleCustomerSelection = isVisible => {
  return (dispatch) => {
    if (isVisible) dispatch(toggleTimeFrameSelection(false))
    dispatch({
      isVisible,
      type: uiActions.TOGGLE_CUSTOMER_SELECTION
    })
  }
}

export const toggleTimeFrameSelection = isVisible => {
  return (dispatch) => {
    if (isVisible) dispatch(toggleCustomerSelection(false))
    dispatch({
      isVisible,
      type: uiActions.TOGGLE_TIMEFRAME_SELECTION
    })
  }
}

export const mergeAccordionStates = states => {
  return {
    states,
    type: uiActions.MERGE_ACCORDION_STATES
  }
}

export const addFilter = (filter, value) => {
  return {
    filter,
    type: filterActions.ADD_FILTER,
    value
  }
}

export const removeFilter = (filter, value) => {
  return {
    filter,
    type: filterActions.REMOVE_FILTER,
    value
  }
}

export const setFilter = (filter, value) => {
  return {
    filter,
    type: filterActions.SET_FILTER,
    value
  }
}

export const setFilters = filters => {
  return {
    filters,
    type: filterActions.SET_FILTERS
  }
}

export const resetFilters = () => {
  const state = store.getState()

  const defaultFilters = state.user && state.user.customers.length === 1
    ? { customerId: [state.user.customers[0].id] }
    : {}

  const filters = Object.assign(defaultFilters, { time: { ...appDefaultState.filters.time } })

  return {
    filters,
    type: filterActions.SET_FILTERS
  }
}

let disableMainMenuTimeout

export const toggleMainMenu = isVisible => dispatch => {
  if (!isVisible) {
    // Disable main menu after delay, which should match main-menu's transition duration
    disableMainMenuTimeout = setTimeout(() => dispatch(disableMainMenu()), 500)
  } else {
    clearTimeout(disableMainMenuTimeout)
  }

  dispatch({
    isVisible,
    type: uiActions.TOGGLE_MAIN_MENU
  })
}

const disableMainMenu = () => ({
  type: uiActions.DISABLE_MAIN_MENU
})