import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client'
const buildEnv = process.env.REACT_APP_STAGE || 'local'
const apiConfig = JSON.parse(
  process.env[`REACT_APP_API_CONFIG_${buildEnv.toUpperCase()}`] || '{}'
)

const cache = new InMemoryCache({
  typePolicies: {
    Asset: {
      keyFields: ['assetId'],
      fields: {
        truConnect: {
          merge: true,
        },
      },
    },
    Facet: {
      keyFields: false,
    },
    TrendPeriod: {
      keyFields: false,
    },
    EventPeriod: {
      keyFields: false,
    },
    MostFrequentEvent: {
      keyFields: false,
    },
  },
})

const link = new HttpLink({
  uri: apiConfig.assetFleetApi,
  fetch: async (uri, options) => {
    // Call function which collects token from localStorage
    return fetch(uri, {
      ...options,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        'Content-Type': 'application/json',
      },
    })
  },
})

// Reload on every fetch
export default new ApolloClient({
  connectToDevTools: true,
  cache,
  link,
})
