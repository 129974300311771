import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-regular-svg-icons'
import * as React from 'react'
import { translateWithPrefix } from '../../translations/initTranslations'

import './MeasurementValueBox.css'
import { getStatusClass } from '../../lib/measurement-helpers'

export class MeasurementValueBox extends React.Component {
  render() {
    // FIXME: filter/date not respected, value translations
    const { measurement, t } = this.props
    if (!measurement) {
      return null
    }
    const v = measurement.latest()
    const status = measurement.getStatus()
    const alertType = measurement.meta.name.toLowerCase().replace(' ', '_').replace('-', '_').replace('.','')
    return (
      <div className={`yd-events-alert yd-events-alert__${getStatusClass(status)}`}>
        <FontAwesomeIcon icon={faCircle} size="lg" />
        <span className="yd-events-alert-number">{v === null ? 'N/A' : v}</span>
        <span className="yd-events-alert-term">{t('alert_type_' + alertType)}</span>
      </div>
    )
  }
}

export default translateWithPrefix('asset')(MeasurementValueBox)
