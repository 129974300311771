import * as React from 'react'
import { translate } from 'react-i18next'
import { connect } from 'react-redux'

import auth from '../../lib/auth/auth'
import StatisticsConditionPage from './StatisticsConditionPage'
import StatisticsUsagePage from './StatisticsUsagePage'
import StatisticsOverviewPage from './StatisticsOverviewPage'
import StatisticsAlertsPage from './StatisticsAlertsPage'
import { setFilter } from '../../actions/common'

import './Statistics.css'

const viewIds = {
  overview: 'overview',
  condition: 'condition',
  usage: 'usage',
  alerts: 'alerts',
}

const sectionIds = {
  conditionPrediction: 'condition-prediction',
}

const translateFunction = (t) => (key, defaultValue) =>
  t(key, { ns: 'statistics-component-library', defaultValue })

const isProdEnv = () => process.env.REACT_APP_STAGE === 'prod'
const isPilotEnv = () => process.env.REACT_APP_STAGE === 'pilot'

const StatisticsEcu = ({ asset, history, match, t, location, propsSetFilter }) => {
  const language = auth.getLanguage()
  const setFiltersWhenNavigate = (section, initialCategory) => {
    section ? propsSetFilter('open', section) : propsSetFilter('open', '')
    initialCategory ? propsSetFilter('category', initialCategory) : propsSetFilter('category', '')
  }

  const showNewFeatures = !(isProdEnv() || isPilotEnv())
  const options = {
    t: translateFunction(t),
    themeKey: 'demag',
    viewIds,
    sectionIds,
    goToView: ({ viewId, section, initialCategory }) => {
      setFiltersWhenNavigate(section, initialCategory)
      history.push(
        `/customer/${asset.customer.id}/location/${asset.location.id}/asset/${asset.id}/statistics/${viewId}`,
      )
    },
    locale: language,
    showNewFeatures,
  }

  const viewId = match.params.viewId || viewIds.overview

  return (
    <div className="statistics-container">
      <main>
        {viewId === viewIds.overview && <StatisticsOverviewPage asset={asset} options={options} />}
        {viewId === viewIds.condition && (
          <StatisticsConditionPage asset={asset} options={options} location={location} />
        )}
        {viewId === viewIds.usage && <StatisticsUsagePage asset={asset} options={options} />}
        {viewId === viewIds.alerts && showNewFeatures && (
          <StatisticsAlertsPage asset={asset} options={options} />
        )}
      </main>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    propsSetFilter: (filter, value) => dispatch(setFilter(filter, value)),
  }
}

export default translate([
  'component-type',
  'measurement-type',
  'statistics-component-library',
  'event-type',
  'recommendations',
  'event'
])(connect(null, mapDispatchToProps)(StatisticsEcu))
