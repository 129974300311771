export default (e) => {
  if (e.path) {
    return e.path
  } else if (typeof e.composedPath === 'function') {
    return e.composedPath()
  } else {
    // see: https://github.com/jsdom/jsdom/issues/1563
    const path = []
    let target = e.target

    while (target.parentNode) {
      path.push(target)
      target = target.parentNode
    }

    path.push(document, window)
    return path
  }
}