import * as React from 'react'
import { connect } from 'react-redux'
import { translateWithPrefix } from '../../translations/initTranslations'

import { formattedValue } from '../../lib/measurement-helpers'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf } from '@fortawesome/pro-solid-svg-icons'

import './Statistics.css'
import './Info.css'
import { formatDate } from '../../lib/date-helpers'

class AssetInfo extends React.Component {

  infoRow(header, value, key) {
    if (value === undefined) {
      return null
    }
    return (
      <tr key={key}>
        <th>{header}</th>
        <td>{value}</td>
      </tr>
    )
  }

  renderSection(title, rows) {
    return (
      <React.Fragment key={title}>
        <h3 className="yd-header-border yd-header--main">{title}</h3>
        <table className="table-two-cols">
          <tbody>
            {rows.map((row) => {
              return this.infoRow(row.header, row.content, row.key)
            })}
          </tbody>
        </table>
      </React.Fragment>
    )
  }

  renderDocuments(documents) {
    return documents.length > 0 &&
      <div className="flex-four-cols">
        {
          documents.map(document => (
            <div className="flex-item">
              <div className="flex-item-bg">
                <FontAwesomeIcon
                  icon={faFilePdf}
                  size="5x"
                  className="highlight"
                />
                <p>{document.name}</p>
              </div>
              <div class="flex-item-actions">
                <p className="flex-item">12mb</p>
                <button className="button button--light flex-item" onClick={() => { }}>Download</button>
              </div>
            </div>
          ))
        }
      </div>
  }

  render() {
    const { t, asset } = this.props
    const documents = []
    const ce = (asset || {}).ce || []

    return (
      <div>
        {asset &&
          <div className="flex-row">
            <main className="yd-info">

              {this.renderSection(
                t('info'),
                [
                  { header: t('name'), content: asset.ni || asset.pi, key: 'name' },
                  { header: t('description'), content: asset.descr, key: 'description' },
                  { header: t('activation_date'), content: asset.scad ? formatDate(new Date(asset.scad), 'MMM dd, yyyy') : undefined, key: 'activation_date' },
                  { header: `${t('customer')} / ${t('location')}`, content: `${asset.customer.n} / ${asset.location.n}`, key: 'customer_location' },
                ]
              )}

              {ce.map((eq) => {
                return this.renderSection(
                  `${t('hoist_component')}${eq.c_sn ? ` ${eq.c_sn}`: ''}`,
                  [
                    { header: t('hoist_serial_number'), content: eq.c_sn, key: 'sn' + eq.c_sn },
                    { header: t('hoist_design_class'), content: eq.dc, key: 'dc' + eq.c_sn },
                    { header: t('nominal_load'), content: formattedValue(eq.cp, 't'), key: 'cp' + eq.c_sn }
                  ]
                )
              })}

              {this.renderDocuments(documents)}

            </main>
          </div>
        }
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    assetsByCustomerId: state.assetsByCustomerId,
    filters: state.filters
  }
}

const mapDispatchToProps = () => {
  return {
  }
}

export default translateWithPrefix('asset')(connect(mapStateToProps, mapDispatchToProps)(AssetInfo))
