
const defaultStyles = {
  marginRight: 35,
  xAxis: {
    lineColor: '#dcdcdc',
    tickColor: '#dcdcdc',
    labelsStyle: {
      color: '#1a1a1a',
      fontSize: '13px',
      fontFamily: '\'iStok Web\', Arial',
      whiteSpace: 'normal',
      minWidth: '70px',
      textAlign: 'right'
    }
  },
  yAxis: {
    labelsStyle: {
      color: '#1a1a1a',
      fontFamily: '\'iStok Web\', Arial'
    },
    gridLineColor: '#dcdcdc'
  },
  plot: {
    bar: {
      dataLabelsColor: '#000000',
      dataLabelsStyle: {
        fontFamily: '\'iStok Web\', Arial'
      },
    }
  }
}

const defaultOptions = {
  categoryFormatter: function() {
    return this.value;
  },
  labelFormatter: function() {
    return this.value;
  },
  tooltipFormatter: function() {
    return this.y;
  },
  dataLabelFormatter: function() {
    return this.y !== 0 ? this.y : null;
  }
}

// options, categories, series
export const buildBarChart = (data, chartOptions, chartStyles) => {
  const options = { ...defaultOptions, ...chartOptions }
  const styles = { ...defaultStyles, ...chartStyles }

  const series = data.series || []
  const categories = data.categories || []

  return {
    chart: {
      type: 'bar',
      height: 200, // highcharts-react does not set container height so relative sizes do not work
      marginRight: styles.marginRight,
      animation: false
    },
    colors: styles.colors,
    title: { text: null },
    xAxis: {
      categories: categories,
      title: { text: null },
      lineColor: styles.xAxis.lineColor,
      tickColor: styles.xAxis.tickColor,
      labels: {
        style: styles.xAxis.labelsStyle,
        formatter: options.categoryFormatter
      }
    },
    yAxis: {
      allowDecimals: false,
      title: { text: null },
      labels: {
        overflow: 'justify',
        style: styles.yAxis.labelsStyle,
        formatter: options.labelFormatter
      },
      stackLabels: {
        enabled: false
      },
      gridLineColor: styles.yAxis.gridLineColor
    },
    tooltip: {
      enabled: true,
      formatter: options.tooltipFormatter
    },
    legend: {
      enabled: options.legend,
      itemWidth: 200
    },
    plotOptions: {
      bar: {
        colorByPoint: !!options.colorByPoint,
        borderWidth: 0,
        borderRadius: 1,
        stacking: false,
        enableMouseTracking: false,
        dataLabels: {
          enabled: true,
          align: 'left',
          padding: 2,
          overflow: 'none',
          crop: false,
          color: styles.plot.bar.dataLabelsColor,
          style: styles.plot.bar.dataLabelsStyle,
          formatter: options.dataLabelFormatter
        }
      },
      series: {
        animation: false
      }
    },
    credits: {
      enabled: false
    },
    series: series
  }
}
