import * as React from 'react'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import { translateWithPrefix } from '../../translations/initTranslations'

import './CustomerSelection.css'
import FilterBar from '../FilterBar/FilterBar'
import {
  setFilter,
  toggleCustomerSelection
} from '../../actions/common'
import { fetchAllCustomers, fetchCustomers, getAssetsByCustomerId, searchCustomers } from '../../actions/customers'
import Select from '../Select/Select'
import { findCustomer } from '../../lib/customer-helpers'

class CustomerSelection extends React.Component {
  constructor(props) {
    super(props)

    this.onSelectChange = this.onSelectChange.bind(this)
    this.onMenuScrollToBottom = this.onMenuScrollToBottom.bind(this)
    this.onInputChange = this.onInputChange.bind(this)
    this.deselectCustomer = this.deselectCustomer.bind(this)
    this.clear = this.clear.bind(this)

    this.state = {
      inputValue: '',
      searchCustomerIds: []
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { searchCustomerIds } = this.state
    const { getAssetsByCustomerId, customers, searchCustomers } = nextProps
    this.getSelectedCustomerIds(nextProps).forEach(customerId => {
      const customer = findCustomer(nextProps, customerId)
      if (customers.length && !customer && !searchCustomerIds.includes(customerId)) {
        this.setState({
          searchCustomerIds: [...searchCustomerIds, customerId]
        })
        searchCustomers(customerId, 'cid')
      } else {
        getAssetsByCustomerId(customerId)
      }
    })
  }

  deselectCustomer(customerId) {
    const selectedCustomerIds = this.getSelectedCustomerIds().filter(id => id !== customerId)
    this.setCustomers(selectedCustomerIds)
  }

  onSelectChange(options, selectAll) {
    if (Array.isArray(options)) {
      this.setCustomers(options.map(o => o.value))
      // Must check if there is something in the input field to prevent selecting unfiltered options
      if (selectAll === true && this.state.inputValue.length === 0) {
        this.props.fetchAllCustomers()
      }
    }
  }

  onMenuScrollToBottom() {
    if (this.state.inputValue.length === 0) {
      this.props.fetchCustomers()
    }
  }

  onInputChange(inputValue, action) {
    // Filter to keep the input value unless the input changes
    if (action.action === 'input-change') {
      this.setState({ inputValue })
      if (inputValue.length > 0) {
        this.props.searchCustomers(inputValue.toLowerCase())
      }
    }
  }

  clear() {
    this.setState({ inputValue: '' })
    this.setCustomers([])
  }

  setCustomers(customerId) {
    this.props.setFilter('customerId', customerId)
  }

  getSelectedCustomerIds(props = this.props) {
    const { filters } = props

    return (filters || {}).customerId || []
  }

  render() {
    const { customers, customerSearch, t } = this.props
    const { inputValue } = this.state
    const selectedCustomerIds = this.getSelectedCustomerIds()
    if (customers.length === 0) {
      return null
    }

    return (
      <FilterBar
        isVisible={this.props.isCustomerSelectionVisible}
        onClose={() => this.props.toggleCustomerSelection(false)}
      >
        <div ref={this.props.innerRef} className="yd-customerselection">
          <div className="yd-customerselection-filter">
            <div className="yd-customerselection-title">{t('customer_selection')}</div>
            <Select
              showSelectAllButtons={(inputValue.length ? customerSearch.result : customers).length > 1}
              isMulti={true}
              controlShouldRenderValue={false}
              name="select-customer"
              onChange={this.onSelectChange}
              autoFocus={this.props.isCustomerSelectionVisible}
              value={selectedCustomerIds.map(id => ({ value: id }))}
              options={(inputValue.length ? customerSearch.result : customers).map(c => ({ value: c.id, label: c.n }))}
              placeholder={t('search_customer')}
              inputValue={inputValue}
              onInputChange={this.onInputChange}
              onMenuScrollToBottom={this.onMenuScrollToBottom}
              hasMore={customerSearch.more}
              isLoading={customerSearch.isLoading}
            />
            <button className="button yd-customerselection-clear" onClick={this.clear}>{t('clear')}</button>
            {selectedCustomerIds.length > 0 &&
              <ul className="yd-selected-customer-list">
                {
                  selectedCustomerIds.map(customerId => {
                    const customer = findCustomer(this.props, customerId)
                    return !customer ? null : (
                      <div className="yd-selected-customer" key={customerId}>
                        <span className="yd-selected-customer-remove" onClick={() => this.deselectCustomer(customerId)}><FontAwesomeIcon icon={faTimes} /> </span>
                        <span className="yd-selected-customer-label">{customer.n}</span>
                      </div>
                    )
                  })
                }
              </ul>
            }
          </div>
        </div>
        {/* <div className="yd-customerselection">
          <div className="yd-customerselection-filter">
            <div className="yd-customerselection-title">Favorite customers</div>
            { favoriteCustomers.length > 0 &&
                <ul className="yd-selected-customer-list">
                  {
                    favoriteCustomers.map(customer => (
                      <div className="yd-selected-customer" key={customer.id}>
                        <span className="yd-selected-customer-label" onClick={() => this.deselectCustomer(customer.id)}>{ customer.name }</span>
                      </div>
                    ))
                  }
                </ul>
              }
          </div>
        </div> */}
      </FilterBar>
    )
  }
}

function mapStateToProps(state) {
  const { customers, favoriteCustomerIds, filters, customerSearch, isCustomerSelectionVisible } = state
  return {
    customers,
    favoriteCustomers: favoriteCustomerIds.map(id => customers.find(c => c.id === id)),
    customerSearch,
    filters,
    isCustomerSelectionVisible
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setFilter: (filter, value) => dispatch(setFilter(filter, value)),
    toggleCustomerSelection: (isVisible) => dispatch(toggleCustomerSelection(isVisible)),
    fetchAllCustomers: () => dispatch(fetchAllCustomers()),
    fetchCustomers: () => dispatch(fetchCustomers()),
    searchCustomers: (query, property) => dispatch(searchCustomers(query, property)),
    getAssetsByCustomerId: (customerId) => dispatch(getAssetsByCustomerId(customerId))
  }
}

export default translateWithPrefix('filter')(connect(mapStateToProps, mapDispatchToProps)(CustomerSelection))
