import * as React from 'react'
import { connect } from 'react-redux'
import { translateWithPrefix } from '../../translations/initTranslations'
import { format } from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle as faCircleSolid } from '@fortawesome/pro-solid-svg-icons'

import './Alerts.css'
import { loadAlerts } from '../../actions/measurements'
import { getTimeFrame } from '../../actions/measurements_v2'
import { getDataFromState, DLTYPE_ALERTS, isLoadingPending } from '../../lib/measurement'
import ListAccordionGroup from '../../components/ListAccordionGroup/ListAccordionGroup'
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner'
import AlertDetails from './AlertDetails'
import {isEcu} from '../../lib/asset-helpers'

const ALERT_STATUS = {
  WARNING: 'warning',
  ERROR: 'error',
}

const rootComponent = 'ROOT'

class AssetAlerts extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      alertsFetched: [],
      statusFilter: [],
    }
  }

  componentDidUpdate() {
    this.loadAlerts()
  }

  loadAlerts() {
    const { loadAlerts } = this.props
    const params = this.getParams()
    if (params) {
      const { asset, sd, ed } = params
      if (this.state.alertsFetched.filter(a => a.sd === sd && a.ed === ed).length === 0) {
        this.setState({
          ...this.state,
          alertsFetched: [...this.state.alertsFetched, { sd, ed }]
        })
        loadAlerts(asset, sd, ed)
      }
    }
  }

  getParams() {
    const { filters, asset } = this.props
    const timeFrame = getTimeFrame((filters || {}).time)
    if (asset && timeFrame) {
      const sd = format(timeFrame.startDate, 'yyyy-MM-dd')
      const ed = format(timeFrame.endDate, 'yyyy-MM-dd')
      return {
        asset,
        sd,
        ed
      }
    }
    return null
  }

  getDailyGroupedAlerts() {
    const params = this.getParams()
    const { statusFilter } = this.state
    const { asset } = this.props
    const rootSn = asset && asset.sn ? asset.sn : rootComponent
    if (params) {
      const { asset, sd, ed } = params
      const alerts = getDataFromState(this.props, DLTYPE_ALERTS, asset, { sd, ed })
      if (alerts && alerts.values) {
        const groupedAlerts = {}
        for (const cn of Object.keys(alerts.values)) {
          for (const alert of alerts.values[cn]) {
            const day = format(new Date(alert.datetime), 'yyyy-MM-dd')
            groupedAlerts[day] = groupedAlerts[day] || {}
            const level = isEcu(asset)? alert.risk: alert.level
            if (level > 1) {
              const dayDetails = groupedAlerts[day][level] = groupedAlerts[day][level] || {}

              const componentSerial = cn === rootComponent ? rootSn : cn

              dayDetails['cns'] = dayDetails['cns'] || []
              if (!dayDetails['cns'].includes(componentSerial)) {
                dayDetails['cns'].push(componentSerial)
              }

              dayDetails['alerts'] = dayDetails['alerts'] || []
              if (!statusFilter.length || statusFilter.includes(this.getStatus(level))) {
                dayDetails['alerts'].push({
                  ...alert,
                  cn: componentSerial
                })
              }
            }
          }
        }
        // Everything is grouped, now sort alerts grouped by day and level
        for (const day of Object.keys(groupedAlerts)) {
          for (const level of Object.keys(groupedAlerts[day])) {
            groupedAlerts[day][level]['alerts'].sort((a, b) => {
              if (a.datetime === b.datetime) {
                return 0
              }
              if (new Date(a.datetime) > new Date(b.datetime)) {
                return -1
              } else {
                return 1
              }
            })
          }
        }
        return groupedAlerts
      }
    }
    return {}
  }

  getStatus(level) {
    switch (level) {
      case '3':
      case 3:
      case '5':
      case 5:
        return ALERT_STATUS.WARNING
      case '2':
      case 2:
      case 6:
      case '6':
        return ALERT_STATUS.ERROR
      default:
        return 'normal'
    }
  }

  toggleTypeFilter(type) {
    const { statusFilter } = this.state
    const selected = statusFilter.includes(type)
    this.setState({
      ...this.state,
      statusFilter: selected
        ? statusFilter.filter(t => t !== type)
        : statusFilter.concat([type])
    })
  }

  getTextWithLinebreaks(text) {
    return text.split('\n').map((t, i) => <div key={i}>{t}</div>)
  }

  alertContentExists(dailyGroupedAlerts) {
    return Object.values(Object.values(dailyGroupedAlerts))
      .flatMap(m => Object.values(m).filter(a => a.alerts.length > 0))
      .length > 0
  }

  getTranslationVersion(alert, selectedLang) {
    if (selectedLang === 'en') {
      return alert.version_id
    } else {
      const key = `version_id_${selectedLang}`
      return alert[key]
    }
  }

  render() {
    const { t } = this.props
    const lang = this.props.lng || 'en'
    const params = this.getParams() || {}
    const isLoading = isLoadingPending(
      this.props,
      DLTYPE_ALERTS,
      params.asset,
      { sd: params.sd, ed: params.ed }
    )

    const dailyGroupedAlerts = !isLoading ? this.getDailyGroupedAlerts() : null
    const displayAlerts = !isLoading ? this.alertContentExists(dailyGroupedAlerts) : false
    return (
      <div>
        <div className="flex-row">

          <main className="yd-info">
            <div className="yd-alert-filters">
              {
                Object.keys(ALERT_STATUS).map(key => {
                  const status = ALERT_STATUS[key]
                  const selected = this.state.statusFilter.includes(status)
                  const classNames = `yd-alert-filter-label yd-alert-filter-label--${key.toLowerCase()} ${selected ? 'yd-alert-filter-label--selected' : ''}`
                  return (
                    <div
                      key={status}
                      onClick={() => this.toggleTypeFilter(status)}
                      className={classNames}>
                      <FontAwesomeIcon icon={faCircleSolid} /> {t(`alert_${status}`)}
                    </div>
                  )
                })
              }
            </div>
            <h2 className="yd-info-header">Alerts</h2>
            <div className="yd-alertlist">
              <LoadingSpinner loading={isLoading} size="lg" className="flex-row text-center" />
              {params && params.asset && !isLoading && dailyGroupedAlerts && !displayAlerts && t('no_alerts')}
              {dailyGroupedAlerts &&
                (Object.keys(dailyGroupedAlerts) || []).sort().reverse().map((day, idx) => {
                  return Object.keys(dailyGroupedAlerts[day] || {}).sort().reverse().map(level => {
                    const dailyData = dailyGroupedAlerts[day][level]
                    const status = this.getStatus(level)
                    if (dailyData.alerts.length > 1) {
                      // Display grouped alerts for the day
                      const accordions = dailyData.alerts.map((item, idx) => {
                        const {event_id} = item
                        const version_id = this.getTranslationVersion(item, lang);
                        return (
                            <AlertDetails
                              key={`${day}-${level}-${idx}-${lang}`}
                              assetId={params.asset.id}
                              eventId={event_id}
                              lang={lang}
                              versionId={version_id}
                              date={new Date(item.datetime)}
                              showDate={false}
                              status={status}
                              title={item.cn}
                              classes="alert-details-row"
                            />
                        )
                      })
                      return (
                      <div key={idx}>
                        {(dailyData.alerts.length > 0) &&
                          <ListAccordionGroup
                            title={t(`alert_title_${status}_multiple`, { count: dailyData.alerts.length })}
                            titleExtra={dailyData.cns.join(', ')}
                            date={new Date(day)}
                            status={status}
                            showDate={true}
                          >
                            {accordions}
                          </ListAccordionGroup>
                        }
                      </div>)
                    } else if (dailyData.alerts.length === 1){
                      const onlyItem = dailyData.alerts[0]
                      const {event_id} = onlyItem
                      const version_id = this.getTranslationVersion(onlyItem, lang)
                      return (
                          <AlertDetails
                            key={`${day}-${level}-${lang}-0`}
                            assetId={params.asset.id}
                            eventId={event_id}
                            lang={lang}
                            versionId={version_id}
                            date={new Date(onlyItem.datetime)}
                            showDate={true}
                            status={status}
                            title={onlyItem.cn}
                            classes="alert-details-row top-level-details"
                          />
                      )
                    } else {
                      return []
                    }
                  })
                })
              }
            </div>
          </main>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    assetsByCustomerId: state.assetsByCustomerId,
    filters: state.filters,
    [DLTYPE_ALERTS]: state[DLTYPE_ALERTS],
    pendingUrls: state.pendingUrls, // TODO!!!
  }
}

const mapDispatchToProps = dispatch => {
  return {
    loadAlerts: (asset, sd, ed) => dispatch(loadAlerts(asset, sd, ed)),
  }
}

export default translateWithPrefix('asset')(connect(mapStateToProps, mapDispatchToProps)(AssetAlerts))
