import { dataLakeAPI } from '../lib/api'
import authLib from '../lib/auth/auth'
import { loginActions } from '../actions/constants'

export default store => next => action => {
  const { auth: authState }  = store.getState()

  // add access token to for datalake api and handle token refresh
  if (authState && authState.isAuthenticated && !authState.isLoggingOut && action.type !== loginActions.LOGOUT) {
    dataLakeAPI.defaults.headers.common.Authorization = `Bearer ${authLib.getAccessToken()}`
  } else {
    delete dataLakeAPI.defaults.headers.common.Authorization
  }

  return next(action)
}