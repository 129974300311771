import { replace } from 'connected-react-router'

import { filterActions } from '../actions/constants'
import { createQueryString } from '../lib/query-string'

export default store => next => action => {
  const { type } = action

  switch (type) {
    case '@@router/LOCATION_CHANGE':
    case filterActions.ADD_FILTER:
    case filterActions.REMOVE_FILTER:
    case filterActions.SET_FILTER:
    case filterActions.SET_FILTERS:
      setTimeout(() => {
        const state = store.getState()
        const { router, filters } = state
        if (router.location.pathname === '/callback') {
          return
        }
        const search = createQueryString(filters)
        if (search !== router.location.search) {
          store.dispatch(replace({
            pathname: router.location.pathname,
            search
          }))
        }
      }, 0)
      break
    default:
      break
  }

  return next(action)
}