import * as React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBars,
  faDotCircle,
} from '@fortawesome/pro-solid-svg-icons'

import './Header.css'

const HeaderContainer = ({ t, toggleMenu, children }) => {
  return (
    <header className="yd-header__container">
      <img src="/assets/sc-logo.svg" alt="Demag" className="yd-header__sc-logo"/>
      <span className="yd-header__grey-wedge"></span>
      <Link to="/"><img src="/assets/demag_logo.svg" alt="Demag" className="yd-header__logo"/></Link>
      {children}
      <p className="yd-header__title">
        <FontAwesomeIcon icon={faDotCircle}/> {t('statuscontrol_assets')}
      </p>
      <div className="yd-header__menu-toggle">
        <button className="button button--ghost" onClick={toggleMenu}>
          {t('menu')} <FontAwesomeIcon icon={faBars} size="lg"/>
        </button>
      </div>
    </header>
  )
}

export default HeaderContainer
