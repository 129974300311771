import * as React from 'react'
import { Route } from 'react-router-dom'
import { connect } from 'react-redux'
import { login } from '../../actions/user'

class PrivateRoute extends React.PureComponent {

  componentDidMount() {
    const { login, auth } = this.props

    if (!auth.isAuthenticated && !auth.isLoggingOut) {
      login()
    }
  }

  render() {
    const { auth } = this.props
    return auth && auth.isAuthenticated
      ? <Route {...this.props} />
      : null
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    login: () => dispatch(login())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute)
