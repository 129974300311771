import * as React from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'

import { addMessage } from '../actions/messages'
import { store } from '../store'
import config from '../config'


const errorHandler = error => {
  let errorResponse

  if (error.response) {
    errorResponse = error.response
  } else if (error.request) {
    errorResponse = {
      status: 0,
      statusText: 'Network error'
    }
  } else {
    errorResponse = {
      status: 0,
      statusText: error.message
    }
  }

  if (!error.config.pmHasErrorHandler) {
    let errorMessage
    const { data, statusText, status } = errorResponse

    switch (status) {
      case 404:
        // no data available for requested params
        errorMessage = null
        break
      case 401:
      // todo.. errorMessages needs translations
      errorMessage = <div>There was error with the request: Unauthorized. Your login session may have been expired. Please <Link to="/logout">log out</Link> and then log in again.</div>
        break
      default:
        errorMessage = `There was error with the request: ${
          data && data.message
            ? data.message
            : (statusText || `HTTP status code ${status}`)
          }.`
        // API returns 500 instead of 404, supress all errors for now
        console.error(errorMessage)
        errorMessage = null
    }

    if (errorMessage) {
      store.dispatch(addMessage(errorMessage))
    }
  }

  return errorResponse
}

export const dataLakeAPI = axios.create({
  baseURL: config.DATALAKE_API_URL,
  headers: {
    'X-api-key': config.STATUS_CONTROL_API_KEY,
//    'Cache-Control': 'no-store, no-cache, max-age=0'
  }
})

dataLakeAPI.interceptors.response.use(
  response => response,
  errorHandler
)

export const activationAPI = axios.create({
  baseURL: config.ACTIVATION_API_URL
})

activationAPI.interceptors.response.use(
  response => response,
  errorHandler
)
