import * as React from 'react'
import { connect } from 'react-redux'
import { translateWithPrefix } from '../../translations/initTranslations'
import { format } from 'date-fns'

import { setFilter } from '../../actions/common'

import { loadLatestMeasurements, loadMeasurements, loadTimeframeSummary } from '../../actions/measurements'

import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner'

import DonutWheel from '../../components/SVG/DonutWheel'
import AccordionGroup from '../../components/AccordionGroup/AccordionGroup'
import MeasurementValueBox from '../../components/MeasurementValueBox/MeasurementValueBox'

import './Statistics.css'
import DWPTrendChart from '../../components/Charts/DWPTrendChart'
import OperatingChart from '../../components/Charts/OperatingTrendChart'
import AssetBarChart from '../../components/Charts/AssetBarChart'
import LoadSpectrumChart from '../../components/Charts/LoadSpectrumChart'
import {
  preprocessMeasurements,
  generateMeasurementMonthlyKeysRange,
  dateToMeasurementMonthlyKey,
  lookupDateKey,
  LOOKUP_PREVIOUS,
  getTimeFrame
} from '../../actions/measurements_v2'
import { loadTranslations } from '../../actions/measurements'
import {
  DLTYPE_MEASUREMENTS,
  DLTYPE_LATEST_MEASUREMENTS,
  DLTYPE_TIMEFRAME_SUMMARY,
  getDataFromState,
  isLoadingPending
} from '../../lib/measurement'
import {
  Measurement,
  formattedValue,
  formattedConditionValue,
  getStatus,
  getWorstStatus,
  getStatusClass
} from '../../lib/measurement-helpers'

import {
  MEASUREMENT_TYPE,
  VALUES,
  MEASUREMENT_VALUES,
  MEASUREMENT_TRANSLATION_KEYS,
  STATUS
} from '../../lib/measurement-constants'

import { stripHtml } from '../../lib/string-helpers'
import HelpLink, { HELP_ITEM } from '../../components/HelpLink/HelpLink'

class AssetStatistics extends React.Component {

  static DWP_TREND_RANGE_START_YEAR = -4
  static DWP_TREND_RANGE_END_YEAR = 10

  static OPERATING_TREND_RANGE_START_YEAR = -1
  static OPERATING_TREND_RANGE_END_YEAR = 0

  constructor(props) {
    super(props)

    this.state = {
      allAccordionsExpanded: false,
      forceUpdate: Math.random()
    }

    props.loadTranslations()
  }

  isLoading() {
    const { asset, filters } = this.props
    const timeFrame = getTimeFrame((filters || {}).time) || {}
    const sd = format(timeFrame.startDate, 'yyyy-MM-dd')
    const ed = format(timeFrame.endDate, 'yyyy-MM-dd')

    return isLoadingPending(this.props, DLTYPE_MEASUREMENTS, asset) ||
      isLoadingPending(this.props, DLTYPE_LATEST_MEASUREMENTS, asset) ||
      (sd && ed
        ? isLoadingPending(this.props, DLTYPE_TIMEFRAME_SUMMARY, asset, { sd, ed })
        : false
      )
  }

  getMeasurementValues(asset) {
    return getDataFromState(this.props, DLTYPE_MEASUREMENTS, asset)
  }

  getLatestMeasurementValues(asset) {
    return getDataFromState(this.props, DLTYPE_LATEST_MEASUREMENTS, asset)
  }

  getTimeframeSummary(asset, timeFrame) {
    if (timeFrame && timeFrame.startDate && timeFrame.endDate) {
      const sd = format(timeFrame.startDate, 'yyyy-MM-dd')
      const ed = format(timeFrame.endDate, 'yyyy-MM-dd')
      return getDataFromState(this.props, DLTYPE_TIMEFRAME_SUMMARY, asset, { sd, ed })
    }
    return null
  }

  getTrendTimeFrameDates() {
    const startDate = new Date()
    startDate.setFullYear(startDate.getFullYear() + AssetStatistics.DWP_TREND_RANGE_START_YEAR)
    const endDate = new Date()
    endDate.setFullYear(endDate.getFullYear() + AssetStatistics.DWP_TREND_RANGE_END_YEAR)
    return { startDate, endDate }
  }

  getTrendTimeFrameSummary(asset) {
    return this.getTimeframeSummary(asset, this.getTrendTimeFrameDates())
  }

  getMeasurementTranslations() {
    const { translations } = this.props
    if (!translations || !Array.isArray(translations.measurements)) {
      return []
    }
    return translations.measurements
  }

  getMeasurementTranslation(measurementId) {
    return this.getMeasurementTranslations().find((m => m.measurement_type_id === measurementId))
  }

  getComponentTranslations() {
    const { translations } = this.props
    if (!translations || !Array.isArray(translations.components)) {
      return []
    }
    return translations.components
  }

  getMeasurements(measurementId) {
    const { filters, asset } = this.props
    const timeFrame = getTimeFrame((filters || {}).time)
    const measurement = this.getMeasurementTranslation(measurementId)
    const measurements = this.getMeasurementValues(asset)
    const latestMeasurements = this.getLatestMeasurementValues(asset)
    //const trendTimeFrameSummary = this.getTrendTimeFrameSummary(asset)

    if (!measurement || !measurements || !timeFrame || !latestMeasurements) {
      return null
    }
    const components = preprocessMeasurements({ measurements, latestMeasurements }, measurementId, timeFrame, asset)
    const cIds = Object.keys(components)
    const m = []

    for (const cId of cIds) {
      const component = components[cId]

      const sv = component.dc[lookupDateKey(component, timeFrame.startDate, LOOKUP_PREVIOUS)]
      const ev = component.dc[timeFrame.endDailyKey] ?
        component.dc[timeFrame.endDailyKey] :
        component.dc[lookupDateKey(component, timeFrame.endDate, LOOKUP_PREVIOUS)]
      const value = ev - sv

      m.push(new Measurement(measurement, cId, value))
    }

    return m
  }

  findComponentMeta(componentId) {
    const meta = this.getComponentTranslations()

    let componentMeta = meta.find((c) => {
      return c.name === componentId || new RegExp(c.regex).test(componentId)
    })
    return componentMeta || {}
  }

  getComponentType(componentId, measurementId) {
    switch (measurementId) {
      case MEASUREMENT_TYPE.CONTACTOR_K1_SERVICE_LIFE_HOIST:
      case MEASUREMENT_TYPE.CONTACTOR_K2_SERVICE_LIFE_HOIST:
      case MEASUREMENT_TYPE.CONTACTOR_K3_SERVICE_LIFE_HOIST:
      case MEASUREMENT_TYPE.BRAKE_SERVICE_LIFE_HOIST:
        return 'Hoist'
      case MEASUREMENT_TYPE.CONTACTOR_K1_SERVICE_LIFE_CRAB:
      case MEASUREMENT_TYPE.CONTACTOR_K2_SERVICE_LIFE_CRAB:
      case MEASUREMENT_TYPE.CONTACTOR_K3_SERVICE_LIFE_CRAB:
      case MEASUREMENT_TYPE.BRAKE_SERVICE_LIFE_CRAB:
        return 'Crab'
      case MEASUREMENT_TYPE.CONTACTOR_K1_SERVICE_LIFE_CRANE:
      case MEASUREMENT_TYPE.CONTACTOR_K2_SERVICE_LIFE_CRANE:
      case MEASUREMENT_TYPE.CONTACTOR_K3_SERVICE_LIFE_CRANE:
      case MEASUREMENT_TYPE.BRAKE_SERVICE_LIFE_CRANE:
        return 'Crane'
      default:
        const componentMeta = this.findComponentMeta(componentId)
        return componentMeta && componentMeta.type ? componentMeta.type : 'Component'
    }
  }

  getDailyValue(data, date, key) {
    let value = data.dc[key]
    if (value === undefined || value === null) {
      value = data.dc[lookupDateKey(data, date, LOOKUP_PREVIOUS)]
    }
    return value
  }

  renderDWPGraphs(asset, filterComponentType, measurementIds, options) {

    const measurements = this.getMeasurementValues(asset)
    //const trendTimeFrameSummary = this.getTrendTimeFrameSummary(asset)
    const latestMeasurements = this.getLatestMeasurementValues(asset)
    const isTranslationAvailable = !!this.getComponentTranslations()
    const data = []
    options = options || {}

    const { t, filters } = this.props
    const timeFrame = getTimeFrame((filters || {}).time)

    if (measurements && Array.isArray(latestMeasurements) && isTranslationAvailable && timeFrame) {
      const latestValues = latestMeasurements
        .filter(m => measurementIds.includes(m.vid))
        .reduce((acc, val) => {
          acc[val.vid] = acc[val.vid] || []
          acc[val.vid].push(val)
          return acc
        }, {})

      for (const measurementId of measurementIds) {
        const values = preprocessMeasurements({ measurements, latestMeasurements }, measurementId, timeFrame, asset)

        for (const componentId in values) {

          const componentType = this.getComponentType(componentId, measurementId)
          if (filterComponentType && filterComponentType !== componentType) {
            continue
          }

          const tkey = componentType.toLowerCase().replace(' ', '')
          const componentData = {
            mid: measurementId,
            meta: this.findComponentMeta(componentId),
            mmeta: this.getMeasurementTranslation(measurementId),
            tkey: tkey,
            cid: componentId,
            nameHtml: { __html: t(tkey + '_component__' + (MEASUREMENT_TRANSLATION_KEYS[measurementId] || 'dwp'), { component: componentId, measurement: measurementId }) },
            //shortName: t(tkey + '_component_short_' + (MEASUREMENT_TRANSLATION_KEYS[measurementId] || 'dwp'), { component: componentId, measurement: measurementId }),
            donut: null,
            trend: []
          }
          componentData.name = stripHtml(componentData.nameHtml)

          //console.log(componentData.name)

          const component = values[componentId]

          componentData.trend = component

          // Latest value from start of time frame
          const rv1 = this.getDailyValue(component, timeFrame.endDate, timeFrame.endDailyKey)
          const v1 = rv1 / 100

          // First value of the time frame (or earlier if no avail)
          let rv2 = this.getDailyValue(component, timeFrame.startDate, timeFrame.startDailyKey)
          const v2 = (rv2 / 100) - v1

          componentData.donut = [v1, v2]
          // change during period (=timeframe) and annual average (=what 'during period' would be for 365 days)
          componentData.info = [v2, v2 * 365 / timeFrame.days]
          componentData.latest_key = component.dc_latest
          componentData.replaced_keys = latestValues[measurementId] ? latestValues[measurementId]
            .filter((v) => v.cn === componentId)
            .reduce((acc, v) => acc.concat(v.rd || []), [])
            .map((d) => dateToMeasurementMonthlyKey(new Date(d))) : []

          componentData.status = getStatus(rv1, componentData.mmeta)

          const index = data.findIndex(cd => cd.cid === componentData.cid)
          if (index !== -1) {
            data.splice(index + 1, 0, componentData)
          } else {
            data.push(componentData)
          }
        }
      }
    }

    const tkeyHeader = (filterComponentType || '').toLowerCase().replace(' ', '')
    options.trendHeader = options.trendHeader || t(tkeyHeader + '_dwp_trend_header')

    const worstStatus = getWorstStatus(data.map(d => d.status))

    return {
      status: worstStatus,
      content: (
        <React.Fragment>
          <div className="yd-donuts">
            {data.map((d, i) => {
              return (
                <div className="yd-donut" key={`${d.name}_#${i}`}>
                  <div className="yd-value-title"><span className="yd-value" dangerouslySetInnerHTML={d.nameHtml}></span></div>
                  <DonutWheel width="100" height="100" type="wheel" values={d.donut} colorValue={true} formatValue={(v) => { return formattedConditionValue(v * 100) }} />
                  <div className="yd-period-average">
                    <span className="yd-period">
                      <span className="yd-number">{formattedConditionValue(d.info[0] * 100, 100)}</span>
                      <span className="yd-value">{t('during_period')}</span>
                    </span>
                    <span className="yd-average">
                      <span className="yd-number">{formattedConditionValue(d.info[1] * 100, 100)}</span>
                      <span className="yd-value">{t('annual_average')}</span>
                    </span>
                  </div>
                </div>
              )
            })}
          </div>
          <div className="yd-graph--header">
            {options.trendHeader}
          </div>
          <div className="yd-graph">
            {this.isLoading() ? (<LoadingSpinner loading size="lg" />) :
              (<DWPTrendChart
                titles={[]}
                data={data}
                range={generateMeasurementMonthlyKeysRange(new Date(), AssetStatistics.DWP_TREND_RANGE_START_YEAR * 12, AssetStatistics.DWP_TREND_RANGE_END_YEAR * 12)}
              />)
            }
          </div>
        </React.Fragment>
      )
    }
  }


  renderTimeGraphs(asset, options = {}) {

    const measurements = this.getMeasurementValues(asset)
    const latestMeasurements = this.getLatestMeasurementValues(asset)
    const isTranslationAvailable = !!this.getComponentTranslations()
    const { t, filters } = this.props
    const timeFrame = getTimeFrame((filters || {}).time)
    const data = {}
    const { measurementTypes } = options

    measurementTypes.map((measurementType) => {

      const measurementId = measurementType.id

      if (!data[measurementId]) {
        data[measurementId] = []
      }

      if (measurements && latestMeasurements && isTranslationAvailable && timeFrame) {
        const values = preprocessMeasurements({ measurements, latestMeasurements }, measurementId, timeFrame, asset)

        for (const componentId in values) {
          const assetComponentType = this.getComponentType(componentId, measurementId)
          const componentData = {
            cid: componentId,
            name: assetComponentType + ' ' + componentId,
            type: assetComponentType,
            donut: null,
            trend: []
          }
          const component = values[componentId]

          componentData.trend = component

          const rangeDayCount = timeFrame.days
          const rv1 = this.getDailyValue(component, timeFrame.endDate, timeFrame.endDailyKey)
          const rv2 = this.getDailyValue(component, timeFrame.startDate, timeFrame.startDailyKey)
          const v1 = rv1 - rv2
          const v2 = 24 * 60 * 60 * rangeDayCount

          componentData.donut = [v1 / v2]
          componentData.info = [v1, v2]

          componentData.status = STATUS.OK // FIXME: is there a status for time graphs?

          data[measurementId].push(componentData)
        }
      }

      // fix for compilation problem, map is much more efficient than forEach
      return {}
    })

    const craneHoursData = data[options.total.measurementId]

    const worstStatus = craneHoursData.length > 0 ? getWorstStatus(craneHoursData.map(d => d.status)) : STATUS.OFFLINE

    const emptyCraneHours = {
      id: 'crane_hours',
      donut: [0],
      info: [0, 0]
    }

    const craneHours = craneHoursData.find((d) => d.type === 'Crane') || emptyCraneHours

    return {
      status: worstStatus,
      content: (
        <React.Fragment>
          <div className="yd-statistics-card-container">
            <div className="yd-statistics-card" key={craneHours.name}>
              <div className="yd-donut">
                <div><span className="yd-value">{t(options.total.title, { component: craneHours.name })}</span></div>
                <DonutWheel width="150" height="150" type="meter" values={craneHours.donut} colorValue={true} formatValue={(v) => { return formattedValue(v * 100, '%') }} />
                <div className="yd-period-average">
                  <span className="yd-period">
                    <span className="yd-number">{Math.round(craneHours.info[0] / 3600)}h</span>
                    <span className="yd-value">{t('crane_powered_on')}</span>
                  </span>
                  <span className="yd-average">
                    <span className="yd-number">{Math.round(craneHours.info[1] / 3600)}h</span>
                    <span className="yd-value">{t('total_hours')}</span>
                  </span>
                </div>
              </div>
            </div>
            <div className="yd-statistics-card">
              <div className="yd-bar-chart">
                <div><span className="yd-value">{t(options.machinery.title)}</span></div>
                <AssetBarChart data={data[options.machinery.measurementId]} />
              </div>
            </div>
          </div>
          <div className="yd-graph--header">
            {t(options.trend.title)}
          </div>
          <div className="yd-graph">
            <OperatingChart
              titles={[t('component_operating_hours')]}
              data={data[options.trend.measurementId]}
              range={generateMeasurementMonthlyKeysRange(new Date(), AssetStatistics.OPERATING_TREND_RANGE_START_YEAR * 12, AssetStatistics.OPERATING_TREND_RANGE_END_YEAR * 12)}
            />
          </div>
        </React.Fragment>
      )
    }
  }

  renderLoadSpectrum(asset, measurementIds) {
    const isTranslationAvailable = !!this.getComponentTranslations()
    const { t, filters } = this.props
    const timeFrame = getTimeFrame((filters || {}).time)
    const timeframeSummary = this.getTimeframeSummary(asset, timeFrame)

    let temp = {}
    let data = []
    let categories = []
    const isTandem = (componentId) => componentId.includes('|')

    if (timeframeSummary && isTranslationAvailable && timeFrame) {
      measurementIds.map((mId, idx, allIds) => {
        const values = timeframeSummary[mId] || {}
        return Object.keys(values).forEach((componentId) => {
          const assetComponentType = this.getComponentType(componentId, mId)
          if (!temp[componentId]) {
            temp[componentId] = {
              cid: componentId,
              name: `${assetComponentType} ${componentId}`,
              type: assetComponentType,
              values: allIds.map(() => 0)
            }
          }
          const delta = values[componentId].end_value - values[componentId].start_value
          temp[componentId].values[idx] = delta > 0 ? delta : 0
        })
      })
      Object.keys(temp).forEach((componentId) => {
        const combinedTandem = data.find(item => isTandem(item.cid))
        if (isTandem(componentId) && combinedTandem) {
          combinedTandem.cid += ` ${temp[componentId].cid}`
          combinedTandem.tooltip += `<br />${temp[componentId].name}`
          combinedTandem.type += ` ${temp[componentId].type}`
          combinedTandem.values = combinedTandem.values.map((v, i) => v + temp[componentId].values[i])
        } else if (isTandem(componentId)) {
          temp[componentId].tooltip = temp[componentId].name
          temp[componentId].name = t("load_spectrum_tandem_mode")
          data.push(temp[componentId])
        } else {
          data.push(temp[componentId])
        }
      })
      categories = measurementIds.map((mId, idx) => {
        return t(((this.getMeasurementTranslation(mId) || {}).name || 'load_spectrum_' + idx)
          .replace(/%/g, '').trim().replace(/ /g, '_').toLowerCase())
      })
    }

    const worstStatus = data.length > 0 ? STATUS.OK : STATUS.OFFLINE // FIXME: any logic for status for load? max spectrum value on higher categories?

    return {
      status: worstStatus,
      content: (!!timeframeSummary && this.isLoadDataAvailable(timeframeSummary)) ? (
        <React.Fragment>
          <div className="yd-graph--header">
            {t('load_spectrum_trend_header')}
          </div>
          <div className={"yd-graph--filter-button" + (this.state.excludeLoad00_10 ? " selected" : "")} onClick={() => this.toggleExcludeLoad00_10Filter()}>{t('load_spectrum_exclude_0-10')}</div>
          <div className="yd-graph">
            <LoadSpectrumChart data={data} categories={categories} />
          </div>
        </React.Fragment>
      ) : null
    }
  }

  // Check if there's load data available in timeframe summary
  isLoadDataAvailable(timeframeSummary) {
    for (const measurementId of MEASUREMENT_VALUES[VALUES.LOAD]) {
      if (timeframeSummary[measurementId] !== undefined) {
        return true
      }
    }
    return false
  }


  renderAlerts(asset, measurementIds) {
    // Collect all events and alerts of all components available
    let eventsAndAlerts = []
    const statusList = []
    for (const mt of measurementIds) {
      const measurements = this.getMeasurements(mt)
      if (measurements) {
        const sumMeasurements = measurements.reduce((acc, cur) => {
          const inList = acc.find(m => m.meta.name === cur.meta.name)
          if (inList) {
            inList.value += cur.value
          } else {
            acc.push(new Measurement(cur.meta, null, cur.value))
          }
          return acc
        }, [])
        sumMeasurements.forEach((m, idx) => {
          statusList.push(m.getStatus())
          eventsAndAlerts.push(
            <MeasurementValueBox key={mt + m.componentId + idx} measurement={m} />
          )
        })
      } else {
        // TODO: loader placeholder(s)?
      }
    }

    const content = eventsAndAlerts.length > 0 ? (
      <div className="">
        <div className="yd-events-alerts">{eventsAndAlerts}</div>
      </div>
    ) : (
        <div>
          No alerts
      </div>
      )

    const worstStatus = getWorstStatus(statusList)

    return {
      status: worstStatus,
      content
    }
  }

  // renderAlertsGrouped(asset, measurementIds) {
  //   // Collect all events and alerts of all components available
  //   let eventsAndAlerts = {}
  //   const statusList = []
  //   for (const mt of measurementIds) {
  //     const measurements = this.getMeasurements(mt)
  //     if (measurements) {
  //       let idx = 0
  //       eventsAndAlerts = measurements.reduce((g, m) => {
  //         g[m.componentId] = g[m.componentId] || []
  //         g[m.componentId].push(<MeasurementValueBox key={mt + m.componentId + idx} measurement={m} />)
  //         statusList.push(m.getStatus())
  //         idx++
  //         return g
  //       }, eventsAndAlerts)
  //     } else {
  //       // TODO: loader placeholder(s)?
  //     }
  //   }

  //   const { t } = this.props
  //   const groups = Object.keys(eventsAndAlerts)
  //   console.log(groups)
  //   const content = groups.length > 0 ? (
  //     <div className="">
  //       <div className="yd-events-alerts">{groups.map(g => {
  //         const tkey = this.getComponentType(g).toLowerCase()
  //         return (<div key={g}><h3 className="yd-header-border yd-header--main">{t(tkey + '_component', { component: g })}</h3>{eventsAndAlerts[g]}</div>)
  //       })}</div>
  //     </div>
  //   ) : (
  //       <div>
  //       </div>
  //     )

  //   const worstStatus = getWorstStatus(statusList)

  //   return {
  //     status: worstStatus,
  //     content
  //   }
  // }

  createDwpAccordions(asset) {
    let accordions = []
    const { t } = this.props

    accordions = accordions.concat([{
      filter: 'Crab', mIds: MEASUREMENT_VALUES[VALUES.HOIST_DWP]
    }].map((params, idx) => {
      const graphs = this.renderDWPGraphs(asset, params.filter, params.mIds,
        { trendHeader: t('hoist_dwp_trend_header') })
      return {
        id: 'hoist-' + idx,
        title: `${t('hoist_dwp')}`,
        status: getStatusClass(graphs.status),
        content: graphs.content
      }
    }))
    accordions = accordions.concat([{
      filter: 'Crab', mIds: MEASUREMENT_VALUES[VALUES.TROLLEY_SERVICE_LIFE]
    }].map((params, idx) => {
      const graphs = this.renderDWPGraphs(asset, params.filter, params.mIds,
        { trendHeader: t('trolley_service_life_trend_header') })
      return {
        id: 'trolley_service_life-' + idx,
        title: t('trolley_service_life'),
        status: getStatusClass(graphs.status),
        content: !graphs.status ? null : graphs.content
      }
    }))
    accordions = accordions.concat([{
      filter: undefined, mIds: MEASUREMENT_VALUES[VALUES.BRAKE_SERVICE_LIFE]
    }].map((params, idx) => {
      const graphs = this.renderDWPGraphs(asset, params.filter, params.mIds,
        { trendHeader: t('brake_service_life_trend_header') })
      return {
        id: 'brake_service_life-' + idx,
        title: t('brake_service_life'),
        status: getStatusClass(graphs.status),
        content: graphs.content
      }
    }))
    accordions = accordions.concat([{
      filter: undefined, mIds: MEASUREMENT_VALUES[VALUES.CONTACTOR_SERVICE_LIFE]
    }].map((params, idx) => {
      const graphs = this.renderDWPGraphs(asset, params.filter, params.mIds,
        { trendHeader: t('contactor_service_life_trend_header') })
      return {
        id: 'contactor_service_life-' + idx,
        title: t('contactor_service_life'),
        status: getStatusClass(graphs.status),
        content: graphs.content
      }
    }))
    return accordions
  }

  createOperatingAccordions(asset) {
    let accordions = []
    const { t } = this.props
    accordions.push((() => {
      const options = {
        total: {
          title: 'operation_hours',
          measurementId: MEASUREMENT_TYPE.OPERATION_HOURS
        },
        machinery: {
          title: 'drive_time_machinery',
          measurementId: MEASUREMENT_TYPE.DRIVE_TIME,
        },
        trend: {
          title: 'drive_time_trend',
          measurementId: MEASUREMENT_TYPE.DRIVE_TIME
        },
        measurementTypes: [{
          id: MEASUREMENT_TYPE.OPERATION_HOURS,
        }, {
          id: MEASUREMENT_TYPE.DRIVE_TIME,
        }]
      }

      const measurementId = MEASUREMENT_TYPE.OPERATION_HOURS

      const graphs = this.renderTimeGraphs(asset, options)

      return {
        id: measurementId,
        title: t('operation_hours'),
        status: getStatusClass(graphs.status),
        content: graphs.content
      }
    })())

    accordions.push((() => {
      const measurementIds = MEASUREMENT_VALUES[VALUES.LOAD]

      const graphs = this.renderLoadSpectrum(asset, measurementIds.filter((id) => {
        if (id === MEASUREMENT_TYPE.LOAD_SPECTRUM_00_10 && this.state.excludeLoad00_10) {
          return false
        }
        return true
      }))
      return {
        id: 'LOAD',
        title: t('load'),
        status: getStatusClass(graphs.status),
        content: graphs.content
      }
    })())

    accordions.push((() => {
      const measurementIds = MEASUREMENT_VALUES[VALUES.EVENTS_AND_ALERTS]
      const graphs = this.renderAlerts(asset, measurementIds)
      return {
        id: 'ALERTS',
        title: t('events_and_alerts'),
        status: getStatusClass(graphs.status),
        content: graphs.content
      }
    })())
    return accordions
  }

  componentDidMount() {
    const { asset, filters } = this.props
    if (!asset) {
      return
    }
    const timeFrame = getTimeFrame((filters || {}).time)

    // Timeout for IE11 optimization
    setTimeout(() => {
      this.loadStatistics(asset, timeFrame)
    }, 10)
  }

  componentDidUpdate(prevProps, prevState) {
    const { asset, filters } = this.props
    if (prevProps.asset !== asset || prevProps.filters !== filters) {
      if (!asset) {
        return
      }
      const timeFrame = getTimeFrame((filters || {}).time)

      // Timeout for IE11 optimization
      setTimeout(() => {
        this.loadStatistics(asset, timeFrame)
      }, 10)
    }
  }

  loadStatistics(asset, timeFrame) {
    const { loadMeasurements, loadLatestMeasurements, loadTimeframeSummary } = this.props

    // Latest data for display and 4 year trenddata
    // TODO: possibly re-order requests (measurementIds) to match visual order
    // const range = generateMeasurementYearRange(new Date(), AssetStatistics.DWP_TREND_RANGE_START_YEAR, 0)
    // const measurementIds = Object.keys(MEASUREMENT_TYPE)
    //   .map(mt => MEASUREMENT_TYPE[mt])
    //   .reduce((acc, val) => acc.concat(val), [])
    // for (const mId of measurementIds) {
    //   for (const year of range) {
    //     loadMeasurements(asset, mId, year)
    //   }
    // }
    // *** LOAD ALL MEASUREMENTS, due to it is faster than separately ***
    loadMeasurements(asset)

    /*
    const trendTimeFrame = this.getTrendTimeFrameDates()
    loadTimeframeSummary(equipmentId, format(trendTimeFrame.startDate, 'yyyy-MM-dd'), format(trendTimeFrame.endDate, 'yyyy-MM-dd'))
    */

    // Trend replaced markers are here and basic linear deltas for prediction data
    loadLatestMeasurements(asset)

    // TimeFrame summary for Load statistics (faster processing to get summaries from API)
    if (timeFrame && timeFrame.startDate && timeFrame.endDate) {
      loadTimeframeSummary(asset, format(timeFrame.startDate, 'yyyy-MM-dd'), format(timeFrame.endDate, 'yyyy-MM-dd'))
    }
  }

  toggleExcludeLoad00_10Filter() {
    this.setState({
      ...this.state,
      excludeLoad00_10: !this.state.excludeLoad00_10
    })
  }

  render() {
    const { t, asset } = this.props

    const conditionSections = (
      <React.Fragment>
        <AccordionGroup
          header={t('condition')}
          rememberStates={false}
          accordions={this.createDwpAccordions(asset)}>
        </AccordionGroup>
        <HelpLink
          to={HELP_ITEM.CONDITION}
          title={t('learn_more_about', { section: t('condition') })} />
      </React.Fragment>
    )
    const operatingSections = (
      <React.Fragment>
        <AccordionGroup
          header={t('operation_statistics')}
          rememberStates={false}
          accordions={this.createOperatingAccordions(asset)}>
        </AccordionGroup>
        <HelpLink
          to={HELP_ITEM.OPERATION_STATISTICS}
          title={t('learn_more_about', { section: t('operation_statistics') })} />
      </React.Fragment>
    )

    return (
      <div>
        {asset &&
          <div className="flex-row">
            <main>
              {conditionSections}
              {operatingSections}
            </main>
          </div>
        }
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    assetsByCustomerId: state.assetsByCustomerId,
    filters: state.filters,
    translations: state.translations,
    [DLTYPE_MEASUREMENTS]: state[DLTYPE_MEASUREMENTS],
    [DLTYPE_LATEST_MEASUREMENTS]: state[DLTYPE_LATEST_MEASUREMENTS],
    [DLTYPE_TIMEFRAME_SUMMARY]: state[DLTYPE_TIMEFRAME_SUMMARY]
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setFilter: (filter, value) => dispatch(setFilter(filter, value)),
    loadMeasurements: (asset, mId, year) => dispatch(loadMeasurements(asset, mId, year)),
    loadLatestMeasurements: (asset) => dispatch(loadLatestMeasurements(asset)),
    loadTimeframeSummary: (asset, sd, ed) => dispatch(loadTimeframeSummary(asset, sd, ed)),
    loadTranslations: () => dispatch(loadTranslations())
  }
}

export default translateWithPrefix('asset')(connect(mapStateToProps, mapDispatchToProps)(AssetStatistics))
