import React from 'react'
import i18n from 'i18next'
import i18nextXhrBackend from 'i18next-xhr-backend'
import config from '../config'
import { translate } from 'react-i18next'
import i18nIsoCountries from 'i18n-iso-countries'
import { languages } from '../App'

const initCountryTranslations = (i18nextInstance) => {
  // register country locales
  for (const language of Object.keys(languages)) {
    i18nIsoCountries.registerLocale(require(`i18n-iso-countries/langs/${language}.json`))
  }

  // helper functions for getting translations using ISO 3166-1 alpha-3 country codes
  // (alpha-3 country codes are used in API, so therefore not using alpha-2)
  const countryCodes = Object.keys(i18nIsoCountries.getAlpha3Codes())
  const getCountryTranslations = (lang) =>
    countryCodes.reduce((acc, cur) => ({ ...acc, [cur]: i18nIsoCountries.getName(cur, lang) }), {})

  Object.keys(languages).forEach((lang) => {
    i18nextInstance.addResourceBundle(lang, 'countries', getCountryTranslations(lang))
  })
}

const initOptions = (options) => ({
  lng: 'en',
  fallbackLng: 'en',
  ns: ['statuscontrol', 'measurement-type', 'component-type'],
  defaultNS: ['statuscontrol'],
  interpolation: { escapeValue: false },
  lowerCaseLng: true,
  react: { useSuspense: false },
  debug: false,
  returnObjects: true,
  ...options,
})

export const initTranslations = (options = {}) => {
  const i18nextInstance = i18n.use(i18nextXhrBackend).init(
    initOptions({
      backend: {
        loadPath: `${config.PROCESS_LAYER_API_URL}/translation-api/translations/{{lng}}/{{ns}}`,
        customHeaders: {
          'x-api-key': config.STATUS_CONTROL_API_KEY,
        },
      },
      ...options,
    }),
  )

  initCountryTranslations(i18nextInstance)

  return i18nextInstance
}

export const translateWithPrefix = (prefix) => (Component) =>
  translate()(({ t, ...props }) => (
    <Component {...props} t={(keyword) => t(`${prefix}.${keyword}`)} tWithoutPrefix={t} />
  ))
