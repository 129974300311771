import { TIME_PERIODS } from '@kc/alphabrands-component-library'
import { PeriodType } from '../../generated/graphql'

export const getQueryVariables = (asset, utcTimeframe = {}, cacheFix = 0) => {
  const timeZone = asset.location.tz || 'UTC'
  return {
    variables: {
      dlid: asset.id,
      startTime: (utcTimeframe.startTime + cacheFix).toString(),
      // TODO: IIFT1-2997 Fix IOT API cache
      endTime: utcTimeframe.endTime.toString(),
      timeZone: timeZone,
      periodType: utcTimeframe.timePeriod === TIME_PERIODS.LAST_3_MONTHS ? PeriodType.Week : null
    }
  }
}
