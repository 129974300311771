import config from '../config'
import auth from './auth/auth'

const TERMS_AND_CONDITIONS_LANGUAGES = ['en', 'de']

export const getHelpLink = () => {
  // for Internal + dealer (roles: DEMAG_DEALER and DEMAG_INTERNAL, link: 'dealer')
  // for direct customer (role: DEMAG_CUSTOMER, link: 'direct')
  // for indirect customers (role: DEMAG_DEALER_CUSTOMER, folder: 'indirect')
  const cidmRole = auth.getCidmRole()
  switch (cidmRole) {
    case 'DEMAG_CUSTOMER':
      return '/help/en/direct/'
    case 'DEMAG_DEALER_CUSTOMER':
      return '/help/en/indirect/'
    default: // default and also for DEMAG_DEALER, DEMAG_INTERNAL
      return '/help/en/dealer/'
  }
}

const getAvailableTermAndConditionsLanguage = () => {
  return TERMS_AND_CONDITIONS_LANGUAGES.find((language) => language === auth.getLanguage()) || 'en'
}

export const getTermsAndConditionsLink = () => {
  return `${config.TERMS_AND_CONDITIONS_DOMAIN}/${getAvailableTermAndConditionsLanguage()}.html`
}
