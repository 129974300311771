import {faChevronLeft} from '@fortawesome/pro-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import * as React from 'react'
import {withRouter} from 'react-router-dom'

import './Backlink.css'


class Backlink extends React.Component {
  render() {
    return (
      <div>
        <div className="back-link-wrap">
          <button className="back-link button button--ghost" onClick={this.props.history.goBack}>
            <FontAwesomeIcon icon={faChevronLeft}/>
            {this.props.text}
          </button>
        </div>
      </div>
    )
  }
}

Backlink.defaultProps = {
  text: 'Back',
}

export default withRouter(Backlink)
