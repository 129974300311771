import { filterActions } from '../actions/constants'

const stateWithNormalizedFilters = (state) => {
  const newState = { ...state }
  if (newState.filters.time) {
    if (newState.filters.time.startDate) {
      newState.filters.time.startDate = new Date(newState.filters.time.startDate)
    }
    if (newState.filters.time.endDate) {
      newState.filters.time.endDate = new Date(newState.filters.time.endDate)
    }
  }
  return newState
}

export default (state, action) => {
  switch (action.type) {
    case filterActions.ADD_FILTER:
      let addFilters = Object.assign({}, state.filters)

      addFilters[action.filter] = !addFilters[action.filter]
        ? [action.value]
        : [...state.filters[action.filter]].concat(action.value)

      return stateWithNormalizedFilters({
        ...state,
        filters: addFilters
      })

    case filterActions.REMOVE_FILTER:
      return stateWithNormalizedFilters({
        ...state,
        filters: {
          ...state.filters,
          [action.filter]: state.filters[action.filter].filter(f => f !== action.value)
        }
      })

    case filterActions.SET_FILTER:
      let filters = Object.assign({}, state.filters)

      if (action.value) {
        filters[action.filter] = action.value
      } else {
        delete filters[action.filter]
      }

      return stateWithNormalizedFilters({
        ...state,
        filters
      })

    case filterActions.SET_FILTERS:
      return stateWithNormalizedFilters({
        ...state,
        filters: action.filters
      })

    default:
      return state
  }
}
