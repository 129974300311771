import * as React from 'react'
import { withRouter } from 'react-router-dom'
import { I18nextProvider } from 'react-i18next'
import i18next from 'i18next'

import 'babel-polyfill'

import './index.css'
import './styles/buttons.css'
import './styles/fonts.css'
import './styles/forms.css'
import './styles/structure.css'
import './styles/tables.css'
import Header from './components/Header/Header'
import MessageBox from './components/MessageBox/MessageBox'
import { initTranslations } from './translations/initTranslations'

// export languages
export const languages = {
  'en': 'English',
  'de': 'Deutsch',
  'pt': 'Português',
  'fr': 'Français',
  'es': 'Español',
  'it': 'Italiano'
}

initTranslations()

class App extends React.Component {
  render() {
    return (
      <I18nextProvider i18n={i18next}>
        <div className="App">
          <Header />
          <MessageBox />
          {this.props.children}
        </div>
      </I18nextProvider>
    )
  }
}

export default withRouter(App)
