import * as React from 'react'
import { connect } from 'react-redux'
import { format, endOfDay, startOfDay } from 'date-fns'
import 'react-day-picker/lib/style.css'
import { translateWithPrefix } from '../../translations/initTranslations'
import { faClock } from '@fortawesome/free-regular-svg-icons'

import { setFilter } from '../../actions/common'
import './TimeFrameSelection.css'
import Select from '../Select/Select'
import { TIME_PERIOD, DEFAULT_TIME_FILTER } from '../../lib/timeframe-constants'
import { mapTimePeriodToTimeRange } from '../../lib/timeframe-helpers'
import DayPicker from '../../components/DayPicker/DayPicker'

class TimeFrameSelection extends React.Component {
  constructor(props) {
    super(props)
    this.handlePeriodChange = this.handlePeriodChange.bind(this)
    this.onDayChange = this.onDayChange.bind(this)

    this.periods = [
      {
        label: props.t('last_24_hours'),
        value: TIME_PERIOD.LAST_24_HOURS
      },
      {
        label: props.t('last_7_days'),
        value: TIME_PERIOD.LAST_7_DAYS
      },
      {
        label: props.t('last_30_days'),
        value: TIME_PERIOD.LAST_30_DAYS
      },
      {
        label: props.t('last_6_months'),
        value: TIME_PERIOD.LAST_6_MONTHS,
      },
      {
        label: props.t('last_12_months'),
        value: TIME_PERIOD.LAST_12_MONTHS
      }
    ]
  }

  getTimeFilter() {
    const { filters } = this.props
    return (filters || {}).time || {}
  }

  setTimeFilterValue(timeFilter = {}) {
    this.props.setTimeFilter(timeFilter)
  }

  handlePeriodChange(selectedOption) {
    if (this.getTimeFilter().selection !== selectedOption.value) {
      this.setTimeFilterValue({ selection: selectedOption.value })
    }
  }

  onDayChange(selectedDay, type) {
    const timeFilter = {
      ...mapTimePeriodToTimeRange(this.getTimeFilter()),
      selection: TIME_PERIOD.CUSTOM,
      [type]: type === 'endDate' ? endOfDay(selectedDay) : startOfDay(selectedDay)
    }
    this.setTimeFilterValue(timeFilter)
  }

  resetTimeframeSelect() {
    this.setTimeFilterValue(DEFAULT_TIME_FILTER)
  }

  render() {
    const { t } = this.props
    const time = this.getTimeFilter()
    const value = time.selection || TIME_PERIOD.CUSTOM
    const timeRange = mapTimePeriodToTimeRange(time)
    const from = timeRange.startDate
    const to = timeRange.endDate
    const periods = this.periods.concat(time.selection === TIME_PERIOD.CUSTOM
      ? [
          {
            label: t('custom'),
            value: TIME_PERIOD.CUSTOM
          }
        ]
      : []
    )

    return (
      <div className="yd-timeframe-container">
        <div className="yd-timeframe-selection">
          <Select
            placeholder={t('time_frame')}
            isMulti={false}
            isClearable={false}
            isSearchable={false}
            onChange={(selectedPeriod) => this.handlePeriodChange(selectedPeriod)}
            options={periods}
            value={periods.find(p => p.value === value)}
            selectIcon={faClock}
          />
        </div>
        <div className="yd-timeframe-date-picker">
          <DayPicker
            value={from}
            placeholder={t('start_date')}
            formatDate={(date) => format(date, 'MMM d, yyyy')}
            onDayChange={(selectedDay) => this.onDayChange(selectedDay, 'startDate')}
            inputProps={{ readOnly: true }}
            dayPickerProps={{
              showWeekNumbers: true,
              firstDayOfWeek: 1,
              selectedDays: [from, { from, to }],
              disabledDays: { after: to },
              modifiers: { highlighted: from }
            }}
          />
        </div>
        <div className="yd-timeframe-date-picker">
          <DayPicker
            value={to}
            placeholder={t('end_date')}
            formatDate={(date) => format(date, 'MMM d, yyyy')}
            onDayChange={(selectedDay) => this.onDayChange(selectedDay, 'endDate')}
            inputProps={{ readOnly: true }}
            dayPickerProps={{
              showWeekNumbers: true,
              firstDayOfWeek: 1,
              selectedDays: [from, { from, to }],
              disabledDays: { before: from },
              modifiers: { highlighted: to }
            }}
          />
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    filters: state.filters
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setTimeFilter: value => dispatch(setFilter('time', value))
  }
}

export default translateWithPrefix('filter')(connect(mapStateToProps, mapDispatchToProps)(TimeFrameSelection))
