export const uiActions = {
  DISABLE_MAIN_MENU: 'DISABLE_MAIN_MENU',
  TOGGLE_CUSTOMER_SELECTION: 'TOGGLE_CUSTOMER_SELECTION',
  TOGGLE_MAIN_MENU: 'TOGGLE_MAIN_MENU',
  TOGGLE_TIMEFRAME_SELECTION: 'TOGGLE_TIMEFRAME_SELECTION',
  MERGE_ACCORDION_STATES: 'MERGE_ACCORDION_STATES'
}

export const messageActions = {
  ADD_MESSAGE: 'ADD_MESSAGE',
  REMOVE_MESSAGE: 'REMOVE_MESSAGE'
}

export const loginActions = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  LOGOUT: 'LOGOUT',
  CHANGE_PROFILE_REQUEST: 'CHANGE_PROFILE_REQUEST',
  CHANGE_PROFILE_SUCCESS: 'CHANGE_PROFILE_SUCCESS',
  CHANGE_PROFILE_FAILURE: 'CHANGE_PROFILE_FAILURE'
}

export const filterActions = {
  ADD_FILTER: 'ADD_FILTER',
  REMOVE_FILTER: 'REMOVE_FILTER',
  SET_FILTER: 'SET_FILTER',
  SET_FILTERS: 'SET_FILTERS'
}

export const YD_USER_DATA_KEY = 'YD_USER_DATA'

export const dataLakeActions = {
  DATALAKE_REQUEST_ADD: 'DATALAKE_REQUEST_ADD',
  DATALAKE_REQUEST_REMOVE: 'DATALAKE_REQUEST_REMOVE',
  SET_DATALAKE_DATA: 'SET_DATALAKE_DATA',
  SET_DATALAKE_ERROR: 'SET_DATALAKE_ERROR',
}

export const customerActions = {
  GET_CUSTOMERS: 'GET_CUSTOMERS',
  SET_CUSTOMERS: 'SET_CUSTOMERS',
  SEARCH_CUSTOMERS: 'SEARCH_CUSTOMERS',
  GET_ASSETS_FOR_CUSTOMER: 'GET_ASSETS_FOR_CUSTOMER',
  SET_ASSETS_FOR_CUSTOMER: 'SET_ASSETS_FOR_CUSTOMER',
  SET_SEARCH_CUSTOMERS: 'SET_SEARCH_CUSTOMERS'
}

export const storageActions = {
  SET_FAVORITE_CUSTOMER_IDS: 'SET_FAVORITE_CUSTOMER_IDS'
}
