import reduceReducers from 'reduce-reducers'
import promiseMiddleware from 'redux-promise-middleware'
import {applyMiddleware, createStore, compose} from 'redux'
import thunk from 'redux-thunk'
import {createBrowserHistory} from 'history'
import {connectRouter, routerMiddleware} from 'connected-react-router'

import appReducers from './reducers'
import {appDefaultState} from './reducers'
import {authMiddleware, qsMiddleware} from './middleware'

export const history = createBrowserHistory()

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const makeStore = (addonReducers = []) => {
  return createStore(
    connectRouter(history)(reduceReducers(...appReducers, ...addonReducers)),
    appDefaultState,
    composeEnhancers(applyMiddleware(
      routerMiddleware(history),
      thunk,
      promiseMiddleware(),
      authMiddleware,
      qsMiddleware,
    ))
  )
}

export const store = makeStore()

if (process.env.NODE_ENV !== 'production') {
  // Quick access to store during development
  window.pmStore = store
}
