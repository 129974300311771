import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { format, subMonths } from "date-fns"

import { buildTrendChart } from './trendChart'
import { dateToMeasurementMonthlyKey, measurementKeyToDate } from '../../actions/measurements_v2'
import { formatDate } from '../../lib/date-helpers'

const chartStyles = {
  yAxis: {
    title: {
      align: 'low',
      textAlign: 'left',
      rotation: 0,
      x: 0,
      y: 30
    }
  },
  lineColors: ['#149FF4', '#26E2E6', '#333333', '#4830D6', '#6597AA'],
  columnColors: ['#486280'],
  useSameYAxis: true
}

const chartOptions = {
  valueDecimals: 1,
  markerDisabled: true,
  xLabelFormatter: function () {
    return format(measurementKeyToDate(this.value || this.x), 'MMM')
  },
  yLabelFormatter: function () {
    return this.value
  }
}

const mapSeries = (input, dates) => {
  return input.map((serie) => {
    const firstDate = measurementKeyToDate(serie.trend.mc_first)
    const output = dates.reduce((data, dk) => {
      let dv = 0

      const date = measurementKeyToDate(dk)
      if (date >= firstDate) {
        const pDate = subMonths(date, 1)
        const v = serie.trend.mc[dk]
        let pv = serie.trend.mc[dateToMeasurementMonthlyKey(pDate)]
        if (!pv && pDate < firstDate) {
          pv = serie.trend.dc[serie.trend.dc_first]
        }
        dv = v > pv ? v - pv : 0
      }

      data.push({
        y: dv / 3600,
        name: formatDate(measurementKeyToDate(dk), 'MMM yyyy'),
      })
      return data
    }, [])
    return {
      name: serie.name,
      data: output
    }
  })
}

export default class OperatingChart extends React.Component {
  mapDataToSeries() {
    const data = {
      categories: [],
      series: [],
      columnSeries: []
    }

    if (this.props.data) {
      data.categories = this.props.range
      data.series = mapSeries(this.props.data.filter((s) => s.type !== 'Crane'), this.props.range)
      data.columnSeries = mapSeries(this.props.data.filter((s) => s.type === 'Crane'), this.props.range)
    }

    return data
  }

  render() {
    const data = this.mapDataToSeries()
    const chart = buildTrendChart(data, this.props.titles || [], chartOptions, chartStyles)

    return (<React.Fragment>
      <HighchartsReact highcharts={Highcharts} options={chart} />
    </React.Fragment>)
  }
}
