export const findWorstConditionOfComponent = (
  componentFleetConditionStatus
) => {
  if (componentFleetConditionStatus == null) {
    return null
  }

  let worstCondition

  for (let kpi in componentFleetConditionStatus) {
    let fleetConditionValue =
      componentFleetConditionStatus[kpi].latestPercentage

    if (
      fleetConditionValue != null &&
      (worstCondition == null ||
        fleetConditionValue < worstCondition.latestPercentage)
    ) {
      worstCondition = componentFleetConditionStatus[kpi]
    }
  }

  return worstCondition
}

export const findWorstComponentCondition = (fleetConditionStatus) => {
  const worstComponentConditions = fleetConditionStatus.map(
    (fleetConditionByComponent) =>
      findWorstConditionOfComponent(fleetConditionByComponent)
  )

  const worstComponentCondition = worstComponentConditions.sort(
    (a, b) => a.latestPercentage - b.latestPercentage
  )[0]

  return {
    wc_30: {
      vid: worstComponentCondition.vid,
      v: worstComponentCondition.latestPercentage,
      cn: worstComponentCondition.cn,
    },
  }
}
