import { messageActions } from './constants'

export const addMessage = (text, status = 'info') => {
  return {
    message: {
      text,
      status,
      timestamp: Date.now()
    },
    type: messageActions.ADD_MESSAGE
  }
}

export const removeMessage = index => {
  return {
    index,
    type: messageActions.REMOVE_MESSAGE
  }
}
