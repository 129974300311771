import { get, set } from 'lodash'
import {getEquipmentId, isEcu} from './asset-helpers'

export const DLTYPE_TRANSLATIONS = 'translations'
export const DLTYPE_MEASUREMENTS = 'measurements'
export const DLTYPE_LATEST_MEASUREMENTS = 'latestMeasurements'
export const DLTYPE_MEASUREMENTS_SUMMARY = 'measurementsSummary'
export const DLTYPE_TIMEFRAME_SUMMARY = 'timeframeSummary'
export const DLTYPE_ALERTS = 'alerts'
export const DLTYPE_EVENT_TRANSLATIONS = 'event-translations'

export const getRequestUrl = (dltype, asset, params = {}) => {
  const { mId, year, sd, ed, lang, eventId, versionId } = params
  const id = asset ? getEquipmentId(asset) : params.id
  const craneType = isEcu({ id })? 'ecu' : 'sc'

  // check datalake version, measurements are relocated on v3
  const { customer, location } = (asset || {})
  const prefix = customer && location ? `customers/${customer.id}/locations/${location.id}/assets/${id}` : ''

  switch (dltype) {
    case DLTYPE_TRANSLATIONS:
      return `translation-${id}`
    case DLTYPE_MEASUREMENTS:
      if (mId && year) {
        return `${prefix}/measurements/${mId}/${year}`
      } else if (mId) {
        return `${prefix}/measurements/${mId}`
      }
      return `${prefix}/measurements`
    case DLTYPE_LATEST_MEASUREMENTS:
      return `${prefix}/measurements/latest`
    case DLTYPE_MEASUREMENTS_SUMMARY:
      return `${prefix}/measurements/condition-summary`
    case DLTYPE_TIMEFRAME_SUMMARY:
      return `${prefix}/measurements/timeframe-summary?startdate=${sd}&enddate=${ed}`
    case DLTYPE_ALERTS:
      return `${prefix}/events/v3?startdate=${sd}&enddate=${ed}&timezone=UTC`
    case DLTYPE_EVENT_TRANSLATIONS:
      return `${prefix}/event-translations/${craneType}/${eventId}/${lang}/${versionId}`
    default:
      return null
  }
}

// Path constructor for lodash get/set function pair
const getPaths = (asset, params = {}) => {
  const { mId, year, sd, ed, eventId, lang, versionId } = params
  const id = asset ? getEquipmentId(asset) : params.id

  if (id && mId && year) {
    return { store: `${id}.${mId}.y${year}`, data: `${id}.${mId}.y${year}` }
  } else if (id && mId) {
    return { store: `${id}.${mId}`, data: `${id}.${mId}` }
  } else if (id && sd && ed) {
    return { store: `${id}.${sd}.${ed}`, data: `${id}` }
  } else if (eventId && lang && versionId) {
    // Data path is undefined, because we store whole object
    return { store: `${eventId}.${lang}.${versionId}` }
  } else {
    return { store: `${id}`, data: `${id}` }
  }
}

export const isLoading = (state) => {
  const { pendingUrls } = state
  return Array.isArray(pendingUrls) && pendingUrls.length > 0
}

export const isLoadingPending = (state, dltype, asset, params) => {
  const url = getRequestUrl(dltype, asset, params)
  return (state.pendingUrls || []).includes(url)
}

export const isLoaded = (state, dltype, asset, params) => {
  const data = getDataFromState(state, dltype, asset, params)
  return data ? true : false
}

export const getDataFromState = (state, dltype, asset, params) => {
  return get(state[dltype], getPaths(asset, params).store)
}

export const setDataToState = (state, action) => {
  const { dltype, asset, data } = action
  const newState = { ...state, [dltype]: { ...state[dltype] } }
  const path = getPaths(asset, action)
  // A bit kinky stuff with exceptions for array etc, loosly coupled with getRequestUrl
  // could use a bit refactoring to define request urls and store data paths possibly with
  // response data destructure functions
  if (data === null) {
    // usually result of 404
    set(newState[dltype], path.store, null)
  } else if (Array.isArray(data)) {
    set(newState[dltype], path.store, data)
  } else {
    const id = asset ? getEquipmentId(asset) : action.id
    if (path.data) {
      set(newState[dltype], path.store, { ...get({ [id]: { ...data } }, path.data) })
    } else {
      set(newState[dltype], path.store, data )
    }
  }
  return newState
}

export const setErrorToState = (state, action) => {
  const { dltype, asset } = action
  const newState = { ...state, [dltype]: { ...state[dltype] } }
  const path = getPaths(asset, action)
  set(newState[dltype], path.store, { error: true })
  return newState
}
