import * as React from 'react'
import { connect } from 'react-redux'
import { Link, matchPath } from 'react-router-dom'

import { translateWithPrefix } from '../../translations/initTranslations'

import { setFilter } from '../../actions/common'
import PrivateRoute from '../../components/PrivateRoute/PrivateRoute'
import Toolbar from '../../components/Toolbar/Toolbar'
import Alerts from './Alerts'
import StatisticsSelector from './StatisticsSelector'
import Info from './Info'
import { DEFAULT_TIME_FILTER } from '../../lib/timeframe-constants'
import {getEquipmentId, isEcu} from '../../lib/asset-helpers'
import TimeFrameSelection from '../../components/TimeFrameSelection/TimeFrameSelection'
import './Asset.css'

class Asset extends React.Component {

  componentDidMount() {
    // check that customer id is set as filter
    const { filters, match, setFilter } = this.props
    const customerIdFilter = filters.customerId || []
    const { customerId } = match.params
    if (!customerIdFilter.includes(customerId)) {
      setFilter('customerId', [...customerIdFilter, customerId])
    }
    // Wanted by Demag: reset time selection whenever asset selection changes
    setFilter('time', DEFAULT_TIME_FILTER)
  }

  getAsset() {
    const { assetsByCustomerId, match } = this.props
    const params = (match || {}).params || {}
    const { assetId, customerId } = params
    return Array.isArray(assetsByCustomerId[customerId])
      ? assetsByCustomerId[customerId].find(a => getEquipmentId(a) === assetId)
      : null
    }

  getSelectOptions() {
    const { assetsByCustomerId, match } = this.props
    const params = (match || {}).params || {}
    const { assetId, customerId } = params
    return Array.isArray(assetsByCustomerId[customerId])
      ? assetsByCustomerId[customerId].map(a => { return { value: a.id, label: a.ni, isDisabled: a.id === assetId }})
      : []
  }

  renderPageTabs(asset) {
    const { t, match, location } = this.props
    const params = (match || {}).params || {}
    const { locationId, customerId } = params

    if (!asset) {
      return null
    }

    const urlPrefix = `/customer/${customerId}/location/${locationId}/asset/${getEquipmentId(asset)}`
    const pages = ['statistics', 'alerts', 'info']

    const tabs = pages.map(page => ({
      title: t(page),
      link: `${urlPrefix}/${page}`
    }))

    const isShowingTimeframeSelectionForDMR =
      (!isEcu(asset) ||
        !matchPath(
          location.pathname,
          "/customer/:customerId/location/:locationId/asset/:assetId/statistics"
        )
      ) && !matchPath(
        location.pathname,
        "/customer/:customerId/location/:locationId/asset/:assetId/info"
      )

    return (
      <main className="yd-statistics-header">
        <ul className="yd-statistics-tabs">
          {
            tabs.map(tab => ((
              <li key={tab.title} className={location.pathname.includes(tab.link) ? 'yd-button--selected' : ''}>
                <Link to={tab.link} className="button">{tab.title}</Link>
              </li>
            )))
          }
        </ul>
        {
          isShowingTimeframeSelectionForDMR &&
            <div className="yd-statistics-timeframe">
              <TimeFrameSelection />
            </div>
        }
      </main>
    )
  }

  render() {
    const asset = this.getAsset()
    const { t } = this.props
    const selectProps = {
      name: "toolbar-select-asset",
      onChange: (selectedAsset) => {
        this.props.history.push(`/customer/${asset.customer.id}/location/${asset.location.id}/asset/${selectedAsset.value}/statistics`)
      },
      value: '',
      options: this.getSelectOptions(),
      components: { DropdownIndicator: () => null },
      placeholder: t('search_asset')
    }

    return (
      <div>
        <Toolbar
          title={asset ? asset.ni : ''}
          titleDetails={asset ? asset.ty : ''}
          selectProps={selectProps}
        />

        {this.renderPageTabs(asset)}
        <PrivateRoute
          path="/customer/:customerId/location/:locationId/asset/:assetId/alerts"
          render={props => <Alerts {...props} asset={asset} />}
        />
        <PrivateRoute
          path="/customer/:customerId/location/:locationId/asset/:assetId/statistics/:viewId?"
          render={props => <StatisticsSelector {...props} asset={asset} />}
        />
        <PrivateRoute
          path="/customer/:customerId/location/:locationId/asset/:assetId/info"
          render={props => <Info {...props} asset={asset} />}
        />

      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    assetsByCustomerId: state.assetsByCustomerId,
    filters: state.filters
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setFilter: (filter, value) => dispatch(setFilter(filter, value)),
  }
}

export default translateWithPrefix('asset')(connect(mapStateToProps, mapDispatchToProps)(Asset))
