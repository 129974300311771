import * as React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { translateWithPrefix } from '../../translations/initTranslations'

import { setLanguage } from '../../actions/user'
import { fetchCustomers } from '../../actions/customers'
import { toggleCustomerSelection, toggleTimeFrameSelection } from '../../actions/common'

import auth from '../../lib/auth/auth'
import HeaderView from './HeaderView'
import CustomerSelection from '../CustomerSelection/CustomerSelection'
import CustomerSelectionHeader from '../CustomerSelection/CustomerSelectionHeader'

import { getHelpLink, getTermsAndConditionsLink } from '../../lib/link-helpers'

import { languages } from '../../App'

import './Header.css'

class Header extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      customersFetched: false,
    }
    this.handleLanguageChange = this.handleLanguageChange.bind(this)
    this.getCustomers(props)
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.props.isCustomerSelectionVisible && this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.props.toggleCustomerSelection(false)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.getCustomers(nextProps)
  }

  getCustomers(props) {
    const { auth, customers, fetchCustomers, customerSearch } = props
    if (auth.isAuthenticated && !customerSearch.isLoading && !customers.length && !this.state.customersFetched) {
      fetchCustomers()
      setTimeout(() => this.setState({ ...this.state, customersFetched: true }))
    }
  }

  handleLanguageChange(selectedLanguage) {
    const { setLanguage } = this.props
    setLanguage(selectedLanguage.value)
  }

  render() {
    const { t, customers, location, profile, updatingProfile } = this.props
    const language = auth.getLanguage()
    return (
      <HeaderView
        t={t}
        showActivation={auth.getCidmRole() !== 'DEMAG_DEALER_CUSTOMER'}
        activationLink="/activation"
        helpLink={getHelpLink()}
        termsAndConditionsLink={getTermsAndConditionsLink()}
        notificationSettingsLink="/notification/#/preferences"
        profile={profile}
        updatingProfile={updatingProfile}
        language={language}
        languages={languages}
        handleLanguageChange={this.handleLanguageChange.bind(this)}
      >
        {this.props.auth.isAuthenticated ? (
          <React.Fragment>
            <CustomerSelectionHeader
              t={t}
              customers={customers}
              location={location}
              toggleCustomerSelection={() => this.props.toggleCustomerSelection(!this.props.isCustomerSelectionVisible)}
            />
            <CustomerSelection innerRef={this.wrapperRef} />
          </React.Fragment>
        ) : null
        }
      </HeaderView>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    customers: state.customers,
    profile: state.profile,
    updatingProfile: state.updatingProfile,
    customerSearch: state.customerSearch,
    isCustomerSelectionVisible: state.isCustomerSelectionVisible,
    isTimeFrameSelectionVisible: state.isTimeFrameSelectionVisible,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setLanguage: (selectedLanguage) => dispatch(setLanguage(selectedLanguage)),
    fetchCustomers: () => dispatch(fetchCustomers()),
    toggleCustomerSelection: isVisible => dispatch(toggleCustomerSelection(isVisible)),
    toggleTimeFrameSelection: isVisible => dispatch(toggleTimeFrameSelection(isVisible)),
  }
}

export default translateWithPrefix('menu')(withRouter(connect(mapStateToProps, mapDispatchToProps)(Header)))
