import * as React from 'react'

function colorValue_under_10_30(index, value, color) {
  if (index === 0) {
    if (color instanceof Array) {
      if (value <= 0.1) {
        return color[2]
      }
      if (value <= 0.3) {
        return color[1]
      }
      return color[0]
    }
  }
  return color
}

function colorValue_over_100_110(index, value, color) {
  if (index === 0) {
    if (color instanceof Array) {
      if (value >= 1.1) {
        return color[2]
      }
      if (value >= 1.0) {
        return color[1]
      }
      return color[0]
    }
  }
  return color
}

const WHEEL_TYPES = {
  "wheel": {
    spread: 0.015,
    rotation: 0,
    max: 1.0,
    colorValue: colorValue_under_10_30,
    bgColor: "#f0f0f0",
    innerFill: "#fefefe",
  },
  "meter": {
    spread: 0.25,
    rotation: 0.5,
    max: 1.0,
    colorValue: colorValue_over_100_110,
    bgColor: "#f0f0f0",
  },
  "meter-overload": {
    spread: 0.25,
    rotation: 0.5,
    max: 2.0,
    bgColor: "#f0f0f0",
  }
}

export default class DonutValue extends React.Component {
  drawArc(cx, cy, sv, ev, rotation) {
    // Offset to start from top
    sv -= 0.25 + rotation
    ev -= 0.25 + rotation + 0.0000001 // hack for invisible full arc
    let sx = Math.cos(sv * Math.PI * 2) * cx
    let sy = Math.sin(sv * Math.PI * 2) * cy
    let ex = Math.cos(ev * Math.PI * 2) * cx + cx
    let ey = Math.sin(ev * Math.PI * 2) * cy + cy
    return [
      "M", cx, cy,
      "l", sx, sy,
      "A", cx, cy, 0, Math.abs(sv - ev) > 0.5 ? 1 : 0, 1, ex, ey,
      "L", cx, cy,
      "z",
    ].join(" ");
  }

  draw(cx, cy, vals, colors, options) {
    let arcs = []
    let arcsDebug = []
    let cv = options.spread * 0.5
    let sv = 0
    const max = options.max || 1.0
    // Build arcs
    for (let v of vals) {
      // cap value
      v = Math.min(Math.max(0, v), max)
      sv += v
      if (sv > max) {
        v = max - sv
        sv = max
      }
      v *= 1 / max
      v *= (1 - options.spread)
      arcs.push(this.drawArc(cx, cy, cv, cv + v, options.rotation || 0))
      arcsDebug.push(v)
      cv += v
    }
    // background fill arc for remainder
    if (max - sv > 0) {
      let v = max - sv
      if (v >= 0) {
        v *= 1 / max
        v *= (1 - options.spread)
        arcs.push(this.drawArc(cx, cy, cv, cv + v, options.rotation || 0))
      }
    } else {
      arcs.push(null)
    }

    // Create path elements
    let paths = []
    let id = 0
    for (let arc of arcs) {
      if (!arc) {
        continue
      }
      let color = colors[id % colors.length]
      if (id === arcs.length - 1) {
        color = options.bgColor
      } else {
        if (options.colorValue) {
          color = options.colorValue(id, vals[id], color)
        } else {
          color = color instanceof Array ? color[0] : color
        }
      }
      paths.push(<path debug={arcsDebug[id]} key={id} id={(this.props.id || 'donut') + '-' + id} d={arc} fill={color} stroke="none" />)
      id++
    }
    return paths
  }

  render() {

    const options = { ...WHEEL_TYPES[this.props.type || 'wheel'] }
    let v = this.props.values || []
    let cx = this.props.width * 0.5
    let cy = this.props.height * 0.5
    let strokeSize = cx * 0.25 // static default
    let r = cx - strokeSize
    const colors = [["#72c90c", "#ff9900", "#f71c1c"], "#333333"]

    return (
      <svg version="1.1" width={this.props.width} height={this.props.height}>
        <style>
          {".valueText {"}{this.props.valueTextStyle || "font: " + 1 * (this.props.width / 100) + "em \"Istok Web\", sans-serif; fill: black;"}{"}"}
        </style>
        {this.draw(cx, cy, v, this.props.colors || colors, options)}
        <circle cx={cx} cy={cy} r={r * 0.97} stroke="#ffffff" strokeWidth={r * 0.03} fill={this.props.innerFill || options.innerFill || "#ffffff"} />
        {/* IE does not support alignment-baseline so therefore replaced
              alignmentBaseline="middle"
            with
               x="50%" y="50%" dy="0.35em"
        */}
        <text textAnchor="middle" className="valueText" x="50%" y="50%" dy="0.35em">
          {this.props.formatValue ? this.props.formatValue(v[0]) : v[0]}
        </text>
      </svg>
    )
  }
}
