import { addDays, endOfDay, startOfDay, subDays, subMonths } from 'date-fns'
import { TIME_PERIOD } from './timeframe-constants'

const mapLast24Hours = () => {
  const endDate = new Date()
  return { startDate: subDays(endDate, 1), endDate: endDate }
}

const mapLastDays = (days) => {
  const tomorrow = addDays(new Date(), 1)
  return { startDate: startOfDay(subDays(tomorrow, days)), endDate: endOfDay(subDays(tomorrow, 1)) }
}

const mapLastMonths = (months) => {
  const tomorrow = addDays(new Date(), 1)
  return { startDate: startOfDay(subMonths(tomorrow, months)), endDate: endOfDay(subDays(tomorrow, 1)) }
}

export const mapTimePeriodToTimeRange = (timeFilter = {}) => {
  const { startDate, endDate } = timeFilter
  switch (timeFilter.selection) {
    case TIME_PERIOD.LAST_24_HOURS: return mapLast24Hours()
    case TIME_PERIOD.LAST_7_DAYS: return mapLastDays(7)
    case TIME_PERIOD.LAST_30_DAYS: return mapLastDays(30)
    case TIME_PERIOD.LAST_6_MONTHS: return mapLastMonths(6)
    case TIME_PERIOD.LAST_12_MONTHS: return mapLastMonths(12)
    case TIME_PERIOD.CUSTOM: return { startDate, endDate }
    default: return null
  }
}
