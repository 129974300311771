import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faTimes
} from '@fortawesome/pro-solid-svg-icons'
import * as React from 'react'
import {connect} from 'react-redux'

import './MessageBox.css'
import {removeMessage} from '../../actions/messages'

const messageTimeoutMs = 5000 // show messages for 5s
const intervalMs = 500 // interval cycle

class MessageBox extends React.PureComponent {

  componentDidMount() {
    this.interval = setInterval(() => {
      const { messages, removeMessage } = this.props
      if (Array.isArray(messages)) {
        messages.forEach((message, index) => {
          if (Date.now() - message.timestamp > messageTimeoutMs) {
            removeMessage(index)
          }
        })
      }
    }, intervalMs)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  render() {
    const { messages, removeMessage } = this.props
    if (!messages) {
      return null
    }

    return (
      <div className="flex-row">
        <div>
          <ul className="message-box">
            {
              messages.map((message, index) => {
                return (
                  <li className={'message-box__message message-box__message--' + message.status} key={index}>
                    {message.text}
                    <button onClick={removeMessage.bind(this, index)}>
                      <FontAwesomeIcon icon={faTimes}/>
                    </button>
                  </li>
                )
              })
            }
          </ul>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  messages: state.messages
})

function mapDispatchToProps(dispatch) {
  return {
    removeMessage: index => dispatch(removeMessage(index))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageBox)
