
const defaultStyles = {
  marginTop: 15,
  lineColors: ['#149FF4', '#26E2E6', '#333333', '#4830D6', '#6597AA'],
  columnColors: ['#149FF4', '#26E2E6', '#333333', '#4830D6', '#6597AA'], // '#486280'
  legendGrayColor: '#f0f0f0',
  xAxis: {
    lineColor: '#dcdcdc',
    labelsStyle: {
      color: '#1a1a1a',
      fontSize: '16px',
      fontFamily: '\'iStok Web\', Arial'
    }
  },
  yAxis: {
    labelsStyle: {
      color: '#1a1a1a',
      fontFamily: '\'iStok Web\', Arial'
    },
    stackLabelsStyle: {
      fontFamily: '\'iStok Web\', Arial'
    },
    dwpLimit1Color: '#ff0000',
    dwpLimit2Color: '#ff8000'
  },
  plot: {
    column: {
      dataLabelsColor: '#000000',
      dataLabelsStyle: {
        fontFamily: '\'iStok Web\', Arial'
      }
    }
  }
}

const defaultOptions = {
  legendGray: false,
  stacking: false,
  labelsLeft: false
}

export const buildTrendChart = (data, axisTitles, chartOptions, chartStyles) => {
  const options = { ...defaultOptions, ...chartOptions }
  const styles = { ...defaultStyles, ...chartStyles }

  let series = data.series || []
  let columnSeries = data.columnSeries || []
  let categories = data.categories || []

  series = series.map((s, idx) => {
    return {
      type: 'line',
      yAxis: 0,
      zIndex: 10, // to ensure lines are drawn on top of columns
      color: styles.lineColors[idx % styles.lineColors.length],
      ...s
    }
  })
  columnSeries = columnSeries.map((s, idx) => {
    return {
      type: 'column',
      yAxis: styles.useSameYAxis ? 0 : 1,
      color: styles.columnColors[idx % styles.columnColors.length],
      ...s
    }
  })

  let yAxisMax = 100
  let allSeries
  if (series.length === 0 && columnSeries.length === 0) {
    allSeries = [{data: Array(12).fill(0), showInLegend: false}]
  } else {
    allSeries = series.concat(columnSeries)
    yAxisMax = undefined;
  }

  return {
    // Chart options
    chart: {
      type: 'column',
      marginTop: styles.marginTop
    },
    legend: {
      backgroundColor: options.legendGray ? styles.legendGrayColor : undefined
    },
    colors: ['#149FF4', '#26E2E6', '#333333', '#4830D6', '#6597AA'],
    title: { text: null },
    credits: {
      enabled: false
    },
    tooltip: {
      enabled: true,
      valueDecimals: options.valueDecimals ? options.valueDecimals : undefined,
      shared: true
    },
    // xAxis options
    xAxis: {
      categories: categories,
      title: { text: null },
      lineColor: styles.xAxis.lineColor,
      tickWidth: 0,
      labels: {
        style: styles.xAxis.labelsStyle,
        step: options.step,
        formatter: options.xLabelFormatter
      },
      plotLines: options.nowLine
    },
    // yAxis options
    yAxis: [
      // y-axis 1
      {
        title: {
          text: axisTitles[0] || null,
          ...styles.yAxis.title
        },
        labels: {
          style: styles.yAxis.labelsStyle,
          formatter: options.yLabelFormatter
        },
        allowDecimals: false,
        stackLabels: {
          enabled: false
        },
        max: options.lineSeriesYAxisMax ? options.lineSeriesYAxisMax : yAxisMax,
        min: options.lineSeriesYAxisMax ? 0 : undefined,
        plotLines: options.viewDwpLimits ? [{
          color: styles.yAxis.dwpLimit1Color,
          width: 1,
          value: 10
        }, {
          color: styles.yAxis.dwpLimit2Color,
          width: 1,
          value: 30
        }] : undefined
      },
      // y-axis 2
      {
        title: { text: axisTitles[1] || null },
        labels: {
          style: styles.yAxis.labelsStyle,
          formatter: options.yLabelFormatter
        },
        allowDecimals: false,
        stackLabels: {
          enabled: options.stacking ? options.stacking : false,
          crop: false,
          style: styles.yAxis.stackLabelsStyle,
          formatter: function () {
            if (this.total) {
              var val = this.total.toFixed(options.valueDecimals);
              if (val > 0) {
                return val;
              }
            }
          }
        },
        max: yAxisMax,
        opposite: options.labelsLeft ? false : true
      }
    ],
    // Data
    series: allSeries,
    // Plot options
    plotOptions: {
      column: {
        stacking: options.stacking ? options.stacking : false,
        borderWidth: 0,
        borderRadius: 1,
//        enableMouseTracking: false,
        groupPadding: 0.25,
        pointPadding: 0,
        dataLabels: {
          allowOverlap: true,
          enabled: options.stacking ? !options.stacking : true,
          crop: false,
          overflow: 'none',
          color: styles.plot.column.dataLabelsColor,
          style: styles.plot.column.dataLabelsStyle,
          formatter: function () {
            if (this.y) {
              var val = this.y.toFixed(0);
              if (val > 0) {
                return val;
              }
            }
          }
        }
      },
      series: {
        animation: false,
        showInLegend: true,
        states: {
          hover: {
            enabled: false
          }
        },
        events: {
          legendItemClick: options.legendItemClick || (() => true)
        },
        marker: {
          enabled: !options.markerDisabled,
          symbol: 'circle',
          radius: 4,
          states: {
            hover: {
              enabled: false
            },
            select: {
              enabled: true
            }
          }
        }
      }
    }
  }
}
