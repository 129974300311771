import { STATUS } from './measurement-constants'

export const getStatusClass = (status) => {
  const statusKey = Object.keys(STATUS).find(key => STATUS[key] === status)
  return statusKey ? statusKey.toLowerCase() : ''
}

export const isMeasurementValueExpired = (measurement, date) => {
  const { expiry_days } = measurement
  if (date && expiry_days !== null && expiry_days !== undefined) {
    const currentDate = new Date()
    if (((currentDate - date) / 86400000) >= expiry_days) {
      return true
    }
  }
  return false
}

export const getStatus = (value, measurement, date) => {
  if (value === Number.MAX_VALUE || value === null || value === undefined) {
    return STATUS.OFFLINE
  }
  if (!measurement) {
    return null
  }
  const { red_upper_limit, yellow_upper_limit, alert_upper_limit } = measurement
  // If date given, we can check if status is expired (value too old to be valid)
  if (isMeasurementValueExpired(measurement, date)) {
    return STATUS.OFFLINE // maybe return EXPIRED?
  }
  if (!red_upper_limit || !yellow_upper_limit) {
    return value > (alert_upper_limit || 0) && alert_upper_limit !== null ? STATUS.ALERT : STATUS.OK
  } else if (value <= red_upper_limit) {
    return STATUS.ALERT
  } else if (value <= yellow_upper_limit) {
    return STATUS.WARNING
  } else {
    return STATUS.OK
  }
}

const statusOrder = [STATUS.ALERT, STATUS.WARNING, STATUS.OK, STATUS.OFFLINE]
export const compareStatus = (statusA, statusB) => {
  const a = statusOrder.indexOf(statusA)
  const b = statusOrder.indexOf(statusB)
  if (a < b) {
    return -1
  }
  if (a > b) {
    return 1
  }
  return 0
}

export const getWorstStatus = (statusList) => {
  const sortedStatusList = [...statusList].sort(compareStatus)
  return sortedStatusList.length > 0 ? sortedStatusList[0] : ''
}

export const formattedValue = (value, unit, precisionBreak, precision) => {
  if (value === undefined || value === null) {
    return undefined
  }
  precisionBreak = precisionBreak !== undefined ? precisionBreak : 10
  const converted = convert(value, unit)
  return unit !== 'pcs'
    ? `${converted.value.toFixed(converted.value < precisionBreak && converted.value !== 0 ? precision || 1 : 0)}${converted.unit}`
    : `${value}${unit}`
}

export const formattedConditionValue = (value, precisionBreak, precision) => {
  if (value === undefined || value === null) {
    return undefined
  }
  precisionBreak = precisionBreak !== undefined ? precisionBreak : 10

  return `${value < precisionBreak && value !== 0 
    ? value.toFixed(precision || 1) 
    : Math.floor(value)}%`
}

const convert = (value, unit) => {
  switch (unit) {
    case 'm': // minutes
      return {
        value: value / 60.0,
        unit: 'h'
      }
    case 's': // seconds
      return {
        value: value / 3600.0,
        unit: 'h'
      }
    default:
      return { value, unit }
  }
}

export class Measurement {
  constructor(measurement, componentId, value) {
    this.meta = measurement
    this.componentId = componentId
    this.value = value
  }

  getId() {
    return this.meta.measurement_type_id
  }

  getStatus(date) {
    return getStatus(this.value, this.meta, date)
  }

  latest() {
    return this.value
  }

  toString() {
    return formattedValue(this.value, this.meta.unit)
  }
}
