export const stripHtml = (s) => {
  if (!s) {
    return s
  }
  // whitespace replacements
  let r = s.__html
  r = r.replace(/(<br>)/ig, ' ')
  // html strip
  r = r.replace(/(<([^>]+)>)/ig, '')
  return r
}
