import * as React from 'react'
import { matchPath } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons'

import '../Header/Header.css'

const enableCustomerSelectionPaths = ['/overview']

const CustomerSelectionHeader = ({ t, customers, location, toggleCustomerSelection }) => (
  <ul className="yd-header__selections">
    {customers.length > 1 && (
      <li className="yd-header__customer-selection">
        <button
          className="button button--small button--ghost"
          disabled={
            !enableCustomerSelectionPaths.find((path) => matchPath(location.pathname, { path }))
          }
          onClick={toggleCustomerSelection}
        >
          <FontAwesomeIcon icon={faMapMarkerAlt} size="lg"/>{' '}
          <span className="yd-header__selection-text">{t('select_customer')}</span>
        </button>
      </li>
    )}
  </ul>
)

export default CustomerSelectionHeader
