import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import { store } from './store'
import Root from './Root'
import { YD_USER_DATA_KEY } from './actions/constants'
import { setLanguage } from './actions/user'
import { setFilters, resetFilters } from './actions/common'
import { parseQueryString } from './lib/query-string'
import { setFavoriteCustomerIds } from './actions/storage'
import auth from './lib/auth/auth'

// favorite customers
const userData = localStorage.getItem(YD_USER_DATA_KEY)
if (userData) {
  try {
    const favoriteCustomerIds = JSON.parse(userData).favoriteCustomerIds
    if (favoriteCustomerIds) {
      store.dispatch(setFavoriteCustomerIds(favoriteCustomerIds))
    }
  } catch (err) {
    // ignore invalid user in storage
  }
}

// language
const language = auth.getLanguage()
if (language) {
  store.dispatch(setLanguage(language))
}

// filters
const state = store.getState()
const { router } = state
const filters = parseQueryString(router.location.search) || {}
delete filters.redirect
store.dispatch(Object.keys(filters).length > 0 ? setFilters(filters) : resetFilters())

ReactDOM.render(
  <Provider store={store}>
    <Root />
  </Provider>,
  document.getElementById('root')
)
// registerServiceWorker()

